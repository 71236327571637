import React from 'react';
import { combineReducers } from 'redux';
import CalendarProcessor from '../../helpers/calendarProcessor';
import { localToObject } from '../../helpers';
import arrayMove from 'array-move';
// ACCOUNTS START ======================================================
const listVertical = (state = {}, action) => {
    if (action.type == 'LIST_VERTICAL') {
        return action.payload;
    } else {
        return state
    }
};

const listVerticalAccount = (state = {}, action) => {
    if (action.type == 'LIST_VERTICAL_ACCOUNT') {
        return action.payload;
    } else {
        return state
    }
};

const listAccount = (state = {}, action) => {
    if (action.type == 'LIST_ACCOUNT') {
        return action.payload;
    } else {
        return state
    }
};

const listAccountSocialMedia = (state = {}, action) => {
    if (action.type == 'LIST_ACCOUNT_SOCIAL_MEDIA') {
        return action.payload;
    } else {
        return state
    }
};

const selectVertical = (state = {}, action) => {
    if (action.type == 'SELECT_VERTICAL') {
        return action.payload;
    } else {
        return state;
    }
};

const selectAccount = (state = {}, action) => {
    if (action.type == 'SELECT_ACCOUNT') {
        return action.payload;
    } else {
        return state;
    }
};

const checkedAccounts = (state = {}, action) => {
    if (action.type == 'CHECKED_ACCOUNT') {
        return action.payload;
    } else {
        return '';
    }
};

const refreshDetailVertical = (state = {}, action) => {
    if (action.type == 'REFRESH_DETAIL_VERTICAL') {
        return action.payload;
    } else {
        return false;
    }
};

const refreshAccountDetail = (state = {}, action) => {
    if (action.type == 'REFRESH_ACCOUNT_DETAIL') {
        return action.payload;
    } else {
        return false;
    }
};

const showVertical = (state = {}, action) => {
    if (action.type == 'SHOW_VERTICAL') {
        return action.payload;
    } else {
        return state;
    }
};

const PageIDs = (state = [], action) => {
    if (action.type == 'PAGE_IDS') {
        return action.payload;
    } else {
        return state;
    }
};

const SMAccountID = (state = {}, action) => {
    if (action.type == 'SM_ACCOUNT_ID') {
        return action.payload;
    } else {
        return state;
    }
};

const accountNameSearch = (state = {}, action) => {
    if (action.type == 'ACCOUNT_NAME_SEARCH') {
        return action.payload;
    } else {
        return state;
    }
};

// ACCOUNTS END   ======================================================
// CALENDAR START=======================================================
// Start calendar
const weekDayNames = [
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat',
    'Sun'
]
const weekDayNamesWeek = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat'
]
const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

var c = new CalendarProcessor({
    DaysOfWeek: weekDayNames,
    Months: monthNames,
    Format: 'dd/mm/yyyy',
});

const calendar = (calendar = null, action) => {
    switch (action.type) {
        case 'GET_CURRENT_CALENDAR':
            let currentCalendar = c.showCurrent();
            return {
                days: currentCalendar.days,
                current: currentCalendar.current,
                monthNames: monthNames,
                weekDayNames: weekDayNames,
                weekDayNamesWeek: weekDayNamesWeek
            }
        // GET_CURRENT_CALENDAR

        case 'GET_NEXT_CALENDAR':
            let nextMonth = c.nextMonth();
            return {
                days: nextMonth.days,
                current: nextMonth.current,
                monthNames: monthNames,
                weekDayNames: weekDayNames,
                weekDayNamesWeek: weekDayNamesWeek
            };
        // GET_NEXT_CALENDAR

        case 'GET_PREV_CALENDAR':
            let prevMonth = c.previousMonth();
            return {
                days: prevMonth.days,
                current: prevMonth.current,
                monthNames: monthNames,
                weekDayNames: weekDayNames,
                weekDayNamesWeek: weekDayNamesWeek
            };
        // GET_PREV_CALENDAR

        case 'GO_CALENDAR':
            let goMonth = c.goCalendar(action.payload);
            return {
                days: goMonth.days,
                current: goMonth.current,
                monthNames: monthNames,
                weekDayNames: weekDayNames,
                weekDayNamesWeek: weekDayNamesWeek
            };
        // GET_PREV_CALENDAR

        case 'GET_CURRENT_WEEK':
            let currentWeek = c.showCurrentWeek();

            return {
                days: currentWeek.days,
                current: currentWeek.current,
                monthNames: monthNames,
                weekDayNames: weekDayNames,
                weekDayNamesWeek: weekDayNamesWeek
            }
        // GET_CURRENT_WEEK

        case 'GET_NEXT_WEEK':
            let nextWeek = c.nextWeek();
            return {
                days: nextWeek.days,
                current: nextWeek.current,
                monthNames: monthNames,
                weekDayNames: weekDayNames,
                weekDayNamesWeek: weekDayNamesWeek
            };
        // GET_NEXT_WEEK

        case 'GET_PREV_WEEK':
            let previousWeek = c.previousWeek();
            return {
                days: previousWeek.days,
                current: previousWeek.current,
                monthNames: monthNames,
                weekDayNames: weekDayNames,
                weekDayNamesWeek: weekDayNamesWeek
            };
        // GET_PREV_WEEK

        // case 'RELOAD_CALENDAR':
        default:
            if (!calendar) {
                let defaultCalendar = c.showCurrent();

                // check url params
                let url = new URL(window.location.href);
                if (url.searchParams.get("m") || url.searchParams.get("y")) {
                    let m = parseInt(url.searchParams.get("m")), y = parseInt(url.searchParams.get("y"));
                    if (!(m > 11 || m < 0 || y > 3000 || y < 1600)) {
                        defaultCalendar = c.goCalendar({
                            m: m,
                            y: y
                        });
                    }
                }

                return {
                    days: defaultCalendar.days,
                    current: defaultCalendar.current,
                    monthNames: monthNames,
                    weekDayNames: weekDayNames,
                    weekDayNamesWeek: weekDayNamesWeek
                }
            } else {
                return calendar;
            }
        // default
    }
}

const calendarWeekViewIndex = (currentIndex = 0, action) => {
    if (action.type == 'GET_CURRENT_WEEK__INDEX_CALENDAR') {
        return action.payload;
    } else {
        return currentIndex;
    }
}
// CALENDAR END  =======================================================

// MEDIA SCHEDULE START=================================================
const selectedMediaSchedule = (state = {}, action) => {
    if (action.type == 'SELECTED_MEDIA_SCHEDULE') {
        return action.payload;
    } else {
        return state
    }
}

const listMediaSchedule = (state = {}, action) => {
    if (action.type == 'LIST_MEDIA_SCHEDULE') {
        return action.payload;
    } else if (action.type == 'LIST_MEDIA_SCHEDULE_APPEND') {
        return {
            ...state,
            ...action.payload
        }
    } else {
        return state
    }
};

const masterMediaSchedule = (state = {}, action) => {
    if (action.type == 'MASTER_MEDIA_SCHEDULE') {
        return action.payload;
    } else {
        return state
    }
};


const hideMediaScheduleOuting = (hide = false, action) => {
    if (action.type == 'HIDE_SELECTED_MEDIA_SCHEDULE_OUTING') {
        return action.payload;
    }

    return hide;
}

const selectedMediaScheduleOuting = (outings = [], action) => {
    if (action.type == 'SELECTED_MEDIA_SCHEDULE_OUTING') {
        return action.payload;
    } else {
        return outings
    }
}

const selectedMediaScheduleCampaignAd = (ads = [], action) => {
    if (action.type == 'SELECTED_MEDIA_SCHEDULE_AD') {
        return action.payload;
    } else {
        return ads
    }
}

const filterMediaScheduleOuting = (state = 0, action) => {
    if (action.type == 'FILTER_MEDIA_SCHEDULE_OUTING') {
        return action.payload;
    } else {
        return state
    }
}

const scheduledSMTypes = (state = [], action) => {
    if (action.type == 'UPDATE_SCHEDULED_SOCIAL_MEDIA_TYPES') {
        return action.payload;
    } else {
        return state
    }
}
const isScheduleAd = (state = false, action) => {
    return window.location.href.includes('schedule-ads');
}

const newOutingSelectedSocialMediaAccountsPages = (socialMediaAccountsPages = [], action) => {
    if (action.type == 'NEW_OUTING_SELECTED_SOCIAL_MEDIA_ACCOUNTS_PAGES') {
        let returnArr = socialMediaAccountsPages;
        const idx = socialMediaAccountsPages.indexOf(action.payload);

        if (idx > -1) {
            returnArr.splice(idx, 1);
        } else {
            returnArr = [...socialMediaAccountsPages, action.payload];
        }

        return returnArr;
    } else if (action.type == 'NEW_OUTING_REMOVE_SINGLE_SOCIAL_MEDIA_ACCOUNT_PAGE') {
        let returnArr = socialMediaAccountsPages;
        const idx = socialMediaAccountsPages.indexOf(action.payload);

        if (idx > -1) {
            returnArr.splice(idx, 1);
        }

        return returnArr;
    } else if (action.type == 'NEW_OUTING_REMOVE_SOCIAL_MEDIA_ACCOUNTS_PAGES') {
        return [];
    } else {
        return socialMediaAccountsPages;
    }
}

const newOutingSelectedSocialMediaAccounts = (socialMediaAccounts = [], action) => {
    if (action.type == 'NEW_OUTING_SELECTED_SOCIAL_MEDIA_ACCOUNTS') {
        let returnArr = socialMediaAccounts;
        const idx = socialMediaAccounts.indexOf(action.payload);

        if (idx > -1) {
            returnArr.splice(idx, 1);
        } else {
            returnArr = [...socialMediaAccounts, action.payload];
        }

        return returnArr;
    } else if (action.type == 'NEW_OUTING_REMOVE_SINGLE_SOCIAL_MEDIA_ACCOUNT') {
        let returnArr = socialMediaAccounts;
        const idx = socialMediaAccounts.indexOf(action.payload);

        if (idx > -1) {
            returnArr.splice(idx, 1);
        }

        return returnArr;
    } else if (action.type == 'NEW_OUTING_REMOVE_SOCIAL_MEDIA_ACCOUNTS') {
        return [];
    }
    else {
        return socialMediaAccounts
    }
}

const selectedMediaScheduleSocialMediaAccounts = (socialMediaAccounts = {}, action) => {

    if (action.type == 'SELECTED_MASTER_SCHEDULE_SOCIAL_MEDIA_ACCOUNTS'
        || action.type == 'SELECTED_MEDIA_SCHEDULE_SOCIAL_MEDIA_ACCOUNTS') {
        let returnObj = {};
        // check if array
        if (Array.isArray(action.payload)) {
            returnObj = socialMediaAccounts;
            action.payload.forEach(sma => {
                if (sma.MediaSchedules.length) {
                    sma.MediaSchedules.forEach(ms => {
                        returnObj[ms] = sma.VerticalID ? sma.VerticalSocialMedia : sma.SocialMediaAccounts
                    })
                }
            })
        } else {

            if (Array.isArray(socialMediaAccounts)) {
                // first call
                returnObj = {
                    [action.payload.MediaScheduleID]: action.payload.socialMediaAccounts
                }
            } else if (!Array.isArray(socialMediaAccounts) && typeof socialMediaAccounts === 'object') {
                returnObj = socialMediaAccounts;
                if (returnObj[action.payload.MediaScheduleID]) {
                    // check if exists
                    action.payload.socialMediaAccounts.forEach((ap) => {
                        const objFound = returnObj[action.payload.MediaScheduleID].some(x => x.SocialMediaTypeID == ap.SocialMediaTypeID);
                        const objFound2 = returnObj[action.payload.MediaScheduleID].some(x => x.SocialMediaAccountID == ap.SocialMediaAccountID);

                        if (!objFound || !objFound2) {
                            returnObj[action.payload.MediaScheduleID].push(ap);
                        }
                    })
                } else {
                    returnObj = {
                        ...returnObj,
                        [action.payload.MediaScheduleID]: action.payload.socialMediaAccounts
                    }
                }
            }

        }

        return returnObj;
    }
    else {
        return socialMediaAccounts
    }
}

const listMediaScheduleUsers = (state = {}, action) => {
    if (action.type == 'LIST_MEDIA_SCHEDULE_USERS') {
        return action.payload;
    } else {
        return state
    }
}

const minimizeList = (state = {}, action) => {
    if (action.type == 'MINIMIZE_LIST') {
        return action.payload;
    } else {
        return false;
    }
}

const outingPopUp = (state = {
    mediaOutingID: null,
    type: null,
    btnProps: null
}, action) => {
    if (action.type == 'OUTING_POP_UP') {
        return action.payload;
    } else {
        return state;
    }
}

const scheduledPostPreview = (scheduledPostsFields = {}, action) => {
    if (action.type == 'SCHEDULED_POST_PREVIEW') {
        const addOnObj = scheduledPostsFields[action.payload.keyName] ? scheduledPostsFields[action.payload.keyName] : {};

        return {
            ...scheduledPostsFields,
            [action.payload.keyName]: {
                ...addOnObj,
                ...action.payload.data
            }
        }

    } else if (action.type == 'RESET_SCHEDULED_POST_PREVIEW') {
        return {};
    } else {
        return scheduledPostsFields;
    }
}

const cellUpdating = (updatingCells = {}, action) => {
    if (action.type == 'CELL_UPDATING') {

        return {
            ...updatingCells,
            [action.payload.keyName]: action.payload.data
        }

    } else {
        return updatingCells;
    }
}

// MEDIA SCHEDULE END  =================================================

// SOCKET START ========================================================
function convertToSlug(text) {
    return text.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-')
}
function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}
const colors = ['orange', 'blue', 'yellow', 'green', 'violet'];

const selfUser = (selfUser = {}, action) => {
    let user = {}
    if (localToObject('UserDetails')) {
        user = {
            UserID: localToObject('UserDetails').User.UserID,
            FirstName: localToObject('UserDetails').UserDetails.Firstname.Value,
            LastName: localToObject('UserDetails').UserDetails.LastName.Value,
            ProfilePictureURL: localToObject('UserDetails').UserDetails.ProfilePictureURL,
            color: colors[getRandomInt(colors.length)]
        }

        if (action.type == 'UPDATE_SELF_USER') {
            user = {
                ...user,
                ...selfUser,
                ...action.payload,
            }
        }
        else {
            user = {
                ...user,
                ...selfUser,
            }
        }

        user = {
            ...user,
            id: user.UserID,
            name: `${user.FirstName} ${user.LastName}`,
            slugname: convertToSlug(`${user.FirstName} ${user.LastName} ${user.UserID}`),
        }

        return user;
    }


    return null;
}
// selfUser

const users = (users = [], action) => {
    if (action.type == 'LIST_USERS') {
        return action.payload;
    } else if (action.type == 'UPDATE_USER') {
        const userIdx = users.findIndex(user => user.id === action.payload.id);
        users[userIdx] = {
            ...users[userIdx],
            ...action.payload
        };
        // console.log(users);
        return users;
    } else {
        return users;
    }
}
// users

const elements = (elements = [], action) => {
    if (action.type == 'LIST_ELEMENTS') {
        const elIdx = elements.findIndex(el => el.id === action.payload.id);

        if (elIdx !== -1) {
            // update
            elements[elIdx] = action.payload;
            // console.log(elements);
            return elements;

        } else {
            // add

            // console.log([
            //     ...elements,
            //     action.payload
            // ]);
            return [
                ...elements,
                action.payload
            ];
        }

    } else {
        return elements;
    }
}
// elements

const operations = (operations = [], action) => {
    if (action.type == 'LIST_OPERATIONS') {
        return action.payload;
    } else {
        return operations;
    }
}
// operations

const socketConnect = (operations = null, action) => {
    if (action.type == 'SOCKET_CONNECT') {
        return action.payload;
    } else {
        return operations;
    }
}
// socketConnect

const enableSocket = (status = false, action) => {
    if (action.type == 'ENABLE_SOCKET') {
        let returnData = {
            status: false,
            module: null
        }
        // console.log(typeof action.payload)
        switch (typeof action.payload) {
            case 'boolean':
                returnData.status = action.payload;
                break;

            case 'object':
                returnData = action.payload;
                break;

            default:
                break;
        }

        return returnData;
    } else {
        return status;
    }
}
// enableSocket

const socketUpdateModule = (data = {}, action) => {
    if (action.type == 'SOCKET_UPDATE_MODULE') {
        const keyName = action.payload.keyName ? action.payload.keyName : action.payload.module;
        return {
            ...data,
            [keyName]: action.payload
        };
    } else {
        return data;
    }
}
// socketUpdateModule

// SOCKET END   ========================================================

// NOTIFICATIONS START ====================================================


const notificationsConnect = (operations = null, action) => {
    if (action.type == 'NOTIFCATIONS_CONNECT') {
        return action.payload;
    } else {
        return operations;
    }
}
// notificationsConnect

const notifSelfCID = (CID = null, action) => {
    if (action.type == 'NOTIFCATIONS_SELF_CLIENT_ID') {
        return action.payload;
    } else {
        return CID;
    }
}
// notifSelfCID

// NOTIFICATIONS END   ====================================================

// FIELD START =========================================================
const fields = (fields = {}, action) => {
    if (action.type == 'UPDATE_FIELDS') {
        const addOnObj = fields[action.payload.keyName] ? fields[action.payload.keyName] : {};

        fields[action.payload.keyName] = {
            ...addOnObj,
            ...action.payload.data
        }
        fields.timestamp = Date.now();

        /* return {
            ...fields,
            timestamp: Date.now(),
            [action.payload.keyName]: {
                ...addOnObj,
                ...action.payload.data
            }
        } */

        return fields;

    } else if (action.type == 'REMOVE_FIELDS') {
        if (Array.isArray(action.payload) && action.payload.length) {
            action.payload.forEach(x => {
                delete fields[x];
            });
        }
        return fields;
    } else {
        return fields;
    }
}
// fields

const reloadFields = (toReload = false, action) => {
    if (action.type == 'RELOAD_FIELDS') {
        return action.payload;
    } else {
        return false;
    }
}

// FIELD END   =========================================================

// GLOBAL START=========================================================
const isLoading = (state = false, action) => {
    if (action.type == 'IS_LOADING') {
        return action.payload;
    } else {
        return false
    }
}

const isLocalStorageUpdated = (state = false, action) => {
    if (action.type == 'IS_LOCAL_STORAGE_UPDATED') {
        return action.payload;
    } else {
        return false
    }
}

const isFullWidthPage = (state = false, action) => {
    if (action.type == 'IS_FULLWIDTH_PAGE') {
        return action.payload;
    } else {
        return state
    }
}

const isMobileWidthPage = (state = false, action) => {
    if (action.type == 'IS_MOBILEWIDTH_PAGE') {
        if (typeof action.payload === "boolean") {
            return action.payload;
        } else if (action.payload.includes("")) {

        }
    }
    else {
        return state;
    }
}

const isProcessing = (data = {
    status: false,
    label: 'Processing'
}, action) => {
    if (action.type == 'IS_PROCESSING') {
        return action.payload;
    } else {
        return {
            status: data.status,
            label: data.label
        }
    }
}

const isSidebarMinimized = (state = {}, action) => {
    let minimizedVal = false;
    let minimizeIconVal = "fa-chevron-circle-left";

    // set default value
    if (localToObject('ApplicationSettings')) {
        const appSettings = localToObject('ApplicationSettings');
        if (appSettings) {
            minimizedVal = appSettings.menuMinimized;
            minimizeIconVal = minimizedVal ? "fa-chevron-circle-right" : "fa-chevron-circle-left";
        }
    }

    if (action.type == 'IS_SIDEBAR_MINIMIZED') {
        minimizedVal = action.payload;
        minimizeIconVal = action.payload ? "fa-chevron-circle-right" : "fa-chevron-circle-left";
    }

    // add class to body
    if (minimizedVal) {
        document.getElementsByTagName("body")[0].classList.add('sidebar-opened');
    } else {
        document.getElementsByTagName("body")[0].classList.remove('sidebar-opened');
    }
    return {
        minimized: minimizedVal,
        minimizeIcon: <i className={`fas ${minimizeIconVal}`} />
    }
}
// GLOBAL END  =========================================================

// MEDIA FILE LIBRARY START ============================================
const selectedMediaFiles = (selectedMediaFiles = {}, action) => {
    if (action.payload && action.payload.keyName) {
        const keyName = action.payload.keyName;
        if (!selectedMediaFiles[keyName]) {
            selectedMediaFiles[keyName] = [];
        }
        if (action.type == 'UPDATE_SELECTED_MEDIA_FILES') {
            if (Array.isArray(action.payload.file)) {
                let file = action.payload.file;
                selectedMediaFiles[keyName] = file;
            }
            else if (action.payload.file) {
                let file = action.payload.file;
                // const indexOfFile = selectedMediaFiles[keyName].findIndex(fileItem => fileItem.MediaFileID === file.MediaFileID);
                const hasFile = selectedMediaFiles[keyName].some(fileItem => fileItem.MediaFileID === file.MediaFileID);

                if (hasFile) {
                    // file exist so remove from list
                    if (!action.payload.doNotDeselect) {
                        selectedMediaFiles[keyName] = selectedMediaFiles[keyName].filter(fileItem => fileItem.MediaFileID !== file.MediaFileID);
                    }
                } else {
                    // file does not exist so add to list

                    // check if single select
                    if (action.payload.singleSelect) {
                        selectedMediaFiles[keyName] = [
                            file
                        ];
                    } else {
                        selectedMediaFiles[keyName] = [
                            ...selectedMediaFiles[keyName],
                            file
                        ];
                    }
                }
            } // if(action.payload.file)
            else {
                selectedMediaFiles[keyName] = [];
            }// else (action.payload.file)
        } else if (action.type == 'REMOVE_ALL_SELECTED_MEDIA_FILES') {
            selectedMediaFiles[keyName] = [];
        } else if (action.type == 'UPDATE_SELECTED_MEDIA_FILES_FIELD') {
            selectedMediaFiles[keyName] = action.payload.selectedFiles;
        } else if (action.type == 'SORT_SELECTED_MEDIA_FILES') {
            selectedMediaFiles[keyName] = arrayMove(selectedMediaFiles[keyName], action.payload.oldIndex, action.payload.newIndex)
        }
    } // if(action.payload && action.payload.name)

    return selectedMediaFiles;
}

const listMediaFilesMetaData = (mediaFiles = {}, action) => {
    if (action.type == 'LIST_MEDIA_FILES_METADATA') {
        if (action.payload && action.payload.keyName) {
            mediaFiles[action.payload.keyName] = action.payload.data;
        }
    }

    return mediaFiles;
}

const listMediaFiles = (mediaFiles = [], action) => {
    if (action.type == 'LIST_MEDIA_FILES') {
        mediaFiles = action.payload;
    }

    return mediaFiles;
}

const listMediaFilesPagination = (mediaFiles = {
    page: 1,
    maxPage: 1
}, action) => {
    if (action.type == 'LIST_MEDIA_FILES_PAGINATION') {
        mediaFiles = action.payload;
    }

    return mediaFiles;
}
// UPDATE_SELECTED_MEDIA_FILES
// MEDIA FILE LIBRARY END  =============================================


// COMMENTS START  ===========================================
const commentsList = (comments = {}, action) => {
    if (action.type == 'COMMENTS_LIST') {
        if (action.payload.type) {
            comments[action.payload.type] = action.payload.comments;
        } else {
            comments = {};
        }
    }
    return comments;
}
// COMMENTS END  =============================================

// GLOBAL MODAL START ===============================
const globalModal = (modalData = {
    modalIsOpen: false
}, action) => {
    if (action.type == 'SHOW_MODAL') {
        modalData = action.payload;
        modalData.modalIsOpen = true;
    } else if (action.type == 'HIDE_MODAL') {
        modalData = {
            modalIsOpen: false
        }
    }

    return modalData;
}
// GLOBAL MODAL END ===============================

// BACKGROUND PROCESS START =========================
const backgroundProcess = (bgProcess = {}, action) => {
    if (action.type == 'ADD_BACKGROUND_PROCESS') {
        bgProcess[action.payload.keyName] = action.payload.data;
    } else if (action.type == 'UPDATE_BACKGROUND_PROCESS') {
        const addOnObj = bgProcess[action.payload.keyName] ? bgProcess[action.payload.keyName] : {};

        bgProcess[action.payload.keyName] = {
            ...addOnObj,
            ...action.payload.data
        }
    }

    return bgProcess;
}
// BACKGROUND PROCESS END =========================

export default combineReducers({
    // media schedule and outing
    selectedMediaSchedule,
    listMediaSchedule,
    masterMediaSchedule,
    hideMediaScheduleOuting,
    selectedMediaScheduleOuting,
    selectedMediaScheduleCampaignAd,
    newOutingSelectedSocialMediaAccounts,
    newOutingSelectedSocialMediaAccountsPages,
    selectedMediaScheduleSocialMediaAccounts,
    listMediaScheduleUsers,
    minimizeList,
    outingPopUp,
    scheduledPostPreview,
    cellUpdating,
    filterMediaScheduleOuting,
    scheduledSMTypes,
    isScheduleAd,

    // global
    calendar,
    isLoading,
    isLocalStorageUpdated,
    isProcessing,
    isSidebarMinimized,
    calendarWeekViewIndex,
    isFullWidthPage,
    isMobileWidthPage,
    backgroundProcess,

    // accounts / verticals
    listVertical,
    listVerticalAccount,
    listAccount,
    listAccountSocialMedia,
    selectVertical,
    checkedAccounts,
    selectAccount,
    refreshDetailVertical,
    refreshAccountDetail,
    showVertical,
    PageIDs,
    SMAccountID,
    accountNameSearch,

    // socket io
    selfUser,
    users,
    operations,
    elements,
    socketConnect,
    enableSocket,
    socketUpdateModule,

    // notifications io
    notificationsConnect,
    notifSelfCID,

    // fieldGenerator
    fields,
    reloadFields,

    // media file library
    selectedMediaFiles,
    listMediaFiles,
    listMediaFilesPagination,
    listMediaFilesMetaData,

    // comments
    commentsList,

    globalModal
});