import React from 'react';
import { connect } from 'react-redux';

import FieldsGenerator from 'helpers/fieldGenerator';
import PaymentForm from 'components/Payment';
import { gHelpers, loading, localToObject } from 'helpers';
import cls from '../style.module.scss';

const mapStateToProps = state => ({
    fieldRdx: state.fields,
});
class Step4Payment extends React.PureComponent {
    constructor(props) {
        super(props);

        this._isMounted = false;
        this.paymentRef = React.createRef();
        this.getFields = this.getFields.bind(this);
    }

    getFields() {
        return this.cardFields && this.termsFieldsRef
            && this.cardFields.getFields() && this.termsFieldsRef.getFields() ? {
                ...this.cardFields.getFields(),
                ...this.termsFieldsRef.getFields()
            } : null
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;

        if (this.props.onRef) {
            this.props.onRef(this)
        }
    }

    render() {
        const { selectedSubscriptionPlan, selectedSubscriptionTermID, selectedCurrencyID, termsFields, wizRef, paymentProvider, fieldRdx } = this.props;
        let price, priceStr = '';

        if (selectedSubscriptionPlan) {
            price = selectedSubscriptionPlan.SubscriptionPlanTerms.find(t => selectedSubscriptionTermID == t.SubscriptionTermID);
            priceStr = "" + price.Amount;
        }
        const planCss = {
            2: cls.plan2,
            4: cls.plan4
        }

        return (
            <div>
                {
                    selectedSubscriptionPlan && (
                        <div className={`row`}>
                            <div className={`col-sm-6`}>
                                <div className={cls.plansWrapper}>
                                    <div className={cls.plans}>
                                        <div
                                            className={`${cls.plan}` + ' ' + planCss[selectedSubscriptionPlan.SubscriptionPlanID] || ''}
                                        >
                                            <h4 className={cls.planName}>{selectedSubscriptionPlan.PlanName}</h4>
                                            <div className={cls.planDesc}>{selectedSubscriptionPlan.PlanDescription}</div>
                                            <div className={cls.planPrice}>{price.CurrencySymbol}{priceStr.includes(".") ? <span>{priceStr.split(".")[0]}<small>.{priceStr.split(".")[1]}/{price.TermName}</small></span> : <span>{priceStr}<small>/{price.TermName}</small></span>}</div>
                                            <div className={cls.planOptions}>
                                                {
                                                    selectedSubscriptionPlan.SubscriptionPlanOptions.map((option, idx) => (
                                                        <div
                                                            className={cls.option}
                                                            key={idx}
                                                        >{option.HasLimit ? option.Limit : null} {option.OptionName}</div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`col-sm-6`}>
                                <div className={cls.plansWrapper}>
                                    <FieldsGenerator
                                        onRef={ref => (this.cardFields = ref)}
                                        fields={{
                                            fields: [
                                                {
                                                    AttributeName: 'CardEmail',
                                                    Label: 'Email',
                                                    DefaultValue: localToObject('UserDetails').User.EmailAddress,
                                                },
                                                {
                                                    AttributeName: 'AvailTrial',
                                                    Label: 'Avail Trial?',
                                                    DataType: 'Bit',
                                                    DefaultValue: 0
                                                }
                                            ],
                                            excluded: []
                                        }}
                                        settings={{
                                            updateByRID: false
                                        }}

                                    />
                                    <div className={`mt-4`} />
                                    <div className={`field-gen`}>
                                        <span className="uik-content-title__wrapper">{gHelpers.getLabel({ isString: true, key: 73012, fallback: 'Card Details' })}</span>
                                        <div className={cls.cardWrap}>
                                            <PaymentForm
                                                ref={this.paymentRef}
                                                {...{
                                                    price: price,
                                                    currencyID: selectedCurrencyID,
                                                    fields: fieldRdx,
                                                    wizRef: wizRef,
                                                    paymentProvider: paymentProvider
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className={`mt-4`} />
                                    {
                                        termsFields && termsFields.length
                                            ? <FieldsGenerator
                                                onRef={ref => (this.termsFieldsRef = ref)}
                                                fields={{
                                                    fields: termsFields,
                                                    excluded: []
                                                }}
                                                settings={{
                                                    updateByRID: true,
                                                    socketEnabled: false,
                                                    overrideFields: {}
                                                }}

                                            />
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        )
    }
}

export default connect(mapStateToProps, null)(Step4Payment);