const listUsers = (data) => dispatch => {
    dispatch({
        type: 'LIST_USERS',
        payload : data
    })
}

const updateUser = (data) => dispatch => {
    dispatch({
        type: 'UPDATE_USER',
        payload : data
    })
}

const listElements = (data) => dispatch => {
    dispatch({
        type: 'LIST_ELEMENTS',
        payload : data
    })
}

const listOperations = (data) => dispatch => {
    dispatch({
        type: 'LIST_OPERATIONS',
        payload : data
    })
}

const updateSelfUser = (data) => dispatch => {
    dispatch({
        type: 'UPDATE_SELF_USER',
        payload : data
    })
}

const socketConnect = (data) => dispatch => {
    dispatch({
        type: 'SOCKET_CONNECT',
        payload : data
    })
}

const enableSocket = (status) => dispatch => {
    dispatch({
        type: 'ENABLE_SOCKET',
        payload : status
    })
}

const updateModule = (data) => dispatch => {
    dispatch({
        type: 'SOCKET_UPDATE_MODULE',
        payload : data
    })
}

const socketActions = {
    listUsers: listUsers,
    listElements: listElements,
    listOperations: listOperations,
    updateUser: updateUser,
    updateSelfUser: updateSelfUser,
    socketConnect: socketConnect,
    enableSocket: enableSocket,
    updateModule: updateModule
}

export {
    socketActions
}