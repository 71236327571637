import React, { Component } from 'react';
import { genericQuery, loading, updateByRID } from '../../helpers';
import cls from './linkPreview.module.scss';

class LinkPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Title: "",
            Description: "",
            Image: "",
            isLoading: false
        }

        this.getLinkPreview = this.getLinkPreview.bind(this);
        this.handleRemoveLink = this.handleRemoveLink.bind(this);
    }

    handleRemoveLink() {
        if (!this.props.fullRID && this.props.fieldName) {
            document.querySelector(`[name='${this.props.fieldName}']`).value = '';
            this.props.updateField({
                keyName: `${this.props.fieldName}`,
                data: {
                    newValue: ''
                }
            });

            return;
        }

        this.setState({
            isLoading: true
        })

        let passObject = {
            'RID': this.props.fullRID,
            'OldValue': this.props.link,
            'Value': '',
        }

        updateByRID({
            formDataObject: passObject,
            ResponseSuccessCallback: function () {
                this.props.updateField({
                    keyName: this.props.fullRID,
                    data: {
                        oldValue: '',
                        newValue: ''
                    }
                });
                this.setState({
                    isLoading: false
                })
            }.bind(this)
        })
    }

    getLinkPreview() {
        const link = this.props.link;

        const formDataObject = new FormData();
        formDataObject.append('link', link);
        this.setState({
            isLoading: true
        })
        genericQuery(
            {
                Url: 'link_preview/',
                Method: 'POST',
                Data: {
                    requestjson: formDataObject
                },
                ResponseSuccessCallback: function (response) {
                    if (response) {
                        this.setState({
                            Title: response.data.title,
                            Description: response.data.description,
                            Image: response.data.image && response.data.image.length ? response.data.image[0].url : null,
                            isLoading: false
                        })
                        this.props.updateField({
                            keyName: this.props.fullRID,
                            data: {
                                linkPreviewDetails: response.data
                            }
                        });
                    }
                }.bind(this),
                ResponseFailCallback: function (response) {
                    // do when fail
                    this.setState({
                        isLoading: false
                    })
                },
            }
        );
    }

    componentDidUpdate(prevProps) {
        if (prevProps.link !== this.props.link) {
            this.getLinkPreview();
        }
    }

    componentDidMount() {
        this.getLinkPreview();
    }

    render() {
        let { Title, Description, Image, isLoading } = this.state;

        return (
            <div className={cls.linkPreviewContainer}>
                {
                    isLoading
                        ? loading('sm') : null
                }
                <a href={this.props.link} className={cls.linkPreviewBox} target="_blank" rel="nofollow">
                    <span
                        className={cls.imagePreview}
                        style={Image ? {
                            backgroundImage: `url(${Image})`
                        } : null} />
                    <span className={cls.textPreview}>
                        <span className={cls.textMainPreview}>
                            <span className={cls.textTitle}>
                                {Title}
                            </span>
                            <span className={cls.textDesc}>
                                {Description}
                            </span>
                        </span>
                    </span>
                </a>
                <a
                    className={cls.btnClose}
                    onClick={this.handleRemoveLink}
                >
                    <i className={"fas fa-times-circle"} />
                </a>
            </div>
        )
    }
}

export default (LinkPreview);