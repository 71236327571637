import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    UikWidgetContent,
    UikWidget,
    UikHeadline,
    UikHeadlineDesc
} from '../../../@uik';
import { genericQuery, config, getLabel } from '../../../helpers';
//import Logo from '../../../logo.svg';

import cls from './account-confirm.module.scss';

class UserInvitation extends Component {
    constructor(props) {
        super(props);

        document.title = props.pageName;

        this.state = {
            messageError: '',
            messageInfo: 'Verifying data...',
            isSuccess: false
        }
    }

    componentDidMount() {
        localStorage.removeItem('Token');
        localStorage.removeItem('UserDetails');
        localStorage.removeItem('SystemSettings');

        genericQuery({
            Url: 'user_activate/',
            Method: 'PUT',
            Data: {
                requestjson: null
            },
            Headers: {
                token: this.props.match.params.token
            },
            ResponseSuccessCallback: function (response) {
                // do when success
                if (response.data.Status.IsSuccess) {

                    this.state = {
                        messageError: '',
                        messageInfo: 'Logging in...',
                    }

                    localStorage.setItem('Token', response.data.Data.Login.Token);

                    const passUserDetails = {
                        'Module': 'system_user_detail',
                        'Parameters': {
                            'UserID': response.data.Data.Login.LoginID
                        }
                    };
                    const formDataUserDetails = new FormData();
                    formDataUserDetails.append('requestjson', JSON.stringify(passUserDetails));
                    genericQuery({
                        Url: 'single_api/',
                        Method: 'POST',
                        Data: {
                            requestjson: formDataUserDetails
                        },
                        ResponseSuccessCallback: function (responseUserDetails) {
                            if (responseUserDetails.data.Status.IsSuccess) {
                                localStorage.setItem('UserDetails', JSON.stringify({

                                    'User': responseUserDetails.data.Data.User,
                                    'UserDetails': responseUserDetails.data.Data.UserDetails,
                                    'Roles': responseUserDetails.data.Data.Roles,
                                    'CustomerDetails': responseUserDetails.data.Data.CustomerDetails,
                                    'UserSystemSettings': responseUserDetails.data.Data.UserSystemSettings
                                }));

                                this.setState({
                                    isLoginSuccess: true
                                });
                                window.location.href = config.baseURL + 'welcome-new-user/';
                            }

                        }.bind(this),
                        ResponseFailCallback: function (responseUserDetails) {
                            // do when fail
                            this.setState({
                                messageError: getLabel({ key: 61351, fallback: 'An error occurred during the process.' }),
                                messageInfo: '',
                                isProcessing: false
                            });
                        }.bind(this),
                    });
                    // genericQuery({ Url: 'single_api/', ... system_user_detail

                } else {
                    this.setState({
                        messageError: response.data.Status.Message,
                        messageInfo: '',
                    });
                }
            }.bind(this),
            ResponseFailCallback: function (response) {
                // do when fail
                this.setState({
                    messageError: getLabel({ key: 62197, fallback: 'Invalid token.' }),
                    messageInfo: '',
                    isProcessing: false
                });
            }.bind(this),
        });
    }

    render() {
        const { messageInfo, messageError } = this.state;

        return (
            <div className={`${cls.pageWrapper}`}>
                <div>
                    <div className="text-center">
                        <Link to="/">
                            {/* <img src={Logo} alt="" width="150" height="150" /> */}
                        </Link>
                    </div>
                    <div>
                        <UikWidget
                            className={cls.widgetWrapper}
                        >
                            <div className={cls.content}>
                                <UikWidgetContent className={cls.left}>
                                    <div className="text-center">
                                        <UikHeadline>
                                            {getLabel({ key: 1451, fallback: 'Activating Account' })}
                                        </UikHeadline>
                                        <UikHeadlineDesc className={`${messageError ? `red` : null}`}>
                                            {messageInfo}
                                            {messageError}
                                        </UikHeadlineDesc>
                                    </div>
                                </UikWidgetContent>
                            </div>
                        </UikWidget>
                    </div>
                </div>
            </div>
        )
    }
}

export default UserInvitation;