import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { genericQuery } from '../../../helpers';
import {
    UikWidgetContent,
    UikWidget,
    UikInput,
    UikFormInputGroup,
    UikHeadline,
    UikHeadlineDesc,
    UikDivider,
    UikButton,
} from '../../../@uik';
import cls from './devlogin.module.scss';

class DevLogin extends Component {
    constructor(props) {
        super(props);

        document.title = 'SOCRATES DEVELOPERS LOGIN';

        this.state = {
            isProcessing: false,
            Username: '',
            Password: '',
            messageError: '',
            messageInfo: '',
            isLoginSuccess: false
        }
        this.usernameChange = this.usernameChange.bind(this);
        this.passwordChange = this.passwordChange.bind(this);
        this.formSubmit = this.formSubmit.bind(this);

    }

    usernameChange(e) {
        this.setState({
            Username: e.target.value
        })
    }

    passwordChange(e) {
        this.setState({
            Password: e.target.value
        })
    }

    formSubmit(e) {
        e.preventDefault(); //prevents reload of page

        const { isProcessing } = this.state;
        // do not proceed if still processing
        if (isProcessing) {
            return false;
        }
        this.setState({
            isProcessing: true
        });


        const formData = new FormData(e.target);

        const config = {
            Url: 'dev_auth/',
            Method: 'POST',
            Data: {
                requestjson: formData
            },
            ResponseSuccessCallback: function (response) {
                // do when success
                if (response.data) {
                    
                    // if login success
                    
                    localStorage.setItem('isDevAuthenticated', true);
                    this.setState({
                        isLoginSuccess: true
                    });
                    window.location.reload();

                } else {
                    // if login failed
                    this.setState({
                        messageError: response.data.Status.Message,
                        messageInfo: ''
                    });
                }
            }.bind(this),

            ResponseFailCallback: function (response) {
                // do when fail
            
            },
        }

        genericQuery(config)

    }

    render() {
        const { isProcessing, messageError, messageInfo, isLoginSuccess } = this.state;

        if (isLoginSuccess) {
            return (
                <Redirect to="/" />
            )
        }

        return (
            <div className={cls.pageWrapper}>
                <UikWidget className={cls.widgetWrapper}>

                    <div className="content">
                        <form onSubmit={this.formSubmit}>
                            <UikWidgetContent className="left">
                                <UikHeadline>
                                    Developers Log In
                                </UikHeadline>

                                <UikHeadlineDesc>
                                        Get started with Socrates and manage your social media accounts easily!       
                                </UikHeadlineDesc>

                                <UikFormInputGroup>

                                    <UikInput

                                        label="Username"
                                        name="username"
                                        placeholder="Enter your username"
                                        value={this.state.Username}
                                        onChange={this.usernameChange}
                                    />

                                    <UikInput
                                        label="Password"
                                        name="password"
                                        placeholder="·····"
                                        type="password"
                                        value={this.state.Password}
                                        onChange={this.passwordChange}
                                    />
                                    <p className="red">{messageError}</p>
                                    <p className="blue">{messageInfo}</p>

                                </UikFormInputGroup>


                                <UikDivider margin />

                                <UikButton
                                        type="submit"
                                        className="btnAction"
                                        success
                                        isLoading={isProcessing ? true : false}
                                    >
                                    Log In
                                </UikButton>

                            </UikWidgetContent>

                        </form>
                    </div>
                </UikWidget>
                <p>
                    Don't have an account yet? Let us take you to
                        {' '}
                    <a>
                        Sign In!
                        </a>
                </p>
            </div>
        )
    }
}

export default DevLogin;