import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { connect } from 'react-redux';
import { mediaFileLibraryActions } from '../../redux/actions/mediaFileLibrary';
import { fieldActions } from '../../redux/actions/fieldGenerator';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { config, loading, gHelpers, formatBytes } from '../../helpers';
import ModalViewFile from './modals/viewFile';
import cls from './mediaLibrary.module.scss';
import smTemplate from '../../assets/json/socialMediaTemplate.json';

const Thumb = (props) => {
    let thumbPreview = <img src={props.thumbUrl} alt={props.thumbUrl} />;
    return (
        <div
            key={props.idx} className={`media-file-thumb-selected media-file-thumb-selected-${props.MediaFileID}${props.fromSelect ? `-fs` : ``} ${cls.fileItem} ${cls.fileItemSm} ${props.isSlider ? cls.fileItemSlider : ''}`}
            data-media_file_id={props.MediaFileID}
        >
            {
                !props.isUploaded && props.type == 'temp'
                    ? loading('sm') : null
            }
            <a
                href="#"
                onClick={props.canSelect ? props.onSelect : props.onClick}
                className={cls.lnkThumbFile}>
                <span className={cls.linkThumbBg}>
                    {thumbPreview}
                </span>
            </a>
            {
                // delete button(X)
                !props.isSlider
                    ?
                    <>
                        <a
                            href={`#`}
                            className={`${cls.btnRemoveSelected} light-gray`}
                            onClick={props.onRemoveClick}>
                            <i className="fas fa-times-circle" />
                        </a>
                    </>
                    : null

            }
        </div>
    )
}
// Sortable
const SortableThumb = SortableElement(({ value }) => value);
const SortableMediaFileThumbs = SortableContainer(({ items, clsMediaFiles, isSlider, disabled }) => {
    return (
        <div
            className={`${cls.mediaFiles} ${clsMediaFiles} ${isSlider ? cls.mediaFilesSlider : ''}`}
        >
            {items.map((value, index) => (
                <SortableThumb key={`item-${index}`} index={index} value={value} disabled={disabled} />
            ))}
        </div>
    );
});
const mapStateToProps = (state, ownProps) => {
    let returnStates = {
        mediaFileLibary: {
            selectedFiles: state.selectedMediaFiles
        }
    }

    if (ownProps.fieldGenerator.attrib.hasSocialMediaRestrictions) {
        returnStates.scheduledSMTypes = state.scheduledSMTypes;
    }

    return returnStates;
};
const mapDispatchToProps = dispatch => ({
    updateSelectedMediaFilesAction: (status) => dispatch(mediaFileLibraryActions.updateSelectedMediaFiles(status)),
    sortSelectedMediaFilesAction: (status) => dispatch(mediaFileLibraryActions.sortSelectedMediaFiles(status)),
    updateField: (data) => dispatch(fieldActions.updateField(data)),
});
class MediaFiles extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalViewFileIsOpen: false,
            openedFileDetails: null,
            isLoading: false,
            mediaFilesView: []
        }

        this.handleModalViewFile = this.handleModalViewFile.bind(this);
        this.refreshMediaFiles = this.refreshMediaFiles.bind(this);
        this.checkAccountRestrictions = this.checkAccountRestrictions.bind(this);
    }

    onSortStart = ({ node }, event) => {
        let mediaFileID = node.dataset.media_file_id;
        const selectedElems = document.querySelectorAll(`.media-file-thumb-selected-${mediaFileID}${this.props.fromSelect ? `-fs` : ``}`);
        if (selectedElems && selectedElems.length) {
            selectedElems.forEach(el => el.classList.add('dragging'));
        }
        node.classList.add('dragging');
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        document.querySelectorAll('.media-file-thumb-selected').forEach(el => el.classList.remove('dragging'));

        this.props.sortSelectedMediaFilesAction({
            keyName: this.props.fieldGenerator.name,
            oldIndex,
            newIndex
        });
        this.refreshMediaFiles();
    };

    handleModalViewFile(e, file) {
        e.preventDefault();
        let isOpen = this.state.modalViewFileIsOpen;

        if (isOpen) {
            isOpen = false;
        } else {
            isOpen = true;
        }
        this.setState({
            modalViewFileIsOpen: isOpen,
            openedFileDetails: file
        });
    }

    checkAccountRestrictions(args) {
        let previewLayout = smTemplate;

        let restrictedSMsCopy = [];
        let connectedErrors = 0;
        Object.entries(previewLayout).forEach((val) => {
            const key = val[0];
            if (this.props.scheduledSMTypes.includes(key)) {

                const template = previewLayout[key] ? previewLayout[key] : null;

                if (template && template.restrictions) {

                    let allowed = true;
                    let notAllowed = {};

                    // check file restrictions
                    if (template.restrictions.fileTypesAllowed) {
                        if (this.props.mediaFiles.length) {

                            // check allowed file types
                            allowed = this.props.mediaFiles.every(x => {
                                return template.restrictions.fileTypesAllowed.includes(x.Type)
                            });
                            if (!allowed) {
                                notAllowed = {
                                    Name: key,
                                    Message: `${gHelpers.getLabel({ isString: true, key: 61051, fallback: `Allowed file types(s)` })}: ${template.restrictions.fileTypesAllowed.join(" or ")}`,
                                    Type: 'file'
                                }
                            } else {

                                // check if removing 1 file
                                if (args && args.beforeRemoveFile && template.restrictions.isFileRequired) {
                                    const filesCount = this.props.mediaFiles.length;
                                    allowed = filesCount - 1 >= 1;
                                    if (!allowed) {
                                        notAllowed = {
                                            Name: key,
                                            Message: `${template.restrictions.fileTypesAllowed.join(" or ")} ${gHelpers.getLabel({ isString: true, key: 61135, fallback: `file is required.` })}`,
                                            Type: 'file'
                                        }
                                    }
                                }

                                // check number of files allowed total
                                if (allowed && template.restrictions.maxFiles) {
                                    const filesCount = this.props.mediaFiles.length;
                                    allowed = filesCount <= template.restrictions.maxFiles;
                                    if (!allowed) {
                                        notAllowed = {
                                            Name: key,
                                            Message: `${gHelpers.getLabel({ isString: true, key: 61057, fallback: `Maximum number of files allowed:` })} ${template.restrictions.maxFiles}`,
                                            Type: 'file'
                                        }
                                    }
                                }

                                Object.keys(template.restrictions.fileTypes).forEach((key2) => {
                                    const fType = template.restrictions.fileTypes[key2];
                                    if (allowed) {

                                        // check number of files allowed
                                        const filesCount = this.props.mediaFiles.filter(x => x.Type == key2).length;
                                        if (fType.maxFiles) {
                                            if (args && args.beforeRemoveFile) {
                                                allowed = (filesCount - 1) <= fType.maxFiles;
                                            } else {
                                                allowed = filesCount <= fType.maxFiles;
                                            }
                                            if (!allowed) {
                                                notAllowed = {
                                                    Name: key,
                                                    Message: `${gHelpers.getLabel({ isString: true, key: 61063, fallback: `Maximum number of` })} ${key2} ${gHelpers.getLabel({ isString: true, key: 61069, fallback: `files allowed:` })} ${fType.maxFiles}`,
                                                    Type: 'file'
                                                }
                                            }
                                        }

                                        // check file size
                                        if (allowed && fType.maxSize) {
                                            allowed = this.props.mediaFiles.every(x => x.Size <= fType.maxSize);
                                            if (!allowed) {
                                                notAllowed = {
                                                    Name: key,
                                                    Message: `${gHelpers.getLabel({ isString: true, key: 61075, fallback: `File size must not exceed` })} ${formatBytes(fType.maxSize)}`,
                                                    Type: 'file'
                                                }
                                            }
                                        }

                                    }
                                })

                            }

                        } else {
                            if (template.restrictions.isFileRequired) {
                                allowed = false;
                                notAllowed = {
                                    Name: key,
                                    Message: `${gHelpers.getLabel({ isString: true, key: 61081, fallback: `Please select file(s):` })} ${template.restrictions.fileTypesAllowed.join(" or ")}`,
                                    Type: 'file'
                                }
                            }
                        }
                    }
                    // check file restrictions end

                    if (!allowed) {
                        restrictedSMsCopy = [
                            ...restrictedSMsCopy,
                            notAllowed
                        ]

                        connectedErrors += 1;
                        gHelpers.notif('error', { title: `${notAllowed.Name} error!`, msg: notAllowed.Message });

                    } else {
                        restrictedSMsCopy = restrictedSMsCopy.filter(x => x.Name !== key)
                    }
                } // if (template.restrictions)

            }
        }); // Object.entries(socialMediaAccountsList).forEach

        if (args && args.cb) {
            args.cb(restrictedSMsCopy)
        }

        // show 
        if (args && args.cb2) {
            args.cb2(connectedErrors)
        }
    }

    refreshMediaFiles() {
        let selectedMediaFilesID = [];
        selectedMediaFilesID = this.props.mediaFileLibary.selectedFiles[this.props.fieldGenerator.name].map(file => file.MediaFileID);

        this.props.updateField({
            keyName: this.props.fieldGenerator.name,
            data: {
                newValue: JSON.stringify(selectedMediaFilesID),
                mediaFiles: this.props.mediaFileLibary.selectedFiles[this.props.fieldGenerator.name]
            }
        });

        if (this.props.fieldGenerator.handleDoneSelecting) {
            this.props.fieldGenerator.handleDoneSelecting(this.props.fieldGenerator.name, JSON.stringify(selectedMediaFilesID));
        }
    }

    handleFileRemove(e, file) {
        e.preventDefault();

        if (this.props.fieldGenerator && this.props.fieldGenerator.readOnly) {
            gHelpers.notif('error', { msg: gHelpers.getLabel({ isString: true, key: 61141, fallback: `Unable to modify read-only file field.` }) });
            return;
        }


        if (this.props.fieldGenerator.attrib.hasSocialMediaRestrictions) {
            this.checkAccountRestrictions({
                reset: true,
                beforeRemoveFile: true,
                cb2: (connectedErrors) => {
                    if (connectedErrors < 1) {
                        this.props.updateSelectedMediaFilesAction({
                            file: file,
                            keyName: this.props.fieldGenerator.name
                        });
                        this.refreshMediaFiles();
                    }
                }
            });
        } else {
            this.props.updateSelectedMediaFilesAction({
                file: file,
                keyName: this.props.fieldGenerator.name
            });
            this.refreshMediaFiles();
        }

    }

    render() {
        const { modalViewFileIsOpen, openedFileDetails, mediaFilesView, isLoading } = this.state;
        let modals = [];
        let files = [];
        let mediaFiles = [];
        const clsMediaFiles = this.props.className ? this.props.className : '';

        if (mediaFilesView.length) {
            mediaFiles = mediaFilesView;
        } else {
            mediaFiles = this.props.mediaFiles ? this.props.mediaFiles : [];
        }

        if (modalViewFileIsOpen) {
            modals.push(<ModalViewFile
                key='modal-viewFile-0'
                modalIsOpen={modalViewFileIsOpen}
                handleClose={this.handleModalViewFile}
                openedFileDetails={openedFileDetails} />);
        }

        mediaFiles.forEach((file, idx) => {
            const fileDetails = {
                ...file,
                fileUrl: `${config.mediaFileURL}file_manager/?path=${file.Path}`,
                thumbUrl: `${config.mediaFileURL}file_manager/?path=${file.Path}&width=130&height=130&crop=true`,
            }
            let thumb = <Thumb
                key={idx}
                idx={idx}
                MediaFileID={file.MediaFileID}
                onClick={(e) => this.handleModalViewFile(e, fileDetails)}
                onDragStart={this.handleDragStart}
                onRemoveClick={(e) => this.handleFileRemove(e, fileDetails)}
                fileUrl={fileDetails.fileUrl}
                thumbUrl={fileDetails.thumbUrl}
                fileName={file.Name}
                type={'uploaded'}
                canSelect={false}
                isSlider={this.props.isSlider ? true : false}
                fromSelect={this.props.fromSelect ? true : false}
            />

            files.push(thumb);
        });

        let compType = <SortableMediaFileThumbs
            pressDelay={100}
            axis={`x`}
            clsMediaFiles={clsMediaFiles}
            items={files}
            onSortEnd={this.onSortEnd}
            onSortStart={this.onSortStart}
            isSlider={this.props.isSlider ? true : false}
            disabled={this.props.fieldGenerator.readOnly ? true : false}
        />;

        // check if slider
        if (this.props.isSlider) {
            let sliderSettings = {
                dots: false,
                infinite: false,
                speed: 500,
                slidesToShow: 5,
                slidesToScroll: 5,
                className: `slick-arrows-inside`,
                centerMode: true,
            };
            compType =
                <Slider {...sliderSettings}>
                    {
                        files.map((file, idx) =>
                            <div key={idx}>
                                {file}
                            </div>
                        )
                    }
                </Slider>
        }

        // check if loading
        if (isLoading) {
            compType = loading('sm');
        }

        return (
            <div>
                {
                    files.length
                        ? compType
                        : null
                }
                {modals}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaFiles);