import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import Logo from '../../../logo.svg';

import {
    UikWidgetContent,
    UikWidget,
    UikHeadline,
    UikHeadlineDesc,
    UikButton
} from '../../../@uik';

import { isAccountActivated, getLabel } from '../../../helpers';

import cls from './style.module.scss';

class AccountNotActivated extends Component {
    constructor(props) {
        super(props);
        document.title = props.pageName;

        if (isAccountActivated) {
            window.location.href = "/";
        }

    }

    handleLogout(e) {
        localStorage.removeItem('Token');
        localStorage.removeItem('UserDetails');
        window.location.reload();
    }

    render() {
        return (
            <div className={`${cls.pageWrapper}`}>
                <div>
                    <div className="text-center">
                        <Link to="/">
                            {/* <img src={Logo} alt="" width="150" height="150" /> */}
                        </Link>
                    </div>
                    <div className={cls.contentWidgetWrapper}>
                        <UikWidget
                            className={cls.widgetWrapper}
                        >
                            <div className={cls.content}>
                                <UikWidgetContent className={cls.left}>
                                    <div className="text-center">
                                        <UikHeadline>
                                            {getLabel({ key: 1452, fallback: 'Account not yet activated' })}
                                        </UikHeadline>
                                        <UikHeadlineDesc>
                                            {getLabel({ key: 62203, fallback: 'Please check your email and click the activation link to activate your account.' })}
                                        </UikHeadlineDesc>
                                    </div>
                                </UikWidgetContent>
                            </div>
                        </UikWidget>
                        <p className="text-center">
                            <UikButton clear to='/logout/' Component={Link}>
                                {getLabel({ key: 1455, fallback: 'Log in to another account.' })}
                            </UikButton>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default AccountNotActivated;