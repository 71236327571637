import React from 'react';
import { Link } from 'react-router-dom';
import {
    UikWidgetContent,
    UikWidget,
    UikHeadline,
    UikButton
} from '@uik';
import WizardSetup from 'components/WizardSetup';

// steps
import Step1 from './components/step1';
import Step2 from './components/step2';
import Step3 from './components/step3';
import Step4 from './components/step4';

import { gHelpers, loading, genericQuery, getFieldValue } from 'helpers';

import cls from './style.module.scss';

let steps = [
    {
        name: 'Account',
        component: (props) => {
            return (
                <div>
                    {props.component ? props.component : null}
                </div>
            )
        }
    },
    {
        name: 'Profile',
        component: (props) => {
            return (
                <div>
                    {props.component ? props.component : null}
                </div>
            )
        }
    },
    {
        name: 'Subscription',
        component: (props) => {
            return (
                <div>
                    {props.component ? props.component : null}
                </div>
            )
        }
    },
    {
        name: 'Payment',
        component: (props) => {
            return (
                <div>
                    {props.component ? props.component : null}
                </div>
            )
        }
    },
    {
        name: 'Finish',
        component: (props) => {
            return (
                <div className={`text-center`}>
                    <h1>{gHelpers.getLabel({ isString: true, key: 1437, fallback: 'Welcome to Socrates!' })}</h1>
                    <UikButton
                        onClick={() => {
                            localStorage.setItem('IsSub', 1);
                            localStorage.setItem('IsSubChecked', 1);
                            window.location.href = "/";
                        }}
                    >{gHelpers.getLabel({ isString: true, key: 1438, fallback: 'Get Started' })}</UikButton>
                </div>
            )
        }
    }
];
class CustomerSetup extends React.PureComponent {
    startAt = 2;

    constructor(props) {
        super(props);
        document.title = props.pageName;

        this.state = {
            isLoading: false,
            isBtnLoading: false
        }

        this.setIsLoading = this.setIsLoading.bind(this);

        this.wizardRef = React.createRef();
    }

    setIsLoading(val) {
        this.setState({
            isLoading: val
        })
    }

    componentDidMount() {
    }

    render() {
        let { isBtnLoading, isLoading } = this.state;
        let { proceed } = false;

        let hiddenFields = null;

        steps[0].component = () => <Step1 />

        steps[1].component = () => <Step2
            onRef={ref => (this.step2Ref = ref)} />
        steps[1].onNextStep = (args) => {
            args.setWizState({
                isBtnLoading: true
            });
            if (this.step2Ref.getFields() && getFieldValue(this.step2Ref.getFields(), 'VatNumber')) {
                genericQuery(
                    {
                        Url: 'validate_vat/',
                        Method: 'POST',
                        Data: {
                            requestjson: {
                                VatNumber: getFieldValue(this.step2Ref.getFields(), 'VatNumber'),
                                CompanyName: getFieldValue(this.step2Ref.getFields(), 'CompanyName')
                            }
                        },
                        ResponseSuccessCallback: function (response) {
                            if (response.data.Status.IsSuccess) {
                                if (args && args.proceed) {
                                    args.proceed();
                                }
                            } else {
                                gHelpers.notif('error', { title: 'Vat Number', msg: response.data.Status.Message });
                                args.setWizState({ isBtnLoading: false });
                            }
                        }.bind(this),
                        ResponseFailCallback: function () {
                            args.setWizState({
                                isBtnLoading: false
                            });
                            gHelpers.notif('error', { title: 'Vat Number', msg: gHelpers.getLabel({ isString: true, key: 61003, fallback: `An error occured during the process` }) });
                        }.bind(this),
                    }
                )
            } else {
                args.setWizState({
                    isBtnLoading: false
                })
                args.proceed();
            }
        }

        steps[2].component = () => <Step3 onRef={ref => (this.stepPlansRef = ref)} />;
        steps[2].onNextStep = (args) => {
            if (this.stepPlansRef && this.stepPlansRef.state['selectedSubscriptionPlan']) {
                args.proceed()
            } else {
                gHelpers.notif('error', { msg: gHelpers.getLabel({ isString: true, key: 73006, fallback: 'Please select plan.' }) });
            }
        }

        // step 4: Payment
        steps[3].component = () => <Step4
            onRef={ref => (this.stepPaymentRef = ref)}
            {...{
                selectedSubscriptionPlan: this.stepPlansRef && this.stepPlansRef.state['selectedSubscriptionPlan'],
                selectedSubscriptionTermID: this.stepPlansRef && this.stepPlansRef.state['selectedSubscriptionTermID'],
                selectedCurrencyID: this.stepPlansRef && this.stepPlansRef.state['selectedCurrencyID'],
                paymentProvider: this.stepPlansRef && this.stepPlansRef.state['paymentProvider'],
                termsFields: this.step2Ref && this.step2Ref.state['termsFields'],
                wizRef: this.wizardRef
            }} />
        steps[3].onNextStep = () => {
            this.stepPaymentRef && this.stepPaymentRef.paymentRef
                && this.stepPaymentRef.paymentRef.current.submitCard();
        }

        return (
            <div className={`${cls.pageWrapper}`}>
                <div>
                    <div className="text-center">
                        <Link to="/">
                            <img src={`/logo_socrates.svg`} alt="" width="150" style={{ background: '#0f2850' }} />
                        </Link>
                    </div>
                    <div className="d-none">{hiddenFields}</div>
                    <div className={`${cls.contentWidgetWrapper} mt-5 ml-auto mr-auto`}>
                        <UikWidget
                            className={`${cls.widgetWrapper}`}
                        >
                            <UikWidgetContent>
                                {
                                    isLoading ? loading('sm') : null
                                }
                                <div className="mb-3">
                                    <UikHeadline className="text-center">
                                        {gHelpers.getLabel({ key: 1437, fallback: 'Welcome to Socrates!' })}
                                    </UikHeadline>
                                    <WizardSetup
                                        ref={this.wizardRef}
                                        steps={steps}
                                        startAt={this.startAt}
                                        onChangeStep={this.handleChangeStep}
                                        proceed={proceed}
                                        isBtnLoading={isBtnLoading}
                                    />
                                </div>
                            </UikWidgetContent>
                        </UikWidget>

                        <div className="text-right mb-3">
                            <UikButton to={`/logout/`} Component={Link} xs>{gHelpers.getLabel({ key: 62233, fallback: 'Logout' })}</UikButton>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default (CustomerSetup);