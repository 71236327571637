import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import {
    UikWidget,
    UikWidgetHeader,
    UikWidgetContent,
    UikContainerHorizontal,
    UikButton
} from '../../@uik';
import { newOutingRemoveSocialMediaAccounts } from '../../redux/actions/mediaSchedule';
import { fieldActions } from '../../redux/actions/fieldGenerator';
import { gHelpers, localToObject, callApi, getAttribVal, loading, getTimeZone, socket, toTimeZoneAPI, toTimeZone } from '../../helpers';
import FormGenerator from '../../helpers/formGenerator';
import OutingPostIcons from '../../components/OutingPostIcons';

import cls from './media.module.scss';

const mapStateToProps = state => ({
    fieldRdx: {
        fields: state.fields,
    },
    globalState: {
        isMobileWidthPage: state.isMobileWidthPage,
    },
    selectedSocialMediaAccounts: state.newOutingSelectedSocialMediaAccounts,
    selectedSocialMediaAccountsPages: state.newOutingSelectedSocialMediaAccountsPages
});
const mapDispatchToProps = dispatch => ({
    newOutingRemoveSocialMediaAccounts: (data) => dispatch(newOutingRemoveSocialMediaAccounts(data)),
    updateField: (data) => dispatch(fieldActions.updateField(data)),
});
class AddMediaOuting extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            selectedSocialMediaAccounts: [],
            MediaScheduleID: null,
            mediaScheduleLoaded: true,
            IsVertical: false,
            TimeZoneID: 1,
            update: false,
            btnStates: [],
            VerticalGroupID: null,
            isLoading: false
        }

        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.callApiMediaSchedule = this.callApiMediaSchedule.bind(this);
        this.submitOuting = this.submitOuting.bind(this);

        this.formRef = React.createRef();
    }

    submitOuting(StateID) {
        // check if has selected social media accounts
        if (this.props.selectedSocialMediaAccounts.length) {

            if (this.outingIcons) {
                this.outingIcons.checkAccountRestrictions({
                    reset: true,
                    showErrors: 'text',
                    cb2: (connectedErrors) => {
                        if (connectedErrors < 1) {
                            document.getElementById("input-gen-1-[270].StateID").value = StateID;
                            this.formRef.handleSubmit();
                        }
                    }
                });
            }
        } else {
            gHelpers.notif('error', { msg: gHelpers.getLabel({ isString: true,  key: 61825, fallback: 'Please select which social media account you want to post.' }) });
        }

    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        // this.getAccountList();
        if (urlParams.get('schedule')) {
            this.handleSelectChange({
                name: '',
                value: urlParams.get('schedule') ? urlParams.get('schedule') : null
            })
        }

        this.props.newOutingRemoveSocialMediaAccounts();
        if (this.props.location && this.props.location.pathname.includes("/schedules/")) {
            const locationSlug = this.props.location.pathname.split("/");
            const MSID = locationSlug[locationSlug.length - 2];
            if (parseInt(MSID)) {
                this.handleSelectChange({ value: MSID })
            }
        }

        if(this.props.fieldRdx && this.props.fieldRdx.fields["[270].MediaScheduleID"] && 
        this.props.fieldRdx.fields["[270].MediaScheduleID"].oldValue !== null){
            const oldMediaScheduleID = this.props.fieldRdx.fields["[270].MediaScheduleID"].oldValue;
            this.setState({ MediaScheduleID: oldMediaScheduleID });
            this.callApiMediaSchedule({ selectedValue : oldMediaScheduleID });
        } 
    }

    callApiMediaSchedule({ selectedValue }){

        let passObject = {
            'Module': 'media_schedule_get',
            'Parameters': {
                'MediaScheduleID': selectedValue
            }
        }

        callApi({
            passObject: passObject,
            success: (response) => {
                if (response.Data.AttributeSpecification) {
                    const AccountID = getAttribVal(response.Data.AttributeSpecification, 'AttributeName', 'AccountID');
                    const IsVertical = getAttribVal(response.Data.AttributeSpecification, 'AttributeName', 'IsVertical');
                    const VerticalID = getAttribVal(response.Data.AttributeSpecification, 'AttributeName', 'VerticalID');
                    const TimeZoneID = getAttribVal(response.Data.AttributeSpecification, 'AttributeName', 'TimeZoneID');

                    this.setState({ AccountID, IsVertical, VerticalID, mediaScheduleLoaded: true, TimeZoneID });
                    if (Object.entries(this.props.fieldRdx.fields).length && this.props.fieldRdx.fields[`[270].DT_Planned`]) {
                        this.props.updateField({
                            keyName: `[270].DT_Planned`,
                            data: {
                                ruleValueParsed: {
                                    ...this.props.fieldRdx.fields[`[270].DT_Planned`].ruleValueParsed,
                                    scheduleTimeZone: getTimeZone(TimeZoneID).n
                                }
                            }
                        })

                        this.formRef.loadComponent();
                    }
                } // if (response.Data.AttributeSpecification)
            },
            unSuccess: (response) => {
            }
        });

        // get initial outing states on creation
        passObject = {
            'Module': 'list_value_state',
            'Parameters': {
                'MediaScheduleID': selectedValue
            }
        }

        callApi({
            passObject: passObject,
            success: (response) => {
                if (response.Data) {
                    let options = response.Data;
                    if (!response.Data.length) {
                        options = []
                    }

                    this.setState({
                        btnStates: options,
                        isLoading: false
                    })
                } // if (response.Data)
            },
            unSuccess: (response) => {
            }
        });

    }

    handleSelectChange(selectedVal) {
        if (!selectedVal) {
            return false;
        }
        this.props.newOutingRemoveSocialMediaAccounts();

        this.setState({
            selectedSocialMediaAccounts: [],
            MediaScheduleID: selectedVal.value,
            mediaScheduleLoaded: false,
            update: !this.state.update,
            isLoading: false
        });

        this.callApiMediaSchedule({selectedValue : selectedVal.value});
    }
    

    render() {

        const { btnStates, isLoading, MediaScheduleID } = this.state;
        const { isMobileWidthPage } = this.props.globalState;
      
        let overrideFields = {
            '[270].StateID': {
                DefaultValue: '1',
                IsReadOnly: true,
                IsSelectList: false,
                HasRule: true,
                RuleValue: JSON.stringify({
                    style: {
                        display: 'none'
                    }
                })
            },
            '[270].AutoPostPropagation': {
                DefaultValue: true,
                RuleValue: JSON.stringify({
                    style: {
                        display: 'none'
                    }
                })
            },
            '[270].Content': {
                WithEmoji: true
            },
            '[270].MediaFiles': {
                hasSocialMediaRestrictions: true
            } //
        };

        if (this.props.formProps) {
            if (this.props.formProps.addNewOutingDateValue) {
                overrideFields[`[270].DT_Planned`] = {
                    DefaultValue: this.props.formProps.addNewOutingDateValue,
                    DoNotConvertInitialUTC: true
                }
            }
        }

        if (this.props.location && this.props.location.pathname.includes("/schedules/")) {
            overrideFields = {
                ...overrideFields,
                '[270].MediaScheduleID': {
                    'DefaultValue': MediaScheduleID ? MediaScheduleID : null
                }
            }
        }

        let bracketValues = {};
        let additionalSubmitDataKey = 'SocialMediaAccounts';
        let excluded = [];

        if (this.state.IsVertical) {
            additionalSubmitDataKey = 'SocialMediaTypes';
        } else {
            excluded.push('[270].VerticalGroupID')
        }

        const formFieldSettings = {
            bracketValues: {
                '[UserID]': localToObject('UserDetails').User.UserID,
                '[MediaScheduleID]': this.state.MediaScheduleID,
                '[ScheduleTimeZone]': getTimeZone(this.state.TimeZoneID).n,
                ...bracketValues
            },
            excluded: excluded,
            onSubmitSuccess: function (response) {
                gHelpers.notif('success', { msg: response.Status.Message });
                const DT_Planned = getAttribVal(response.Data.MediaOuting, 'AttributeName', 'DT_Planned');
                const DT_PlannedConverted = new Date(toTimeZone(DT_Planned, getTimeZone(this.state.TimeZoneID).n));

                socket.emit('updateModule', {
                    status: true,
                    module: 'MediaSchedule',
                    update: `${DT_PlannedConverted.getDate() - 1}-${DT_PlannedConverted.getMonth() + 1}-${DT_PlannedConverted.getFullYear()}`
                });
                socket.emit('updateModule', {
                    status: true,
                    module: 'MediaSchedule',
                    update: `${DT_PlannedConverted.getDate()}-${DT_PlannedConverted.getMonth() + 1}-${DT_PlannedConverted.getFullYear()}`
                });
                socket.emit('updateModule', {
                    status: true,
                    module: 'MediaSchedule',
                    update: `${DT_PlannedConverted.getDate() + 1}-${DT_PlannedConverted.getMonth() + 1}-${DT_PlannedConverted.getFullYear()}`
                });

                if (this.timeout) clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    window.location.href = `/schedules/${this.state.MediaScheduleID}/?outing=${response.Data.ReturnID}&m=${DT_PlannedConverted.getMonth()}&y=${DT_PlannedConverted.getFullYear()}`;
                }, 1000);

                /* 
                toTimeZoneAPI(
                    DT_Planned,
                    getTimeZone(this.state.TimeZoneID).n,
                    function (response2) {
                        const DT_Planned = new Date(response2.data.Data.DT_utc);
                        socket.emit('updateModule', {
                            status: true,
                            module: 'MediaSchedule',
                            update: `${DT_Planned.getDate() - 1}-${DT_Planned.getMonth() + 1}-${DT_Planned.getFullYear()}`
                        });
                        socket.emit('updateModule', {
                            status: true,
                            module: 'MediaSchedule',
                            update: `${DT_Planned.getDate()}-${DT_Planned.getMonth() + 1}-${DT_Planned.getFullYear()}`
                        });
                        socket.emit('updateModule', {
                            status: true,
                            module: 'MediaSchedule',
                            update: `${DT_Planned.getDate() + 1}-${DT_Planned.getMonth() + 1}-${DT_Planned.getFullYear()}`
                        });

                        // window.location.href = `/schedules/${this.state.MediaScheduleID}/?outing=${response.Data.ReturnID}&m=${DT_Planned.getMonth()}&y=${DT_Planned.getFullYear()}`; // temp comment
                    }.bind(this)
                );
                */

            }.bind(this),
            onSubmitUnsuccess: (response) => {
                if (response.type) {
                    if (response.type === 1) {
                        gHelpers.notif('error', { msg: response.data.Status.Message });
                    }
                } else {
                    gHelpers.notif('error', { msg: response.Status.Message });
                }
            },
            onSubmitFail: (response) => {
                gHelpers.notif('error', { msg: gHelpers.getLabel({ isString: true,  key: 61351, fallback: 'An error occurred during the process.' }) });
            },
            additionalFields: {
                '[270].MediaScheduleID': {
                    onSelectChange: function (val) {
                        this.handleSelectChange(val)
                    }.bind(this),
                    onLoadOptions: function (options) {
                        if (!options.length) {
                            gHelpers.notif('error', { msg: gHelpers.getLabel({ key: 62836, fallback: 'No assigned schedules or do not have permission to create an outing' }) })
                        }
                    },
                    retainFieldValue: true
                },
                '[270].Title': {
                    retainFieldValue: true
                },
                '[270].Description': {
                    retainFieldValue: true
                },
                '[270].Content': {
                    onPasteImage: true,
                    retainFieldValue: true
                },
                '[270].MediaFiles': {
                    retainFieldValue: true
                },
                '[270].VerticalGroupID': {
                    additionalProps: {
                        onMenuOpen: function () {

                            if (this.timeout) clearTimeout(this.timeout);
                            this.timeout = setTimeout(() => {
                                this.formWrapperEnd.scrollIntoView({ behavior: "smooth" });
                            }, 100);

                        }.bind(this)
                    },
                    retainFieldValue: true,
                    className: `select-menu-is-relative`,
                    onSelectChange: function (val) {
                        this.setState({
                            VerticalGroupID: val.value
                        })
                    }.bind(this),


                }
            },
            overrideFields: overrideFields,
            overrideFieldAttributes: {
                '[270].StateID': {
                    DefaultValue: '1',
                    IsReadOnly: true,
                    IsSelectList: false,
                    HasRule: true,
                    RuleValue: JSON.stringify({
                        style: {
                            display: 'none'
                        }
                    })
                }
            },
            formProps: {
                style: {
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }
            },
            additionalSubmitData: {
                [additionalSubmitDataKey]: this.props.selectedSocialMediaAccounts,
                'SocialMediaAccountPageIDs': this.props.selectedSocialMediaAccountsPages
            },
            additionalComponents: {
                component:
                    <>
                        {
                            this.state.mediaScheduleLoaded
                                ?
                                <div className={`field-gen`} style={{ marginTop: '8px' }}>
                                    <span className={`uik-content-title__wrapper`}>{gHelpers.getLabel({ key: 60913, fallback: `Post in` })}</span>
                                    <OutingPostIcons
                                        onRef={ref => (this.outingIcons = ref)}
                                        accountID={this.state.AccountID}
                                        isVertical={this.state.IsVertical}
                                        VerticalID={this.state.VerticalID}
                                        VerticalGroupID={this.state.VerticalGroupID}
                                        MediaScheduleID={this.state.MediaScheduleID}
                                        MediaFileFieldKey={`[270].MediaFiles`}
                                    />
                                </div>
                                : null
                        }
                    </>
            },
            hideSubmitBtn: true
        }

        const form = <div
            className={`${cls.formWrap} showScrollbar`}
        >
            {
                !this.state.mediaScheduleLoaded || isLoading
                    ? loading() : null
            }
            <FormGenerator
                onRef={ref => (this.formRef = ref)}
                module={`media_outing_form`}
                formFieldSettings={formFieldSettings}
                formProps={{
                    style: {
                        paddingBottom: '70px'
                    }
                }}
            />
            <div ref={(el) => { this.formWrapperEnd = el }} />
            <div className={cls.buttonGroup}>
                {
                    btnStates.map((state, idx) => (
                        <UikButton
                            key={idx}
                            className={cls.btnState}
                            style={{
                                background: `${state.StateProperties.color}`,
                                borderColor: `${state.StateProperties.color}`,
                            }}
                            onClick={
                                () => this.submitOuting(state.value)
                            }
                        >
                            {state.label}
                        </UikButton>
                    ))
                }
            </div>
        </div >;

        if (this.props.formOnly) {
            return form;
        }

        return (
            <UikContainerHorizontal>
                <div className={`${cls.mainContainerQuickPost} mr-auto ml-auto`}>
                    <UikWidget className={`${cls.widgetQuickPost} small`}
                        style={{
                            width: isMobileWidthPage ? "95vw" : "760px"
                        }}>
                        <UikWidgetHeader
                            className="uik-widget-title"
                        >
                            {gHelpers.getLabel({ key: 61831, fallback: 'Create New Outing' })}
                        </UikWidgetHeader>
                        <UikWidgetContent>
                            {form}
                        </UikWidgetContent>
                    </UikWidget>
                </div>
            </UikContainerHorizontal>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddMediaOuting));