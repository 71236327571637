import React from 'react';
import PropTypes from 'prop-types';

import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { globalStateAction } from '../redux/actions/global';
import { socketActions } from '../redux/actions/socket';
import { fieldActions } from '../redux/actions/fieldGenerator';
import { mediaFileLibraryActions } from '../redux/actions/mediaFileLibrary';
import {
    UikInput,
    UikCheckbox,
    UikFormInputGroup,
    UikTag,
    UikButton,
    UikNavLink
} from '../@uik';
import BtnSelectMediaFiles from '../components/MediaLibrary/btnSelectMediaFiles';
import LinkPreview from '../components/LinkPreview/';
import EmojiPicker from '../components/EmojiPicker';
import DatePicker from 'react-datepicker';
import { setMinutes, setHours } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import { genericQuery, gHelpers, convertToSlug, loading, isValidDate, callApi, textCaptureLink, updateByRID, mentionInputStyle, toTimeZone, formatDate, toTimeZoneAPI, insertStrAtPos, appendLeadingZeroes, socket, refreshUserDetails, loading3, IsJSON, localToObject, loading2 } from './index';
import smTemplate from '../assets/json/socialMediaTemplate.json';

import axios from 'axios';
import cls from './helpers.module.scss';

import Select from 'react-select';
import { MentionsInput, Mention } from 'react-mentions';

let latestFieldsTime = 0;
let appliedUpdatedElements = false;

const selectStyles = {
    control: (styles, { isFocused }) => ({
        ...styles,
        backgroundColor: !isFocused ? 'rgb(244, 247, 246)' : '#fff',
        borderColor: '#EAEDF3'
    }),
    option: (styles, { isSelected }) => {
        return {
            ...styles,
            ...{
                color:
                    isSelected
                        ? '#fff !important'
                        : 'rgb(55, 55, 55)',
                fontSize: '12px'
            }
        };
    },
    input: styles => ({
        ...styles, ...{
            fontSize: '12px'
        }
    }),
    placeholder: styles => ({ ...styles }),
    singleValue: (styles, { data }) => ({
        ...styles, ...{
            fontSize: '12px',
            width: '100%'
        }
    }),
};

const tokenCodes = [
    'company_name',
    'company_type',
    'state',
    'title',
    'description',
    'post_date',
];
const mapStateToProps = state => ({
    globalState: {
        isLoading: state.isLoading
    },
    fieldRdx: {
        fields: state.fields,
        reloadFields: state.reloadFields
    },
    socketRdx: {
        selfUser: state.selfUser,
        users: state.users,
        elements: state.elements,
        socketEnabled: state.enableSocket
    },
    notifIO: {
        connect: state.notificationsConnect
    },
    scheduledSMTypes: state.scheduledSMTypes
});
const mapDispatchToProps = dispatch => ({
    isLoadingAction: (status) => dispatch(globalStateAction.isLoading(status)),
    isLocalStorageChangedAction: (status) => dispatch(globalStateAction.isLocalStorageUpdated(status)),
    updateSelfUser: (data) => dispatch(socketActions.updateSelfUser(data)),
    listElements: (data) => dispatch(socketActions.listElements(data)),
    updateUser: (data) => dispatch(socketActions.updateUser(data)),
    updateModule: (data) => dispatch(socketActions.updateModule(data)),
    updateField: (data) => dispatch(fieldActions.updateField(data)),
    removeFields: (data) => dispatch(fieldActions.removeFields(data)),
    reloadFields: (data) => dispatch(fieldActions.reloadFields(data)),
    removeAllSelectedMediaFilesAction: (status) => dispatch(mediaFileLibraryActions.removeAllSelectedMediaFiles(status)),
    updateSelectedMediaFilesAction: (status) => dispatch(mediaFileLibraryActions.updateSelectedMediaFiles(status)),
});

class FieldsGenerator extends React.PureComponent {
    static propTypes = {
        fields: PropTypes.object,
    }

    static defaultProps = {
        fields: {
            fields: [],
            excluded: []
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            fieldValues: {},
            newFocusedElementId: null,
            isLoading: false,
            compUpdated: false,
            restrictions: null,
            scheduledSMTypes: [],
            updatedField: ''
        };

        this.handleSelectChangeCustom = this.handleSelectChangeCustom.bind(this);
        this.handleFieldTooltipModal = this.handleFieldTooltipModal.bind(this);
        this.handleOpenEditable = this.handleOpenEditable.bind(this);
        this.handleShowPassword = this.handleShowPassword.bind(this);
        this.handleUpdatePassword = this.handleUpdatePassword.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleTextChangeDebounce = this.handleTextChangeDebounce.bind(this);
        this.onSelectEmoji = this.onSelectEmoji.bind(this);
        this.handleOnFocus = this.handleOnFocus.bind(this);
        this.handleOnFocusDebounce = this.handleOnFocusDebounce.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.handleOnBlurDebounce = this.handleOnBlurDebounce.bind(this);
        this.updateByRID = this.updateByRID.bind(this);
        this.updateField = this.updateField.bind(this);
        this.updateListValue = this.updateListValue.bind(this);
        this.handleDoneSelecting = this.handleDoneSelecting.bind(this);
        this.checkBitValue = this.checkBitValue.bind(this);
        this.updateMentionListValue = this.updateMentionListValue.bind(this);
        this.handleMentionTextChange = this.handleMentionTextChange.bind(this);
        this.parseTokenCodesAPI = this.parseTokenCodesAPI.bind(this);
        this.parseTokenCodes = this.parseTokenCodes.bind(this);
        this.findFieldName = this.findFieldName.bind(this);
        this.onSelectSearchAdvance = this.onSelectSearchAdvance.bind(this);
        this.loadComponent = this.loadComponent.bind(this);
        this.updateByRIDCall = this.updateByRIDCall.bind(this);
        this.updateStateField = this.updateStateField.bind(this);
        this.clearFields = this.clearFields.bind(this);
        this.isLoadingFn = this.isLoadingFn.bind(this);
        this.removeField = this.removeField.bind(this);
        this.getRestrictions = this.getRestrictions.bind(this);
        this.handleBitChange = this.handleBitChange.bind(this);
        this.getFields = this.getFields.bind(this);
        this.handleOnPaste = this.handleOnPaste.bind(this);
        this.handleOldPasswordChange = this.handleOldPasswordChange.bind(this);
        this.removeFields = this.removeFields.bind(this);
    }

    getFields() {
        return this.state.fieldValues;
    }

    removeField(fieldName) {
        let fieldValues = this.state.fieldValues;
        delete fieldValues[fieldName];

        this.setState({
            fieldValues: fieldValues
        })

        this.props.removeFields([fieldName]);
    }

    updateStateField(fieldName, data) {
        this.setState(prevState => ({
            updatedField: fieldName,
            fieldValues: {
                ...prevState.fieldValues,
                [fieldName]: {
                    ...prevState.fieldValues[fieldName],
                    ...data
                }
            }
        }))
    }

    isLoadingFn(val) {
        if (this.props.settings.isLoadingFn) {
            this.props.settings.isLoadingFn(val)
        }
    }

    clearFields() {
        const { fieldValues } = this.state;
        Object.keys(fieldValues).forEach(fieldName => {
            const field = fieldValues[fieldName];

            if (Object.keys(field).length && field.attrib) {

                let newData = {
                    newValue: ''
                }

                if (field.attrib.DataType === 'Bit') {
                    newData.newValue = false;
                }

                if (field.attrib.DataType === 'Password') {
                    newData.maskedPassword = '';
                }

                if (field.attrib.DataType === 'FileSet') {
                    this.props.removeAllSelectedMediaFilesAction({ keyName: fieldName });
                }

                if ((field.attrib.RuleValue && !field.attrib.RuleValue.includes('"display":"none"') && !field.attrib.RuleValue.includes('"display": "none"'))
                    || !field.attrib.RuleValue) {
                    this.updateStateField(fieldName, newData);
                    this.props.updateField({
                        keyName: fieldName,
                        data: newData
                    });
                }

            }
        });
    }

    operationGenerator = (action, type, element, content, newFocusedElement) => {
        const slugname = convertToSlug(element + this.props.socketRdx.selfUser.id)
        return {
            user: this.props.socketRdx.selfUser,
            action,
            type,
            element,
            slugname,
            content,
            newFocusedElement
        }
    }

    handleDoneSelecting(name, newValue) {
        const { settings } = this.props;
        const fieldValues = this.props.fieldRdx.fields;
        const getData = fieldValues[name];
        let passData = {
            RID: name,
            oldValue: getData.oldValue,
            newValue: newValue,
        };

        // filechange callback
        if (getData.attrib.onFileChange) {
            getData.attrib.onFileChange(newValue);
        }

        if (settings.updateByRID) {
            if (settings.socketEnabled) {
                passData.triggerSocket = true;
                // check if there's no more focused user then update by RID
                const inputUsers = this.props.socketRdx.elements.find(x => x.id == passData.RID);
                if (!(inputUsers && inputUsers.users.length)) {
                    this.updateByRID(passData);
                }
            } else {
                this.updateByRID(passData);
            }
        }

        this.updateStateField(name, passData)
    }

    handleFieldTooltipModal(name, content) {
        const fieldValues = this.props.fieldRdx.fields;
        const getData = fieldValues[name];

        gHelpers.confirmDialog({
            title: `${getData.attrib.Label ? getData.attrib.Label : getData.attrib.DefaultLabel} Help`,
            desc: '',
            content: content
        }); // gHelpers.confirmDialog({
    }

    handleOpenEditable(e, name) {
        e.preventDefault();
        // console.log(e, name)
        this.updateStateField(name, {
            editOnClick: {
                status: 'editable'
            }
        });
        this.props.updateField({
            keyName: name,
            data: {
                editOnClick: {
                    status: 'editable'
                }
            }
        });
    }

    handleOnBlur(e) {
        e.persist();
        const el = e.target;
        this.handleOnBlurDebounce(el);
    }

    handleOnBlurDebounce = debounce((el) => {

        const { settings } = this.props;
        const fieldValues = this.props.fieldRdx.fields;
        const getData = fieldValues[el.name];

        if (!getData) {
            return;
        }

        let passData = {
            RID: el.name,
            oldValue: getData.oldValue,
            newValue: el.value,
            el: el,
            triggerSocket: true
        };

        let newFieldData = {
            oldValue: getData.oldValue,
            newValue: passData.newValue
        }

        // format password
        if (getData.attrib.DataType === 'Password') {
            newFieldData.maskedPassword = passData.newValue;
        }

        // format checkbox
        if (getData.attrib.DataType === 'Bit' && el.checked) {
            passData.newValue = 1;
            newFieldData.newValue = 1;
        } else if (getData.attrib.DataType === 'Bit' && !el.checked) {
            passData.newValue = 0;
            newFieldData.newValue = 0;
        }

        // edit on click
        if (settings.editOnClick) {
            this.updateStateField(passData.RID, {
                editOnClick: {
                    status: 'read-only'
                }
            });
            this.props.updateField({
                keyName: passData.RID,
                data: {
                    editOnClick: {
                        status: 'read-only'
                    }
                }
            });
        }

        if (getData.attrib.DataType !== 'DateTime') {
            this.updateStateField(passData.RID, {
                ...newFieldData
            });
            this.props.updateField({
                keyName: passData.RID,
                data: {
                    ...newFieldData
                }
            });
        }

        if (getData.attrib.DataType === 'FileSet') {
            return false;
        }

        if (settings.socketEnabled) {
            // if (settings.socketEnabled) {
            this.props.updateSelfUser({
                focusedElementId: null,
                blurredElementId: passData.RID,
            });


            const operation = this.operationGenerator('blur', 'remove', passData.RID, '')
            socket.emit('operation', operation);
        }

        if (settings.updateByRID) {
            // if (settings.socketEnabled) {
            //     // if (settings.socketEnabled) {
            //     // check if there's no more focused user then update by RID
            //     // const inputUsers = this.props.socketRdx.elements.find(x => x.id == passData.RID);
            //     // if (!(inputUsers && inputUsers.users.length)) {
            //     //     this.updateByRID(passData);
            //     // }
            // } else {
            //     this.updateByRID(passData);
            // }

            this.parseTokenCodes(el.name);
        } // if (settings.updateByRID)
    }, 300)

    handleOnFocus = (e) => {
        const { settings } = this.props;
        if (!settings.socketEnabled) {
            return;
        }

        e.persist();

        const el = e.target;
        this.handleOnFocusDebounce(el);
    }

    handleOnFocusDebounce = debounce((el) => {
        const { settings } = this.props;

        // return
        if (settings.socketEnabled) {
            this.setState({
                newFocusedElementId: el.name
            })

            this.props.updateSelfUser({
                focusedElementId: el.name,
                blurredElementId: null,
            });


            const operation = this.operationGenerator('focus', 'add', el.name, '')
            socket.emit('operation', operation);
        }
    }, 300);

    handleSelectChange = (selectedVal, name) => {
        this.setState({
            newFocusedElementId: name
        });

        const { settings } = this.props;
        const fieldValues = this.props.fieldRdx.fields;
        const getData = fieldValues[name];
        const passData = {
            RID: name,
            oldValue: getData.oldValue,
            newValue: selectedVal.value,
            optionValue: selectedVal,
            triggerSocket: true
        };

        if (getData.attrib.onChangeParams) {
            this.handleSelectChangeCustom(getData.attrib.onChangeParams, name, selectedVal.value);
        }

        if (settings.socketEnabled) {
            let operation = this.operationGenerator('focus', 'add', name, '')
            socket.emit('operation', operation);
        }

        // check if has callback
        if (getData.attrib.onSelectChange) {
            getData.attrib.onSelectChange(selectedVal);
        }


        this.updateStateField(passData.RID, {
            oldValue: getData.oldValue,
            newValue: passData.newValue,
            optionValue: passData.optionValue
        });
        this.props.updateField({
            keyName: passData.RID,
            data: {
                oldValue: getData.oldValue,
                newValue: passData.newValue,
                optionValue: passData.optionValue
            }
        });

        // check if has callback
        if (getData.attrib.onChangeCb) {
            getData.attrib.onChangeCb(selectedVal, name)
        }

        if (settings.updateByRID && !getData.attrib.onChangeParams) {
            this.updateByRID(passData);
        } else {
            if (settings.socketEnabled) {
                let operation = this.operationGenerator('input', 'updateByRID', name, passData.newValue, this.state.newFocusedElementId);
                socket.emit('operation', operation);
            }
        }
    }

    handleSelectChangeCustom(params, name, value) {
        let requestjson = JSON.stringify(params);

        if (requestjson.includes('[Value]')) {
            requestjson = requestjson.replace(/\[[Value]*\]/g, value);
        }

        const formDataObject = new FormData();
        formDataObject.append('requestjson', requestjson);
        genericQuery(
            {
                Url: 'single_api/',
                Method: 'POST',
                Data: {
                    requestjson: formDataObject
                },
                ResponseSuccessCallback: function (response) {
                    if (this.timeout) clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                        let operation = this.operationGenerator('focus', 'remove', name, '')

                        socket.socketConnect.emit('operation', operation);
                    }, 5000);
                }.bind(this)
            }
        );
    }

    onSelectSearchAdvance(option, searchText) {
        if (
            option.data.label.toLowerCase().includes(searchText.toLowerCase())
        ) {
            return true;
        } else {
            return false;
        }
    }

    checkBitValue(fullRID, checked) {
        const fieldValues = this.props.fieldRdx.fields;
        const getData = fieldValues[fullRID];

        if (getData.attrib.onChecked) {
            getData.attrib.onChecked.hideElements.forEach(item => {
                let keyName = `${item}`;
                if (getData.attrib.RID) {
                    keyName = `${getData.attrib.RID}.${item}`;
                }

                let style = {}, style2 = {};
                let ruleValueParsed = {}, ruleValueParsed2 = {};

                style = {
                    'display': checked ? 'none' : 'block'
                };

                ruleValueParsed = {
                    style: style
                }

                if (fieldValues[keyName].ruleValueParsed) {
                    ruleValueParsed2 = fieldValues[keyName].ruleValueParsed;

                    if (fieldValues[keyName].ruleValueParsed.style) {
                        style2 = fieldValues[keyName].ruleValueParsed.style;
                    }
                }

                this.updateStateField(keyName, {
                    ruleValueParsed: {
                        ...ruleValueParsed,
                        ...ruleValueParsed2,
                        style: {
                            ...style2,
                            ...style,
                        }
                    }
                });
                this.props.updateField({
                    keyName: keyName,
                    data: {
                        ruleValueParsed: {
                            ...ruleValueParsed,
                            ...ruleValueParsed2,
                            style: {
                                ...style2,
                                ...style,
                            }
                        }
                    }
                });
            });
            // hideElements.foreach
            getData.attrib.onChecked.showElements.forEach(item => {
                let keyName = `${item}`;
                if (getData.attrib.RID) {
                    keyName = `${getData.attrib.RID}.${item}`;
                }

                let style = {}, style2 = {};
                let ruleValueParsed = {}, ruleValueParsed2 = {};

                style = {
                    'display': checked ? 'block' : 'none'
                };

                ruleValueParsed = {
                    style: style
                }

                if (fieldValues[keyName].ruleValueParsed) {
                    ruleValueParsed2 = fieldValues[keyName].ruleValueParsed;

                    if (fieldValues[keyName].ruleValueParsed.style) {
                        style2 = fieldValues[keyName].ruleValueParsed.style;
                    }
                }

                this.updateStateField(keyName, {
                    ruleValueParsed: {
                        ...ruleValueParsed,
                        ...ruleValueParsed2,
                        style: {
                            ...style2,
                            ...style,
                        }
                    }
                });
                this.props.updateField({
                    keyName: keyName,
                    data: {
                        ruleValueParsed: {
                            ...ruleValueParsed,
                            ...ruleValueParsed2,
                            style: {
                                ...style2,
                                ...style,
                            }
                        }
                    }
                });
            });
            // showElements.foreach
        } // if (getData.attrib.onChecked)
    }
    // checkBitValue

    findFieldName(queryString) {
        const fields = this.props.fieldRdx.fields;
        let returnFieldName = '';

        Object.entries(fields).forEach(val => {
            let objKey = val[0];

            if (objKey.includes(queryString) && returnFieldName === '') {
                returnFieldName = objKey;
            }
        })

        return returnFieldName;
    }

    handleMentionTextChange(e, newValue, newPlainTextValue, mentions, fieldName) {
        const fieldValues = this.props.fieldRdx.fields;
        const getData = fieldValues[fieldName];

        let passData = {
            RID: fieldName,
            oldValue: getData.oldValue,
            newValue: newValue
        };

        let newFieldData = {
            oldValue: getData.oldValue,
            newValue: passData.newValue
        }

        let taggedUsers = [];
        if (mentions.length) {
            mentions.forEach(mention => taggedUsers.push(mention.id));
        }

        newFieldData['taggedUsers'] = taggedUsers;

        this.updateStateField(passData.RID, {
            ...newFieldData
        });

        this.props.updateField({
            keyName: passData.RID,
            data: {
                ...newFieldData
            }
        });
    }

    onSelectEmoji(emoji, fullRID) {
        const { settings } = this.props;
        // const fieldValues = this.props.fieldRdx.fields;
        const fieldValues = this.state.fieldValues;
        const getData = fieldValues[fullRID];

        // check if read-only
        if (getData.attrib.IsReadOnly) {
            return;
        }

        let passData = {
            RID: fullRID,
            oldValue: getData.oldValue,
            newValue: insertStrAtPos(getData.newValue, emoji.native, getData.commentInputCaretPos)
        };

        let newFieldData = {
            oldValue: getData.oldValue,
            newValue: passData.newValue
        }

        this.updateStateField(passData.RID, {
            ...newFieldData
        });
        this.props.updateField({
            keyName: passData.RID,
            data: {
                ...newFieldData
            }
        });

        if (settings.socketEnabled) {
            let operation = this.operationGenerator('input', 'update', passData.RID, passData.newValue);
            socket.emit('operation', operation);
        }
        if (settings.updateByRID) {
            this.updateByRID(passData);
        }
    }

    handleBitChange(e) {
        const el = e.target;

        const { settings } = this.props;
        const fieldValues = this.props.fieldRdx.fields;
        const getData = fieldValues[el.name];

        if (!getData) {
            return;
        }
        let passData = {
            RID: el.name,
            oldValue: getData.oldValue,
            newValue: el.value
        };

        let newFieldData = {
            oldValue: getData.oldValue,
            newValue: passData.newValue
        }

        // check if checkbox
        if (getData.attrib.DataType === 'Bit') {
            if (el.checked) {
                passData.newValue = true;
                newFieldData.newValue = true;
            } else {
                passData.newValue = false;
                newFieldData.newValue = false;
            }

            if (settings.socketEnabled) {
                const operation = this.operationGenerator('focus', 'add', el.name, '')
                socket.emit('operation', operation);

                if (this.timeout) clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    let operation = this.operationGenerator('focus', 'remove', el.name, '');
                    socket.emit('operation', operation);
                }, 5000);
            }

            this.checkBitValue(el.name, passData.newValue)
        }

        this.updateStateField(passData.RID, {
            ...newFieldData
        });

        // check if has callback
        if (getData.attrib.onChangeCb) {
            getData.attrib.onChangeCb(newFieldData.newValue)
        }

        this.props.updateField({
            keyName: passData.RID,
            data: {
                ...newFieldData
            }
        });

        if (settings.socketEnabled) {
            let operation = this.operationGenerator('input', 'update', el.name, el.value);

            if ((el.nodeName === 'INPUT') && (['checkbox', 'radio'].includes(el.type))) {
                operation = this.operationGenerator('input', 'update', el.name, el.checked)
            }

            socket.emit('operation', operation);
        }
        if (settings.updateByRID) {
            if ((el.nodeName === 'INPUT') && (['checkbox', 'radio', 'text'].includes(el.type))) {
                this.updateByRID(passData);
            } // if (settings.updateByRID)
        }
    }

    handleOldPasswordChange(e) {
        const el = e.target;

        const newFieldData = {
            oldPasswordChecker: el.value
        }

        this.updateStateField(el.name, {
            ...newFieldData
        });
        this.props.updateField({
            keyName: el.name,
            data: {
                ...newFieldData
            }
        });
    }

    handleTextChange(e) {
        e.persist();
        const el = e.target;
        const getData = this.state.fieldValues[el.name];

        let newFieldData = {
            oldValue: getData.oldValue,
            newValue: el.value
        }
        // console.log(`newFieldData`, newFieldData)

        // check if password
        if (getData.attrib.DataType === 'Password') {
            newFieldData.maskedPassword = newFieldData.newValue;
        }

        // check if has callback
        if (getData.attrib.onChangeCb) {
            getData.attrib.onChangeCb(newFieldData.newValue)
        }

        this.setState(prevState => ({
            fieldValues: {
                ...prevState.fieldValues,
                [el.name]: {
                    ...prevState.fieldValues[el.name],
                    ...newFieldData
                }
            }
        }), () => {
            this.handleTextChangeDebounce(el);
        })
    }

    handleOnPaste(e) {
        if (e.clipboardData && e.clipboardData.items && e.clipboardData.items.length) {
            // Get the items from the clipboard
            var items = e.clipboardData.items;

            // Loop through all items, looking for any kind of image
            for (var i = 0; i < items.length; i++) {
                if (items[i].type.indexOf('image') !== -1) {
                    // We need to represent the image as a file
                    var blob = items[i].getAsFile();
                    // console.log(`blob`, blob)

                    if (this.fileUploadField) {
                        this.fileUploadField.processUpload([blob])
                    }

                    e.preventDefault();
                }
            }
        }
    }

    handleTextChangeDebounce = debounce((el) => {

        const { settings } = this.props;
        const fieldValues = this.props.fieldRdx.fields;
        const getData = fieldValues[el.name];

        if (!getData) {
            return;
        }

        let passData = {
            RID: el.name,
            oldValue: getData.oldValue,
            newValue: el.value
        };

        let newFieldData = {
            oldValue: getData.oldValue,
            newValue: passData.newValue,
            isSuccess: true,
            errorMessage: ''
        }

        // check datetime to cancel cos it's already done on datetime change event
        if (getData.attrib.DataType === 'DateTime') {
            return false;
        }

        // check if password
        if (getData.attrib.DataType === 'Password') {
            newFieldData.maskedPassword = passData.newValue;
        }

        // check if array text
        if (getData.attrib.DataType === 'Array') {
            newFieldData.JSONValue = JSON.stringify(passData.newValue.split("\n"))
        }

        // check maxcharacters
        if (getData.attrib.maxCharacters) {
            newFieldData = {
                ...newFieldData,
                charactersLeft: getData.attrib.maxCharacters - passData.newValue.length
            }
        }

        // check if has link preview generator
        if (getData.attrib.RuleValue) {
            let fieldRules = getData.ruleValueParsed;
            if (fieldRules.onChange && fieldRules.onChange.FE) {

                // parse link preview
                if (fieldRules.onChange.FE) {
                    if (fieldRules.onChange.FE.ParseLink) {
                        // check if has link
                        let textLinks = textCaptureLink(passData.newValue);
                        if (textLinks) {

                            if (settings.updateByRID) {
                                const linkFullRID = `${getData.attrib.RID}.${fieldRules.onChange.FE.ParseLink}`;

                                let passObject = {
                                    'RID': linkFullRID,
                                    'OldValue': fieldValues[linkFullRID].oldValue,
                                    'Value': textLinks[0],
                                }
                                this.updateStateField(linkFullRID, {
                                    oldValue: fieldValues[linkFullRID].oldValue,
                                    newValue: textLinks[0]
                                });
                                this.props.updateField({
                                    keyName: linkFullRID,
                                    data: {
                                        oldValue: fieldValues[linkFullRID].oldValue,
                                        newValue: textLinks[0]
                                    }
                                });

                                updateByRID({
                                    formDataObject: passObject,
                                    ResponseSuccessCallback: function () {
                                        this.updateStateField(linkFullRID, {
                                            oldValue: fieldValues[linkFullRID].oldValue,
                                            newValue: textLinks[0]
                                        });
                                        this.props.updateField({
                                            keyName: linkFullRID,
                                            data: {
                                                oldValue: fieldValues[linkFullRID].oldValue,
                                                newValue: textLinks[0]
                                            }
                                        });
                                    }.bind(this),
                                    ResponseFailCallback: function () {
                                        console.log(`error occured`)
                                    }
                                })

                            } else {

                                let prefix = el.name.split(".")[0];
                                let linkFieldName = `${prefix}.${fieldRules.onChange.FE.ParseLink}`;
                                if (!fieldValues[fieldRules.onChange.FE.ParseLink]) {
                                    linkFieldName = this.findFieldName("Link");
                                }

                                if (document.querySelector(`[name='${linkFieldName}']`)) {

                                    document.querySelector(`[name='${linkFieldName}']`).value = textLinks[0];
                                    this.updateStateField(linkFieldName, {
                                        newValue: textLinks[0]
                                    });
                                    this.props.updateField({
                                        keyName: linkFieldName,
                                        data: {
                                            newValue: textLinks[0]
                                        }
                                    });
                                    newFieldData.hasLink = true;
                                }

                            }

                        }

                    } // if(fieldRules.onChange.FE.ParseLink)
                } // if(fieldRules.onChange.FE)
            } // if (fieldRules.onChange && fieldRules.onChange.FE)

        }// if (getData.attrib.RuleValue)

        this.updateStateField(passData.RID, {
            ...newFieldData
        });
        this.props.updateField({
            keyName: passData.RID,
            data: {
                ...newFieldData
            }
        });

        if (settings.socketEnabled) {
            let operation = this.operationGenerator('input', 'update', el.name, el.value);
            socket.emit('operation', operation);
        }
        if (settings.updateByRID) {
            if (getData.attrib.DataType === 'Password') {
                return false;
            }

            this.updateByRID(passData);
        }
    }, 1000)

    parseTokenCodesAPI(fieldName, newValue, displayValue, parseTokenRule) {
        const { settings } = this.props;

        const re = /\[.*?\]/g;
        const matches = newValue.match(re);
        if (matches && matches.length) {
            matches.forEach(tokenMatch => {
                let cleanTokenMatch = tokenMatch.substring(1, tokenMatch.length - 1); // remove []
                if (this.props.fieldRdx.fields[fieldName]
                    && this.props.fieldRdx.fields[fieldName].newValue) {
                    displayValue = this.props.fieldRdx.fields[fieldName].newValue;
                }

                if (tokenCodes.includes(cleanTokenMatch)) {
                    let params = {};
                    parseTokenRule.parameters.forEach(param => {
                        if (settings.genVars && settings.genVars[param]) {
                            params[param] = settings.genVars[param]
                        }
                    })
                    params['TokenCode'] = cleanTokenMatch;

                    let passObject = {
                        'Module': parseTokenRule.module,
                        'Parameters': params
                    }

                    callApi({
                        passObject: passObject,
                        success: (response) => {
                            this.updateStateField(fieldName, {
                                displayValue: displayValue.replace(tokenMatch, response.Data.Value)
                            });

                            this.props.updateField({
                                keyName: fieldName,
                                data: {
                                    displayValue: displayValue.replace(tokenMatch, response.Data.Value)
                                }
                            });
                        },
                        unSuccess: (response) => {
                            console.log(response);
                        }
                    });
                } else {

                    this.updateStateField(fieldName, {
                        displayValue: displayValue
                    });
                    this.props.updateField({
                        keyName: fieldName,
                        data: {
                            displayValue: displayValue
                        }
                    });
                }
            })
        } // if (matches && matches.length)
        else {
            if (this.props.fieldRdx.fields[fieldName]
                && this.props.fieldRdx.fields[fieldName].displayValue) {
                displayValue = this.props.fieldRdx.fields[fieldName].newValue
            }

            this.updateStateField(fieldName, {
                displayValue: displayValue
            });
            this.props.updateField({
                keyName: fieldName,
                data: {
                    displayValue: displayValue
                }
            });
        }
    }

    parseTokenCodes(fieldName) {
        const fieldValues = this.props.fieldRdx.fields;
        const getData = fieldValues[fieldName];

        // check if parsable
        if (getData.ruleValueParsed
            && getData.ruleValueParsed.parseToken) {

            this.parseTokenCodesAPI(fieldName, getData.newValue, getData.displayValue, getData.ruleValueParsed.parseToken);

            /* 
            tokenCodes.forEach(token => {
                if (getData.newValue.includes(token.label)) {
                    let params = {};
                    getData.ruleValueParsed.parseToken.parameters.forEach(param => {
                        if (settings.genVars[param]) {
                            params[param] = settings.genVars[param]
                        }
                    })
                    params['TokenCode'] = token.value;
    
                    let passObject = {
                        'Module': getData.ruleValueParsed.parseToken.module,
                        'Parameters': params
                    }
    
                    callApi({
                        passObject: passObject,
                        success: (response) => {
                            this.props.updateField({
                                keyName: fieldName,
                                data: {
                                    displayValue: getData.displayValue.replace(token.label, response.Data.Value)
                                }
                            });
                        },
                        unSuccess: (response) => {
                            console.log(response);
                        }
                    });
    
                } else {
                    this.props.updateField({
                        keyName: fieldName,
                        data: {
                            displayValue: getData.displayValue
                        }
                    });
                }
            }); 
            */

        }
    }
    // parseTokenCodes

    handleDateTimeChange(date, field) {
        const { settings } = this.props;
        const fieldValues = this.props.fieldRdx.fields;
        const getData = fieldValues[field.name];
        let formatted_date = formatDate(date);

        const passData = {
            RID: field.name,
            oldValue: getData.oldValue,
            newValue: formatted_date,
            triggerSocket: false
        };

        let newOptions = {};

        if (date.getDate() > getData.initialDatetimeValue.getDate()
            && typeof getData.minTime !== 'undefined') {
            newOptions.minTime = setHours(setMinutes(new Date(), 0), 0)
        } else {

            if (getData.attrib.minTime) {
                newOptions.minTime = getData.attrib.minTime;
            }

            if (getData.attrib.minTimeOfMinDate) {
                newOptions.minTime = setHours(setMinutes(getData.initialDatetimeValue, getData.initialDatetimeValue.getMinutes()), getData.initialDatetimeValue.getHours())
            }
        }

        this.updateStateField(passData.RID, {
            oldValue: getData.oldValue,
            newValue: passData.newValue,
            datetimeValue: date,
            ...newOptions
        });
        this.props.updateField({
            keyName: passData.RID,
            data: {
                oldValue: getData.oldValue,
                newValue: passData.newValue,
                datetimeValue: date,
                ...newOptions
            }
        });

        if (settings.socketEnabled) {
            let operation = this.operationGenerator('input', 'update', field.name, passData.newValue);
            socket.emit('operation', operation);
        }

        if (settings.updateByRID) {
            this.updateByRID(passData);
        }
    }

    handleUpdatePassword(fieldName) {
        const fieldValues = this.state.fieldValues;
        const getData = fieldValues[fieldName];

        // check if strong password
        if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(getData.newValue)) {
            let passwordErrors = [];

            if (!/^(?=.*[a-z])/.test(getData.newValue)) {
                passwordErrors.push(`One lowercase`);
            }
            if (!/^(?=.*[A-Z])/.test(getData.newValue)) {
                passwordErrors.push(`One uppercase`);
            }
            if (!/^(?=.*[0-9])/.test(getData.newValue)) {
                passwordErrors.push(`One number`);
            }
            if (!/^(?=.*[!@#\$%\^&\*])/.test(getData.newValue)) {
                passwordErrors.push(`One special case character`);
            }
            if (!/^(?=.{8,})/.test(getData.newValue)) {
                passwordErrors.push(`Atleast 8 characters`);
            }

            gHelpers.notif('error', {
                msg: <>
                    {
                        passwordErrors.map((e, idx) => (
                            <span key={idx}>{e}<br /></span>
                        ))
                    }
                </>
            })
        } else {
            this.updateByRID({
                RID: fieldName,
                oldValue: getData.oldValue,
                newValue: getData.newValue,
            })
        }
    }

    handleShowPassword(fieldName) {
        const fieldValues = this.props.fieldRdx.fields;
        const getData = fieldValues[fieldName];

        let showPassword = getData.showPassword ? false : true;

        this.updateStateField(fieldName, {
            showPassword: showPassword
        });
        this.props.updateField({
            keyName: fieldName,
            data: {
                showPassword: showPassword
            }
        });
    }

    updateByRID(data) {
        const fieldValues = this.props.fieldRdx.fields;
        const getData = fieldValues[data.RID];

        let passObject = {
            'RID': data.RID,
            'OldValue': data.oldValue,
            'Value': data.newValue,
        }

        if (getData.attrib.DataType === 'Array') {
            passObject['Value'] = getData.JSONValue;
        }

        // check if an image
        if (data.logo) {
            passObject.logo = data.logo;
        }

        const formData = new FormData();

        if (getData.attrib.IsMandatory && passObject['Value'] === "") {
            this.updateField({
                keyName: data.RID,
                data: {
                    isSuccess: false,
                    errorMessage: gHelpers.getLabel({ isString: true, key: 61339, fallback: 'This field is required.' })
                }
            });
            return false;
        } else {
            this.updateField({
                keyName: data.RID,
                data: {
                    isSuccess: true,
                    errorMessage: ''
                }
            });
        }

        // check if there's change in values
        if (passObject['OldValue'] == passObject['Value']) {
            return false;
        }

        // remove if null
        if (passObject['Value'] === null) {
            delete passObject['Value'];
        }

        // check if datetime that requires conversion to UTC
        if (getData.attrib.DataType === 'DateTime'
            && getData.ruleValueParsed.convertUTCOnUpdate) {

            toTimeZoneAPI(
                passObject.Value,
                getData.ruleValueParsed.scheduleTimeZone,
                function (response) {
                    passObject.Value = response.data.Data.DT_utc;

                    for (const key in passObject) {
                        if (passObject.hasOwnProperty(key)) {
                            const item = passObject[key];
                            formData.append(key, item);
                        }
                    }

                    this.updateByRIDCall(data, formData);

                }.bind(this)
            );

        } else {

            for (const key in passObject) {
                if (passObject.hasOwnProperty(key)) {
                    const item = passObject[key];
                    formData.append(key, item);
                }
            }

            this.updateByRIDCall(data, formData);
        }
    }

    updateByRIDCall(data, formData) {
        const fieldValues = this.props.fieldRdx.fields;
        const getData = fieldValues[data.RID];
        const { settings } = this.props;
        const source = axios.CancelToken.source();

        let updateByRidUrl = 'update_by_rid/';

        this.updateStateField(data.RID, {
            updating: true
        });
        this.props.updateField({
            keyName: data.RID,
            data: {
                updating: true
            }
        });

        // check if password then encrypt
        if (getData.attrib.DataType === 'Password') {
            formData.set(`OldValue`, getData.oldPasswordChecker);
            updateByRidUrl = 'update_by_rid_password/';
        }

        genericQuery(
            {
                Url: updateByRidUrl,
                Method: 'PUT',
                CancelToken: source,
                Data: {
                    requestjson: formData
                },
                Headers: {
                    'Content-Type': 'multipart/form-data'
                },
                ResponseSuccessCallback: function (response) {
                    let newValue = typeof response.data.detail.Data.NewValue === 'object' ? JSON.stringify(response.data.detail.Data.
                        NewValue) : response.data.detail.Data.NewValue;
                    if (!newValue && getData.attrib.DataType !== 'Bit') {
                        newValue = formData.get('OldValue');
                    }

                    let newFieldData = {
                        isSuccess: response.data.detail.Status.IsSuccess,
                        oldValue: newValue,
                        updating: false,
                        message: response.data.detail.Status.Message
                    }

                    // format datetime
                    if (getData.attrib.DataType === 'DateTime') {
                        newFieldData.datetimeValue = new Date(newValue);

                        // check if datetime that requires conversion to UTC
                        if (getData.attrib.DataType === 'DateTime' && getData.ruleValueParsed.convertUTCOnUpdate) {
                            newFieldData.datetimeValue = new Date(toTimeZone(newValue, getData.ruleValueParsed.scheduleTimeZone));
                            newValue = toTimeZone(newValue, getData.ruleValueParsed.scheduleTimeZone);
                        }
                    }

                    if (response.data.detail.Status.IsSuccess) {
                        if (settings.socketEnabled && data.triggerSocket) {
                            if (this.timeout) clearTimeout(this.timeout);
                            this.timeout = setTimeout(() => {
                                let operation = this.operationGenerator('focus', 'remove', data.RID, '');
                                socket.emit('operation', operation);
                            }, 5000);

                            let operation = this.operationGenerator('input', 'updateByRID', data.RID, newValue);
                            socket.emit('operation', operation);
                        } // if (settings.socketEnabled && data.triggerSocket)

                        if (this.props.socketRdx.socketEnabled.module) {
                            const passUpdateModule = {
                                status: true,
                                module: this.props.socketRdx.socketEnabled.module
                            };

                            // socket.emit('updateModule', passUpdateModule);
                            // this.props.updateModule(passUpdateModule);
                        } // if (this.props.socketRdx.socketEnabled.module)

                        if (getData.attrib.IsSelectList) {
                            if (getData.attrib.ReloadListOnUpdate) {
                                this.updateListValue(getData.attrib, {}, data.RID);
                            }

                            // check rule
                            if (getData.ruleValueParsed) {
                                // check reload page on change
                                if (getData.ruleValueParsed.onChange && getData.ruleValueParsed.onChange.FE) {
                                    if (getData.ruleValueParsed.onChange.FE && getData.ruleValueParsed.onChange.FE.Page == "Reload") {
                                        window.location.reload();
                                    }
                                }

                            }
                        } // if (getData.attrib.IsSelectList)

                        // check if it will trigger notifications to users
                        if (response.data.detail.Notification && Object.entries(response.data.detail.Notification).length) {
                            this.props.notifIO.connect.emit('notify', response.data.detail.Notification.targetUsers);
                        } // if (response.data.detail.Notification)

                        this.updateStateField(data.RID, {
                            ...newFieldData,
                            errorMessage: '',
                        });
                        this.props.updateField({
                            keyName: data.RID,
                            data: {
                                ...newFieldData,
                                errorMessage: '',
                            }
                        });
                        if (getData.attrib.AttributeName === "ProfilePicture") {
                            refreshUserDetails({
                                success: function () {
                                    this.props.isLocalStorageChangedAction(true);
                                }.bind(this)
                            });
                        }

                        // show notification on password
                        if (getData.attrib.AttributeName === 'Password') {
                            gHelpers.notif('success', { msg: gHelpers.getLabel({ isString: true, key: 61345, fallback: 'Password updated' }) });
                            document.querySelector(`[name='${getData.attrib.FullRID}']`).value = '';
                        }

                    } else {
                        if (getData.attrib.DataType === 'Array') {
                            if (IsJSON(newValue)) {
                                newValue = JSON.parse(newValue).join("\n");
                            }
                        }

                        newFieldData.newValue = newValue;

                        if (!response.data.detail.Status.Message.includes("Updated by")) {
                            this.updateStateField(data.RID, {
                                ...newFieldData,
                                errorMessage: response.data.detail.Status.Message,
                            });
                            this.props.updateField({
                                keyName: data.RID,
                                data: {
                                    ...newFieldData,
                                    errorMessage: response.data.detail.Status.Message,
                                }
                            });
                            gHelpers.notif('error', { msg: response.data.detail.Status.Message });
                        } else {
                            this.updateStateField(data.RID, {
                                ...newFieldData,
                                isSuccess: true
                            });
                            this.props.updateField({
                                keyName: data.RID,
                                data: {
                                    ...newFieldData,
                                    isSuccess: true
                                }
                            });

                            // retry update password once
                            if (getData.attrib.AttributeName === 'Password' && !data.retry) {
                                formData.set(`OldValue`, newValue);
                                data.retry = true;
                                this.updateByRIDCall(data, formData)
                            } else if (getData.attrib.AttributeName === 'Password' && data.retry) {
                                gHelpers.notif('error', { msg: gHelpers.getLabel({ isString: true, key: 72974, fallback: 'Incorrect old password!' }) });
                            }

                        }

                    }

                    // check callback on update
                    if (getData.attrib.onUpdateCb) {
                        getData.attrib.onUpdateCb({ ...newFieldData, attrib: getData.attrib, formData });
                    }

                }.bind(this),
                ResponseFailCallback: function (response) {
                    console.log(response)

                    this.updateStateField(data.RID, {
                        updating: false
                    });
                    this.props.updateField({
                        keyName: data.RID,
                        data: {
                            updating: false
                        }
                    });
                    // do when fail
                    gHelpers.notif('error', { msg: gHelpers.getLabel({ isString: true, key: 61351, fallback: 'An error occurred during the process.' }) });
                }.bind(this),
            }
        ); // genericQuery()
    }

    updateField(data) {
        if (data) {
            this.updateStateField(data.keyName, data.data);
            this.props.updateField(data);
        }
    }

    replaceBracket(data) {
        // const re = new RegExp(`\[${data.bracket}*\]`, 'g');
        const re = new RegExp(`[${data.bracket}+]`, 'g');
        return data.string.replace(re, data.replace);
    }

    updateMentionListValue(field, fieldOptions, fieldName, plugin) {
        const { settings } = this.props;

        const source = axios.CancelToken.source();
        let requestjson = JSON.stringify({
            Module: plugin.Module,
            Parameters: plugin.Parameters
        });

        // check if has brackets
        if (requestjson.includes('[') && settings.bracketValues) {
            for (const key in settings.bracketValues) {
                if (settings.bracketValues.hasOwnProperty(key)) {
                    const value = settings.bracketValues[key];
                    if (requestjson.includes(key)) {
                        requestjson = this.replaceBracket({
                            bracket: key,
                            string: requestjson,
                            replace: value
                        });
                    }
                }
            }
        }

        genericQuery({
            Url: 'single_api/',
            Method: 'POST',
            CancelToken: source,
            Data: {
                requestjson: {
                    'requestjson': requestjson
                }
            },
            BeforeSubmit: function () {
                this.setState({ isLoading: true });
            }.bind(this),
            ResponseSuccessCallback: function (response) {
                if (response.data.Status.IsSuccess && Object.entries(response.data.Data).length !== 0) {
                    let mentionData = response.data.Data.map(x => {
                        return { id: x.value, display: x.label }
                    });

                    // add myself
                    mentionData.push({
                        id: localToObject('UserDetails').User.UserID,
                        display: `${localToObject('UserDetails').UserDetails.Firstname.Value}${localToObject('UserDetails').UserDetails.LastName.Value}`
                    })

                    fieldOptions.mentionData = mentionData;
                } else {
                    fieldOptions.mentionData = [];
                }
                this.updateField({
                    keyName: fieldName,
                    data: fieldOptions
                })
                this.setState({ isLoading: false });
            }.bind(this),
            ResponseFailCallback: function (response) {
                this.setState({ isLoading: false });
            }.bind(this),
        });
    }

    updateListValue(field, fieldOptions, fieldName, cb) {
        this.isLoadingFn(true);

        const { settings } = this.props;

        const source = axios.CancelToken.source();
        let requestjson = field.ListValue;

        // check if has brackets
        if (requestjson.includes('[') && settings.bracketValues) {
            for (const key in settings.bracketValues) {
                if (settings.bracketValues.hasOwnProperty(key)) {
                    const value = settings.bracketValues[key];
                    if (requestjson.includes(key)) {
                        requestjson = this.replaceBracket({
                            bracket: key,
                            string: requestjson,
                            replace: value
                        });
                    }
                }
            }
        }

        // check if media files
        if (requestjson.includes('[') && field.DataType === 'FileSet') {
            if (field.Value) {
                requestjson = this.replaceBracket({
                    bracket: '[Value]',
                    string: requestjson,
                    replace: field.Value
                });
            }
        }

        // check if list value is returned already
        if (!requestjson.includes("Module")) {
            const listValueArray = JSON.parse(field.ListValue);
            if (Array.isArray(listValueArray)) {
                fieldOptions.options = listValueArray;

                this.updateField({
                    keyName: fieldName,
                    data: fieldOptions
                })
            }
            return;
        }

        genericQuery({
            Url: 'single_api/',
            Method: 'POST',
            CancelToken: source,
            Data: {
                requestjson: {
                    'requestjson': requestjson
                }
            },
            BeforeSubmit: function () {
                this.setState({ isLoading: true });
            }.bind(this),
            ResponseSuccessCallback: function (response) {
                if (response.data.Status.IsSuccess) {
                    let options = response.data.Data;

                    if (!response.data.Data.length) {
                        options = [];
                    }

                    if (field.DataType === 'FileSet') {
                        this.props.updateSelectedMediaFilesAction({
                            file: options,
                            keyName: fieldName
                        });

                        fieldOptions.mediaFiles = options;
                    } else {
                        fieldOptions.options = options.map(option => ({
                            ...option,
                            label: option.label ? option.label : 'No label'
                        }));
                    }

                    // select first value
                    if (field.SelectFirstValue) {
                        fieldOptions.newValue = options[0].value;
                    }

                    // CUSTOMISE MEDIA SCHEDULE ID. ADD ICON FOR WETHER ACCOUNT OR VERTICAL
                    if (fieldName === '[270].MediaScheduleID') {
                        fieldOptions.options = fieldOptions.options.map(option => {
                            const style = {
                                marginRight: '7px',
                                width: '17px',
                                height: '17px',
                            }

                            let icon = <span className={`icon-account`} style={style} />;
                            if (option.IsVertical) {
                                icon = <span className={`icon-vertical`} style={style} />
                            }

                            return {
                                ...option,
                                displayLabel: <span>{icon} {option.label ? option.label : gHelpers.getLabel({ key: 61357, fallback: 'No label' })}</span>
                            }
                        })
                    }

                    this.updateField({
                        keyName: fieldName,
                        data: fieldOptions
                    })

                    if (field.onLoadOptions) {
                        field.onLoadOptions(options);
                    }
                }
                this.isLoadingFn(false);
                this.setState({ isLoading: false });

                // callback
                if (cb) {
                    cb();
                }
            }.bind(this),
            ResponseFailCallback: function (response) {
                this.setState({ isLoading: false });
                this.isLoadingFn(false);
            }.bind(this),
        });

        this.updateField({
            keyName: fieldName,
            data: {
                ...fieldOptions,
                options: []
            }
        })

    }

    componentWillUnmount() {
        this.removeFields();
    }

    removeFields() {
        const { fields, settings } = this.props;
        if (fields.fields) {
            this.props.removeFields(fields.fields.map(x => `${x.RID}.${x.AttributeName}`));
            fields.fields.forEach(field => {
                let keyName = field.AttributeName;
                if (settings.updateByRID) {
                    keyName = field.FullRID;
                }

                this.props.removeAllSelectedMediaFilesAction({ keyName: keyName });
            })
        }
    }

    loadComponent() {
        this.removeFields();

        const { fields, settings } = this.props;
        const urlParams = new URLSearchParams(window.location.search);

        if (fields.fields) {
            this.setState({ isLoading: true });

            fields.fields.forEach((field) => {

                let fieldValue = field.Value ? field.Value : '';
                let fieldName = `${field.AttributeName}`;
                let included = false;

                if (field.RID) {
                    fieldName = `${field.RID}.${field.AttributeName}`;
                }

                // check if has an override
                if (settings.overrideFields) {
                    let overrideName = fieldName;
                    if (settings.updateByRID) {
                        overrideName = field.AttributeName;
                    }

                    for (const key in settings.overrideFields[overrideName]) {
                        if (settings.overrideFields[overrideName].hasOwnProperty(key)) {
                            const value = settings.overrideFields[overrideName][key];
                            field[key] = value;
                        }
                    }
                }

                // check if there are additional fields
                if (settings.additionalFields) {
                    if (settings.additionalFields[field.AttributeName]) {
                        field = {
                            ...field,
                            ...settings.additionalFields[field.AttributeName]
                        }
                    }
                }

                // check if excluded then skip
                if (!settings.updateByRID) {
                    fields.excluded.forEach(excludedItem => {
                        if (!fieldName.includes(excludedItem)) {
                            included = true;
                            return false;
                        }
                    });
                }
                if (!fields.excluded.includes(field.AttributeName)) {
                    included = true;
                }

                if (included) {

                    // check if there are any existing values
                    if (this.props.fieldRdx.fields[fieldName]
                        && this.props.fieldRdx.fields[fieldName].newValue
                        && field.retainFieldValue) {
                        fieldValue = this.props.fieldRdx.fields[fieldName].newValue;
                    }

                    // CONFIG BIT START
                    if (field.DataType === 'Bit') {
                        if (field.Value === false || parseInt(field.Value) === 0) {
                            fieldValue = false;
                        } else if (field.Value === true || parseInt(field.Value) === 1) {
                            fieldValue = true;
                        }
                    }
                    // CONFIG BIT END

                    // set default value if no value (from URL Query)
                    if (!fieldValue && urlParams.has(`fdefault-${field.AttributeName}`)) {
                        fieldValue = urlParams.get(`fdefault-${field.AttributeName}`);
                    }

                    // set default value if no value
                    if ((fieldValue === null || typeof fieldValue === 'undefined' || fieldValue === '') && field.DefaultValue) {
                        fieldValue = field.DefaultValue;

                        // check if has brackets
                        if (typeof fieldValue === 'string' && fieldValue.includes('[')
                            && settings.bracketValues) {
                            fieldValue = settings.bracketValues[fieldValue];
                        }
                    }

                    let fieldOptions = {
                        oldValue: fieldValue,
                        newValue: fieldValue,
                        errorMessage: '',
                        attrib: field,
                        isSuccess: true
                    };

                    // Parse Rule Value
                    if (field.RuleValue && field.RuleValue !== '') {
                        // check if has brackets
                        if (field.RuleValue.includes('[') && settings.bracketValues) {
                            for (const key in settings.bracketValues) {
                                if (settings.bracketValues.hasOwnProperty(key)) {
                                    const value = settings.bracketValues[key];
                                    if (field.RuleValue.includes(key)) {
                                        field.RuleValue = this.replaceBracket({
                                            bracket: key,
                                            string: field.RuleValue,
                                            replace: value
                                        });
                                    }
                                }
                            }
                        }

                        try {
                            fieldOptions.ruleValueParsed = JSON.parse(field.RuleValue);
                        } catch (e) {
                            console.log(e)
                        }
                    }

                    // check if parsable
                    if (fieldOptions.ruleValueParsed
                        && fieldOptions.ruleValueParsed.parseToken) {
                        let displayValue = fieldValue;
                        fieldOptions.displayValue = displayValue;

                        this.parseTokenCodesAPI(fieldName, fieldOptions.newValue, displayValue, fieldOptions.ruleValueParsed.parseToken);
                    }

                    // check plugins
                    if (fieldOptions.ruleValueParsed) {
                        if (fieldOptions.ruleValueParsed.plugins && fieldOptions.ruleValueParsed.plugins.length) {
                            fieldOptions.ruleValueParsed.plugins.forEach(plugin => {
                                if (plugin.Plugin == "input-mention") {
                                    this.updateMentionListValue(field, fieldOptions, fieldName, plugin);
                                }
                            });
                            // fieldOptions.ruleValueParsed.plugins.forEach...
                        }
                    }

                    // check if field has emoji picker
                    if (field.WithEmoji === true) {
                        fieldOptions.commentInputCaretPos = 0;
                    }

                    // CONFIG ARRAY START
                    if (field.DataType === 'Array') {
                        if (IsJSON(fieldValue)) {
                            fieldOptions.newValue = JSON.parse(fieldValue).join("\n");
                            fieldOptions.oldValue = fieldValue;
                            fieldOptions.JSONValue = fieldValue;
                        } else {
                            fieldOptions.JSONValue = JSON.stringify(fieldValue.split("\n"));
                        }
                    }
                    // CONFIG ARRAY END

                    // CONFIG FILESET FIELD START
                    if (field.DataType === 'FileSet' && fieldValue) {
                        if (!field.Value) {
                            field.Value = fieldValue
                        }

                        this.updateListValue(field, fieldOptions, fieldName);
                    }
                    // CONFIG FILESET FIELD END

                    // console.log(`field`, field)
                    // console.log(`fieldOptions`, fieldOptions)
                    // CONFIG DATETIME FIELD START
                    // check if datetime that requires conversion to UTC
                    if (field.DataType === 'DateTime'
                        && fieldOptions.ruleValueParsed
                        && fieldOptions.ruleValueParsed.convertUTCOnUpdate
                        && !field.DoNotConvertInitialUTC) {
                        // && settings.updateByRID) {

                        fieldOptions.utcValue = fieldValue;
                        fieldValue = toTimeZone(fieldValue, fieldOptions.ruleValueParsed.scheduleTimeZone);
                        fieldOptions.newValue = fieldValue;
                    }

                    // minDate
                    if (field.DataType === 'DateTime'
                        && field.minDate) {
                        if (fieldOptions.ruleValueParsed
                            && fieldOptions.ruleValueParsed.convertUTCOnUpdate) {
                            fieldOptions.minDate = new Date(toTimeZone(field.minDate, fieldOptions.ruleValueParsed.scheduleTimeZone))
                        } else {
                            fieldOptions.minDate = field.minDate;
                        }
                    }

                    // minTime
                    if (field.DataType === 'DateTime'
                        && field.minTime) {
                        fieldOptions.minTime = field.minTime;
                    }

                    if (field.DataType === 'DateTime'
                        && field.minTimeOfMinDate) {
                        fieldOptions.minTime = setHours(setMinutes(new Date(fieldOptions.minDate), new Date(fieldOptions.minDate).getMinutes()), new Date(fieldOptions.minDate).getHours())
                    }

                    // handle datetime
                    if (field.DataType === 'DateTime' && !settings.updateByRID && !fieldValue) {
                        fieldOptions.datetimeValue = new Date();
                        fieldOptions.initialDatetimeValue = new Date();

                    } else if (field.DataType === 'DateTime' && settings.updateByRID) {
                        fieldOptions.datetimeValue = new Date(fieldValue);
                        fieldOptions.initialDatetimeValue = new Date(fieldValue);

                    } else if (field.DataType === 'DateTime' && fieldValue) {
                        fieldOptions.datetimeValue = new Date(fieldValue);
                        fieldOptions.initialDatetimeValue = new Date(fieldValue);
                    }

                    if (field.DataType === 'DateTime' && fieldOptions.datetimeValue) {
                        const date = fieldOptions.datetimeValue;
                        let formatted_date = date.getFullYear() + "-" + appendLeadingZeroes(date.getMonth() + 1) + "-" + appendLeadingZeroes(date.getDate()) + " " + appendLeadingZeroes(date.getHours()) + ":" + appendLeadingZeroes(date.getMinutes()) + ":" + appendLeadingZeroes(date.getSeconds());

                        fieldOptions.newValue = formatted_date;
                    }
                    // CONFIG DATETIME FIELD END

                    // CONFIG FILE FIELD START
                    // if image supply the temporary src
                    if (field.IsFileUpload && field.DataType === 'Image') {
                        fieldOptions.tempSrc = fieldValue;
                    }
                    // CONFIG FILE FIELD END

                    // CONFIG PASSWORD FIELD START
                    if (field.DataType === 'Password') {
                        fieldOptions.showPassword = false;
                        fieldOptions.maskedPassword = '';
                    }
                    // CONFIG PASSWORD FIELD END

                    // CONFIG TEXT FIELD START
                    if (field.DataType === 'Text') {
                        if (field.maxCharacters) {
                            fieldOptions.charactersLeft = field.maxCharacters - fieldValue.length;
                        }
                    }
                    // CONFIG TEXT FIELD END

                    // CONFIG SELECT FIELD START
                    if (field.IsSelectList && 'optionValue' in field) {
                        fieldOptions.optionValue = field.optionValue;
                    }
                    // check if select field to get the options first
                    if (field.IsSelectList && field.ListValue) {
                        this.updateListValue(field, fieldOptions, fieldName);
                    }
                    // if (field.IsSelectList && field.ListValue)

                    // check if select field and have options already
                    else if (field.IsSelectList && field.Options) {
                        fieldOptions.options = field.Options;

                        this.updateField({
                            keyName: fieldName,
                            data: fieldOptions
                        })
                    }
                    // if (field.IsSelectList && field.Options)
                    // CONFIG SELECT FIELD END

                    // set default
                    else {

                        this.updateStateField(fieldName, fieldOptions);
                        this.props.updateField({
                            keyName: fieldName,
                            data: fieldOptions
                        })
                    }

                    // check if edit on click
                    if (settings.editOnClick) {
                        let editOnClick = {
                            status: 'read-only'
                        };

                        if (field.editOnClick) {
                            editOnClick = field.editOnClick;
                        }

                        this.updateStateField(fieldName, {
                            editOnClick: editOnClick
                        });
                        this.props.updateField({
                            keyName: fieldName,
                            data: {
                                editOnClick: editOnClick
                            }
                        });
                    }

                } //  if (included)


                return true;

            }); // fields.fields.map((field)
            this.setState({ isLoading: false });
        }
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this)
        }

        // step 3 on updating latest elements
        socket.on('applyUpdatedElements', function (fields) {
            latestFieldsTime = fields.timestamp;

            if (this.props.socketRdx.socketEnabled.status && this.props.socketRdx.socketEnabled.module != 'MediaSchedule') {
                if (fields.timestamp >= latestFieldsTime && !appliedUpdatedElements) {
                    for (var key in fields) {
                        if (fields.hasOwnProperty(key) && key != 'timestamp') {
                            // check if datetime to convert it to datetime type
                            if (fields[key].attrib) {
                                if (fields[key].attrib.DataType === 'DateTime') {
                                    fields[key].newValue = new Date(fields[key].attrib.Value);
                                }
                            }

                            this.updateStateField({
                                keyName: key,
                                data: fields[key]
                            });
                        }
                    }
                    appliedUpdatedElements = true;
                }
            }
        }.bind(this));

        socket.on('operation', operation => {
            const { type } = operation
            let newValue = operation.content;
            const getData = this.state.fieldValues[operation.element];

            switch (type) {

                case 'update' || 'init':
                    // console.log(getData)
                    let fNewData = {
                        newValue: newValue
                    }

                    if (getData && getData.attrib && getData.attrib.DataType === 'Bit') {
                        fNewData.newValue = operation.content ? 1 : 0;
                    }

                    // format datetime
                    if (getData && getData.attrib && getData.attrib.DataType === 'DateTime') {
                        fNewData.datetimeValue = new Date(newValue);
                    }

                    this.updateStateField(operation.element, fNewData);
                    break

                case 'updateByRID':
                    let passData = {
                        oldValue: newValue,
                        newValue: newValue
                    }

                    // image file
                    if (getData && getData.attrib && getData.attrib.DataType === 'Image') {
                        passData.tempSrc = newValue;
                    }

                    // checkbox or radio button
                    if (getData && getData.attrib && getData.attrib.DataType === 'Bit') {
                        passData.newValue = operation.content ? 1 : 0;
                    }

                    // format datetime
                    if (getData && getData.attrib && getData.attrib.DataType === 'DateTime') {
                        passData.datetimeValue = new Date(newValue);
                    }

                    this.updateStateField(operation.element, passData);
                    break
                default:
                    break
            }
        });

        this.loadComponent();
    }

    getRestrictions() {
        if (this.props.scheduledSMTypes.length) {
            let previewLayout = smTemplate;
            // get the least
            let leastMaxChars = 0;
            Object.entries(previewLayout).forEach((val) => {
                const key = val[0];
                if (this.props.scheduledSMTypes.includes(key)) {

                    const template = previewLayout[key] ? previewLayout[key] : null;

                    if (template && template.restrictions) {
                        if (leastMaxChars <= 0) {
                            leastMaxChars = template.restrictions.text.Content.maxCharacters
                        } else {
                            if (template.restrictions.text.Content.maxCharacters < leastMaxChars) {
                                leastMaxChars = template.restrictions.text.Content.maxCharacters;
                            }
                        }
                    } // if (template.restrictions)

                }
            }); // Object.entries(socialMediaAccountsList).forEach

            Object.keys(this.state.fieldValues).forEach(fieldName => {
                const field = this.state.fieldValues[fieldName];
                if (field.attrib.AttributeName == 'Content') {
                    this.updateField({
                        keyName: fieldName,
                        data: {
                            attrib: {
                                ...field.attrib,
                                maxCharacters: leastMaxChars
                            },
                            charactersLeft: leastMaxChars - field.newValue.length
                        }
                    })
                }
            })

            this.setState({
                compUpdated: false
            })

        }
    }

    componentDidUpdate() {
        const { compUpdated } = this.state;

        if (!compUpdated
            && this.props.scheduledSMTypes.length !== this.state.scheduledSMTypes.length) {
            this.setState({
                compUpdated: true,
                scheduledSMTypes: this.props.scheduledSMTypes
            }, () => this.getRestrictions())
        }

        if (this.props.fieldRdx.reloadFields) {
            this.loadComponent();
            this.props.reloadFields(false);
        }
    }

    render() {
        const { fields, settings, isLoading } = this.props;
        // const fieldValues = this.props.fieldRdx.fields;
        const fieldValues = this.state.fieldValues;
        let fieldComponents = [];
        let fieldComponentGroups = [];
        let fieldGroups = [];

        const ErrorMessageBtn = (props) => {
            return (<span>{props.errorMessage}</span>)
        };

        if (fields.fields && fieldValues) {
            fields.fields.forEach((field, idx) => {
                // check if excluded then skip
                if (!fields.excluded.includes(field.AttributeName)) {
                    let component;

                    let fieldName = `${field.AttributeName}`;

                    if (field.RID) {
                        fieldName = `${field.RID}.${field.AttributeName}`;
                    }
                    const fieldRIDName = fieldValues[fieldName];
                    if (fieldRIDName) {
                        // console.log(`fieldRIDName`, fieldRIDName)

                        let initialProps = {
                            id: `input-gen-${idx}-${field.AttributeName}`,
                            key: idx,
                            label: field.Label ? field.Label : field.DefaultLabel,
                            placeholder: field.Placeholder ? field.Placeholder
                                : (field.Label ? field.Label : field.DefaultLabel),
                            name: fieldName,
                            required: field.IsMandatory,
                        }
                        let wrapperProps = {};

                        // check placeholder if string
                        if (typeof initialProps.placeholder !== "string") {
                            initialProps.placeholder = '';
                        }

                        // check if there is tooltip modal
                        if (fieldRIDName.attrib && fieldRIDName.attrib.FieldTooltipModal) {
                            initialProps.label = <div>{initialProps.label} <UikButton icon={<i className={`fas fa-question-circle`} />} clear iconOnly className='ml-1 blue' onClick={() => this.handleFieldTooltipModal(fieldName, fieldRIDName.attrib.FieldTooltipModal.content)} /></div>
                        }

                        // check if edit on click then remove the label
                        if (settings.editOnClick || settings.fieldOnly || settings.navLinkIconComponent) {
                            initialProps = {
                                ...initialProps,
                                label: null
                            }
                        } // if (settings.editOnClick)

                        // check if socketIO enabled
                        if (settings.socketEnabled) {
                            initialProps['data-socket'] = true;
                        }

                        // check if field has rule
                        let fieldRules = {};
                        // console.log(fieldRIDName)
                        if (fieldRIDName && fieldRIDName.ruleValueParsed) {
                            // fieldRules = JSON.parse(fieldRIDName.attrib.RuleValue);
                            fieldRules = fieldRIDName.ruleValueParsed;

                            if (fieldRules.style) {
                                wrapperProps.style = fieldRules.style;

                                // check if hidden then remove the required attr
                                if (wrapperProps.style.display == 'none') {
                                    initialProps.required = false;
                                }
                            }
                        } // fieldRIDName.attrib.HasRule

                        // check if has onPaste handler
                        if (fieldRIDName.attrib.onPasteImage) {
                            initialProps.onPaste = this.handleOnPaste;
                        }

                        let textValue = fieldValues ? fieldRIDName.newValue : '';
                        let errorMessage = fieldValues ? fieldRIDName.errorMessage : null;
                        let errorMessageProps = null;

                        // check if read-only
                        if (fieldRIDName.attrib && (fieldRIDName.attrib.IsReadOnly || fieldRIDName.attrib.isReadOnly)) {
                            initialProps.readOnly = true;
                        }

                        if (!fieldRIDName.isSuccess) {
                            errorMessageProps = {
                                errorMessage: errorMessage,
                                options: {
                                    name: initialProps.name,
                                    type: 'text'
                                }
                            }
                        }

                        // add help button
                        if (initialProps.label
                            && !fieldRIDName.attrib.hideLabel
                            && fieldRIDName.attrib.help) {
                            initialProps.label = <span>
                                {initialProps.label} <a
                                    href="#"
                                    title="Help"
                                    onClick={(e) => {
                                        const help = fieldRIDName.attrib.help;
                                        e.preventDefault();

                                        gHelpers.confirmDialog({
                                            title: help.title,
                                            desc: '',
                                            content: help.content,
                                            cancelBtn: {
                                                text: gHelpers.getLabel({ key: 1369, fallback: `Close` })
                                            }
                                        }); // gHelpers.confirmDialog({
                                    }}
                                ><i className="fas fa-question-circle"></i></a>
                            </span>;
                        }

                        if (initialProps.label
                            && !fieldRIDName.attrib.hideLabel
                            && fieldRIDName.attrib.appendLabel) {
                            // console.log(`appendLabel`)
                            initialProps.label = <span>
                                {initialProps.label}
                                {fieldRIDName.attrib.appendLabel}
                            </span>
                        }

                        // START PROCESSING FIELD COMPONENTS
                        // select field start
                        if (field.IsSelectList) {
                            if (fieldRIDName.attrib.hideLabel) {
                                initialProps.label = null;
                            }

                            wrapperProps = {
                                ...wrapperProps,
                                id: initialProps.id
                            }

                            component = <div
                                key={`select-${fieldName}-${idx}`}
                                {...wrapperProps}
                            >
                                {
                                    initialProps.label
                                        ? <span className='uik-content-title__wrapper'>{initialProps.label}</span>
                                        : null
                                }
                                <div className='uik-input__inputWrapper'>
                                    {
                                        (() => {

                                            if (fieldRIDName.options) {
                                                let isDisabled = fieldRIDName.attrib.IsReadOnly;
                                                let val = fieldRIDName.options.find(option => option.value == textValue);
                                                let isSelectLoading = false;

                                                // check if grouped
                                                fieldRIDName.options.forEach(option => {
                                                    // if grouped
                                                    if (option.options && !val) {
                                                        const optionOptions = option.options.find(x => x.value == textValue);
                                                        val = optionOptions;
                                                    }
                                                });

                                                if (!val && fieldRIDName.attrib.IsReadOnly) {
                                                    isDisabled = false;
                                                }

                                                if (fieldRIDName.isSelectLoading) {
                                                    isSelectLoading = true;
                                                }

                                                let additionalProps = {};
                                                if (fieldName === '[270].MediaScheduleID' || field.SelectHasCustomLabel) {
                                                    additionalProps = {
                                                        getOptionLabel: option => option.displayLabel,
                                                        filterOption: this.onSelectSearchAdvance,
                                                    }
                                                }

                                                if (fieldRIDName.attrib.additionalProps) {
                                                    additionalProps = {
                                                        ...additionalProps,
                                                        ...fieldRIDName.attrib.additionalProps
                                                    }
                                                }

                                                let className = '';
                                                if (fieldRIDName.attrib.className) {
                                                    className = fieldRIDName.attrib.className;
                                                }

                                                return (
                                                    <Select
                                                        key={`select-main-${fieldName}-${idx}`}
                                                        placeholder={initialProps.placeholder}
                                                        value={[val]}
                                                        name={fieldName}
                                                        options={fieldRIDName.options}
                                                        className={`basic-multi-select ${className}`}
                                                        classNamePrefix="select"
                                                        onChange={(val) => this.handleSelectChange(val, initialProps.name)}
                                                        styles={selectStyles}
                                                        isDisabled={isDisabled}
                                                        // defaultMenuIsOpen={true} // temp
                                                        {...additionalProps}
                                                        isLoading={isSelectLoading}
                                                    />
                                                )
                                            } else {
                                                return loading3();
                                            }
                                        })()
                                    }
                                </div>
                                {
                                    errorMessage ?
                                        <p className="uik-input__errorMessage">{errorMessageProps ? <ErrorMessageBtn {...errorMessageProps} /> : null}</p>
                                        : null
                                }
                            </div>
                        }
                        // select field end

                        else if (field.DataType === 'Bit') {
                            let componentProps = {};
                            // componentProps.onClick = this.handleTextChange;
                            componentProps.onChange = this.handleBitChange;
                            componentProps.value = textValue;

                            if (textValue === 1 || textValue == "true" || textValue === true) {
                                // componentProps.defaultChecked = true;
                                componentProps.checked = true;
                            } else {
                                // componentProps.defaultChecked = false;
                                componentProps.checked = false;
                            }

                            component = <div
                                key={`checkbox-${fieldName}-${idx}`}
                                {...wrapperProps}
                            >
                                <UikCheckbox
                                    {...initialProps}
                                    {...field.fieldProps}
                                    {...componentProps}
                                />
                                {
                                    errorMessage ?
                                        <p className="uik-input__errorMessage">{errorMessageProps ? <ErrorMessageBtn {...errorMessageProps} /> : null}</p>
                                        : null
                                }
                            </div>
                        }

                        // field datetime picker start
                        else if (field.DataType === 'DateTime' && fieldRIDName.datetimeValue) {

                            // check if valid date to trap error
                            if (!isValidDate(fieldRIDName.datetimeValue)) {
                                fieldRIDName.isSuccess = false;
                                errorMessage = 'Invalid datetime format';
                                fieldRIDName.datetimeValue = new Date();
                                initialProps['selected'] = fieldRIDName.datetimeValue;

                            } else {
                                initialProps['selected'] = fieldRIDName.datetimeValue;
                            }

                            if (fieldRIDName.minDate) {
                                initialProps.minDate = fieldRIDName.minDate;
                            }

                            if (fieldRIDName.minTime) {
                                initialProps.minTime = fieldRIDName.minTime;

                                if (!fieldRIDName.attrib.maxTime) {
                                    initialProps.maxTime = setHours(setMinutes(new Date(), 45), 23);
                                } else {
                                    initialProps.maxTime = fieldRIDName.attrib.maxTime;
                                }
                            }

                            initialProps = {
                                ...initialProps,
                                onFocus: this.handleOnFocus,
                                onBlur: this.handleOnBlur
                            }

                            component = <div
                                key={`datepicker-${idx}`}
                                {...wrapperProps}
                            >
                                {
                                    settings.editOnClick
                                        ? null
                                        :
                                        <span className='uik-content-title__wrapper'>{initialProps.label}</span>
                                } {/* scheduled date */}


                                <div className='uik-input__inputWrapper'>
                                    <DatePicker
                                        {...initialProps}
                                        onChange={(date) => this.handleDateTimeChange(date, {
                                            name: initialProps.name
                                        })}
                                        showTimeSelect
                                        locale="en-GB"
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        dateFormat="yyyy-MM-dd HH:mm:ss" //MMMM d, yyyy h:mm aa"
                                        timeCaption="time"
                                        className='uik-input__input'
                                    />
                                    {/* scheduled date picker */}
                                    {
                                        errorMessage ?
                                            <p className="uik-input__errorMessage">{errorMessageProps ? <ErrorMessageBtn {...errorMessageProps} /> : null}</p>
                                            : null
                                    }
                                </div>
                            </div>
                        }
                        // field datetime picker start

                        // email field start
                        else if (field.DataType === 'Email') {
                            if (fieldRIDName.attrib.hideLabel) {
                                initialProps.label = null;
                            }

                            let componentProps = {
                                type: 'email',
                                errorMessage: errorMessageProps ? <ErrorMessageBtn {...errorMessageProps} /> : null
                            }

                            componentProps.value = textValue;
                            componentProps.onChange = this.handleTextChange;

                            initialProps = {
                                ...initialProps,
                                onFocus: this.handleOnFocus,
                                onBlur: this.handleOnBlur
                            }

                            component = <UikInput
                                wrapperProps={wrapperProps}
                                {...initialProps}
                                {...field.fieldProps}
                                {...componentProps}
                            />
                        }
                        // email field end

                        // number field start
                        else if (field.DataType === 'Number') {
                            if (fieldRIDName.attrib.hideLabel) {
                                initialProps.label = null;
                            }

                            let componentProps = {
                                type: 'number',
                                errorMessage: errorMessageProps ? <ErrorMessageBtn {...errorMessageProps} /> : null
                            }

                            componentProps.value = textValue;
                            componentProps.onChange = this.handleTextChange;

                            initialProps = {
                                ...initialProps,
                                onFocus: this.handleOnFocus,
                                onBlur: this.handleOnBlur
                            }

                            component = <UikInput
                                wrapperProps={wrapperProps}
                                {...initialProps}
                                {...field.fieldProps}
                                {...componentProps}
                            />
                        }
                        // number field end

                        // password field start
                        else if (field.DataType === 'Password') {
                            if (fieldRIDName.attrib.hideLabel) {
                                initialProps.label = null;
                            } else {
                                if(settings.updateByRID) {
                                    initialProps.label = gHelpers.getLabel({ key: 61363, fallback: 'New Password' });
                                }
                            }
                            // console.log(`fieldName`, fieldName);
                            // console.log(`initialProps`, initialProps);
                            // console.log(`field`, field)

                            let iconClass = '';
                            let componentProps = {
                                type: 'password',
                                iconPosition: 'right',
                                errorMessage: errorMessageProps ? <ErrorMessageBtn {...errorMessageProps} /> : null
                            }

                            if (fieldRIDName.showPassword) {
                                componentProps.type = 'text';
                                iconClass = 'primary';
                            }
                            componentProps.icon =
                                <div>
                                    <UikButton clear xs onClick={() => this.handleShowPassword(fieldName)}>
                                        <i className={`clickable ${iconClass} fas fa-eye`} />
                                    </UikButton>
                                    {
                                        settings.updateByRID
                                            ?
                                            <UikButton xs onClick={() => this.handleUpdatePassword(fieldName)}>
                                                {gHelpers.getLabel({ key: 61369, fallback: 'Update' })}
                                            </UikButton>
                                            : null
                                    }
                                </div>;

                            componentProps.onChange = this.handleTextChange;
                            componentProps.value = fieldRIDName.maskedPassword;

                            initialProps = {
                                ...initialProps,
                                onFocus: this.handleOnFocus,
                                onBlur: this.handleOnBlur
                            }

                            component = <UikFormInputGroup
                                key={`field-group-${idx}`}
                                direction={`horizontal`}
                                className={`align-items-center`}
                            >
                                {
                                    settings.updateByRID
                                        ? <UikInput
                                            label={gHelpers.getLabel({ key: 61375, fallback: 'Old Password' })}
                                            placeholder={gHelpers.getLabel({ isString: true, key: 61375, fallback: 'Old Password' })}
                                            key={`pw-old-${idx}`}
                                            wrapperProps={wrapperProps}
                                            type={componentProps.type}
                                            name={`${field.FullRID}`}
                                            onChange={this.handleOldPasswordChange}
                                        /> : null
                                }

                                <UikInput
                                    key={`pw-new-${idx}`}
                                    wrapperProps={wrapperProps}
                                    {...initialProps}
                                    {...field.fieldProps}
                                    {...componentProps}
                                />
                            </UikFormInputGroup>
                        }
                        // password field end

                        // field textarea start
                        else if (field.DataType === 'Text' || field.DataType === 'Array') {
                            if (fieldRIDName.attrib.hideLabel) {
                                initialProps.label = null;
                            }

                            let componentProps = {
                                // errorMessage: errorMessageProps ? <ErrorMessageBtn {...errorMessageProps} /> : null
                            }
                            let footerNotes;

                            if (wrapperProps.style) {
                                initialProps.style = wrapperProps.style;
                                wrapperProps.style = {};
                            }

                            componentProps.onChange = this.handleTextChange;
                            componentProps.value = textValue;

                            initialProps = {
                                ...initialProps,
                                onFocus: this.handleOnFocus,
                                onBlur: this.handleOnBlur,
                                onClick: (e) => {
                                    this.updateStateField(initialProps.name, {
                                        commentInputCaretPos: e.target.selectionStart
                                    });
                                    // this.props.updateField({
                                    //     keyName: initialProps.name,
                                    //     data: {
                                    //         commentInputCaretPos: e.target.selectionStart
                                    //     }
                                    // });
                                }
                            }

                            // on key down events start
                            if (field.onKeyDownEvents && Object.entries(field.onKeyDownEvents).length) {
                                initialProps.onKeyDown = (e) => {
                                    if (e.keyCode === 13 && e.shiftKey === true) {
                                        //
                                    }
                                    else {
                                        Object.entries(field.onKeyDownEvents).forEach(val => {
                                            const keyCode = val[0];
                                            if (keyCode == e.keyCode) {
                                                val[1](e);
                                            }
                                        })
                                    }
                                };
                            }
                            // on key down events end

                            // check maxcharacters
                            if (fieldRIDName.attrib.maxCharacters) {
                                initialProps = {
                                    ...initialProps,
                                    maxLength: fieldRIDName.attrib.maxCharacters
                                }
                                footerNotes = <p className={`gray`}>{fieldRIDName.charactersLeft} {gHelpers.getLabel({ key: 61381, fallback: 'out of' })} {fieldRIDName.attrib.maxCharacters} {gHelpers.getLabel({ key: 61387, fallback: 'characters.' })}</p>
                            }
                            let mainInputComp = <textarea
                                {...initialProps}
                                {...field.fieldProps}
                                {...componentProps}
                                className={`uik-input__input ${errorMessage ? "uik-input__errorHighlight" : ""}`}
                            />;

                            let linkCardComp = null;

                            // check if has link preview and update by RID
                            if (fieldRules
                                && fieldRules.onChange
                                && fieldRules.onChange.FE
                                && fieldRules.onChange.FE.ParseLink) {

                                if (settings.updateByRID

                                    && fieldValues[`${field.RID}.${fieldRules.onChange.FE.ParseLink}`]
                                    && fieldValues[`${field.RID}.${fieldRules.onChange.FE.ParseLink}`].newValue != '') {
                                    linkCardComp = <div>
                                        <LinkPreview
                                            fullRID={`${field.RID}.${fieldRules.onChange.FE.ParseLink}`}
                                            link={fieldValues[`${field.RID}.${fieldRules.onChange.FE.ParseLink}`].newValue}
                                            updateField={this.updateField}
                                        />
                                    </div>;
                                } else {
                                    let prefix = fieldName.split(".")[0];
                                    let linkFieldName = `${prefix}.${fieldRules.onChange.FE.ParseLink}`;
                                    if (!fieldValues[linkFieldName]) {
                                        linkFieldName = this.findFieldName("Link");
                                    }

                                    if (fieldValues[`${linkFieldName}`] && fieldValues[`${linkFieldName}`].newValue != '') {
                                        linkCardComp = <div>
                                            <LinkPreview
                                                fieldName={`${linkFieldName}`}
                                                link={fieldValues[`${linkFieldName}`].newValue}
                                                updateField={this.updateField} />
                                        </div>;
                                    }
                                }
                            }

                            // check if mention is enabled
                            if (field.WithMention) {
                                componentProps.onChange = (e, newValue, newPlainTextValue, mentions) => this.handleMentionTextChange(e, newValue, newPlainTextValue, mentions, fieldName);
                                componentProps.value = textValue;

                                delete initialProps.onBlur;
                            }

                            if (field.WithEmoji && field.WithMention) {
                                if (field.WithEmoji) {
                                    initialProps = {
                                        ...initialProps,
                                        onKeyUp: (e) => {
                                            this.updateStateField(initialProps.name, {
                                                commentInputCaretPos: e.target.selectionStart
                                            });
                                            // this.props.updateField({
                                            //     keyName: initialProps.name,
                                            //     data: {
                                            //         commentInputCaretPos: e.target.selectionStart
                                            //     }
                                            // });
                                        }
                                    }
                                }

                                mainInputComp = <div className={cls.commentsBox}>
                                    {
                                        field.WithEmoji === true &&
                                        <EmojiPicker
                                            onSelectEmoji={(emoji) => this.onSelectEmoji(emoji, initialProps.name)}
                                        />
                                    }
                                    {
                                        field.WithMention === true &&
                                        <MentionsInput
                                            name={fieldName}
                                            value={textValue}
                                            // onChange={(e, newValue, newPlainTextValue, mentions) => this.handleMentionTextChange(e, newValue, newPlainTextValue, mentions, fieldName)}
                                            className='uik-input__input'
                                            style={mentionInputStyle}
                                            {...initialProps}
                                            {...field.fieldProps}
                                            {...componentProps}
                                        >
                                            <Mention
                                                className={cls.commentsMention}
                                                trigger="@"
                                                data={fieldRIDName.mentionData}
                                                renderSuggestion={(
                                                    highlightedDisplay,
                                                    focused
                                                ) => {
                                                    return (
                                                        <div className={`user ${focused ? 'focused' : ''}`}>
                                                            <strong>{highlightedDisplay}</strong>
                                                        </div>
                                                    )
                                                }}
                                            />
                                        </MentionsInput>
                                    }
                                </div>

                            } else if (field.WithEmoji) {
                                if (field.WithEmoji) {
                                    initialProps = {
                                        ...initialProps,
                                        onKeyUp: (e) => {
                                            this.updateStateField(initialProps.name, {
                                                commentInputCaretPos: e.target.selectionStart
                                            });
                                            // this.props.updateField({
                                            //     keyName: initialProps.name,
                                            //     data: {
                                            //         commentInputCaretPos: e.target.selectionStart
                                            //     }
                                            // });
                                        }
                                    }
                                }

                                mainInputComp = <div className={cls.commentsBox}>
                                    {
                                        field.WithEmoji === true &&
                                        <EmojiPicker
                                            onSelectEmoji={(emoji) => this.onSelectEmoji(emoji, initialProps.name)}
                                        />
                                    }
                                    <textarea
                                        {...initialProps}
                                        {...field.fieldProps}
                                        {...componentProps}
                                        className={`uik-input__input ${errorMessage ? "uik-input__errorHighlight" : ""}`}
                                    />
                                </div>
                            }

                            component = <div
                                key={`text-area-${idx}`}
                                {...wrapperProps}>
                                {
                                    settings.editOnClick
                                        ? null
                                        :
                                        <span className='uik-content-title__wrapper'>{initialProps.label}</span> //content label
                                }
                                <div className='uik-input__inputWrapper'>
                                    {mainInputComp}  {/*comment box */}
                                    {footerNotes}
                                    {
                                        errorMessage ?
                                            <p className="uik-input__errorMessage">{errorMessageProps ? <ErrorMessageBtn {...errorMessageProps} /> : null}</p>
                                            : null
                                    }
                                </div>
                                {
                                    linkCardComp
                                }
                            </div>
                        }
                        // field textarea end

                        // file set field start
                        else if (field.DataType === 'FileSet' || field.DataType === 'Image') {
                            let componentProps = {};

                            if (settings.updateByRID) {
                                componentProps.value = textValue;
                            }

                            initialProps = {
                                ...initialProps,
                                value: !textValue ? "[]" : textValue,
                                mediaFiles: fieldRIDName.mediaFiles,
                                handleDoneSelecting: this.handleDoneSelecting,
                                // componentProps: componentProps,
                                fields: fields,
                            }

                            // check if single image
                            if (field.DataType === 'Image') {
                                initialProps.singleSelect = true;
                            }

                            component =
                                <div
                                    key={`file-set-${idx}`}
                                    {...wrapperProps}>
                                    {
                                        settings.editOnClick || settings.displayAsThumbnail
                                            ? null
                                            :
                                            <span className='uik-content-title__wrapper'>{initialProps.label}</span>
                                    }
                                    <div className={settings.displayAsThumbnail ? '' : 'uik-input__inputWrapper uik-input__inputFile'}>
                                        <BtnSelectMediaFiles
                                            onRef={ref => (this.fileUploadField = ref)}
                                            fieldGenerator={{
                                                ...initialProps,
                                                ...field.fieldProps,
                                                attrib: fieldRIDName.attrib,
                                                settings: {
                                                    displayAsThumbnail: settings.displayAsThumbnail ? true : false,
                                                    updateByRID: settings.updateByRID ? true : false
                                                },
                                                reloadComponent: this.loadComponent
                                            }}
                                        />
                                        {
                                            errorMessage ?
                                                <p className="uik-input__errorMessage">{errorMessageProps ? <ErrorMessageBtn {...errorMessageProps} /> : null}</p>
                                                : null
                                        }
                                    </div>
                                </div>
                        }
                        // file set field end
                        // default text field start
                        else {
                            if (fieldRIDName.attrib && fieldRIDName.attrib.hideLabel) {
                                initialProps.label = null;
                            }

                            let componentProps = {
                                type: 'text',
                                errorMessage: errorMessageProps ? <ErrorMessageBtn {...errorMessageProps} /> : null
                            }

                            initialProps = {
                                ...initialProps,
                                onFocus: this.handleOnFocus,
                                onBlur: this.handleOnBlur
                            }

                            // check if field has emoji picker start
                            if (field.WithEmoji === true) {
                                initialProps = {
                                    ...initialProps,
                                    icon: <EmojiPicker
                                        onSelectEmoji={(emoji) => this.onSelectEmoji(emoji, initialProps.name)}
                                    />,
                                    onKeyUp: (e) => {

                                        this.updateStateField(initialProps.name, {
                                            commentInputCaretPos: e.target.selectionStart
                                        });

                                        // this.props.updateField({
                                        //     keyName: initialProps.name,
                                        //     data: {
                                        //         commentInputCaretPos: e.target.selectionStart
                                        //     }
                                        // });
                                    },
                                    onClick: (e) => {
                                        this.updateStateField(initialProps.name, {
                                            commentInputCaretPos: e.target.selectionStart
                                        });

                                        // this.props.updateField({
                                        //     keyName: initialProps.name,
                                        //     data: {
                                        //         commentInputCaretPos: e.target.selectionStart
                                        //     }
                                        // });
                                    }
                                }
                                // check if field has emoji picker end

                                // on key down events start
                                if (field.onKeyDownEvents && Object.entries(field.onKeyDownEvents).length) {
                                    initialProps.onKeyDown = (e) => {
                                        Object.entries(field.onKeyDownEvents).forEach(val => {
                                            const keyCode = val[0];
                                            if (keyCode == e.keyCode) {
                                                if (settings.editOnClick) {
                                                    this.handleOnBlur(e);
                                                    return;
                                                }
                                                val[1]();
                                            }
                                        })
                                    };
                                }
                                // on key down events end

                                wrapperProps = {
                                    ...wrapperProps,
                                    className: `input-w-emoji`
                                }
                            }

                            componentProps.onChange = this.handleTextChange;
                            componentProps.value = textValue;

                            // check input type
                            if (fieldRIDName.attrib && fieldRIDName.attrib.InputType) {
                                initialProps.type = fieldRIDName.attrib.InputType;
                            }

                            // check if input has icon
                            if (fieldRIDName.attrib && fieldRIDName.attrib.InputIcon) {
                                initialProps.icon = fieldRIDName.attrib.InputIcon;
                            }
                            component = <UikInput
                                wrapperProps={wrapperProps}
                                {...initialProps}
                                {...field.fieldProps}
                                {...componentProps}
                            />
                        }
                        // default text field end


                        // if custom display component start
                        if (field.CustomComponent) {
                            component = <field.CustomComponent
                                key={`custom-component-${idx}`} {...fieldRIDName} initialProps={initialProps} />
                        }
                        // if custom display component end
                        else {
                            let fieldWrapperProps = {};
                            if (fieldRules.style) {
                                fieldWrapperProps.style = fieldRules.style;
                            }
                            component = <div
                                key={`fieldWrapper-${idx}`}
                                className={`fieldWrapper`}
                                {...fieldWrapperProps}
                            >
                                {component}
                                {
                                    fieldRIDName.updating
                                        ? loading2({
                                            borderWidth: 3,
                                            additionalCss: {
                                                position: 'absolute',
                                                top: `3px`,
                                                right: `3px`
                                            }
                                        }) : null
                                }
                            </div>
                        }

                        // if has additional component
                        if (field.AdditionalComponent) {
                            if (field.AdditionalComponent.addBefore) {
                                component = <React.Fragment key={`add-component`}><field.AdditionalComponent.component {...fieldRIDName} initialProps={initialProps} />{component}</React.Fragment>
                            } else {
                                component = <React.Fragment key={`add-component`}>{component} <field.AdditionalComponent.component {...fieldRIDName} initialProps={initialProps} /></React.Fragment>
                            }
                        }

                        // check if socket enabled to wrap the field component
                        if (settings.socketEnabled) {
                            const inputUsers = this.props.socketRdx.elements.find(x => x.id == fieldName);

                            if (inputUsers && inputUsers.users.length) {
                                component =
                                    <div
                                        key={`socket-wrapper-${idx}`}
                                        className="socket-wrapper">
                                        {component}
                                        <div key={`socket-user-${idx}`} className="socket-users">
                                            <UikTag
                                                key={`socket-user-${idx}`}
                                                className="socket-user-tag" color="blue" fill
                                                Component={"a"}
                                                to='#'
                                            >
                                                <span className='mr-1'>{inputUsers.users.length}</span><i className={`fas fa-user`} />
                                            </UikTag>
                                            <div className={`socket-users-list`}>
                                                <ul>
                                                    {
                                                        inputUsers.users.map((user, idx) => {
                                                            return <li key={idx} className={`color-${user.color}`}>{user.FirstName} {user.LastName}</li>
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                            } // if (inputUsers && inputUsers.users.length)
                        } // if(settings.socketEnabled)

                        // check if edit on click
                        const excludedOnEditOnClick = [
                            'FileSet',
                            'Image'
                        ];
                        if (settings.editOnClick && !excludedOnEditOnClick.includes(field.DataType)) {
                            const currentField = fieldValues[fieldName];

                            if (currentField && currentField.editOnClick) {
                                const cName1 = currentField.editOnClick.status == 'editable' ? 'd-none' : '';
                                const cName2 = currentField.editOnClick.status == 'read-only' ? 'd-none' : '';
                                component =
                                    <div key={`editable-${idx}`}>
                                        <div className={`display-text ${cName1}`} onClick={(e) => this.handleOpenEditable(e, initialProps.name)}>{textValue}</div>
                                        <div className={`editable-text ${cName2}`}>{component}</div>
                                    </div>
                            }
                        } // if(settings.editOnClick) {

                        // if avatar component
                        if (settings.navLinkIconComponent) {
                            let icon = <span class="far fa-file" />;

                            if (fieldRIDName.attrib.icon) {
                                icon = fieldRIDName.attrib.icon;
                            }

                            component = <UikNavLink
                                Component={'div'}
                                key={idx}
                                className={`navLink3`}
                                icon={icon}
                            >
                                {component}
                            </UikNavLink>
                        }

                        // check groupings
                        if (fieldRules.position) {
                            if (!fieldGroups[fieldRules.position.row]) {
                                fieldGroups[fieldRules.position.row] = [];
                            }

                            fieldGroups[fieldRules.position.row][fieldRules.position.col] = component;

                        } // if(fieldRules.position)

                        else {
                            fieldComponents.push(component);
                        } // else !(settings.fieldGroups)

                    } // if(fieldRIDName)

                } //if (!fields.excluded.includes(field.AttributeName))

            }); //  fields.fields.foreach((field, idx)

            // process field groups
            if (fieldGroups.length) {
                fieldGroups.forEach((rowComponents, idx) => {
                    const comp = <UikFormInputGroup
                        key={`field-group-${idx}`}
                        direction={`horizontal`}
                        className={`align-items-center`}
                    >
                        {rowComponents}
                    </UikFormInputGroup>

                    fieldComponentGroups.push(comp)
                })
            } // if(fieldGroups.length)

            return (
                <UikFormInputGroup className={`${settings.editOnClick ? `edit-on-click ${cls.editableBox}` : ''} field-gen ${settings.fieldGenAddClass ? settings.fieldGenAddClass : ''}`} style={{ maxWidth: '100%' }}>
                    {
                        isLoading ? loading('sm') : null
                    }
                    {fieldComponentGroups}
                    {fieldComponents}
                </UikFormInputGroup>
            )

        } else {
            return loading('sm');
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldsGenerator);