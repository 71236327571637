import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";

import { config } from '../../../helpers';

import cls from './header.module.scss';

class Breadcrumbs extends Component {

    render() {
        const { routes, location } = this.props;
        const menuList = [];

        const currentPath = (window.location.href).replace(config.baseURL, '');
        const currentModule = location.pathname.split("/")[1];
        // console.log(currentModule)

        let i = 0;
        let route = routes[i];
        while (route && route.path != currentPath) {
            route = routes[i];
            if(route && route.path.indexOf(`/${currentModule}`) === 0) {
                menuList.push(
                    <li key={`bcrumb-${i}`} className={cls.menuItem}>
                        <Link to={route.path} className={`${cls.menuLink} ${route.path == currentPath ? cls.menuLinkActive : null}`}>{route.name}</Link>
                    </li>
                )
            }

            i++;
        }

        return null; // temp

        return (
            <div className={cls.breadcrumbsWrapper}>
                <ul className={cls.menuList}>
                    <li className={cls.menuItem}>
                        <Link to="/" className={cls.menuLink}>Home</Link>
                    </li>
                    {menuList}
                </ul>
            </div>
        )
    }
}

export default withRouter(Breadcrumbs);