const updateField = (data) => dispatch => {
    dispatch({
        type: 'UPDATE_FIELDS',
        payload: data
    })
}

const removeFields = (data) => dispatch => {
    dispatch({
        type: 'REMOVE_FIELDS',
        payload: data
    })
}

const reloadFields = (data) => dispatch => {
    dispatch({
        type: 'RELOAD_FIELDS',
        payload: data
    })
}

const fieldActions = {
    updateField: updateField,
    reloadFields: reloadFields,
    removeFields: removeFields
}

export {
    fieldActions
}