const isLoading = (status) => dispatch => {
    dispatch({
        type: 'IS_LOADING',
        payload : status
    })
}

const isLocalStorageUpdated = (status) => dispatch => {
    dispatch({
        type: 'IS_LOCAL_STORAGE_UPDATED',
        payload : status,
    })
}

const isFullWidthPage = (status) => dispatch => {
    dispatch({
        type: 'IS_FULLWIDTH_PAGE',
        payload : status
    })
}

const isMobileWidthPage = (status) => dispatch => {
    dispatch({
        type: 'IS_MOBILEWIDTH_PAGE',
        payload : status
    })
}

const isProcessing = (data) => dispatch => {
    dispatch({
        type: 'IS_PROCESSING',
        payload : data
    })
}

const isSidebarMinimized = (status) => dispatch => {
    dispatch({
        type: 'IS_SIDEBAR_MINIMIZED',
        payload : status
    })
}

const globalStateAction = {
    isLoading: isLoading,
    isLocalStorageUpdated: isLocalStorageUpdated,
    isProcessing: isProcessing,
    isSidebarMinimized: isSidebarMinimized,
    isFullWidthPage: isFullWidthPage,
    isMobileWidthPage: isMobileWidthPage,
}

export {
    globalStateAction
}