import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { callApi, gHelpers, config, localToObject, loading, toTimeZone, genericQuery, IsJSON } from '../../../../helpers';
import { notificationActions } from '../../../../redux/actions/notifications';
import { InView } from 'react-intersection-observer';

import {
    UikDropdown,
    UikTopBarLink,
    UikScrollArea,
    UikTag
} from '../../../../@uik';

import Notification from '../../../../components/Notification';
import io from 'socket.io-client';
import ReactTooltip from 'react-tooltip';

import cls from './header-notifications.module.scss';

var socket;

const mapStateToProps = state => ({
    socketRdx: {
        selfUser: state.selfUser,
    },
    notifIO: {
        notifSelfCID: state.notifSelfCID,
        connect: state.notificationsConnect
    }
});
const mapDispatchToProps = dispatch => ({
    notificationsConnect: (data) => dispatch(notificationActions.notificationsConnect(data)),
    notifSelfCIDAction: (data) => dispatch(notificationActions.notifSelfCID(data)),
});
// test notifcations data

const NotifcationBtn = ({ onClick, countNotifs, onOpen }) => (
    <>
        <UikTopBarLink className={cls.navLink}
            onClick={() => {
                onClick();
                onOpen();
            }}
            data-tip
            data-for={`ttip-top-header-notif`}
        >
            <i className={`${cls.iconsStyle} fas fa-bell`} />
            {
                countNotifs
                    ? <UikTag className={cls.counterTag} color="red" fill>{countNotifs}</UikTag>
                    : null
            }
        </UikTopBarLink>

        <ReactTooltip id={`ttip-top-header-notif`} type='info'>
            <span>{gHelpers.getLabel({ isString: true,  key: 61279, fallback: 'Notifications' })}</span>
        </ReactTooltip>
    </>
);

class HeaderNotifications extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            nextPage: 0,
            nextPageLoading: false,
            notifications: [],
            newNotifications: [],
            countNotifs: 0
        }

        this.getNotification = this.getNotification.bind(this);
        this.testNotif = this.testNotif.bind(this);
        this.getNextPage = this.getNextPage.bind(this);
        this.handlePreviewNotifications = this.handlePreviewNotifications.bind(this);
        this.markAllAsRead = this.markAllAsRead.bind(this);
    }

    testNotif(e) {
        e.preventDefault();
        this.props.notifIO.connect.emit('notify', [10004]);
    }

    getNextPage() {
        // e.preventDefault();

        this.setState(prevState => {
            return {
                page: prevState.page + 1,
                nextPageLoading: true
            }
        }, () => this.getNotification())
    }

    getNotification() {

        let passObject = {
            'Module': 'system_notification_getall',
            'Parameters': {
                'Page': this.state.page,
                // 'Limit': 3
            }
        }

        callApi({
            passObject: passObject,
            success: (response) => {
                if (response.Data.Notifications) {
                    // reset if page 1
                    if (this.state.page <= 1) {
                        this.setState({
                            notifications: [],
                            nextPageLoading: false
                        })
                    }

                    const notifications = response.Data.Notifications.map(notif => {
                        return {
                            ...notif,
                            DT_Created: toTimeZone(notif.DT_Created, Intl.DateTimeFormat().resolvedOptions().timeZone)
                        }
                    });

                    if (typeof notifications === 'object') {
                        // console.log(notifications)
                        notifications.forEach(notification => {
                            if (!notification.IsSeen && !notification.IsPreviewed) {
                                if (notification.Type == 'Facebook Boosted Failed') {
                                    const msg = IsJSON(notification.Message) ? JSON.parse(notification.Message) : null;
                                    if (msg) {
                                        notification.Message = <div dangerouslySetInnerHTML={{ __html: `<i class="fas fa-times red"></i> ${msg.error.error_user_title ? msg.error.error_user_title : msg.error.message} <br> <i class="fas fa-times red"></i> ${msg.error.error_user_msg}` }} />;
                                    }
                                }

                                gHelpers.notif('notify', {
                                    title: notification.Type,
                                    msg: notification.Message,
                                    cb: () => {

                                        passObject = {
                                            'Module': 'system_notification_seen',
                                            'Parameters': {
                                                'NotificationID': notification.NotificationID
                                            }
                                        }
                                        callApi({
                                            passObject: passObject,
                                            success: () => {
                                                // window.location.href = notification.URL;
                                                window.open(
                                                    notification.URL,
                                                    '_blank'
                                                );
                                            },
                                            unSuccess: (response) => {
                                                console.log(response);
                                            }
                                        });

                                    }
                                });
                            }
                        });

                        if (notifications.some(n => (!n.IsSeen && !n.IsPreviewed))) {
                            passObject = {
                                'Module': 'system_notification_preview',
                                'Parameters': {
                                    'NotificationIDs': notifications
                                        .filter(n => (!n.IsSeen && !n.IsPreviewed))
                                        .map(n => n.NotificationID)
                                }
                            }
                            callApi({
                                passObject: passObject,
                                success: (response) => {
                                    // console.log(response);
                                },
                                unSuccess: (response) => {
                                    console.log(response);
                                }
                            });
                        }

                    } // if(typeof notifications === 'object')

                    this.setState(prevState => ({
                        notifications: [
                            ...prevState.notifications,
                            ...notifications
                        ],
                        nextPage: response.Data.NextPage ? response.Data.NextPage : 0,
                        countNotifs: response.Data.NotificationCount,
                        nextPageLoading: false
                    }))
                }
            },
            unSuccess: (response) => {
                gHelpers.notif('error', { msg: response.Status.Message });
            }
        });
    }

    handlePreviewNotifications() {
        const { notifications } = this.state;
        const previewedIDs = [];

        let holdNotifs = notifications.map(notification => {
            if (!notification.IsPreviewed) {
                previewedIDs.push(notification.NotificationID)
            }

            return {
                ...notification,
                IsPreviewed: true
            }
        });

        if (previewedIDs.length > 0) {
            this.setState({
                notifications: holdNotifs
            })
        }

        let passObject = {
            'Module': 'system_notification_preview',
            'Parameters': {
                'NotificationIDs': previewedIDs
            }
        }

        callApi({
            passObject: passObject,
            success: (response) => {
                // console.log(response)
                // this.getNotification()
            },
            unSuccess: (response) => {
                console.log(response);
            }
        });
    }

    componentDidMount() {

        socket = io.connect(config.socketURL, { transports: ['websocket'], secure: true });
        this.props.notificationsConnect(socket);

        socket.on('disconnect', function () {
            console.log('disconnected!');
        });

        socket.on('roomdetails', (data) => {
            console.log('roomdetails', data)
        });

        socket.on('userJoined', user => {
            // console.log(user)
            if (user.UserID === this.props.socketRdx.selfUser.id) {
                this.props.notifSelfCIDAction(user.socketid);
            }
            socket.emit('updateUsers', this.props.socketRdx.selfUser)
        });

        socket.emit('subscribe', {
            roomid: `/notifications-${localToObject('UserDetails').User.CustomerID}/`,
            user: this.props.socketRdx.selfUser
        });

        socket.on('notify', (users) => {
            // console.log('notify', users);
            if (users.length
                && users.some(u => u == localToObject('UserDetails').User.UserID)) {
                this.setState({
                    page: 1,
                    nextPage: 0
                }, () => this.getNotification())
            }
        });

        this.getNotification();
    }

    markAllAsRead() {
        let _this = this;
        const formDataObject = new FormData();
        formDataObject.append('requestjson', JSON.stringify({
            'Module': 'system_notification_seenall',
        }));
        genericQuery(
            {
                Url: 'single_api/',
                Method: 'POST',
                Data: {
                    requestjson: formDataObject
                },
                ResponseSuccessCallback: function (response) {
                    if (response.data.Status.IsSuccess) {
                        _this.getNotification();
                        gHelpers.notif('success', { msg: response.data.Status.Message });
                    } else {
                        gHelpers.notif('error', { msg: response.data.Status.Message });
                    }
                }.bind(this),
                ResponseFailCallback: function (response) {
                    // do when fail
                    gHelpers.notif('error', { msg: response.data.Status.Message });
                }.bind(this),
            }
        );
    }

    render() {
        return (
            <UikDropdown
                DisplayComponent={NotifcationBtn}
                displayComponentProps={
                    {
                        countNotifs: this.state.countNotifs,
                        onOpen: this.handlePreviewNotifications
                    }
                } position="bottomRight"
            >
                <div className={cls.dropdown}>
                    <div
                        className={cls.readAllNotifications}
                    >
                        <a
                            onClick={(e) => {
                                e.preventDefault();
                                this.markAllAsRead();
                            }}>
                            {gHelpers.getLabel({ key: 61285, fallback: 'Mark all as read' })}
                        </a>
                    </div>
                    <UikScrollArea className={`${cls.dropdownMain} showScrollbar`}>
                        {
                            this.state.notifications.length
                                ? // has notifs
                                this.state.notifications.map((notification, idx) => {
                                    return <Notification key={idx} notification={notification} />
                                })
                                : // has no notifs
                                <p className={`p-2`}>{gHelpers.getLabel({ key: 61291, fallback: 'No notifications' })}</p>
                        }
                        <div className={`p-2`}>
                            {
                                this.state.nextPageLoading ?
                                    loading('xs')
                                    : null
                            }
                            {
                                this.state.nextPage > 0
                                    ?
                                    <InView as="div" onChange={(inView, entry) => {
                                        if (inView) {
                                            this.getNextPage()
                                        }
                                    }}>
                                        <div className={`text-center`}>{gHelpers.getLabel({ key: 61297, fallback: 'Loading more...' })}</div>
                                    </InView>

                                    : null
                            }
                        </div>
                    </UikScrollArea>
                    {/* Ends Here */}

                    {/* <button onClick={this.testNotif}>
                            test btn
                        </button> */}
                </div>
            </UikDropdown>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNotifications);