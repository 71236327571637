import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../../logo.svg';

import {
    UikWidgetContent,
    UikWidget,
    UikFormInputGroup,
    UikHeadline,
    UikDivider,
    UikButton,
    UikScrollArea,
    UikCheckbox,
} from '../../../@uik';

import { genericQuery, localToObject, config, isAccountTermsSigned, refreshUserDetails, loading, gHelpers } from '../../../helpers';
import FieldsGenerator from '../../../helpers/fieldGenerator';

import cls from './style.module.scss';

class NewAccountWelcome extends Component {
    constructor(props) {
        super(props);
        document.title = props.pageName;

        this.state = {
            isProcessing: false,
            messageError: '',
            isSuccess1: false,
            isSuccess2: false,
            TimeZones: [],
            isLoading: false,
            fields: {},
        };

        this.handleSubmit = this.handleSubmit.bind(this);

        if (isAccountTermsSigned) {
            window.location.href = "/";
        }
    }

    handleSubmit(e) {
        e.preventDefault(); //prevents reload of page
        const { isProcessing } = this.state;
        const { RID } = localToObject('UserDetails').User;

        // do not proceed if still processing
        if (isProcessing) {
            return false;
        }

        this.setState({
            isProcessing: true,
            messageError: '',
        });

        // accept terms and conditions
        const passObjectTerms = {
            'Module': 'system_user_acceptterms',
            'Parameters': {
                'RID': RID
            }
        };
        const formDataObjectTerms = new FormData();
        formDataObjectTerms.append('requestjson', JSON.stringify(passObjectTerms));
        genericQuery({
            Url: 'single_api/',
            Method: 'POST',
            Data: {
                requestjson: formDataObjectTerms
            },
            ResponseSuccessCallback: function (response) {
                if (response.data.Status.IsSuccess) {
                    refreshUserDetails({
                        success: function () {
                            window.location.href = config.baseURL;
                        }.bind(this)
                    });
                }

            }.bind(this),
            ResponseFailCallback: function (response) {
                // do when fail
                this.setState({
                    messageError: gHelpers.getLabel({ key: 61351, fallback: 'An error occurred during the process.' }),
                    messageInfo: '',
                    isProcessing: false
                });
            }.bind(this),
        });
        // genericQuery({ Url: 'single_api/', ... user_company_update

    }

    componentDidMount() {
        this.setState({
            isLoading: false
        })

        // get TimeZones List
        const passObjectTerms = {
            'Module': 'system_setting_get',
            'Parameters': {
                'Settings': [
                    'TimeZone'
                ]
            }
        };
        const formDataObjectTerms = new FormData();
        formDataObjectTerms.append('requestjson', JSON.stringify(passObjectTerms));
        genericQuery({
            Url: 'single_api/',
            Method: 'POST',
            Data: {
                requestjson: formDataObjectTerms
            },
            ResponseSuccessCallback: function (response) {
                if (response.data.Status.IsSuccess) {
                    this.setState({
                        TimeZones: response.data.Data.Timezone,
                        isLoading: false
                    });
                }
            }.bind(this),
            ResponseFailCallback: function (response) {
                // do when fail
                this.setState({
                    messageError: gHelpers.getLabel({ key: 61351, fallback: 'An error occurred during the process.' }),
                    messageInfo: '',
                    isProcessing: false
                });
            }.bind(this),
        });
        // genericQuery({ Url: 'single_api/', ... user_company_update

        if (localToObject('UserDetails').User.UserID) {
            const userID = localToObject('UserDetails').User.UserID;

            const formDataObject = new FormData();
            formDataObject.append('requestjson', JSON.stringify({
                'Module': 'media_schedule_getuser',
                'Parameters': {
                    'UserID': userID,
                }
            }));

            // get options for additional fields
            genericQuery(
                {
                    Url: 'single_api/',
                    Method: 'POST',
                    Data: {
                        requestjson: formDataObject
                    },
                    ResponseSuccessCallback: function (response) {
                        if (response.data.Status.IsSuccess) {
                            const passFields = response.data.Data.MediaScheduleUser;

                            let fields = {
                                fields: passFields,
                                excluded: [
                                    'CustomerID', 'WebsiteTermsSigned', 'ConfirmPassword'
                                ]
                            };

                            this.setState({
                                fields: fields
                            });
                        } else {
                            gHelpers.notif('error', { msg: response.data.Status.Message });

                        }
                    }.bind(this),
                    ResponseFailCallback: function (response) {
                        // do when fail
                    }.bind(this),
                }
            ); // genericQuery(...
        }
    }

    render() {
        const { isProcessing, messageError, isSuccess1, isSuccess2, isLoading, fields } = this.state;

        let fieldGenerator;

        if (fields.fields) {
            fieldGenerator = <FieldsGenerator fields={fields}
                settings={{
                    updateByRID: true
                }}
            />
        }

        if (isSuccess1 && isSuccess2) {
            refreshUserDetails({
                success: (response) => {
                    window.location.href = config.baseURL + '/';
                }, fail: (response) => {

                }
            });
        }

        return (
            <div className={`${cls.pageWrapper}`}>
                <div>
                    <div className="text-center">
                        <Link to="/">
                            <img src={`/logo_socrates.svg`} alt="" width="150" style={{ background: '#0f2850', padding: '5px' }} />
                        </Link>
                    </div>
                    <div className={cls.contentWidgetWrapper}>
                        <UikWidget
                            className={cls.widgetWrapper}
                        >
                            <div className={cls.content}>
                                {
                                    isLoading ? loading() : null
                                }
                                <form method="POST" onSubmit={this.handleSubmit}>
                                    <UikWidgetContent className={cls.left}>
                                        <div className="text-center">
                                            <UikHeadline>
                                                {gHelpers.getLabel({ key: 1437, fallback: 'Welcome to Socrates!' })}
                                            </UikHeadline>
                                        </div>
                                        <p className="red">{messageError}</p>
                                        {fieldGenerator}

                                        <UikDivider margin />

                                        <div className={`${cls.textContent} mt-2`}>
                                            <h4>Terms and Conditions</h4>
                                            <UikScrollArea className={cls.scrollTextContent}>
                                                <h5>Lorem ipsum dolor sit amet</h5>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                                <h5>Lorem ipsum dolor sit amet</h5>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                                <h5>Lorem ipsum dolor sit amet</h5>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                                <h5>Lorem ipsum dolor sit amet</h5>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                                <h5>Lorem ipsum dolor sit amet</h5>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                            </UikScrollArea>
                                        </div>

                                        <UikDivider margin />

                                        <UikFormInputGroup className="mb-2">
                                            <UikCheckbox
                                                label={(<span>{gHelpers.getLabel({ key: 62209, fallback: 'By signing up, you agree to the' })} <Link to="#" className="blue">{gHelpers.getLabel({ key: 62215, fallback: 'Terms of Service' })}</Link> {gHelpers.getLabel({ key: 62227, fallback: 'and' })} <Link to="#" className="blue">{gHelpers.getLabel({ key: 62221, fallback: 'Privacy Policy' })}</Link>.</span>)}
                                                required
                                            />
                                        </UikFormInputGroup>

                                        <div className="text-center">
                                            <UikButton
                                                type="submit"
                                                className={cls.btnAction}
                                                success
                                                isLoading={isProcessing ? true : false}
                                            >
                                                {gHelpers.getLabel({ key: 1488, fallback: 'Get Started with Socrates!' })}
                                            </UikButton>
                                        </div>
                                    </UikWidgetContent>
                                </form>
                            </div>
                        </UikWidget>
                    </div>

                </div>
            </div>

        )
    }
}

export default NewAccountWelcome;