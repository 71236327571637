import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { localToObject, socket, getLabel } from './index';
import { socketActions } from '../redux/actions/socket';
import { fieldActions } from '../redux/actions/fieldGenerator';
import { globalStateAction } from '../redux/actions/global';
import { mediaFileLibraryActions } from '../redux/actions/mediaFileLibrary';
import { UikTag } from '../@uik';
import cls from './helpers.module.scss';
import ReactTooltip from 'react-tooltip';

function convertToSlug(text) {
    return text.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-')
}

const mapStateToProps = state => ({
    globalState: {
        isLoading: state.isLoading
    },
    socketRdx: {
        selfUser: state.selfUser,
        users: state.users,
        operations: state.operations,
        elements: state.elements,
        socketEnabled: state.enableSocket,
        connect: state.socketConnect,
    },
    fieldRdx: {
        fields: state.fields
    },
    mediaFileLibary: {
        selectedFiles: state.selectedMediaFiles,
        listMediaFiles: state.listMediaFiles
    },
});
const mapDispatchToProps = dispatch => ({
    updateSelectedMediaFilesFieldAction: (status) => dispatch(mediaFileLibraryActions.updateSelectedMediaFilesField(status)),
    updateSelectedMediaFilesAction: (status) => dispatch(mediaFileLibraryActions.updateSelectedMediaFiles(status)),
    isLoadingAction: (status) => dispatch(globalStateAction.isLoading(status)),
    listOperations: (data) => dispatch(socketActions.listOperations(data)),
    updateSelfUser: (data) => dispatch(socketActions.updateSelfUser(data)),
    socketConnect: (data) => dispatch(socketActions.socketConnect(data)),
    listElements: (data) => dispatch(socketActions.listElements(data)),
    updateModule: (data) => dispatch(socketActions.updateModule(data)),
    updateField: (data) => dispatch(fieldActions.updateField(data)),
    updateUser: (data) => dispatch(socketActions.updateUser(data)),
    listUsers: (data) => dispatch(socketActions.listUsers(data)),
});

class SocketIO extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            selfuser: {},
            operations: []
        }

        // this.initSocketFields = this.initSocketFields.bind(this);
        this.updateInputUsers = this.updateInputUsers.bind(this);
        this.refreshSocket = this.refreshSocket.bind(this);
    }

    refreshSocket() {

        if (socket) {

            socket.on('disconnect', function () {
                console.log('disconnected!');
                socket.emit('subscribe', {
                    roomid: `${localToObject('UserDetails').User.CustomerID}-${this.props.location.pathname}`,
                    user: this.props.socketRdx.selfUser
                });
            }.bind(this));

            socket.on('roomdetails', (data) => {
                console.log('roomdetails', data)
            })

            socket.on('userJoined', user => {
                socket.emit('updateUsers', this.props.socketRdx.selfUser)
                // step 1 on updating latest elements
                socket.emit('updateNewUserElements');
            })

            // socket.emit('connectedClients');
            // socket.on('connectedClients', (data) => {
            //     console.log('connectedClients', data)
            // })

            this.props.listUsers([]);
            socket.on('updateUsers', user => {
                // console.log("[check user]", user);
                // update users list
                const objIndex = this.props.socketRdx.users.findIndex(el => el.id === user.id);
                if (objIndex === -1 && localToObject('UserDetails').User.UserID !== user.id) {
                    this.props.listUsers([...this.props.socketRdx.users, user]);
                }
            });

            // for existing users
            // step 2 on updating latest elements
            socket.on('getLatestElements', payload => {
                socket.emit('passUpdatedElements', this.props.fieldRdx.fields);
            });

            /* 
            // commented, socket-enabled field updates will be done on fieldgenerator.js 22/04/2020 - Jhun
            // step 3 on updating latest elements
            socket.on('applyUpdatedElements', fields => {
                latestFieldsTime = fields.timestamp;

                if (this.props.socketRdx.socketEnabled.status && this.props.socketRdx.socketEnabled.module != 'MediaSchedule') {
                    if (fields.timestamp >= latestFieldsTime && !appliedUpdatedElements) {
                        for (var key in fields) {
                            if (fields.hasOwnProperty(key) && key != 'timestamp') {
                                // check if datetime to convert it to datetime type
                                if (fields[key].attrib) {
                                    if (fields[key].attrib.DataType === 'DateTime') {
                                        fields[key].newValue = new Date(fields[key].attrib.Value);
                                    }
                                }

                                this.props.updateField({
                                    keyName: key,
                                    data: fields[key]
                                });
                            }
                        }
                        appliedUpdatedElements = true;
                    }
                }
            }); 
            */

            socket.on('userLeft', socketid => {
                console.log('a user has left', socketid)
                const users = this.props.socketRdx.users.filter(el => el.socketid !== socketid)
                this.props.listUsers(users);
            })

            socket.on('updateModule', data => {
                // trigger update on existin module
                if (data.status) {
                    this.props.updateModule(data);
                }
            })

            socket.on('updateComments', data => {
                console.log("[comments received!]");
            })

            socket.on('operation', operation => {
                const { type, element, user, newFocusedElement } = operation
                this.updateInputUsers(type, element, user, newFocusedElement);
                // console.log(type, element, user);
                // const elem = document.getElementById(operation.element);
                let newValue = operation.content;
                const fieldValues = this.props.fieldRdx.fields;
                const getData = fieldValues[operation.element];

                switch (type) {

                    case 'add':
                        const objFound = this.props.socketRdx.operations.some(el => el.slugname === operation.slugname)
                        if (!objFound) {
                            this.props.listOperations([...this.props.socketRdx.operations, operation]);
                        }
                        break

                    case 'remove':
                        const operations = this.props.socketRdx.operations.filter(el => el.slugname !== operation.slugname)
                        this.props.listOperations(operations)
                        break

                    case 'update' || 'init':
                        // console.log(getData)
                        let fNewData = {
                            newValue: newValue
                        }

                        if (getData && getData.attrib && getData.attrib.DataType === 'Bit') {
                            fNewData.newValue = operation.content ? 1 : 0;
                        }

                        // format datetime
                        if (getData && getData.attrib && getData.attrib.DataType === 'DateTime') {
                            fNewData.datetimeValue = new Date(newValue);
                        }

                        this.props.updateField({
                            keyName: operation.element,
                            data: fNewData
                        });
                        break

                    case 'updateByRID':
                        let passData = {
                            oldValue: newValue,
                            newValue: newValue
                        }

                        // image file
                        if (getData && getData.attrib && getData.attrib.DataType === 'Image') {
                            passData.tempSrc = newValue;
                        }

                        // checkbox or radio button
                        if (getData && getData.attrib && getData.attrib.DataType === 'Bit') {
                            passData.newValue = operation.content ? 1 : 0;
                        }

                        // format datetime
                        if (getData && getData.attrib && getData.attrib.DataType === 'DateTime') {
                            passData.datetimeValue = new Date(newValue);
                        }

                        // media file
                        if (getData && getData.attrib && getData.attrib.DataType === 'FileSet') {

                            let mediaFiles = this.props.mediaFileLibary.listMediaFiles;
                            const selectedFilesId = JSON.parse(newValue);
                            let selectedFiles = [];

                            selectedFilesId.map(fileId => {
                                selectedFiles.push(mediaFiles.find(mf => mf.MediaFileID == fileId));
                            })
                            this.props.updateSelectedMediaFilesFieldAction({
                                selectedFiles: selectedFiles,
                                keyName: getData.attrib.FullRID
                            });
                        }

                        this.props.updateField({
                            keyName: operation.element,
                            data: passData
                        });
                        break

                    default:
                        break
                }
            });

            socket.emit('subscribe', {
                roomid: `${localToObject('UserDetails').User.CustomerID}-${this.props.location.pathname}`,
                user: this.props.socketRdx.selfUser
            });

        }
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this)
        }
        this.refreshSocket();
        // this.props.isLoadingAction(true);
        // const socket = io.connect(config.socketURL, { transports: ['websocket'], secure: true });
        // this.props.socketConnect(socket);
    }

    componentDidUpdate(prevProps) {
        // check location change
        if (this.props.location.pathname !== prevProps.location.pathname) {
            socket.emit('unsubscribe');
            this.refreshSocket();
        }
    }

    operationGenerator = (action, type, element, content) => {
        const slugname = convertToSlug(element + this.props.socketRdx.selfUser.id)
        return {
            user: this.props.socketRdx.selfUser,
            action,
            type,
            element,
            slugname,
            content,
        }
    }

    updateInputUsers(action, elemId, user, newFocusedElementId) {
        // console.log(action, elemId, user)
        const elements = this.props.socketRdx.elements;
        let users = [];

        // check if has users
        if (elements.length) {
            const elIdx = elements.findIndex(el => el.id === elemId && el.users.length);
            if (elIdx !== -1) {
                users = elements[elIdx].users;
            }
        }

        switch (action) {
            case 'add':
                // check if exists
                if (!users.find(x => x.id == user.id)) {
                    users.push(user);
                }
                break;

            case 'remove':
                const removeIdx = users.indexOf(user)
                users.splice(removeIdx, 1);
                break;

            case 'updateByRID':
                elemId = newFocusedElementId;
                break;

            default:
                break;
        }
        // console.log(users.length)

        this.props.listElements({
            id: elemId,
            users: users
        });


        if (user) {
            this.props.updateUser({
                id: user.id,
                focusedElementId: elemId
            });
        } // if (user)
    }

    render() {
        const pageUsers = this.props.socketRdx.users;
        let userTags = [];

        if (pageUsers.length) {
            pageUsers.map((user, idx) => {
                const userPageDisplay = user.ProfilePictureURL ? <img style={{ height: "100%" }} src={user.ProfilePictureURL} /> : user.FirstName[0] + user.LastName[0];
                // const userInitials = user.FirstName[0] + user.LastName[0];
                const tag =
                    <span
                        className={user.ProfilePictureURL && cls.withImage}
                        key={`socket-user-box-${idx}`}>
                        <UikTag
                            key={`socket-user-${idx}`}
                            className={`${cls.userTag}`}
                            color={user.color}
                            fill
                            Component={Link}
                            to='#'
                            data-tip
                            data-for={`socket-user-${idx}`}
                        >
                            {userPageDisplay}
                        </UikTag>
                        <ReactTooltip
                            key={`socket-user-tooltip-${idx}`} id={`socket-user-${idx}`} type='info'>
                            <span>{`${user.FirstName} ${user.LastName}`}</span>
                        </ReactTooltip>
                    </span>
                userTags.push(tag);
            });
        }

        return (
            <div className={cls.userTags} onClick={this.initSocketFields}>
                <span className={cls.title}>{getLabel({ key: 61411, fallback: 'Other users on this page' })}</span>
                <div>{userTags}</div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SocketIO));