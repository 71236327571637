import React, { Component } from 'react';
import Modal from 'react-modal';
import {
    UikWidget,
    UikWidgetContent,
    UikHeadline,
    UikHeadlineDesc
} from '../../@uik';

import cls from './modal.module.scss';

const customStyles = {
    content: {
        top: '0',
        left: '0',
        margin: 'auto',
        minWidth: '150px',
        maxWidth: '75%',
        padding: '0',
        borderRadius: '10px',
        maxHeight: '768px',
        position: 'relative'
    }
}

class ModalGeneric extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { modalIsOpen, fullHeight } = this.props;
        let customStylesComp = customStyles;
        let hasFooter = false;
        const mediaMatch = window.matchMedia('(min-width: 500px)');

        if (this.props.modalFooterComponent) {
            hasFooter = true;
        }

        if (this.props.customStyles) {
            customStylesComp = {
                ['content']: {
                    ...customStylesComp['content'],
                    ...this.props.customStyles['content']
                },
            };
        }

        return (
            <Modal
                isOpen={modalIsOpen}
                style={customStylesComp}
                className={`${!mediaMatch.matches ? cls.isMobileViewModalStyle : ''}`}
                onRequestClose={this.props.handleClose}
            >
                {
                    !this.props.noHeader
                        ?
                        <div className={`${cls.modalHeader} ${this.props.headerCentered ? 'text-center' : ''}`}>
                            <UikHeadline className={cls.modalHeaderText}>
                                {
                                    this.props.headerText
                                        ? this.props.headerText
                                        : ''
                                }
                            </UikHeadline>
                            {
                                this.props.headerSubText
                                    ? <UikHeadlineDesc className={cls.modalHeaderSubText}>
                                        {this.props.headerSubText}
                                    </UikHeadlineDesc>
                                    : null
                            }
                            <a to='#' className={`btnClose ${cls.btnClose}`} onClick={(e) => {
                                e.preventDefault();
                                this.props.handleClose()
                            }}>
                                <i className={`${cls.iconClose} fas fa-times`} />
                            </a>
                        </div>
                        : null
                }
                <UikWidget className={`${cls.modalWidgetContainer} ${!hasFooter || fullHeight ? cls.modalWidgetContainerFullHeight : ''} showScrollbar ${!mediaMatch.matches && cls.isMobileModalWidgetContainerFullHeight}`}>
                    {
                        this.props.noHeader
                            ? <a to='#' className={`btnClose ${cls.btnClose}`} onClick={(e) => {
                                e.preventDefault();
                                this.props.handleClose()
                            }}>
                                <i className={`${cls.iconClose} fas fa-times`} />
                            </a>
                            : null
                    }
                    <UikWidgetContent className={cls.modalWidgetContent}>
                        {this.props.children}
                    </UikWidgetContent>
                </UikWidget>
                {
                    hasFooter
                        ? <div
                            className={`${cls.modalSelectFilesFooter}`}
                            style={this.props.modalFooterComponentStyle ? this.props.modalFooterComponentStyle : null}
                        >
                            {
                                this.props.modalFooterComponent
                            }
                        </div>
                        : null
                }
            </Modal>
        )
    }
}

export default ModalGeneric;
