import React, { Component } from 'react';
import { connect } from 'react-redux';
import ModalGeneric from '../../../../components/Modal';
import QuickPostForm from '../../../../components/Forms/quickpost';
import { hideMediaScheduleOuting } from '../../../../redux/actions/mediaSchedule';

const customStyles = {
    content: {
        maxWidth: '100%',
        width: '760px',
        margin: 'auto'
    }
}

const mapDispatchToProps = dispatch => ({
    hideMediaScheduleOuting: (data) => dispatch(hideMediaScheduleOuting(data))
});
class ModalQuickPost extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: false
        }
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.setState({
            modalIsOpen: false
        })
    }

    componentWillUnmount() {
        this.props.hideMediaScheduleOuting(false)
    }

    componentDidMount() {
        this.props.hideMediaScheduleOuting(true)

        this.setState({
            modalIsOpen: this.props.modalIsOpen
        })
    }

    render() {
        let modalIsOpen = this.props.modalIsOpen;

        return (
            <ModalGeneric
                {...this.props.modalProps}
            >
                <QuickPostForm />
            </ModalGeneric>
        )
    }
}

export default connect(null, mapDispatchToProps)(ModalQuickPost);