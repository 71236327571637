import React from 'react';
import { userHasFunction, getLabel } from './helpers';

//import FacebookTest from './views/Pages/FacebookTest';
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
// const Page403 = React.lazy(() => import('./views/Pages/Page403'));
let Dashboard = React.lazy(() => import('./views/Dashboard'));

// let Users = React.lazy(() => import('./views/Users'));
let Users2 = React.lazy(() => import('./views/Usersv2'));
// let UserAdd = React.lazy(() => import('./views/Users/add'));
// let UserEdit = React.lazy(() => import('./views/Users/edit'));
// let UserProfile = React.lazy(() => import('./views/Users/profile'));

let Schedules = React.lazy(() => import('./views/Schedules'));
let SchedulesAdd = React.lazy(() => import('./views/Schedules/SchedulesAdd'));
let SchedulesEdit = React.lazy(() => import('./views/Schedules/SchedulesEdit'));

let Accountsv2 = React.lazy(() => import('./views/Accountsv2'));
let Verticals = React.lazy(() => import('./views/Accountsv2/verticals'));

//outing
let AddMediaOuting = AddMediaOuting = React.lazy(() => import('./views/MediaOuting'));

// post
let MediaOutingBoost = MediaOutingBoost = React.lazy(() => import('./views/MediaOutingBoost'));
let MediaOutingBoostSingle = MediaOutingBoostSingle = React.lazy(() => import('./views/MediaOutingBoost/single'));

// outing comments
let OutingComments = OutingComments = React.lazy(() => import('./views/MediaOutingCommentsV2'));

// super admin
let SuperAdmin = SuperAdmin = React.lazy(() => import('./views/SuperAdmin'));

//test
// let TestSocket = React.lazy(() => import('./views/Pages/Test/socket'));
let Testpage = React.lazy(() => import('./views/Pages/Test'));
// let ModalFormWizard = React.lazy(() => import('./views/MediaOuting/components/modals/ModalFormWizard'));
// let TestSocket = React.lazy(() => import ('./views/Pages/Test/socket'));

// let Example = React.lazy(() => import('./views/Tabs1'));
// let SimpleTabs = React.lazy(() => import('./views/MediaOuting/components/Tabs'));

// Media Library
let MediaLibrary = React.lazy(() => import('./views/MediaLibrary'));

// Company/Customer Details
let Company = React.lazy(() => import('./views/Company'));

let Settings = React.lazy(() => import('./views/Settings'));

const routes = [
    { path: '/', exact: true, name: 'Dashboard', component: Dashboard },
    { path: '/dashboard/schedules/:id/', exact: true, name: 'Dashboard', component: Dashboard },
    { path: '/page-not-found/', exact: true, name: 'Page Not Found', component: Page404 },
    // Users2
    { path: '/users/:userID/', exact: true, name: 'User', hasTitle: true, component: Users2, userHasFunction: userHasFunction('Users', "Read/View user") },
    { path: '/users/', exact: true, name: 'Users', component: Users2, userHasFunction: userHasFunction('Users', "Read/View user") },

    // { path: '/users-tmp/', exact: true, name: 'Users', component: Users, userHasFunction: userHasFunction('Users', "Read/View user") },
    // { path: '/users-tmp/add/', exact: true, name: 'Add Users', component: UserAdd, userHasFunction: userHasFunction('Users', "Create user") },
    // { path: '/users-tmp/profile/', exact: true, name: 'User Profile', component: UserProfile },
    // { path: '/users-tmp/profile/:id', exact: true, name: 'User Profile', component: UserProfile, userHasFunction: userHasFunction('Users', "Read/View user") },

    { path: '/accounts/:accountID/', exact: false, name: 'Accounts', component: Accountsv2, userHasFunction: userHasFunction('Account', "Read/view account") },
    { path: '/accounts/', exact: false, name: 'Accounts', component: Accountsv2, userHasFunction: userHasFunction('Account', "Read/view account") },
    { path: '/verticals/:verticalID/', exact: false, name: 'Verticals', component: Verticals, userHasFunction: userHasFunction('Account', "Read/view account") },
    { path: '/verticals/', exact: false, name: 'Verticals', component: Verticals, userHasFunction: userHasFunction('Account', "Read/view account") },

    { path: '/schedules/', exact: true, name: 'Schedules', component: Schedules, isFullWidthPage: true, userHasFunction: userHasFunction('Media Schedule', "Read/View media schedule") },
    { path: '/schedules/add/', exact: true, name: 'Add Schedule', component: SchedulesAdd, userHasFunction: userHasFunction('Media Schedule', "Create media schedule") },
    { path: '/schedules/list/:id/', exact: true, name: 'List Schedule', component: Schedules, isFullWidthPage: true, userHasFunction: userHasFunction('Media Schedule', "Read/View media schedule") },
    { path: '/schedules/list/', exact: true, name: 'List Schedule', component: Schedules, isFullWidthPage: true, userHasFunction: userHasFunction('Media Schedule', "Read/View media schedule") },
    { path: '/schedules/:id/', exact: true, name: 'View Schedule', component: Schedules, isFullWidthPage: true, userHasFunction: userHasFunction('Media Schedule', "Read/View media schedule") },
    { path: '/schedules/edit/:id/', exact: true, name: getLabel({ isString: true,  key: 1371, fallback: `Edit` }) + ' Schedule', component: SchedulesEdit, isFullWidthPage: true, userHasFunction: userHasFunction('Media Schedule', "Read/View media schedule") },
    { path: '/schedule-ads/', exact: true, name: 'Ads Schedule', component: Schedules, isFullWidthPage: true, userHasFunction: userHasFunction('Media Schedule', "Read/View media schedule")},
    { path: '/schedule-ads/:id', exact: true, name: 'Ads Schedule', component: Schedules, isFullWidthPage: true, userHasFunction: userHasFunction('Media Schedule', "Read/View media schedule")},

    { path: '/outing/add/', exact: true, name: 'Add New Media Outing', component: AddMediaOuting, userHasFunction: userHasFunction('Media Outing', "Create media outing") },
    // { path: '/outing/edit/:id/', exact: true, name: getLabel({ isString: true,  key: 1371, fallback: `Edit` }) + ' Media Outing', component: EditMediaOuting, userHasFunction: userHasFunction('Media Outing', "Update media outing") },

    { path: '/boost-post-vertical/', exact: true, name: 'Boost Vertical Post', component: MediaOutingBoost, userHasFunction: userHasFunction('Media Outing', "Create Vertical Post Ad") },

    { path: '/boost-post/', exact: true, name: 'Boost Account Post', component: MediaOutingBoost, userHasFunction: userHasFunction('Media Outing', "Create Media Post Ad") },

    { path: '/comments/:mediaOutingID/', exact: true, name: 'Media Outing ' + getLabel({ isString: true,  key: 1370, fallback: `Comments` }), component: OutingComments, userHasFunction: userHasFunction('Media Outing Comment', "Create Media Outing Comments") },

    { path: '/media-library/', exact: true, name: 'Media Library', component: MediaLibrary, userHasFunction: userHasFunction('Media File', "Manage Media File") },

    { path: '/company/', exact: true, name: 'Company Details', component: Company, userHasFunction: userHasFunction("Customer/Company", "Update customer") },

    { path: '/settings/', exact: true, name: 'Settings', component: Settings },
    { path: '/super-admin/', exact: true, name: 'Super Admin', component: SuperAdmin },

    //test fb login
    { path: '/test/', exact: true, name: 'Media Outing', component: Testpage },
    // { path: '/testSocket', exact: true, name: 'Test Socket', component: TestSocket},

    // //Tabs
    // { path: '/tabs', exact: true, name: 'Tabs testing example', component: Example},
    // { path: '/tabs3', exact: true, name: 'Tabs testing example 3', component: SimpleTabs},
];

export default routes;