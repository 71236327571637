import React, { Component, Suspense } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
//import Logo from '../../../logo.svg';

import cls from './register.module.scss';
const RegisterPanel = React.lazy(() => import('./RegisterPanel'));
const SuccessPanel = React.lazy(() => import('./SuccessPanel'));
const routes = [
    { path: '/register/success/', exact: true, name: "Registered Successfully", component: SuccessPanel },
    { path: '/register/', exact: true, name: "Register", component: RegisterPanel },
];

class Register extends Component {
    loading = () => <div className="loading-container"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>;

    render() {
        return (
            <div className={`${cls.pageWrapper}`}>
                <div>
                    <div className="text-center">
                        <Link to="/">
                            {/* <img src={Logo} alt="" width="150" height="150" /> */}
                        </Link>
                    </div>
                    <Suspense fallback={this.loading()}>
                        <Switch>
                            {routes.map((route, idx) => {
                                return route.component ? (
                                    <Route
                                        key={idx}
                                        path={route.path}
                                        exact={route.exact}
                                        name={route.name}
                                        render={props => (
                                            <route.component route={route} {...props} />
                                        )}
                                    />
                                ) : (null);
                            })}
                        </Switch>
                    </Suspense>
                </div>
            </div>
        )
    }
}

export default Register;