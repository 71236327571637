import React from 'react';
import { connect } from 'react-redux';
import FormAddOuting from '../../../MediaOuting';
import ModalGeneric from '../../../../components/Modal';
import { hideMediaScheduleOuting } from '../../../../redux/actions/mediaSchedule';
// import cls from './modals.module.scss';

const mapDispatchToProps = dispatch => ({
    hideMediaScheduleOuting: (data) => dispatch(hideMediaScheduleOuting(data))
});
class AddNewOuting extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
        this.props.hideMediaScheduleOuting(false)
    }

    componentDidMount() {
        this.props.hideMediaScheduleOuting(true)
    }

    render() {

        return (
            <ModalGeneric {...this.props.modalProps}>
                {/* <div className={`${cls.modalMain}`}> */}
                    <FormAddOuting formOnly={true} formProps={this.props.formProps ? this.props.formProps : null} />
                {/* </div> */}
            </ModalGeneric>
        )
    }
}

export default connect(null, mapDispatchToProps)(AddNewOuting);