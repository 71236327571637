import React from 'react';
import PropTypes from 'prop-types';
import cls from './style.module.scss';
import {
    UikButton
} from '@uik';
import { gHelpers } from 'helpers';


class WizardSetup extends React.Component {
    static propTypes = {
        steps: PropTypes.array,
        startAt: PropTypes.number
    }

    static defaultProps = {
        steps: [{
            name: 'Step 1',
            component: () => (
                <div>Step 1</div>
            )
        }, {
            name: 'Step 2',
            component: () => (
                <div>Step 2</div>
            )
        }],
        startAt: 0
    }

    constructor(props) {
        super(props);

        this.state = {
            stepNumber: this.props.startAt,
            isBtnLoading: false,
            isBtnBackLoading: false,
            isBtnDisabled: null
        }

        this.setBtnLoading = this.setBtnLoading.bind(this);
    }

    setBtnDisabled(val, btn) {
        if (btn) {
            this.setState(prevState => ({
                isBtnDisabled: {
                    ...prevState.isBtnDisabled,
                    [btn]: val
                }
            }))
        }
    }

    setBtnLoading(val, btn = 'next') {
        if (btn == 'next') {
            this.setState({
                isBtnLoading: val || false,
                isBtnDisabled: {
                    [btn]: val
                }
            })
        } else if (btn == 'back') {
            this.setState({
                isBtnBackLoading: val || false,
                isBtnDisabled: {
                    [btn]: val
                }
            })
        }
    }

    handleNextStep() {
        this.setState(prevState => ({
            stepNumber: prevState.stepNumber + 1
        }))
    }

    componentDidMount() {

    }

    render() {
        let { stepNumber, isBtnLoading, isBtnBackLoading, isBtnDisabled } = this.state;

        return (
            <div className={cls.wizardWrapper}>
                <div className={cls.stepsHeader}>
                    {
                        this.props.steps.map((step, idx) => (
                            <div
                                key={idx}
                                className={`${cls.stepItem} ${idx == stepNumber ? cls.active : ''}`}
                            >
                                <span className={cls.num}>{idx + 1}</span>
                                <span className={cls.name}>{step.name}</span>
                            </div>
                        ))
                    }
                </div>
                <div className={cls.currentStep}>
                    {
                        this.props.steps.map((step, idx) => (
                            <div key={idx} className={`${idx != stepNumber ? 'd-none' : ''}`}>
                                <step.component />
                            </div>
                        ))
                    }
                    {/* <CurrentStepComp /> */}
                </div>
                <div className={`mt-4 text-center`}>
                    <UikButton
                        onClick={() => {
                            if (stepNumber > 0) {
                                this.setState(prevState => ({
                                    stepNumber: prevState.stepNumber - 1
                                }), () => this.props.onChangeStep ? this.props.onChangeStep(stepNumber - 1) : false)
                            }
                        }}
                        isLoading={isBtnBackLoading || false}
                        disabled={isBtnDisabled && isBtnDisabled['back'] ? isBtnDisabled['back'] : false}
                    >{gHelpers.getLabel({ key: 72956, fallback: 'Back' })}</UikButton>
                    <UikButton
                        primary
                        onClick={() => {
                            if (typeof this.props.steps[stepNumber].onNextStep !== 'undefined') {
                                this.props.steps[stepNumber].onNextStep({
                                    setWizState: (data) => this.setState(data),
                                    proceed: () => {
                                        if (stepNumber < (this.props.steps.length - 1)) {
                                            this.setState(prevState => ({
                                                stepNumber: prevState.stepNumber + 1,
                                                isBtnLoading: false
                                            }), () => this.props.onChangeStep ? this.props.onChangeStep(stepNumber + 1) : false)
                                        }
                                    }
                                })
                            } else {
                                let proceed = true;
                                if (typeof this.props.steps[stepNumber].proceed !== 'undefined') {
                                    proceed = this.props.steps[stepNumber].proceed;
                                }

                                if (stepNumber < (this.props.steps.length - 1) && proceed) {
                                    this.setState(prevState => ({
                                        stepNumber: prevState.stepNumber + 1,
                                        isBtnLoading: false
                                    }), () => this.props.onChangeStep ? this.props.onChangeStep(stepNumber + 1) : false)
                                }
                            }
                        }}
                        disabled={stepNumber >= (this.props.steps.length - 1) || (isBtnDisabled && isBtnDisabled['next']) ? true : false}
                        isLoading={isBtnLoading || false}
                    >{gHelpers.getLabel({ key: 72962, fallback: 'Next' })}</UikButton>
                </div>
            </div>
        )
    }
}

export default WizardSetup;