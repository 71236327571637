const updateSelectedMediaFiles = (data) => dispatch => {
    dispatch({
        type: 'UPDATE_SELECTED_MEDIA_FILES',
        payload: data
    })
}
const removeAllSelectedMediaFiles = (data) => dispatch => {
    dispatch({
        type: 'REMOVE_ALL_SELECTED_MEDIA_FILES',
        payload: data
    })
}
const listMediaFiles = (data) => dispatch => {
    dispatch({
        type: 'LIST_MEDIA_FILES',
        payload: data
    })
}
const listMediaFilesPagination = (data) => dispatch => {
    dispatch({
        type: 'LIST_MEDIA_FILES_PAGINATION',
        payload: data
    })
}
const listMediaFilesMetaData = (data) => dispatch => {
    dispatch({
        type: 'LIST_MEDIA_FILES_METADATA',
        payload: data
    })
}
const updateSelectedMediaFilesField = (data) => dispatch => {
    dispatch({
        type: 'UPDATE_SELECTED_MEDIA_FILES_FIELD',
        payload: data
    })
}
const sortSelectedMediaFiles = (data) => dispatch => {
    dispatch({
        type: 'SORT_SELECTED_MEDIA_FILES',
        payload: data
    })
}


const mediaFileLibraryActions = {
    updateSelectedMediaFiles: updateSelectedMediaFiles,
    removeAllSelectedMediaFiles: removeAllSelectedMediaFiles,
    listMediaFiles: listMediaFiles,
    updateSelectedMediaFilesField: updateSelectedMediaFilesField,
    sortSelectedMediaFiles: sortSelectedMediaFiles,
    listMediaFilesMetaData: listMediaFilesMetaData,
    listMediaFilesPagination: listMediaFilesPagination
}

export {
    mediaFileLibraryActions
}