import React from 'react';
import CalendarProcessor from './calendarProcessor.js';
import axios from 'axios';
import TimeZones from './timezones';
import cls from './helpers.module.scss';
import fieldGenerator from './fieldGenerator';
import { NotificationManager } from 'react-notifications';
import confirmDialog from './confirmDialog';
import io from 'socket.io-client';
import SocketIO from './socketIO';
import {
    UikNavLink,
    UikAvatar,
    UikButtonGroup,
    UikButton
} from '@uik';
import { defaultPhoto as profileImg } from 'exampleAssets';
import ChangeLangForm from './changeLang';

String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1)
}

const config = {
    API_URL: process.env.REACT_APP_API_URL,
    mediaFileURL: process.env.REACT_APP_MEDIA_FILE_URL,
    baseURL: window.location.origin + '/',
    socketURL: process.env.REACT_APP_SOCKET_URL,
    SocialMediaTypeNames: {
        1: "Facebook",
        2: "Twitter",
        3: "LinkedIn",
        4: "Youtube",
        5: "Pinterest",
        6: "Instagram"
    },
    SocialMediaTypeIDs: {
        "Facebook": 1,
        "Twitter": 2,
        "LinkedIn": 3,
        "Youtube": 4,
        "Pinterest": 5,
        "Instagram": 6
    },
    stateColors: [
        {
            name: 'New',
            color: 'grey'
        },
        {
            name: 'Edit',
            color: 'grey'
        },
        {
            name: 'Draft',
            color: 'blue'
        },
        {
            name: 'For Approval',
            color: 'orange'
        },
        {
            name: 'Requires Edit',
            color: 'orange'
        },
        {
            name: 'Approved',
            color: 'blue'
        },
        {
            name: 'For Posting',
            color: 'green'
        },
        {
            name: 'Posted',
            color: 'blue'
        },
        {
            name: 'Failed',
            color: 'red'
        },
        {
            name: 'default',
            color: 'grey'
        }
    ]
}

const testAjaxTimeOut = () => {
    let countSeconds = 0;
    let to = setInterval(() => {
        countSeconds++;
    }, 1000);
    genericQuery(
        {
            Url: 'sleep_api/',
            Method: 'POST',
            Data: {},
            ResponseSuccessCallback: function (response) {
                console.log(`success response`, response)
                clearInterval(to);
                console.log(`success: stopped at ${countSeconds} seconds.`);
                gHelpers.notif('success', { msg: `success: stopped at ${countSeconds} seconds.` });

            }.bind(this),

            ResponseFailCallback: function (response) {
                console.log(`fail response`, response)
                clearInterval(to);
                console.log(`fail: stopped at ${countSeconds} seconds.`);
                gHelpers.notif('success', { msg: `success: stopped at ${countSeconds} seconds.` });
            }.bind(this),
        }
    );
}

const API_URL = config.API_URL;
const genericQuery = (config) => {
    if (config.BeforeSubmit) {
        config.BeforeSubmit();
    }

    let newCancelToken = axios.CancelToken,
        newSource = newCancelToken.source();

    if (config.CancelToken) {
        config.CancelToken.cancel("Cancel request because another request will be made.");
    }

    let configHeaders = {};

    if (config.Headers) {
        configHeaders = { ...configHeaders };
        configHeaders.token = localStorage.getItem("Token") ? localStorage.getItem("Token") : '';

        // override header token
        if (config.Headers.token) {
            configHeaders.token = config.Headers.token;
        }

    } else {
        configHeaders.token = localStorage.getItem("Token") ? localStorage.getItem("Token") : '';
    }

    // additional params
    let addParams = {};
    if (config.addParams) {
        addParams = config.addParams;
    }

    let axiosRequest = axios.create();
    axiosRequest.defaults.timeout = 1800000;
    axiosRequest.request({
        baseURL: config.baseURL && config.baseURL != "" ? config.baseURL : API_URL,
        cancelToken: newSource.token,
        data: config.Method != "GET" ? config.Data.requestjson : null,
        params: config.Method == "GET" ? config.Data : null,
        headers: configHeaders,
        method: config.Method,
        responseType: config.responseType ? config.responseType : 'json', //json
        url: config.Url,
        timeout: 1800000,
        // `onUploadProgress` allows handling of progress events for uploads
        onUploadProgress: function (progressEvent) {
            if (config.onUploadProgress) {
                config.onUploadProgress(progressEvent)
            }
        },
        onDownloadProgress: function (progressEvent) {
            if (config.onDownloadProgress) {
                config.onDownloadProgress(progressEvent)
            }
        },
        ...addParams,
    })
        .then(function (response) {
            config.ResponseSuccessCallback(response, config);
        })
        .catch(function (thrown) {
            console.log(thrown)
            config.ResponseFailCallback(thrown, config);
        });

    if (config.CancelToken) {
        config.CancelToken = newSource;
    }

    return config;
};

const callApi = (config) => {
    if (config.beforeSubmit) {
        config.beforeSubmit();
    }

    const additionalConfig = config.additionalConfig || {};

    let formDataObject = new FormData();
    formDataObject.append('requestjson', JSON.stringify(config.passObject));

    genericQuery({
        Url: config.Url ? config.Url : 'single_api/',
        Method: 'POST',
        Data: {
            requestjson: formDataObject
        },
        ResponseSuccessCallback: function (response) {
            if (response.data.Status.IsSuccess) {
                if (config.success) {
                    config.success(response.data)
                }
                if (config.done) {
                    config.done(response.data)
                }
            } else {
                if (config.unSuccess) {
                    config.unSuccess(response.data)
                }
                if (config.done) {
                    config.done(response.data)
                }
            }
        }.bind(this),
        ResponseFailCallback: function (response) {
            if (config.fail) {
                config.fail(response.data);
            }
            if (config.done) {
                config.done(response.data)
            }
        }.bind(this),
        ...additionalConfig
    });
    // genericQuery({ Url: 'single_api/', ... media_schedule_get
}

const localToObject = (key) => {
    return JSON.parse(localStorage.getItem(key))
}

// ACCOUNT CHECKER
const isAuthenticated = localStorage.getItem("Token") != null && localStorage.getItem("UserDetails") != null ? true : false;
const isAccountActivated = localToObject('UserDetails') ? localToObject('UserDetails').User.HasActivated : false;
const isAccountTermsSigned = localToObject('UserDetails') ? localToObject('UserDetails').User.WebsiteTermsSigned : false;
const isSubscribed = localStorage.getItem('IsSub') ? true : true; // temp; true : false
const isSuperUser = localToObject('UserDetails')
    && (
        (localToObject('UserDetails') && localToObject('UserDetails').Roles && localToObject('UserDetails').Roles.some(role => role.Name == "Super Admin"))
        || (localToObject('UserDetails') && localToObject('UserDetails').User && localToObject('UserDetails').User.EmailAddress == "jhun.lorenzo@prlbpo.technology")
    );

// DEV AUTHENTICATE
const isDevAuthenticated = window.location.origin.includes("live.") ? true : localStorage.getItem("isDevAuthenticated");

const loading = (type, customCSS) => {
    if (type || customCSS) {
        if (type == 'sm2') {
            return (
                <div className="soc-loading-container soc-loading-container-abs">
                    <div className="soc-loading">
                        <div className="coll">
                            <div className="rowl v1 d1"></div>
                            <div className="rowl v3 d2"></div>
                            <div className="rowl v3 d3"></div>
                        </div>
                        <div className="coll">
                            <div className="rowl v3 d6"></div>
                            <div className="rowl v3 d5"></div>
                            <div className="rowl v3 d4"></div>
                        </div>
                        <div className="coll">
                            <div className="rowl v3 d7"></div>
                            <div className="rowl v3 d8"></div>
                            <div className="rowl v2 d9"></div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={`loading-container ${type ? `loading-container-${type}` : ''}`} style={customCSS ? customCSS : null}>
                    <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                </div>
            )
        }
    } else {
        return (
            <div className="soc-loading-container">
                <div className="soc-loading">
                    <div className="coll">
                        <div className="rowl v1 d1"></div>
                        <div className="rowl v3 d2"></div>
                        <div className="rowl v3 d3"></div>
                    </div>
                    <div className="coll">
                        <div className="rowl v3 d6"></div>
                        <div className="rowl v3 d5"></div>
                        <div className="rowl v3 d4"></div>
                    </div>
                    <div className="coll">
                        <div className="rowl v3 d7"></div>
                        <div className="rowl v3 d8"></div>
                        <div className="rowl v2 d9"></div>
                    </div>
                </div>
            </div>
        )
    }
};

const loadingNew = (type) => <div className={`loading-container ${type && type == 'sm' ? 'loading-container-sm' : ''}`}>
    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
</div>;

const processing = (options) => {
    let label = 'Processing';
    if (options && options.label) {
        label = options.label;
    }
    return (
        <div className={`processing-container-sm`}>
            <div className={`processing-label`}>
                {
                    label
                }
            </div>
            <div className={`processing-icon`}>
                {
                    loading('sm')
                }
            </div>
        </div>
    )
}

// REFRESH LOGGED IN USER DETAILS. TRIGGERS WHEN THE BROWSER IS REFRESHED
const refreshUserDetails = (callbacks, passRID) => {

    // get user functions
    // system_userfunctions_getall
    callApi({
        passObject: {
            'Module': 'system_userfunctions_getall'
        },
        success: (response) => {
            if (response.Status.IsSuccess) {
                localStorage.setItem('UserApplicationFunctions', JSON.stringify(response.Data.ApplicationFunctions));

                const { UserID } = passRID ? passRID : localToObject('UserDetails').User;
                // UPDATE USER DETAILS
                const passUserDetails = {
                    'Module': 'system_user_detail',
                    'Parameters': {
                        'UserID': UserID
                    }
                };

                const formDataUserDetails = new FormData();
                formDataUserDetails.append('requestjson', JSON.stringify(passUserDetails));

                genericQuery({
                    Url: 'single_api/',
                    Method: 'POST',
                    Data: {
                        requestjson: formDataUserDetails
                    },
                    ResponseSuccessCallback: function (responseUserDetails) {
                        if (responseUserDetails.data.Status.IsSuccess) {
                            // get profile picture
                            if (responseUserDetails.data.Data.UserDetails.ProfilePicture) {
                                const mediaFileID = responseUserDetails.data.Data.UserDetails.ProfilePicture.Value ? JSON.parse(responseUserDetails.data.Data.UserDetails.ProfilePicture.Value)[0] : false;
                                if (mediaFileID) {
                                    let passObject = {
                                        'Module': 'media_file_get',
                                        'Parameters': {
                                            'MediaFileID': mediaFileID
                                        }
                                    }
                                    let UserDetails = responseUserDetails.data.Data.UserDetails;
                                    localStorage.setItem('UserDetails', JSON.stringify({
                                        'User': responseUserDetails.data.Data.User,
                                        'UserDetails': UserDetails,
                                        'Roles': responseUserDetails.data.Data.Roles,
                                        'CustomerDetails': responseUserDetails.data.Data.CustomerDetails,
                                        'UserSystemSettings': responseUserDetails.data.Data.UserSystemSettings
                                    }));
                                    if (mediaFileID) {

                                        callApi({
                                            passObject: passObject,
                                            success: (response) => {
                                                if (document.querySelector("#header-top-user-img .uik-avatar__avatar")) {
                                                    document.querySelector("#header-top-user-img .uik-avatar__avatar").src = `${config.mediaFileURL}file_manager/?path=${response.Data.MediaFile.Path}&width=100&height=100&crop=true`;
                                                }

                                                UserDetails.ProfilePictureURL = `${config.mediaFileURL}file_manager/?path=${response.Data.MediaFile.Path}&width=100&height=100&crop=true`;
                                                localStorage.setItem('UserDetails', JSON.stringify({
                                                    'User': responseUserDetails.data.Data.User,
                                                    'UserDetails': UserDetails,
                                                    'Roles': responseUserDetails.data.Data.Roles,
                                                    'CustomerDetails': responseUserDetails.data.Data.CustomerDetails,
                                                    'UserSystemSettings': responseUserDetails.data.Data.UserSystemSettings,
                                                }));
                                            },
                                            unSuccess: (response) => {
                                                // gHelpers.notif('error', { msg: response.Status.Message === "Invalid Access" ? "No profile image has been set" : response.Status.Message });
                                            }
                                        });
                                    }
                                } else {
                                    localStorage.setItem('UserDetails', JSON.stringify({
                                        'User': responseUserDetails.data.Data.User,
                                        'UserDetails': responseUserDetails.data.Data.UserDetails,
                                        'Roles': responseUserDetails.data.Data.Roles,
                                        'CustomerDetails': responseUserDetails.data.Data.CustomerDetails,
                                        'UserSystemSettings': responseUserDetails.data.Data.UserSystemSettings,
                                    }));
                                }

                            } else {
                                localStorage.setItem('UserDetails', JSON.stringify({
                                    'User': responseUserDetails.data.Data.User,
                                    'UserDetails': responseUserDetails.data.Data.UserDetails,
                                    'Roles': responseUserDetails.data.Data.Roles,
                                    'CustomerDetails': responseUserDetails.data.Data.CustomerDetails,
                                    'UserSystemSettings': responseUserDetails.data.Data.UserSystemSettings,
                                }));
                            }

                            // REFRESH SYSTEM SETTINGS
                            refreshTimeZone({
                                success: function () {
                                    if (callbacks && callbacks.success) {
                                        callbacks.success(responseUserDetails);
                                    }
                                }
                            }); // temp

                            if (responseUserDetails.data.Data.UserSystemSettings) {
                                getLanguage(responseUserDetails.data.Data.UserSystemSettings.DefaultLanguage.Value);
                            }

                            // get all social media template

                            const passObject = {
                                'Module': 'socialmedia_template_getall'
                            }

                            callApi({
                                passObject: passObject,
                                success: (response) => {
                                    if (response.Status.IsSuccess) {
                                        localStorage.setItem('SocialMediaTemplate', JSON.stringify(response.Data.SocialMedia));
                                    }
                                },
                                unSuccess: (response) => {
                                    console.log(response)
                                    // gHelpers.notif('error', { msg: response.Status.Message });
                                }
                            });

                            if (isSuperUser) {
                                // get language lists
                                const passObject = {
                                    'Module': 'list_value_userlanguage'
                                }

                                callApi({
                                    passObject: passObject,
                                    success: (response) => {
                                        if (response.Status.IsSuccess) {
                                            let langObj = {};
                                            Object.keys(response.Data).forEach(key => {
                                                langObj[response.Data[key].value] = response.Data[key].label;

                                                if (localStorage.getItem(`lang-${response.Data[key].value}-v${process.env.REACT_APP_LANG_VERSION}`) === null) {
                                                    genericQuery({
                                                        baseURL: config.baseURL,
                                                        Url: `Languages/lang-${response.Data[key].value}-v${process.env.REACT_APP_LANG_VERSION}.json`,
                                                        Method: 'GET',
                                                        Data: {},
                                                        ResponseSuccessCallback: function (response2) {
                                                            if (response2.data && response2.data[1]) {
                                                                localStorage.setItem(`lang-${response.Data[key].value}-v${process.env.REACT_APP_LANG_VERSION}`, JSON.stringify(response2.data[1].Labels));
                                                            }
                                                        }.bind(this),
                                                        ResponseFailCallback: function () {
                                                        },
                                                    });
                                                }
                                            })

                                            localStorage.setItem('langObj', JSON.stringify(langObj));
                                        }
                                    },
                                    unSuccess: (response) => {
                                        console.log(response)
                                        // gHelpers.notif('error', { msg: response.Status.Message });
                                    }
                                });
                            }

                        }
                        else if (!responseUserDetails.data.Status.IsSuccess && responseUserDetails.data.Status.Message == 'Invalid User') {
                            logout();
                        }
                    },
                    ResponseFailCallback: function (responseUserDetails) {
                        // do when fail
                        if (callbacks && callbacks.fail) {
                            callbacks.fail(responseUserDetails);
                        }

                    },
                });
                // genericQuery({ Url: 'single_api/', ... system_user_detail

            } // success: system_userfunctions_getall
        },
        unSuccess: (response) => {
            console.log(response)
            // gHelpers.notif('error', { msg: response.Status.Message });
        }
    });



}
// refreshUserDetails

const getLanguage = (langID = null) => {
    if (!(localToObject("UserDetails"))) {
        return;
    }
    const UserDetails = localToObject("UserDetails");
    if (UserDetails.UserSystemSettings && UserDetails.UserSystemSettings.DefaultLanguage) {
        if (!langID) {
            langID = UserDetails.UserSystemSettings.DefaultLanguage.Value;
        }
        if (langID) {
            genericQuery({
                baseURL: config.baseURL,
                Url: `Languages/lang-${langID}-v${process.env.REACT_APP_LANG_VERSION}.json`,
                Method: 'GET',
                Data: {},
                ResponseSuccessCallback: function (response) {
                    if (response.data && response.data[1]) {
                        localStorage.setItem('lang', JSON.stringify(response.data[1]));
                    }
                },
                ResponseFailCallback: function () {
                    gHelpers.notif('error', { msg: 'No translation available.' });
                },
            });
        }
    }
}

const getLabel = (data) => {
    let lang;
    if (localStorage.getItem('lang') !== null && localStorage.getItem('lang') !== 'undefined') {
        lang = localStorage.getItem('lang');
        const langObj = JSON.parse(lang);
        if (langObj) {
            let label = data.fallback ? data.fallback : "N/A";

            if (langObj.Labels[data.key] && langObj.Labels[data.key].Value) {

                if (data.isString) {
                    label = langObj.Labels[data.key].Value; //
                } else {
                    if (isSuperUser && (localStorage.getItem('translate') !== null && localStorage.getItem('translate') == 'true')) {
                        const Label = (props) => {
                            const data = props.data;
                            const langObj = props.langObj

                            return (
                                <span
                                    key={`lbl-${data.key}`}
                                    className={`lbl-trns`}
                                    title={getLabel({ isString: true, key: 62854, fallback: `Translate` })}
                                >
                                    <span
                                        className={`type1`}
                                        dangerouslySetInnerHTML={{
                                            __html: langObj.Value
                                        }}
                                    />
                                    <span className={`lbl-trns-btn`}
                                        onClick={() => {
                                            confirmDialog({
                                                title: getLabel({ key: 62714, fallback: `Change Language` }),
                                                desc: getLabel({ key: 62860, fallback: `Please <a href="/super-admin#language" target="_blank"><strong>Regenerate Static Labels</strong></a> to reflect your changes` }),
                                                content: (
                                                    <ChangeLangForm {...{
                                                        Key: props.data.key,
                                                        Label: props.langObj.Value,
                                                        RID: props.langObj.RID
                                                    }} />
                                                ),
                                                cancelBtn: {
                                                    text: getLabel({ key: 1378, fallback: `Done` }),
                                                    props: {
                                                        success: true
                                                    }
                                                },
                                            });
                                        }}
                                    >
                                        <i className={`fas fa-language`} />
                                    </span>
                                </span>
                            )
                        };

                        return <Label data={data} langObj={langObj.Labels[data.key]} />
                    } else {
                        label =
                            <span>
                                <span
                                    className={`type2`}
                                    dangerouslySetInnerHTML={{
                                        __html: langObj.Labels[data.key].Value
                                    }}
                                />
                            </span>
                    }
                }
            } else {
                if (!data.isString) {
                    label =
                        <span>
                            <span
                                className={`type3`}
                                dangerouslySetInnerHTML={{
                                    __html: data.fallback
                                }}
                            />
                        </span>
                }
            }

            return label;
        }
    } else {

        if (data.isString) {
            return data.fallback;

        } else {

            return (
                <span>
                    <span
                        className={`type4`}
                        dangerouslySetInnerHTML={{
                            __html: data.fallback ? data.fallback : 'N/A'
                        }}
                    />
                </span>
            )
        }
    }

}

const userGetFunctions = (module) => {
    const userAppFunctions = localToObject('UserApplicationFunctions');
    if (userAppFunctions) {
        if (userAppFunctions[module]) {
            return userAppFunctions[module];
        }
    }

    return false;
}

const userHasFunction = (module, fn) => {
    if (userGetFunctions(module)) {
        if (userGetFunctions(module).Function.includes(fn)) {
            return true;
        }
    }
    return false;
}

const refreshTimeZone = (callbacks) => {
    // // check if authenticated
    // if (!isAuthenticated) {
    //     return false;
    // }

    if (localStorage.getItem('SystemSettings') !== null && JSON.parse(localStorage.getItem('SystemSettings')).TimeZones) {
        callbacks.success(1);
        return false;
    }

    const passSystemSettings = {
        'Module': 'system_setting_get',
        'Parameters': {
            'Settings': [
                'TimeZone'
            ]
        }
    };

    const formDataSystemSettings = new FormData();
    formDataSystemSettings.append('requestjson', JSON.stringify(passSystemSettings));

    genericQuery({
        Url: 'single_api/',
        Method: 'POST',
        Data: {
            requestjson: formDataSystemSettings
        },
        ResponseSuccessCallback: function (response) {
            if (response.data.Status.IsSuccess) {
                localStorage.setItem('SystemSettings', JSON.stringify({
                    'TimeZones': response.data.Data.Timezone
                }));
            }
            if (callbacks && callbacks.success) {
                callbacks.success(response);
            }
        },
        ResponseFailCallback: function (response) {
            // do when fail
            if (callbacks && callbacks.fail) {
                callbacks.fail(response);
            }

        },
    });
    // genericQuery({ Url: 'single_api/', ... system_setting_get
}
// refreshTimeZone

const getTimeZone = (TimeZoneID) => {
    if (parseInt(TimeZoneID) && localToObject('SystemSettings') && localToObject('SystemSettings').TimeZones) {
        return localToObject('SystemSettings').TimeZones.find(timezone => timezone.id === parseInt(TimeZoneID))
    }
    else if (!(localToObject('SystemSettings') && localToObject('SystemSettings').TimeZones)) {
        refreshTimeZone({
            callbacks: {
                success: () => {
                    getTimeZone(TimeZoneID)
                }
            }
        })
    }
}
// getTimeZone

function isValidTimeZone(tz) {
    if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) {
        throw 'Time zones are not available in this environment';
    }

    try {
        Intl.DateTimeFormat(undefined, { timeZone: tz });
        return true;
    }
    catch (ex) {
        return false;
    }
}


const toTimeZoneAPI = (datetime, tz, cb) => {
    const formData = new FormData();
    formData.append('Timezone', tz);
    formData.append('UserDT', datetime);

    genericQuery({
        Url: 'to_utc/',
        Method: 'POST',
        Data: {
            requestjson: formData
        },
        ResponseSuccessCallback: function (response) {
            // console.log(`response`, response)
            cb(response);
        },
        ResponseFailCallback: function (response) {
            cb(response);
        },
    });
}

const toUTC = (datetime) => {
    const toUTCTmp = new Date(datetime);
    const toUTC = new Date(Date.UTC(
        toUTCTmp.getFullYear(),
        toUTCTmp.getMonth(),
        toUTCTmp.getDate(),
        toUTCTmp.getHours(),
        toUTCTmp.getMinutes(),
        toUTCTmp.getSeconds()
    ));

    return toUTC;
}

const toUTCDate = (datetime) => {
    return new Date(
        new Date().getUTCFullYear(),
        new Date().getUTCMonth(),
        new Date().getUTCDate(),
        new Date().getUTCHours(),
        new Date().getUTCMinutes(),
        new Date().getUTCSeconds()
    );
}

// const toTimeZone = (datetime, tz, format = 'YYYY-MM-DD HH:mm:ss') => {
const toTimeZone = (datetime, tz, format = false) => {
    if (isValidTimeZone(tz)) {

        const toUTCTmp = new Date(datetime);
        const toUTC = new Date(Date.UTC(
            toUTCTmp.getFullYear(),
            toUTCTmp.getMonth(),
            toUTCTmp.getDate(),
            toUTCTmp.getHours(),
            toUTCTmp.getMinutes(),
            toUTCTmp.getSeconds()
        ));

        if (format) {
            return formatDate(new Date(new Date(toUTC).toLocaleString("en-US", { timeZone: tz })))
        } else {
            return new Date(toUTC).toLocaleString("en-US", { timeZone: tz })
        }
    }

    // moment.tz.setDefault(tz);
    // return moment.tz(datetime, "UTC").clone().tz(tz).format(format);
    // return  moment.tz(datetime, tz).format(format);
    // return  moment.tz(datetime, "UTC").clone().tz(tz).format(format);
    // return  moment.tz(datetime, tz).clone().tz("UTC").format(format);
}

const formatDate = (date) => {
    return date.getFullYear() + "-" + appendLeadingZeroes(date.getMonth() + 1) + "-" + appendLeadingZeroes(date.getDate()) + " " + appendLeadingZeroes(date.getHours()) + ":" + appendLeadingZeroes(date.getMinutes()) + ":" + appendLeadingZeroes(date.getSeconds());
}

const formatDateYMOnly = (date) => {
    return date.getFullYear() + "-" + appendLeadingZeroes(date.getMonth() + 1) + "-" + "01 00:00:00";
}

const centerAlert = (options) => {
    return (
        <div className={cls.alertWrapper}>
            <div className={`${cls.alertMessage} ${options.color}`}>
                {options.icon}<span className={`${cls.alertMessageText} ml-2`}>{options.message}</span>
            </div>
        </div>
    )
}

const goTo404 = () => window.location.href = `${config.baseURL}page-not-found/`;

const getAttrib = (attribArr, name, val) => {
    return attribArr.find((attr) => attr[name] == val)
}
const getAttribVal = (attribArr, name, val) => {
    const attr = getAttrib(attribArr, name, val);
    return attr && attr.Value ? attr.Value : null;
}
const objAttrib = (attribArr) => {
    let obj = {};

    attribArr.map((attr) => {
        if (attr.AttributeName) {
            obj[attr.AttributeName] = attr.Value;
        }
    })

    return obj;
}
const addRuleValue = (attribArr, attribName, newRuleObj) => {
    let ruleValueContent = getAttrib(attribArr, 'AttributeName', attribName).RuleValue;
    ruleValueContent = insertNewJSONItem(ruleValueContent, newRuleObj);
    return ruleValueContent;
}

const gHelpers = {
    notif: (type, data) => {
        switch (type) {
            case 'info':
                NotificationManager.info(data.msg || '', data.title || 'Info', 3000, data.cb ? data.cb : null);
                break;
            case 'success':
                NotificationManager.success(data.msg || '', data.title || 'Success!', 3000, data.cb ? data.cb : null);
                break;
            case 'warning':
                NotificationManager.warning(data.msg || '', data.title || 'Warning!', 5000, data.cb ? data.cb : null);
                break;
            case 'error':
                NotificationManager.error(data.msg || '', data.title || 'Error!', 10000, data.cb ? data.cb : null);
                break;
            case 'loading':
                NotificationManager.error(data.msg || '', data.title || 'Error!');
                break;
            case 'notify':
                NotificationManager.info(data.msg || '', data.title || 'Info', 5000, data.cb ? data.cb : null);
                break;

        }
    }
    // notification
    , confirmDialog: (props) => {
        confirmDialog(props);
    },
    getLabel: (data) => getLabel(data)
}

function convertToSlug(text) {
    return text.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-')
}

function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}

function updateByRID(data) {

    const source = axios.CancelToken.source();
    const formData = new FormData();
    for (const key in data.formDataObject) {
        if (data.formDataObject.hasOwnProperty(key)) {
            const item = data.formDataObject[key];
            formData.append(key, item);
        }
    }

    genericQuery(
        {
            Url: 'update_by_rid/',
            Method: 'PUT',
            CancelToken: source,
            Data: {
                requestjson: formData
            },
            Headers: {
                'Content-Type': 'multipart/form-data'
            },
            ResponseSuccessCallback: function (response) {
                if (data.ResponseSuccessCallback) {
                    data.ResponseSuccessCallback(response)
                }

                // if force udpate
                if (!response.data.detail.Status.IsSuccess) {
                    if (data.forceUpdate && response.data.detail.Data.NewValue) {
                        updateByRID({
                            formDataObject: {
                                ...data.formDataObject,
                                OldValue: response.data.detail.Data.NewValue
                            }
                        })
                    }
                }
            }.bind(this),
            ResponseFailCallback: function (response) {
                if (data.ResponseFailCallback) {
                    data.ResponseFailCallback(response)
                }
                // do when fail
                gHelpers.notif('error', { msg: getLabel({ isString: true, key: 61351, fallback: 'An error occurred during the process.' }) });
            }.bind(this),
        }
    );
}

function appendLeadingZeroes(n) {
    if (n <= 9) {
        return "0" + n;
    }
    return n
}

function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}

function formattedDateTime(date) {
    return date.getFullYear() + "-" + appendLeadingZeroes(date.getMonth() + 1) + "-" + appendLeadingZeroes(date.getDate()) + " " + appendLeadingZeroes(date.getHours()) + ":" + appendLeadingZeroes(date.getMinutes()) + ":" + appendLeadingZeroes(date.getSeconds());
}

function isPortrait(src) {
    let img = new Image();
    img.src = src;

    let w = img.naturalWidth || img.width,
        h = img.naturalHeight || img.height;
    return (h > w)
}

function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}

function textHasLink(text) {
    if (new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(text)) {
        return true;
    }
    return false;
}

function textCaptureLink(text) {
    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    const match = text.match(urlRegex);
    return match;
}

function insertNewJSONItem(json, item) {
    let jsonObj = JSON.parse(json);

    if (typeof item === 'object') {
        jsonObj = {
            ...jsonObj,
            ...item
        }
    }

    return JSON.stringify(jsonObj);
}

const mentionInputStyle = {
    control: {
        backgroundColor: '#fff',

        fontSize: 14,
        fontWeight: 'normal',
    },

    highlighter: {
        overflow: 'hidden',
    },

    input: {
        margin: 0,
    },

    '&singleLine': {
        control: {
            display: 'inline-block',

            width: 130,
        },

        highlighter: {
            padding: 1,
            border: '2px inset transparent',
        },

        input: {
            padding: 1,

            border: '2px inset',
        },
    },

    '&multiLine': {
        control: {
            border: 0,
        },

        highlighter: {
            padding: 9,
        },

        input: {
            padding: 9,
            minHeight: 63,
            outline: 0,
            border: 0,
        },
    },

    suggestions: {
        list: {
            backgroundColor: 'white',
            border: '1px solid rgba(0,0,0,0.15)',
            fontSize: 14,
        },

        item: {
            padding: '5px 15px',
            borderBottom: '1px solid rgba(0,0,0,0.15)',

            '&focused': {
                backgroundColor: '#cee4e5',
            },
        },
    },

    padding: 0
};

const getMediaFileImg = (filePath, size, fullPath) => {
    let width = 180;
    let height = 180;
    if (size) {
        width = size[0];
        height = size[1];
    }
    let path = `api/image_custom_size/?path=${filePath}&width=${width}&height=${height}`;

    if (fullPath) {
        path = `${config.API_URL}image_custom_size/?path=${filePath}&width=${width}&height=${height}`;
    }

    return path;
};

const loading2 = (settings = {}) => {
    settings = {
        width: 15,
        height: 15,
        borderWidth: 5,
        ...settings
    }

    const additionalCss = settings.additionalCss ? settings.additionalCss : {};

    return (
        <div
            className={`loader`}
            style={
                {
                    width: `${settings.width}px`,
                    height: `${settings.height}px`,
                    borderWidth: `${settings.borderWidth}px`,
                    ...additionalCss
                }
            } />
    )
}

const loading3 = () => {
    return (
        <div className={`placeholder`}>
            <div className="animated-background"></div>
        </div>
    )
}

function getNumberWithOrdinal(n) {
    var s = ["th", "st", "nd", "rd"],
        v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

function arrayUnique(array) {
    var a = array.concat();
    for (var i = 0; i < a.length; ++i) {
        for (var j = i + 1; j < a.length; ++j) {
            if (a[i] === a[j])
                a.splice(j--, 1);
        }
    }

    return a;
}

function getWeek(fromDate) {
    var monday = new Date(fromDate.setDate(fromDate.getDate() - (fromDate.getDay() - 1))),
        result = [new Date(monday)];
    while (monday.setDate(monday.getDate() + 1) && monday.getDay() !== 1) {
        result.push(new Date(monday));
    }
    return result;
}

function getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(+d);
    d.setHours(0, 0, 0);
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    // Get first day of year
    var yearStart = new Date(d.getFullYear(), 0, 1);
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
    // Return array of year and week number
    return weekNo;
}

function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function eraseCookie(name) {
    document.cookie = name + '=; Max-Age=-99999999;';
}

function nl2br(str, is_xhtml) {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

function formatBytes(bytes, decimals) {
    if (bytes == 0) return '0 Bytes';
    var k = 1024,
        dm = decimals <= 0 ? 0 : decimals || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function insertStrAtPos(a, b, position) {
    return a.substring(0, position) + b + a.substring(position);
}

const countSelect = (from, to) => {
    let options = [];
    for (let i = from; i <= to; i++) {
        options.push({
            label: i,
            value: i
        })
    }


    return options;
}

function getUserInitial(props) {
    return props.Firstname.charAt(0) + props.Lastname.charAt(0);
}

const socket = io.connect(config.socketURL, { transports: ['websocket'], secure: true });

function removeParam(key, sourceURL) {
    var rtn = sourceURL.split("?")[0],
        param,
        params_arr = [],
        queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
            param = params_arr[i].split("=")[0];
            if (param === key) {
                params_arr.splice(i, 1);
            }
        }
        rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
}

function checkIfMobile() {
    let hasTouchScreen = false;
    // if ("maxTouchPoints" in navigator) {
    //     hasTouchScreen = navigator.maxTouchPoints > 0;
    // } else if ("msMaxTouchPoints" in navigator) {
    //     hasTouchScreen = navigator.msMaxTouchPoints > 0;
    // } else {
    //     let mQ = window.matchMedia && matchMedia("(pointer:coarse)");
    //     if (mQ && mQ.media === "(pointer:coarse)") {
    //        hasTouchScreen = !!mQ.matches;
    //     } else if ('orientation' in window) {
    //        hasTouchScreen = true;
    //     } else {
    //        let UA = navigator.userAgent;
    //        hasTouchScreen = (
    //            /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
    //            /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
    //        );
    //     }
    //     console.log('[has touch screen]', mQ, hasTouchScreen);
    // }
    let mQ = window.matchMedia && matchMedia("(pointer:coarse)");
    if (mQ && mQ.media === "(pointer:coarse)") {
        hasTouchScreen = !!mQ.matches;
    } else if ('orientation' in window) {
        hasTouchScreen = true;
    } else {
        let UA = navigator.userAgent;
        hasTouchScreen = (
            /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
            /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
        );
    }
    console.log('[has touch screen]', mQ, hasTouchScreen);
    // let mQ2 = window.matchMedia && matchMedia("(max-width: 767px), (max-height: 767px)");
    // return ((hasTouchScreen === true) && (mQ2.matches === true));
    return ((hasTouchScreen === true) || window.innerWidth <= 768);
}

function IsJSON(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

function connectSocialMedia(params, cb) {
    const {
        currentPage,
        state,
        selectedOption,
        SocialMediaType,
        SocialMediaOverride,
        forceRefresh,
        refreshAll,
        EnablePropagation,
        isReconnectPages
    } = params;

    if (typeof EnablePropagation !== 'undefined') {
        state.propagate = EnablePropagation;
    }

    if (refreshAll && !isReconnectPages) {
        state.refreshAll = true;
    }

    if (isReconnectPages) {
        state.isReconnectPages = true;
    }

    // Twitter connect step 1: Get Request Token
    if (SocialMediaType.Name === 'Twitter') {
        const formData = new FormData();
        let appendStr = `${window.location.origin}/${currentPage}/?connect=Twitter&accountID=${selectedOption.AccountID}${state.verticalID ? `&verticalID=${state.verticalID}` : ``}`;

        if (typeof EnablePropagation !== 'undefined') {
            appendStr += `&propagate=${EnablePropagation}`;
        }

        if (refreshAll) {
            appendStr += `&refreshAll=${refreshAll}`;
        }

        if (state.SocialMediaIDAccount) {
            appendStr += `&SocialMediaIDAccount=${state.SocialMediaIDAccount}`;
        }

        formData.append('oauth_callback', appendStr);
        genericQuery({
            Url: 'socialmedia/twit_get_request_token/',
            Method: 'POST',
            Data: {
                requestjson: formData
            },
            ResponseSuccessCallback: function (response) {
                const urlParams = new URLSearchParams(response.data[0]);
                // Twitter connect step 2: Pass oauth_token to get the Twitter User Details
                window.location.href = `https://api.twitter.com/oauth/authenticate?oauth_token=${urlParams.get('oauth_token')}`;
            }.bind(this),
            ResponseFailCallback: function () {
                gHelpers.notif('error', { msg: "An error occured during the process." });
            }.bind(this),
        });

        return;
    }

    const socialmedia_type_get = {
        "Module": "socialmedia_type_get",
        "Parameters": {
            "SocialMediaTypeID": SocialMediaType.SocialMediaTypeID
        }
    };
    const formData = new FormData();
    formData.append('requestjson', JSON.stringify(socialmedia_type_get));

    genericQuery({
        Url: 'socialmedia/socialmedia_account/',
        Method: 'GET',
        Data: {
            CustomerID: localToObject('UserDetails').CustomerDetails.CustomerID,
            Socialmedia: SocialMediaOverride && SocialMediaOverride === "FacebookInstagram" ? SocialMediaOverride : SocialMediaType.Name
        },
        ResponseSuccessCallback: function (response) {
            // console.log("[check response]", response);
            if (response.data.Status.IsSuccess && !forceRefresh) {
                cb({ oldParams: params, mediaPages: response.data.Data, socialMediaName: SocialMediaType.Name });
            }
            else {
                genericQuery({
                    Url: 'filtered_single_api/',
                    Method: 'POST',
                    Data: {
                        requestjson: formData
                    },
                    ResponseSuccessCallback: function (response) {
                        if (SocialMediaType.Name === 'Facebook') {
                            let client_id = response.data.Data.SocialMediaType.ConfigurationData.client_id;
                            if (SocialMediaOverride && SocialMediaOverride === "FacebookInstagram") {
                                state.connectToIG = true;
                            }

                            window.location.href = `https://www.facebook.com/v4.0/dialog/oauth?client_id=${client_id}&redirect_uri=${window.location.origin}/${currentPage}/?connect=${SocialMediaType.Name}&response_type=code,granted_scopes&scope=pages_show_list,pages_manage_posts,ads_management,email,business_management,instagram_basic,instagram_content_publish&state=${JSON.stringify(state)}`;
                        }
                        // if (SocialMediaType.Name === 'Facebook')

                        else if (SocialMediaType.Name === 'LinkedIn') {
                            let client_id = response.data.Data.SocialMediaType.ConfigurationData.client_id;
                            window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${client_id}&redirect_uri=${window.location.origin}/${currentPage}/?connect=${SocialMediaType.Name}&scope=r_liteprofile,w_member_social,w_organization_social,r_basicprofile,rw_organization_admin,r_emailaddress&state=${encodeURIComponent(JSON.stringify(state))}`; //r_basicprofile
                        }
                        // else if(SocialMediaType.Name === 'LinkedIn')

                        else if (SocialMediaType.Name === 'Youtube') {
                            let client_id = response.data.Data.SocialMediaType.ConfigurationData.client_id;
                            let scope = 'https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/userinfo.email';
                            window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?scope=${scope}&access_type=offline&include_granted_scopes=true&redirect_uri=${window.location.origin}/${currentPage}/?connect=${SocialMediaType.Name}&response_type=code&client_id=${client_id}&state=${encodeURIComponent(JSON.stringify(state))}`;
                        }
                        // else if (SocialMediaType.Name === 'Youtube')

                        else if (SocialMediaType.Name === 'Pinterest') {
                            let client_id = response.data.Data.SocialMediaType.ConfigurationData.client_id;
                            window.location.href = `https://api.pinterest.com/oauth/?response_type=code&redirect_uri=${window.location.origin}/${currentPage}/?connect=${SocialMediaType.Name}&client_id=${client_id}&scope=read_public,write_public&state=${encodeURIComponent(JSON.stringify(state))}`;
                        }
                        // else if (SocialMediaType.Name === 'Pinterest')

                        else if (SocialMediaType.Name === 'Instagram') {
                            let client_id = response.data.Data.SocialMediaType.ConfigurationData.client_id;
                            state.connect = 'Instagram';
                            window.location.href = `https://api.instagram.com/oauth/authorize/?client_id=${client_id}&redirect_uri=${window.location.origin}/${currentPage}/?connect=${SocialMediaType.Name}&response_type=code&scope=user_profile&state=${encodeURIComponent(JSON.stringify(state))}`;
                        }
                        // else if (SocialMediaType.Name === 'Instagram')

                        // this.setState({ loadingConnectBtnSMId: null })

                    }.bind(this),
                    ResponseFailCallback: function () {
                        gHelpers.notif('error', { msg: gHelpers.getLabel({ isString: true, key: 61351, fallback: 'An error occurred during the process.' }) });
                    }.bind(this),
                });
            }
        }.bind(this),
        ResponseFailCallback: function (response) {

        }.bind(this)
    })
}

function viewSocialMediaProfile(socmed) {

    switch (socmed.SocialMediaTypeName) {
        case 'Instagram':
            // check if connected via fb page
            if (socmed.Pages && socmed.Pages.length) {
                if (socmed.Pages[0].Configuration.instagram_business_account.username) {
                    window.open(
                        `https://www.instagram.com/${socmed.Pages[0].Configuration.instagram_business_account.username}/`,
                        '_blank'
                    );
                }
            } else if (socmed.SocialMediaAccountConfigurationData && socmed.SocialMediaAccountConfigurationData.name) {
                window.open(
                    `https://www.instagram.com/${socmed.SocialMediaAccountConfigurationData.name}/`,
                    '_blank'
                );
            }

            break;
        // end Instagram
        case 'Facebook':
            if (socmed.Pages && socmed.Pages.length) {
                // check if more than 1
                if (socmed.Pages.length > 1) {
                    let pages = [];

                    socmed.Pages.forEach((page, idx) => {
                        let pageIcon;
                        let pageName;

                        pageIcon = page.Configuration.profile_image ? page.Configuration.profile_image : profileImg;
                        pageName = page.Name ? page.Name : 'N/A';

                        pages.push(
                            <UikNavLink
                                Component={'label'}
                                key={idx}
                                className={`navLink4 ${cls.navLinkPage}`}
                                onClick={() => {
                                    window.open(
                                        `https://www.facebook.com/${page.Configuration.id}/`,
                                        '_blank'
                                    );
                                }}
                            >
                                <UikAvatar
                                    className={`avatarRoundCorners`}
                                    imgUrl={pageIcon}
                                    name={(
                                        <span className={cls.iconText}>
                                            {pageName}
                                        </span>
                                    )}
                                />
                            </UikNavLink>
                        )
                    })

                    gHelpers.confirmDialog({
                        title: gHelpers.getLabel({ key: 61405, fallback: 'Select facebook page to view profile' }),
                        desc: '',
                        content:
                            <div>
                                {pages}
                            </div>
                    })
                } else {
                    window.open(
                        `https://www.facebook.com/${socmed.Pages[0].Configuration.id}/`,
                        '_blank'
                    );
                }
            }

            break;
        // end case 'Facebook':
        case 'Twitter':
            // https://twitter.com/{screen_name}
            if (socmed.SocialMediaAccountConfigurationData.screen_name && socmed.SocialMediaAccountConfigurationData.screen_name.length) {
                window.open(
                    `https://twitter.com/${socmed.SocialMediaAccountConfigurationData.screen_name[0]}`,
                    '_blank'
                );
            }
            break;
        // end case 'Twitter'
        case 'Youtube':
            // https://www.youtube.com/channel/{SocialMediaID}
            if (socmed.SocialMediaID) {
                window.open(
                    `https://www.youtube.com/channel/${socmed.SocialMediaID}`,
                    '_blank'
                );
            }
            break;
        // end case 'Youtube'

        case 'Pinterest':
            // https://www.pinterest.ph/{username}/
            if (socmed.SocialMediaAccountConfigurationData.username) {
                window.open(
                    `https://www.pinterest.ph/${socmed.SocialMediaAccountConfigurationData.username}/`,
                    '_blank'
                );
            }

            break;
        // end case 'Pinterest'

        case 'LinkedIn':
            if (socmed.Pages && socmed.Pages.length) {
                // check if more than 1
                if (socmed.Pages.length > 1) {
                    let pages = [];

                    socmed.Pages.forEach((page, idx) => {
                        let pageIcon;
                        let pageName;

                        pageIcon = page.Configuration.profile_image ? page.Configuration.profile_image : profileImg;
                        pageName = page.Name ? page.Name : 'N/A';

                        pages.push(
                            <UikNavLink
                                Component={'label'}
                                key={idx}
                                className={`navLink4 ${cls.navLinkPage}`}
                                onClick={() => {
                                    window.open(
                                        `https://www.linkedin.com/company/${page.Configuration.organization}/`,
                                        '_blank'
                                    );
                                }}
                            >
                                <UikAvatar
                                    className={`avatarRoundCorners`}
                                    imgUrl={pageIcon}
                                    name={(
                                        <span className={cls.iconText}>
                                            {pageName}
                                        </span>
                                    )}
                                />
                            </UikNavLink>
                        )
                    })

                    gHelpers.confirmDialog({
                        title: gHelpers.getLabel({ key: 61405, fallback: 'Select facebook page to view profile' }),
                        desc: '',
                        content:
                            <div>
                                {pages}
                            </div>
                    })
                } else {
                    window.open(
                        `https://www.linkedin.com/company/${socmed.Pages[0].Configuration.organization}/`,
                        '_blank'
                    );
                }
            }
            break;
        // end case 'LinkedIn'

        default:
            break;
    }
    // switch (socmed.SocialMediaTypeName)
}
// viewSocialMediaProfile

/* 
args = {
    length: int,
    offset: int,
    itemsPerPage: int,
    currentPage: int,
    goToPage: function
} 
*/
const pagination = (args) => {
    const maxInitialPage = 4;

    const toDisplayLength = args.length;
    if (toDisplayLength < args.itemsPerPage) {
        return;
    }

    let btns = [];

    if (args.currentPage > 1) {
        btns.push(
            <UikButton xs
                key={`page-prev`}
                onClick={() => args.goToPage(args.currentPage - 1, args.goToPageOptions ? args.goToPageOptions : null)}
            >
                <i className={`fas fa-angle-left`}></i>
            </UikButton>
        )
    }

    let totalPages = Math.ceil(toDisplayLength / args.itemsPerPage)

    if (totalPages >= 7) {
        if (args.currentPage <= maxInitialPage) {
            for (let pageNum = 1; pageNum <= maxInitialPage; pageNum++) {
                btns.push(
                    <UikButton
                        xs
                        primary={args.currentPage == pageNum}
                        key={`page-${pageNum}`}
                        onClick={() => args.goToPage(pageNum, args.goToPageOptions ? args.goToPageOptions : null)}
                    >
                        {pageNum}
                    </UikButton>
                )
            }
            // add ellipsis
            btns.push(
                <UikButton
                    xs
                    key={`page-elps-2`}
                    readOnly
                    clear
                >
                    ...
                </UikButton>,
                <UikButton
                    xs
                    primary={args.currentPage == totalPages}
                    key={`page-${totalPages}`}
                    onClick={() => args.goToPage(totalPages, args.goToPageOptions ? args.goToPageOptions : null)}
                >
                    {totalPages}
                </UikButton>
            )

        } else {
            btns.push(
                <UikButton
                    xs
                    primary={args.currentPage == 1}
                    key={`page-1`}
                    onClick={() => args.goToPage(1, args.goToPageOptions ? args.goToPageOptions : null)}
                >
                    1
                </UikButton>,
                <UikButton
                    xs
                    key={`page-elps-1`}
                    readOnly
                    clear
                >
                    ...
                </UikButton>
            )

            for (let pageNum = args.currentPage - 2; pageNum <= (totalPages > args.currentPage + 2 ? args.currentPage + 2 : totalPages); pageNum++) {
                btns.push(
                    <UikButton
                        xs
                        primary={args.currentPage == pageNum}
                        key={`page-${pageNum}`}
                        onClick={() => args.goToPage(pageNum, args.goToPageOptions ? args.goToPageOptions : null)}
                    >
                        {pageNum}
                    </UikButton>
                )
            }

            if (totalPages > args.currentPage + 2) {
                // add ellipsis
                if (args.currentPage + 3 < totalPages) {
                    btns.push(
                        <UikButton
                            xs
                            key={`page-elps-2`}
                            readOnly
                            clear
                        >
                            ...
                    </UikButton>)
                }

                btns.push(
                    <UikButton
                        xs
                        primary={args.currentPage == totalPages}
                        key={`page-${totalPages}`}
                        onClick={() => args.goToPage(totalPages, args.goToPageOptions ? args.goToPageOptions : null)}
                    >
                        {totalPages}
                    </UikButton>
                )
            }
        }



    } else {
        // show all pages
        for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
            btns.push(
                <UikButton
                    xs
                    primary={args.currentPage == pageNum}
                    key={`page-${pageNum}`}
                    onClick={() => args.goToPage(pageNum, args.goToPageOptions ? args.goToPageOptions : null)}
                >
                    {pageNum}
                </UikButton>
            )
        }

    }

    if (args.offset < (toDisplayLength - 1) - args.itemsPerPage) {
        btns.push(
            <UikButton xs
                key={`page-next`}
                onClick={() => args.goToPage(args.currentPage + 1, args.goToPageOptions ? args.goToPageOptions : null)}
            >
                <i className={`fas fa-angle-right`}></i>
            </UikButton>
        )
    }

    return (
        <div className={`${cls.paginationContainer} p-1`}>
            <UikButtonGroup>
                {btns}
            </UikButtonGroup>
        </div>
    );
}

const selectStyles = {
    control: (styles, { isFocused }) => ({
        ...styles,
        backgroundColor: !isFocused ? 'rgb(244, 247, 246)' : '#fff',
        borderColor: '#EAEDF3'
    }),
    option: (styles, { isSelected }) => {
        return {
            ...styles,
            ...{
                color:
                    isSelected
                        ? '#fff !important'
                        : 'rgb(55, 55, 55)',
                fontSize: '12px'
            }
        };
    },
    input: styles => ({
        ...styles, ...{
            fontSize: '12px'
        }
    }),
    placeholder: styles => ({ ...styles }),
    singleValue: (styles, { data }) => ({
        ...styles, ...{
            fontSize: '12px',
            width: '100%'
        }
    }),
};

const getFieldValue = (fields, attrName) => {
    const field = Object.entries(fields).find(f => f[0].includes(attrName));

    return field.length ? field[1].newValue : null;
}

const logout = () => {
    localStorage.removeItem('Token');
    localStorage.removeItem('UserDetails');
    localStorage.removeItem('UserApplicationFunctions');
    localStorage.removeItem('SystemSettings');
    localStorage.removeItem('IsSub');
    localStorage.removeItem('IsSubChecked');
    eraseCookie('IsLogin');
    window.location.href = "/";
}
const toSeconds = (hms) => {
    if (!hms) {
        hms = '00:00:00'
    }

    let a = hms.split(':'); // split it at the colons
    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    let seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
    return parseFloat(seconds);
}

export {
    genericQuery,
    isAuthenticated,
    isAccountActivated,
    isAccountTermsSigned,
    CalendarProcessor,
    loading,
    processing,
    config,
    TimeZones,
    localToObject,
    isDevAuthenticated,
    refreshUserDetails,
    centerAlert,
    getTimeZone,
    fieldGenerator,
    goTo404,
    getAttrib,
    getAttribVal,
    objAttrib,
    callApi,
    gHelpers,
    SocketIO,
    convertToSlug,
    isValidDate,
    updateByRID,
    appendLeadingZeroes,
    getRandomInt,
    formattedDateTime,
    isPortrait,
    dataURLtoBlob,
    textHasLink,
    textCaptureLink,
    insertNewJSONItem,
    mentionInputStyle,
    getMediaFileImg,
    loading2,
    addRuleValue,
    toTimeZone,
    formatDate,
    formatDateYMOnly,
    getNumberWithOrdinal,
    userGetFunctions,
    userHasFunction,
    arrayUnique,
    getWeek,
    getWeekNumber,
    setCookie,
    getCookie,
    eraseCookie,
    isValidTimeZone,
    toTimeZoneAPI,
    loading3,
    nl2br,
    formatBytes,
    insertStrAtPos,
    toUTC,
    toUTCDate,
    countSelect,
    getUserInitial,
    socket,
    removeParam,
    checkIfMobile,
    IsJSON,
    connectSocialMedia,
    getLabel,
    isSuperUser,
    viewSocialMediaProfile,
    pagination,
    getLanguage,
    selectStyles,
    isSubscribed,
    getFieldValue,
    logout,
    toSeconds
};