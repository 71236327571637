export { default as a01 } from './a01.jpg'
export { default as a02 } from './a02.jpg'
export { default as a03 } from './a03.jpg'
export { default as a04 } from './a04.jpg'
export { default as a05 } from './a05.jpg'
export { default as a06 } from './a06.jpg'
export { default as a07 } from './a07.jpg'
export { default as a08 } from './a08.jpg'
export { default as a09 } from './a09.jpg'
export { default as a10 } from './a10.jpg'
export { default as a11 } from './a11.jpg'
export { default as a12 } from './a12.jpg'
export { default as a13 } from './a13.jpg'
export { default as a14 } from './a14.jpg'
export { default as a15 } from './a15.jpg'
export { default as a16 } from './a16.jpg'
export { default as a17 } from './a17.jpg'
export { default as a18 } from './a18.jpg'
export { default as a19 } from './a19.jpg'
export { default as a20 } from './a20.jpg'
export { default as a21 } from './a21.jpg'
export { default as a21c } from './a21c.jpg'
export { default as a21d } from './a21d.png'
export { default as a22 } from './a22.jpg'
export { default as a23 } from './a23.jpg'
export { default as a24 } from './a24.jpg'
export { default as a25 } from './a25.jpg'
export { default as a26 } from './a26.jpg'
export { default as a27 } from './a27.jpg'

// video tutorials
export { default as vt01 } from './vt01.jpg'
export { default as vt02 } from './vt02.jpg'
export { default as vt03 } from './vt03.jpg'
export { default as vt04 } from './vt04.jpg'
export { default as vt05 } from './vt05.jpg'

// tutorials
export { default as t01 } from './t01.jpg'
export { default as t02 } from './t02.jpg'
export { default as t03 } from './t03.jpg'
export { default as t04 } from './t04.jpg'
export { default as t05 } from './t05.jpg'
export { default as t06 } from './t06.jpg'

// buildings
export { default as b01 } from './buildings/b01.jpg'
export { default as b02 } from './buildings/b02.jpg'
export { default as b03 } from './buildings/b03.jpg'
export { default as b04 } from './buildings/b04.jpg'
export { default as b05 } from './buildings/b05.jpg'
export { default as b06 } from './buildings/b06.jpg'
export { default as b07 } from './buildings/b07.jpg'
export { default as b08 } from './buildings/b08.jpg'
export { default as b09 } from './buildings/b09.jpg'
export { default as b10 } from './buildings/b10.jpg'
export { default as b11 } from './buildings/b11.jpg'
export { default as b12 } from './buildings/b12.jpg'
export { default as b13 } from './buildings/b13.jpg'
export { default as b14 } from './buildings/b14.jpg'
export { default as b15 } from './buildings/b15.jpg'
export { default as b16 } from './buildings/b16.jpg'
export { default as b17 } from './buildings/b17.jpg'
export { default as b18 } from './buildings/b18.jpg'
export { default as b19 } from './buildings/b19.jpg'
export { default as b20 } from './buildings/b20.jpg'
export { default as b21 } from './buildings/b21.jpg'
export { default as b22 } from './buildings/b22.jpg'
export { default as b23 } from './buildings/b23.jpg'
export { default as b24 } from './buildings/b24.jpg'
export { default as b25 } from './buildings/b25.jpg'
export { default as b26 } from './buildings/b26.jpg'
export { default as b101 } from './buildings/b101.jpg'
export { default as b102 } from './buildings/b102.jpg'
export { default as b103 } from './buildings/b103.jpg'
export { default as b104 } from './buildings/b104.jpg'
export { default as b105 } from './buildings/b105.jpg'

export { default as map } from './buildings/map.jpg'


// flags
export { default as flag01 } from './flag01.jpg'

// ser action icons
export { default as ua01 } from './ua01.svg'
export { default as ua02 } from './ua02.svg'


export { default as fIreland } from './flags/Ireland.png'
export { default as fCzechia } from './flags/Czechia.png'
export { default as fUk } from './flags/Uk.png'
export { default as fFrance } from './flags/France.png'
export { default as fTuvalu } from './flags/Tuvalu.png'
export { default as fNorway } from './flags/Norway.png'

// places
export { default as pl01 } from './pl01.jpg'


// social
export { default as s01 } from './social/s01.jpg'
export { default as s02 } from './social/s02.jpg'
export { default as s03 } from './social/s03.jpg'
export { default as s04 } from './social/s04.jpg'
export { default as s05 } from './social/s05.jpg'
export { default as s06 } from './social/s06.jpg'
export { default as s07 } from './social/s07.jpg'
export { default as s08 } from './social/s08.jpg'
export { default as s09 } from './social/s09.jpg'

//landing page-social media logos
export { default as fb } from './logos/facebook.png'
export { default as tw } from './logos/twitter.png'
export { default as ig } from './logos/instagram.png'
export { default as pt } from './logos/pinterest.png'
export { default as li } from './logos/linkedin.png'
export { default as yt } from './logos/youtube.png'

//landing page pictures
export { default as subsBG } from './landingPagePictures/subscriptionBG.png'

//fb default picture
export { default as fb1 } from './fb1.jpg'

export { default as tw1 } from './tw1.jpg'

//fb reactions box
export { default as fbBox } from './social/fbBox.png'
export { default as twBox } from './social/twBox.PNG'

//sample images for content
export { default as sampleImg1 } from './sampleImages/sampleImage1.jpg'
export { default as sampleImg2 } from './sampleImages/sampleImage2.jpg'
export { default as sampleImg3 } from './sampleImages/sampleImage3.jpg'
export { default as sampleImg4 } from './sampleImages/sampleImage4.jpg'
export { default as sampleImg5 } from './sampleImages/sampleImage5.jpg'

//play button for file upload media type
export { default as videoType } from './fileType/video.png'


// default photo image
export { default as defaultPhoto } from '../assets/images/default-photo.jpg'
