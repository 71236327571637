import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    UikWidgetContent,
    UikWidget,
    UikHeadline,
    UikHeadlineDesc
} from '../../../@uik';
import { genericQuery, refreshUserDetails, setCookie, logout } from '../../../helpers';

import cls from './account-confirm.module.scss';

class UserAuth extends Component {
    constructor(props) {
        super(props);

        document.title = props.pageName;

        this.state = {
            messageError: '',
            messageInfo: 'Verifying data...',
            isSuccess: false
        }
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);

        logout();

        if (urlParams.get('Token') && urlParams.get('UserID')) {
            localStorage.setItem('Token', urlParams.get('Token'));
            setCookie('IsLogin', urlParams.get('Token'), 365);

            // get user details
            const passUserDetails = {
                'Module': 'system_user_detail',
                'Parameters': {
                    'UserID': urlParams.get('UserID')
                }
            };

            const formDataUserDetails = new FormData();
            formDataUserDetails.append('requestjson', JSON.stringify(passUserDetails));

            genericQuery({
                Url: 'single_api/',
                Method: 'POST',
                Data: {
                    requestjson: formDataUserDetails
                },
                ResponseSuccessCallback: function (responseUserDetails) {
                    if (responseUserDetails.data.Status.IsSuccess) {
                        localStorage.setItem('UserDetails', JSON.stringify({
                            'User': responseUserDetails.data.Data.User,
                            'UserDetails': responseUserDetails.data.Data.UserDetails,
                            'CustomerDetails': responseUserDetails.data.Data.CustomerDetails
                        }));
                        localStorage.setItem('APP_VERSION', process.env.REACT_APP_VERSION);

                        this.setState({
                            isLoginSuccess: true
                        });

                        refreshUserDetails({
                            success: function () {
                                window.location.href= "/";
                            }.bind(this)
                        });
                        // window.location.reload();
                    }

                }.bind(this),
                ResponseFailCallback: function (responseUserDetails) {
                    // do when fail
                    this.setState({
                        messageError: 'There was an error. Please try again later.',
                        messageInfo: '',
                        isProcessing: false
                    });
                }.bind(this),
            });
            // genericQuery({ Url: 'single_api/', ... 
        }
    }

    render() {
        const { messageInfo, messageError } = this.state;

        return (
            <div className={`${cls.pageWrapper}`}>
                <div>
                    <div className="text-center">
                        <Link to="/">
                            {/* <img src={Logo} alt="" width="150" height="150" /> */}
                        </Link>
                    </div>
                    <div>
                        <UikWidget
                            className={cls.widgetWrapper}
                        >
                            <div className={cls.content}>
                                <UikWidgetContent className={cls.left}>
                                    <div className="text-center">
                                        <UikHeadline>
                                            Login User
                                        </UikHeadline>
                                        <UikHeadlineDesc className={`${messageError ? `red` : null}`}>
                                            {messageInfo}
                                            {messageError}
                                        </UikHeadlineDesc>
                                    </div>
                                </UikWidgetContent>
                            </div>
                        </UikWidget>
                    </div>
                </div>
            </div>
        )
    }
}

export default UserAuth;