import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { localToObject, getUserInitial, getLabel } from '../../../helpers';
import ReactTooltip from 'react-tooltip';
import defaultPhoto from 'assets/images/default-photo.jpg';

import {
    UikTopBar,
    UikTopBarSection,
    UikDivider,
    UikTopBarLink,
    UikTopBarLinkContainer,
    UikAvatar,
    UikDropdown,
    UikDropdownItem,
    UikNavBurger,
    UikNavSection,
    UikNavLink
} from '../../../@uik';

import HeaderBackgroundProcesses from './HeaderBackgroundProcesses';
import HeaderNotifications from './HeaderNotifications';

// import modals
import ModalQuickPost from './modals/ModalQuickPost';
import { sideMenuLinks } from '../menuLinks';
import cls from './header.module.scss';

const HelpActions = ({ onClick }) => {
    return (
        <UikTopBarLink className={`${cls.navLink}`}
            onClick={onClick}>
            <i className={`${cls.iconStyle} fas fa-caret-down`} />
        </UikTopBarLink>
    )
};

const UserActions = ({ onClick, userName }) => {
    let userDetails = {
        UserID: localToObject('UserDetails').User.UserID,
        FirstName: localToObject('UserDetails').UserDetails.Firstname.Value,
        LastName: localToObject('UserDetails').UserDetails.LastName.Value,
        ProfilePictureURL: localToObject('UserDetails').UserDetails.ProfilePictureURL,
    }

    if (!userDetails.ProfilePictureURL) {
        userDetails.ProfilePictureURL = defaultPhoto;
    }

    // const profilePicture = localToObject('UserDetails').UserDetails.ProfilePictureURL ? localToObject('UserDetails').UserDetails.ProfilePictureURL : null;
    return (
        <UikTopBarLink className={`${cls.navLink} ${cls.navLinkUser}`}
            onClick={onClick}>
            <UikAvatar
                id="header-top-user-img"
                className={`${cls.avatarUser} avatar-sm avatar-header-top`}
                highlighted
                imgUrl={userDetails.ProfilePictureURL}
                avatarPlaceholder={{
                    content: getUserInitial({
                        Firstname: localToObject('UserDetails').UserDetails.Firstname.Value,
                        Lastname: localToObject('UserDetails').UserDetails.LastName.Value
                    }),
                    color: 'blue',
                }}
                name={<>
                    {userName}
                    <i className={`${cls.iconStyle} fas fa-caret-down pl-2`} />
                </>}
            />
        </UikTopBarLink>
    )
};

const mapStateToProps = state => ({
    globalState: {
        isFullWidthPage: state.isFullWidthPage,
        isMobileWidthPage: state.isMobileWidthPage,
    }
});

class Header extends Component {
    constructor() {
        super();

        this.state = {
            modalQuickPostIsOpen: false,
            isOpen: false,
        }

        this.handleLogout = this.handleLogout.bind(this);
        this.handleModalQuickPost = this.handleModalQuickPost.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            (
                this.props.globalState.isFullWidthPage !== nextProps.globalState.isFullWidthPage
                || this.props.globalState.isMobileWidthPage !== nextProps.globalState.isMobileWidthPage
            )
            ||
            (
                this.state.modalQuickPostIsOpen !== nextState.modalQuickPostIsOpen
                || this.state.isOpen !== nextState.isOpen
            )
        ) {
            return true;
        }

        return false
    }

    handleLogout(e) {
        localStorage.removeItem('Token');
        localStorage.removeItem('UserDetails');
        window.location.href = "/";
    }

    handleModalQuickPost(e) {
        if (e) {
            e.preventDefault();
        }

        let isOpen = this.state.modalQuickPostIsOpen;

        if (isOpen) {
            isOpen = false;
        } else {
            isOpen = true;
        }

        this.setState({
            modalQuickPostIsOpen: isOpen
        });
    }

    toggle = () => {
        const { isOpen } = this.state;
        this.setState({
            isOpen: !isOpen
        })
    }

    render() {
        const { modalQuickPostIsOpen, isOpen } = this.state;
        const { isFullWidthPage, isMobileWidthPage } = this.props.globalState;
        let modals = [];
        const userName = localToObject('UserDetails').UserDetails && localToObject('UserDetails').UserDetails.Firstname ? `${localToObject('UserDetails').UserDetails.Firstname.Value} ${localToObject('UserDetails').UserDetails.LastName.Value}` : null;

        if (modalQuickPostIsOpen) {
            modals.push(<ModalQuickPost
                key='modal-addUser-0'
                modalIsOpen={modalQuickPostIsOpen}
                handleClose={this.handleModalQuickPost} />);
        }

        const BurgerMenu = <UikNavSection
            className={cls.burgerMenu}
            style={{
                transform: isOpen ? 'translateY(0)' : 'translateY(-150%)',
                opacity: isOpen ? '1' : '0',
                transition: 'transform 0.3s ease-in-out, opacity 0.5s'
            }}
        >
            <UikNavLink>
                {getLabel({ key: 1394, fallback: `Settings` })}
            </UikNavLink>
            <UikNavLink Component={Link} to={`/users/profile/`}>
                {getLabel({ key: 1395, fallback: `Profile` })}
            </UikNavLink>
            <UikNavLink Component={Link} to={`/logout/`}>
                {getLabel({ key: 1396, fallback: `Log out` })}
            </UikNavLink>
        </UikNavSection>;

        return (
            <UikTopBar className={`topBarMain`}>
                <UikTopBarSection className={`${cls.topbarLogo} ${isMobileWidthPage && cls.mobile}`}>
                    <Link to={`/`}>
                        <img src={`/logo_socrates.svg`} height={`50px`} />
                    </Link>
                </UikTopBarSection>
                {isMobileWidthPage && BurgerMenu}
                {
                    isMobileWidthPage ?
                        // <UikButton
                        //     clear
                        // >
                        //     <i className="fas fa-bars fa-lg fa-inverse"></i>
                        // </UikButton>
                        <div>
                            <UikNavBurger
                                isOpen={isOpen}
                                onClick={this.toggle}
                                style={{
                                    marginRight: '2em',
                                    cursor: 'pointer'
                                }}
                            />
                        </div>
                        :
                        <div className={cls.topBarDesktop}>
                            {/* 
                            <UikTopBarSection className={`${cls.topBar} ${cls.topBar1}`}>
                            <form className={cls.searchForm}>
                                <UikFormInputGroup className={cls.formInputGroup} direction="horizontal">
                                    <UikInput
                                        wrapperProps={{
                                            className: cls.searchBarWrapper
                                        }}
                                        className={cls.searchBarInput}
                                        placeholder="Search"
                                    />
                                    <UikButton
                                        className={cls.btnSubmit}
                                        icon={(<i className="fas fa-search"/>)}
                                        iconOnly
                                    />
                                </UikFormInputGroup>
                            </form>
                        </UikTopBarSection> 
                        */}
                            <UikTopBarSection className={`${cls.topBar} ${cls.topBar2}`}>
                                <UikDivider
                                    margin
                                    vertical
                                    className={cls.divider}
                                />
                                {
                                    isFullWidthPage
                                        ?
                                        <>
                                            <UikTopBarLinkContainer>
                                                {
                                                    sideMenuLinks.map((link, idx) => {

                                                        let hasFunction = true;
                                                        if ("userHasFunction" in link) {
                                                            if (!link["userHasFunction"]) {
                                                                hasFunction = false;
                                                            }
                                                        }

                                                        return (
                                                            hasFunction
                                                                ?
                                                                <React.Fragment key={idx}>
                                                                    <UikTopBarLink
                                                                        to={link.link}
                                                                        key={link.text}
                                                                        className={cls.navLink}
                                                                        Component={Link}
                                                                        data-tip
                                                                        data-for={`ttip-top-header-${idx}`}
                                                                    >
                                                                        <i className={`${cls.iconsStyle} ${link.icon}`} />
                                                                    </UikTopBarLink>
                                                                    <ReactTooltip
                                                                        key={`ttip-top-header-${idx}`} type='info'
                                                                        id={`ttip-top-header-${idx}`} type='info'>
                                                                        <span>{link.text}</span>
                                                                    </ReactTooltip>
                                                                </React.Fragment>
                                                                : null
                                                        )

                                                    })
                                                }
                                            </UikTopBarLinkContainer>
                                            <UikDivider
                                                margin
                                                vertical
                                                className={cls.divider}
                                            />
                                        </>
                                        : null
                                }
                                <UikTopBarLinkContainer>
                                    <HeaderNotifications iconStyle={cls.iconsStyle} />
                                    <HeaderBackgroundProcesses iconStyle={cls.iconsStyle} />
                                    <UikTopBarLink className={cls.navLink}>
                                        <i className={`${cls.iconsStyle} fas fa-question-circle`} />
                                    </UikTopBarLink>
                                    {/* 
                                    <UikDropdown DisplayComponent={HelpActions} position="bottomRight">
                                        <UikDropdownItem>
                                            {getLabel({ key: 1394, fallback: `Settings` })}
                                        </UikDropdownItem>
                                        <UikDropdownItem Component={Link} to={`/users/${localToObject('UserDetails').User.UserID}/`}>
                                            {getLabel({ key: 1395, fallback: `Profile` })}
                                        </UikDropdownItem>
                                        <UikDropdownItem Component={Link} to={`/logout/`}>
                                            {getLabel({ key: 1396, fallback: `Log out` })}
                                        </UikDropdownItem>
                                    </UikDropdown> 
                                    */}
                                </UikTopBarLinkContainer>
                                <UikDivider
                                    margin
                                    vertical
                                    className={cls.divider}
                                />
                                <UikDropdown
                                    DisplayComponent={({ ...rest }) => {
                                        return <UserActions {...rest} />
                                    }}
                                    displayComponentProps={
                                        { userName: userName }
                                    }
                                    position="bottomRight"
                                    className={cls.navLinkAvatar}
                                >
                                    <UikDropdownItem>
                                        {getLabel({ key: 1394, fallback: `Settings` })}
                                    </UikDropdownItem>
                                    <UikDropdownItem Component={Link} to={`/users/${localToObject('UserDetails').User.UserID}/`}>
                                        {getLabel({ key: 1395, fallback: `Profile` })}
                                    </UikDropdownItem>
                                    <UikDropdownItem Component={Link} to={`/logout/`}>
                                        {getLabel({ key: 1396, fallback: `Log out` })}
                                    </UikDropdownItem>
                                </UikDropdown>
                            </UikTopBarSection>
                        </div>
                }
                {modals}
            </UikTopBar>
        )
    }
}

export default connect(mapStateToProps)(Header);