import React, { Component } from 'react';
import { withRouter } from "react-router";
import {
    UikNavSection,
    UikNavSectionTitle,
    UikNavLink
} from '../../../@uik';
import { userHasFunction, getLabel, gHelpers } from '../../../helpers';

// import modals
import ModalQuickPost from './modals/ModalQuickPost';
import cls from './header.module.scss';
import ModalAddNewOuting from 'views/Schedules/components/modals/ModalAddNewOuting';
import AddNewSchedule from 'views/Schedules/components/modals/ModalAddSchedule';

class NavBottomSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalQuickPostIsOpen: false,
            isModalNewOutingIsOpen: false
        }

        this.handleModalQuickPost = this.handleModalQuickPost.bind(this);
        this.handleModalNewOuting = this.handleModalNewOuting.bind(this);
        this.handleModalNewSchedule = this.handleModalNewSchedule.bind(this);
    }

    handleModalQuickPost(e) {
        if (e) e.preventDefault();

        let isOpen = this.state.modalQuickPostIsOpen;

        if (isOpen) isOpen = false;
        else isOpen = true;

        this.setState({ modalQuickPostIsOpen: isOpen});
    }

    handleModalNewOuting(e) {
        if (e) e.preventDefault();

        let isOpen = this.state.isModalNewOutingIsOpen;

        if (isOpen) isOpen = false;
        else isOpen = true;

        this.setState({ isModalNewOutingIsOpen: isOpen});

    }

    handleModalNewSchedule(e) {
        if (e) e.preventDefault();

        let isOpen = this.state.isModalNewScheduleIsOpen;

        if (isOpen) isOpen = false;
        else isOpen = true;

        this.setState({ isModalNewScheduleIsOpen: isOpen});
    }
    
    render() {
        const { modalQuickPostIsOpen, isModalNewOutingIsOpen, isModalNewScheduleIsOpen } = this.state;
        let modals = [];

        if (modalQuickPostIsOpen) {
            modals.push(<ModalQuickPost
                key='modal-addUser-0'
                modalProps={{
                    modalIsOpen: modalQuickPostIsOpen,
                    handleClose: this.handleModalQuickPost,
                    headerText: getLabel({ key: 1391, fallback: `Quick Post` }),
                    customStyles: {
                        content: {
                            width: '1024px',
                            height: '90vh',
                            maxWidth: '90%',
                            overflow: 'hidden'
                        }
                    },
                }}
            />);
        }

        if (isModalNewOutingIsOpen) {
            modals.push(<ModalAddNewOuting                  
                key={'modal-add-outing'}
                modalProps={{
                    modalIsOpen: isModalNewOutingIsOpen,
                    handleClose: () => this.setState({ isModalNewOutingIsOpen: false }),
                    headerText: gHelpers.getLabel({ key: 60919, fallback: `New Outing` }),
                    customStyles: {
                        content: {
                            width: '1024px',
                            height: '90vh',
                            maxWidth: '90%',
                            overflow: 'hidden'
                        }
                    },
                }}
            />);
        }

        if (isModalNewScheduleIsOpen) {
            modals.push(
                <AddNewSchedule
                    key={'modal-add-schedule'}
                    modalProps={{
                        modalIsOpen: isModalNewScheduleIsOpen,
                        handleClose: () => this.setState({ isModalNewScheduleIsOpen: false }),
                        headerText: gHelpers.getLabel({ key: 62257, fallback: 'Add New Media Schedule' }),
                        customStyles: {
                            content: {
                                width: '1024px',
                                maxWidth: '90%',
                                overflow: 'hidden'
                            }
                        },
                    }}
                />
             );
        }

        return (
            <>
                <UikNavSection>
                    <UikNavSectionTitle className={cls.quickLinksSectionTitle}>
                        {getLabel({ key: 1397, fallback: 'Quick Links' })}
                    </UikNavSectionTitle>
                    {
                        userHasFunction('Media Outing', "Create media outing")
                            && this.props.location.pathname !== "/"
                            ? <UikNavLink onClick={this.handleModalQuickPost} className={cls.quickLink}>
                                {getLabel({ key: 61309, fallback: 'Create a quick post' })}
                            </UikNavLink>
                            : null
                    }
                    {
                        userHasFunction('Media Outing', "Create media outing") 
                            ? <UikNavLink onClick={this.handleModalNewOuting} className={cls.quickLink}>
                                {getLabel({ key: 1399, fallback: 'Create a new outing' })}
                            </UikNavLink>
                            : null
                    }
                    {
                        userHasFunction('Media Outing', "Create media outing") 
                            ? <UikNavLink onClick={this.handleModalNewSchedule} className={cls.quickLink}>
                                {getLabel({ key: 1398, fallback: 'Create a new schedule' })}
                            </UikNavLink>
                            : null
                    }
                    <UikNavLink className={cls.quickLink}>
                        {getLabel({ key: 1401, fallback: 'Support' })}
                    </UikNavLink>
                </UikNavSection>
                {modals}
            </>
        )
    }
}

export default withRouter(NavBottomSection);
