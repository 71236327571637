import React, { Component, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import './App.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';

import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Modal from 'react-modal';

// Containers
import DefaultLayout from './containers/DefaultLayout';

// Pages
import Login from './views/Pages/Login';
import LoginProcess from './views/Pages/LoginProcess';
import DevLogin from './views/Pages/DevLogin';
import Landing from './views/Pages/Login';
import Register from './views/Pages/Register';
import AccountConfirm from './views/Pages/AccountConfirmation';
import UserInvitation from './views/Pages/UserInvitation';
import NewUserWelcome from './views/Pages/WelcomeNewUser';
import AccountNotActivated from './views/Pages/AccountNotActivated';
// import NewAccountWelcome from './views/Pages/Welcome';
import NewAccountWelcome2 from './views/Pages/WelcomeNewUser/index3';
import CustomerSetup from './views/Pages/ContinueRegistration';

// import Page404 from './views/Pages/Page404';
import { isAuthenticated, isAccountActivated, isAccountTermsSigned, loading, isDevAuthenticated, refreshUserDetails, localToObject, getLabel, isSubscribed, logout } from './helpers';

// pages for testing
// import Test from './views/Pages/Test';
// import TestPage2 from './views/Pages/Test/testpage2';
// import TestFieldGenerator from './views/Pages/Test/testFieldGenerator';
// import TestSocket from './views/Pages/Test/socket';

Modal.setAppElement("#root");

const GuestOnlyRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                !isAuthenticated ? (
                    <Component pageName={rest.name} {...props} />
                ) : (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: { from: props.location }
                            }}
                        />
                    )
            }
        />
    )
};
const LoggedInRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                if (isAuthenticated) { // check if authenticated
                    if (!isAccountActivated && rest.path != "/account-not-activated/") {
                        // check if account activated then redirect to not activated notice
                        return <Redirect
                            to={{
                                pathname: "/account-not-activated/"
                            }}
                        />
                    } else if (!isAccountActivated && rest.path == "/account-not-activated/") {
                        return <Component pageName={rest.name} {...props} />

                    } else if (localToObject('UserDetails').Roles && localToObject('UserDetails').Roles.length) {
                        const roleName = localToObject('UserDetails').Roles[0].Name;

                        if (roleName == 'Admin') {
                            // return <Component pageName={rest.name} {...props} />; // temp
                            /* 
                            if (!isAccountTermsSigned && rest.path != "/customer-setup/") { // "/welcome/"

                                // check if account has not yet signed with terms and conditions
                                return <Redirect
                                    to={{
                                        pathname: "/welcome/" // "/welcome/"
                                    }}
                                />
                            } else if (!isAccountTermsSigned && rest.path == "/welcome/") {
                                return <Component pageName={rest.name} {...props} />
                            } else if (isAccountTermsSigned && rest.path == "/welcome/") {
                                return <Redirect
                                    to={{
                                        pathname: "/"
                                    }}
                                />
                            } 
                            */
                            if (!isSubscribed && rest.path != "/customer-setup/") {
                                return <Redirect
                                    to={{
                                        pathname: "/customer-setup/"
                                    }}
                                />
                            } else if (!isSubscribed && rest.path == "/customer-setup/") {
                                return <Component pageName={rest.name} {...props} />
                            } else if (isSubscribed && rest.path == "/customer-setup/") {
                                return <Redirect
                                    to={{
                                        pathname: "/"
                                    }}
                                />
                            }

                            // if (roleName == 'Admin') end
                        } else {
                            if (!isAccountTermsSigned && rest.path != "/welcome-new-user/") {
                                return <Redirect
                                    to={{
                                        pathname: "/welcome-new-user/"
                                    }}
                                />
                            } else if (!isAccountTermsSigned && rest.path == "/welcome-new-user/") {
                                return <Component pageName={rest.name} {...props} />
                            }


                        } // else (roleName == 'Admin')
                    } // if (!isAccountActivated  && rest.path != "/account-not-activated/")

                    return <Component pageName={rest.name} {...props} />

                } else {
                    // redirect to login if not authenticated
                    return <Redirect
                        to={{
                            pathname: "/login/",
                            state: { from: props.location }
                        }}
                    />
                } // if(isAuthenticated)
            }
            }
        />
    );
}
class App extends Component {

    constructor() {
        super();

        this.state = {
            isLoading: true
        }

        document.title = 'Socrates - Calendar-based Social Media Management Tool';

        if (isAuthenticated) {
            if (localStorage.getItem('APP_VERSION')) {
                if (localStorage.getItem('APP_VERSION') !== process.env.REACT_APP_VERSION) {
                    alert(getLabel({ isString: true, key: 62437, fallback: 'The system has been updated. You will be logged out.' }));
                    localStorage.setItem('APP_VERSION', process.env.REACT_APP_VERSION);
                    logout();
                }
            } else {
                alert(getLabel({ isString: true, key: 62437, fallback: 'The system has been updated. You will be logged out.' }));
                localStorage.setItem('APP_VERSION', process.env.REACT_APP_VERSION);
                logout();
            }
        }
    }

    componentDidMount() {
        // document.querySelector("#loading-container").classList.add('d-none');
        // remove html pre loader
        const loader = document.getElementById("loading-container");
        loader.parentNode.removeChild(loader);
        if (isAuthenticated) {
            refreshUserDetails({
                success: function () {
                    this.setState({
                        isLoading: false
                    })
                }.bind(this)
            });
        } else {
            this.setState({
                isLoading: false
            })
        }

    }

    render() {
        const { isLoading } = this.state;

        if (!isDevAuthenticated) {
            return (
                <Router>
                    <Suspense fallback={loading()}>
                        <Switch>
                            <Route path="/" name="Dev Login" component={DevLogin} />
                        </Switch>
                    </Suspense>
                </ Router>
            )
        }

        if (isLoading) {
            return (
                <div style={{
                    width: '100vh',
                    height: '100vh'
                }}>
                    {loading()}
                </div>
            )
        }

        return (
            <div>
                <Router>
                    <Suspense fallback={loading()}>
                        <Switch>

                            <GuestOnlyRoute exact path="/login/" name="Login" component={Login} />
                            <Route exact path="/login-process/" name="Logging in" component={LoginProcess} />

                            <GuestOnlyRoute path="/register/" name="Register" component={Register} />

                            <Route path="/account-confirmation/:token/" name="Account Confirmation" component={({ ...rest }) => <AccountConfirm pageName="Account Confirmation" {...rest} />} />

                            <Route path="/user-invitation/:token/" name="User Invitation" component={({ ...rest }) => <UserInvitation pageName="User Invitation" {...rest} />} />

                            <Route path="/account-not-activated/" name="Account not yet activated" component={({ ...rest }) => <AccountNotActivated pageName="Account not yet activated" {...rest} />} />

                            <LoggedInRoute path="/customer-setup/" name="Customer Setup" component={({ ...rest }) => <CustomerSetup pageName="Customer Setup" {...rest} />} />

                            <LoggedInRoute path="/welcome-new-user/" name="Welcome New User" component={({ ...rest }) => <NewUserWelcome pageName="Welcome New User" {...rest} />} />

                            <LoggedInRoute path="/welcome/" name="Get Started with Socates!" component={({ ...rest }) => <NewAccountWelcome2 pageName="Get Started with Socates!" {...rest} />} />

                            <LoggedInRoute path="/welcome-test/" name="Get Started with Socates!" component={({ ...rest }) => <NewAccountWelcome2 pageName="Get Started with Socates!" {...rest} />} />


                            <Route path="/logout/" name="Log out" component={() => {
                                logout();
                            }} />

                            {
                                isAuthenticated
                                    ? <LoggedInRoute path="/" name="Home" component={DefaultLayout} />
                                    : <Route path="/" name="Landing Page" component={Landing} />
                            }
                        </Switch>
                    </Suspense>
                </Router>
                <NotificationContainer />
            </div>
        );
    }
}

export default App;