import { userHasFunction, getLabel } from '../../helpers';

const sideMenuLinks = [
    {
        text: getLabel({ key: 61315, fallback: 'Dashboard' }),
        icon: "fas fa-tachometer-alt",
        link: "/",
    },
    {
        text: getLabel({ key: 1424, fallback: 'Accounts' }),
        icon: "fas fa-address-book",
        link: "/accounts/",
        sysFunction: {
            m: 'Account',
            a: "Read/view account"
        }
        // userHasFunction: userHasFunction('Account', "Read/view account")
    },
    {
        text: getLabel({ key: 61321, fallback: 'Users' }),
        icon: "fas fa-users",
        link: "/users/",
        sysFunction: {
            m: 'Users',
            a: "Read/View user"
        }
        // userHasFunction: userHasFunction('Users', "Read/View user")
    },
    {
        text: getLabel({ key: 61327, fallback: 'Schedules' }),
        icon: "fas fa-calendar-alt",
        link: "/schedules/",
        sysFunction: {
            m: 'Media Schedule',
            a: "Read/View media schedule"
        }
        // userHasFunction: userHasFunction('Media Schedule', "Read/View media schedule")
    },
    {
        text: getLabel({ key: 1383, fallback: 'Media Library' }),
        icon: "fas fa-images",
        link: "/media-library/",
        sysFunction: {
            m: 'Media File',
            a: "Manage Media File"
        }
        // userHasFunction: userHasFunction('Media File', "Manage Media File")
    },
    /* {
        text: 'Settings',
        icon: faCogs,
        link: '/settings/'
    }, */
    {
        text: getLabel({ key: 61333, fallback: 'Company' }),
        icon: "fas fa-building",
        link: '/company/',
        sysFunction: {
            m: 'Customer/Company',
            a: "Read/View customer"
        }
        // userHasFunction: userHasFunction('Customer/Company', "Read/View customer")
    },
    {
        text: getLabel({ key: 1396, fallback: 'Log out' }),
        icon: "fas fa-sign-out-alt",
        link: '/logout/'
    },
];

export { sideMenuLinks };