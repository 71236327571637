import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    UikButton
} from '../@uik';
import { genericQuery, gHelpers, loading, toTimeZoneAPI } from './index';
import { addBGProcesses, updateBGProcesses } from 'redux/actions/bgProcess';
import FieldsGenerator from './fieldGenerator';
import cls from './helpers.module.scss';

const mapStateToProps = state => ({
    fieldRdx: {
        fields: state.fields,
    },
    mediaFileLibary: {
        selectedFiles: state.selectedMediaFiles
    },
    notifIO: {
        connect: state.notificationsConnect
    }
});

const mapDispatchToProps = dispatch => ({
    addBGProcesses: (data) => dispatch(addBGProcesses(data)),
    updateBGProcesses: (data) => dispatch(updateBGProcesses(data)),
});

class FormGenerator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            isProcessing: false,
            targetModule: '',
            isLoading: false,
            componentUpdated: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSaveSubmit = this.handleSaveSubmit.bind(this);
        this.loadComponent = this.loadComponent.bind(this);
        this.fieldGenRef = this.fieldGenRef.bind(this);

        this.fieldGen = React.createRef();
    }

    fieldGenRef() {
        return this.fieldGen;
    }

    handleSaveSubmit(thisFormData, options) {
        const { formFieldSettings } = this.props;
        const { targetModule } = this.state;

        // check if has override form data
        if (formFieldSettings.overrideFormData) {
            thisFormData = formFieldSettings.overrideFormData(thisFormData);
        }

        let taggedUsers = null;
        let params = [];

        // Display the values
        for (var pair of thisFormData.entries()) {

            let nameValue = pair[0];
            let valueValue = pair[1];

            if (valueValue == 'on') {
                valueValue = 1;
            }

            const fieldRdx = this.props.fieldRdx.fields[nameValue];

            if (fieldRdx.attrib.DataType === "Array") {
                valueValue = fieldRdx.JSONValue ? fieldRdx.JSONValue : valueValue;
            }

            // check if has mentions/tags
            if (fieldRdx.taggedUsers) {
                taggedUsers = fieldRdx.taggedUsers;
                valueValue = fieldRdx.newValue;
                taggedUsers.forEach(user => {
                    let regExp = new RegExp("@[[a-zA-Z]+]\\(" + user + "\\)");
                    valueValue = valueValue.replace(regExp, `[^${user}^]`);
                })
            }
            params.push({
                name: nameValue,
                value: valueValue
            })
        }

        const formDataObject = new FormData();
        let targetModuleObj = {
            'Module': targetModule,
            'Parameters': {
                'AttributeSpecification': params
            }
        };

        // check if has additional submit data
        if (formFieldSettings.additionalSubmitData) {
            targetModuleObj['Parameters'] = {
                ...targetModuleObj['Parameters'],
                ...formFieldSettings.additionalSubmitData
            }
        }

        if (options && options.additionalParameters) {
            targetModuleObj['Parameters'] = {
                ...targetModuleObj['Parameters'],
                ...options.additionalParameters
            }
        }

        if (taggedUsers) {
            targetModuleObj['Parameters'] = {
                ...targetModuleObj['Parameters'],
                TaggedUsers: taggedUsers
            }
        }
        // console.log(JSON.stringify(targetModuleObj)); return;
        formDataObject.append('requestjson', JSON.stringify(targetModuleObj));
        let targetUrl = 'single_api/';
        if (formFieldSettings.overrideTarget) {
            targetUrl = formFieldSettings.overrideTarget;
        }
        let additionalSettings = {};

        if (formFieldSettings.isBgProcess) {
            this.fieldGen.clearFields();
        }

        genericQuery(
            {
                Url: targetUrl,
                Method: 'POST',
                Data: {
                    requestjson: formDataObject
                },
                Headers: {
                    'Content-Type': 'multipart/form-data'
                },
                ResponseSuccessCallback: function (response) {
                    if (response.data.Status.IsSuccess) {

                        this.fieldGen.clearFields();
                        if (formFieldSettings.onSubmitSuccess) {

                            if (taggedUsers) {
                                // notify invovled users
                                this.props.notifIO.connect.emit('notify', taggedUsers);
                            }

                            if (response.data.Data) {
                                if (response.data.Data.Notification) {
                                    this.props.notifIO.connect.emit('notify', response.data.Data.Notification.targetUsers);
                                }
                            }

                            if (formFieldSettings.onSubmitSuccessReturnFormData) {
                                formFieldSettings.onSubmitSuccess({
                                    response: response.data,
                                    thisFormData: thisFormData
                                });
                            } else {
                                formFieldSettings.onSubmitSuccess(response.data);
                            }
                        }
                    } else {
                        if (formFieldSettings.onSubmitUnsuccess) {
                            formFieldSettings.onSubmitUnsuccess(response.data);
                        }
                    }

                    this.setState({
                        isProcessing: false
                    });
                }.bind(this),
                ResponseFailCallback: function (response) {

                    if (formFieldSettings.onSubmitFail) {
                        formFieldSettings.onSubmitFail(response.data);
                    }

                    // do when fail
                    this.setState({
                        messageError: gHelpers.getLabel({ isString: true, key: 61351, fallback: 'An error occurred during the process.' }),
                        messageInfo: '',
                        isProcessing: false
                    });
                }.bind(this),
                ...additionalSettings
            }
        ); // genericQuery(...
    }

    handleSubmit(e, showLoading = true, proceed = false, options = null) {
        if (e) {
            e.preventDefault();
        }
        const { formFieldSettings } = this.props;
        const { isProcessing } = this.state;
        let formPassed = { status: true, msg: '' };

        if (isProcessing) {
            return false;
        }

        if (formFieldSettings.beforeSubmit && !proceed) {
            if (formFieldSettings.beforeSubmit() === false) {
                return false;
            }
        }

        if (showLoading) {
            this.setState({
                isProcessing: true
            });
        }

        let thisFormData = new FormData(document.getElementById(`form-${this.props.module}`));

        // check if has override form data
        if (formFieldSettings.overrideFormData) {
            thisFormData = formFieldSettings.overrideFormData(thisFormData);
        }

        let dateTimeUTCFieldName = null;

        // Display the values
        for (var pair of thisFormData.entries()) {
            let nameValue = pair[0];
            const fieldRdx = this.props.fieldRdx.fields[nameValue];
            if (fieldRdx && fieldRdx.attrib) {
                // CONFIG DATETIME FIELD START
                // check if datetime that requires conversion to UTC
                if (fieldRdx.attrib.DataType === 'DateTime' && fieldRdx.ruleValueParsed.convertUTCOnUpdate) {
                    dateTimeUTCFieldName = fieldRdx;
                }

                // check if required
                if (fieldRdx.attrib.IsMandatory && !fieldRdx.newValue) {
                    this.fieldGen.updateField({
                        keyName: nameValue,
                        data: {
                            errorMessage: gHelpers.getLabel({ key: 61339, fallback: 'This field is required.' }),
                            isSuccess: false
                        }
                    })
                    formPassed.status = false;
                    formPassed.msg = gHelpers.getLabel({ key: 61393, fallback: 'Some fields is required.' });
                }
            }
        }

        if (formPassed.status) {
            if (dateTimeUTCFieldName) {
                toTimeZoneAPI(
                    dateTimeUTCFieldName.newValue,
                    dateTimeUTCFieldName.ruleValueParsed.scheduleTimeZone,
                    function (response) {
                        var CurrentDate = moment().format('YYYY-MM-DD HH:mm:ss');
                        // passObject.Value = response.data.Data.DT_utc;
                        if (response && response.data && response.data.Data && response.data.Data.DT_utc) thisFormData.set(dateTimeUTCFieldName.attrib.AttributeName, response.data.Data.DT_utc)
                        else thisFormData.set(dateTimeUTCFieldName.attrib.AttributeName, CurrentDate);

                        this.handleSaveSubmit(thisFormData, options);

                    }.bind(this)
                );
            } else {
                this.handleSaveSubmit(thisFormData, options);
            }
        } else {

            formFieldSettings.onSubmitUnsuccess({
                data: {
                    Status: {
                        IsSuccess: false,
                        Message: formPassed.msg
                    }
                },
                type: 1
            });

            if (showLoading) {
                this.setState({
                    isProcessing: false
                });
            }
        }

    }

    loadComponent() {
        const { module, formFieldSettings } = this.props;

        // this.props.isLoadingAction(true); // loading
        this.setState({ isLoading: true, componentUpdated: true });

        const formDataObject = new FormData();
        formDataObject.append('requestjson', JSON.stringify({
            'Module': module
        }));

        genericQuery(
            {
                Url: 'single_api/',
                Method: 'POST',
                Data: {
                    requestjson: formDataObject
                },
                ResponseSuccessCallback: function (response) {
                    if (response.data.Status.IsSuccess) {
                        let passFields = response.data.Data.AttributeSpecification;
                        let getLogo = passFields.find(field => field.AttributeName.includes("Logo"));

                        if (formFieldSettings.overrideFieldAttributes) {
                            passFields = passFields.map(field => {
                                let additionalFields = {};
                                if (formFieldSettings.overrideFieldAttributes[field.AttributeName]) {
                                    additionalFields = formFieldSettings.overrideFieldAttributes[field.AttributeName];
                                }

                                return {
                                    ...field,
                                    ...additionalFields
                                }
                            })
                        }

                        let fields = {
                            fields: passFields,
                            excluded: [getLogo !== undefined ? getLogo.AttributeName : ""],
                        };

                        this.setState({
                            fields: fields,
                            targetModule: response.data.Data.TargetModule
                        });

                    } else {
                        gHelpers.notif('error', { msg: response.data.Status.Message });
                    }

                    // callback
                    if (this.props.formFieldSettings.onFormLoad) {
                        this.props.formFieldSettings.onFormLoad(response);
                    }
                    // this.props.isLoadingAction(false);
                    this.setState({
                        isLoading: false,
                        componentUpdated: false
                    });

                }.bind(this),
                ResponseFailCallback: function (response) {
                    // do when fail
                    this.setState({
                        messageError: gHelpers.getLabel({ key: 61351, fallback: 'An error occurred during the process.' }),
                        messageInfo: '',
                        isProcessing: false,
                        isLoading: false,
                        componentUpdated: false
                    });
                    // this.props.isLoadingAction(false); // done loading
                }.bind(this),
            }
        ); // genericQuery(...

    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this)
        }

        this.loadComponent();
    }

    render() {
        const { formFieldSettings } = this.props
        const { fields } = this.state;
        let isProcessing = this.state.isProcessing;

        // check if no loading
        if (formFieldSettings.noLoading) {
            isProcessing = false;
        }

        let fieldGenerator;
        let userAvatar = null;
        let formProps = {};

        if (fields.fields) {
            let excluded = fields.excluded;
            if (formFieldSettings.excluded) {
                excluded = [...fields.excluded, ...formFieldSettings.excluded];
            }

            if (fields.fields.some(field => field.DataType === "Image")) {
                let userAvatarFields = {
                    fields: fields.fields.filter(field => field.DataType === "Image"),
                    excluded: fields.excluded,
                };
                userAvatar = <FieldsGenerator
                    fields={userAvatarFields}
                    settings={{
                        // ...formFieldSettings,
                        updateByRID: false,
                        displayAsThumbnail: true,
                    }}
                />
            }
            fieldGenerator = <FieldsGenerator
                onRef={ref => (this.fieldGen = ref)}
                fields={{
                    fields: fields.fields,
                    excluded: excluded
                }}
                settings={{
                    ...formFieldSettings,
                    updateByRID: false
                }}
            />
        }

        // check if has form props
        if (formFieldSettings.formProps) {
            formProps = formFieldSettings.formProps;
        }

        if (this.props.module) {
            formProps.id = `form-${this.props.module}`;
        }

        let comp =
            <div style={{
                position: 'relative'
            }}>
                {isProcessing ? loading() : null}
                <form {...formProps} onSubmit={this.handleSubmit}>
                    {userAvatar}
                    {fieldGenerator}
                    {
                        formFieldSettings.additionalComponents
                            ? formFieldSettings.additionalComponents.component
                            : null
                    }
                    {
                        formFieldSettings.hideSubmitBtn
                            ?
                            null
                            : <>
                                <div className={`mt-2 ${formFieldSettings.stickyFooter ? cls.stickyFooter : ''}`}>
                                    <UikButton
                                        type="submit"
                                        className={cls.btnAction}
                                        success
                                        isLoading={isProcessing ? true : false}
                                    >
                                        {
                                            formFieldSettings.btnSubmitText
                                                ? formFieldSettings.btnSubmitText
                                                : gHelpers.getLabel({ key: 61399, fallback: 'Create' })
                                        }
                                    </UikButton>
                                </div>
                            </>
                    }
                </form>
            </div>;

        if (formFieldSettings.formStyle === 'inline') {
            comp =
                <div style={{
                    position: 'relative'
                }}>
                    {isProcessing ? loading() : null}
                    <form {...formProps} onSubmit={this.handleSubmit}>
                        <div className={`row`}>
                            <div className={`col-9`}>
                                {userAvatar}
                                {fieldGenerator}
                            </div>
                            <div className={`col-3 align-items-center d-flex`}>
                                {
                                    formFieldSettings.hideSubmitBtn
                                        ?
                                        null
                                        : <UikButton
                                            type="submit"
                                            className={cls.btnAction}
                                            success
                                            isLoading={isProcessing ? true : false}
                                        >
                                            {
                                                formFieldSettings.btnSubmitText
                                                    ? formFieldSettings.btnSubmitText
                                                    : gHelpers.getLabel({ key: 61399, fallback: 'Create' })
                                            }
                                        </UikButton>
                                }
                            </div>
                        </div>
                    </form>
                </div>;

        }
        if (this.state.isLoading) {
            comp = loading('sm2');
        }

        return comp;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormGenerator);