import React from 'react';
import cls from './socialicons.module.scss';

const FacebookIcon = (props) => (
    <i {...props} className={`fab fa-facebook ${cls.facebookColor} ${props.className || '' }`} />
);
const InstagramIcon = (props) => (
    <i {...props} className={`fab fa-instagram ${cls.instagramColor} ${props.className || '' }`} />
);
const LinkedinIcon = (props) => (
    <i {...props} className={`fab fa-linkedin ${cls.linkedinColor} ${props.className || '' }`} />
);
const TwitterIcon = (props) => (
    <i {...props} className={`fab fa-twitter ${cls.twitterkColor} ${props.className || '' }`} />
);
const PinterestIcon = (props) => (
    <i {...props} className={`fab fa-pinterest ${cls.pinterestColor} ${props.className || '' }`} />
);
const YoutubeIcon = (props) => (
    <i {...props} className={`fab fa-youtube ${cls.youtubeColor} ${props.className || '' }`} />
);

export {
    FacebookIcon,
    InstagramIcon,
    LinkedinIcon,
    TwitterIcon,
    PinterestIcon,
    YoutubeIcon
};