import React from 'react';
import FieldsGenerator from 'helpers/fieldGenerator';
import { gHelpers, callApi, localToObject, loading } from 'helpers';
import cls from '../style.module.scss';

class Step2Profile extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            profileFields: [],
            companyFields: [],
            termsFields: [],
            isLoading: false
        }

        this._isMounted = false;
        this.getFields = this.getFields.bind(this);
    }

    getFields() {
        return this.profileFields && this.companyFields
            && this.profileFields.getFields() && this.companyFields.getFields() ? {
            ...this.profileFields.getFields(),
            ...this.companyFields.getFields()
        } : null
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;

        if (this.props.onRef) {
            this.props.onRef(this)
        }

        callApi({
            beforeSubmit: () => {
                this.setState({ isLoading: true })
            },
            passObject: {
                'Module': 'media_schedule_getuser',
                'Parameters': {
                    'UserID': localToObject('UserDetails').User.UserID,
                }
            },
            success: (response) => {
                if (response.Data.MediaScheduleUser && response.Data.MediaScheduleUser.length) {
                    this._isMounted && this.setState({
                        profileFields: response.Data.MediaScheduleUser.filter(f => ['Firstname', 'LastName'].includes(f.AttributeName)),
                        termsFields: response.Data.MediaScheduleUser.filter(f => ['ReceiveEmailUpdates', 'WebsiteTermsSigned'].includes(f.AttributeName)),
                        isLoading: false
                    })
                } else {
                    this._isMounted && this.setState({ isLoading: false })
                }

            },
            unSuccess: (response) => {
                this._isMounted && this.setState({ isLoading: false })
                gHelpers.notif('error', { msg: response.Status.Message });
            }
        });

        callApi({
            beforeSubmit: () => {
                this._isMounted && this.setState({ isLoading: true })
            },
            passObject: {
                'Module': 'customer_detail_get'
            },
            success: (response) => {
                if (response.Data.Customer && response.Data.Customer.length) {
                    this._isMounted && this.setState({
                        companyFields: response.Data.Customer.filter(f => ['TimeZoneID', 'CustomerTypeID', 'CompanyName', 'CountryID']),
                        isLoading: false
                    })
                } else {
                    this._isMounted && this.setState({ isLoading: false })
                }

            },
            unSuccess: (response) => {
                gHelpers.notif('error', { msg: response.Status.Message });
                this._isMounted && this.setState({ isLoading: false })
            }
        });
    }

    render() {
        const { companyFields, profileFields, isLoading } = this.state;

        return (
            <div className={cls.stepContent}>
                {
                    isLoading ? loading('sm') : null
                }
                {
                    profileFields.length
                        ? <FieldsGenerator
                            onRef={ref => (this.profileFields = ref)}
                            fields={{
                                fields: profileFields,
                                excluded: []
                            }}
                            settings={{
                                updateByRID: true,
                                socketEnabled: false,
                            }}
                        /> : null
                }
                {
                    companyFields.length
                        ? <FieldsGenerator
                            onRef={ref => (this.companyFields = ref)}
                            fields={{
                                fields: companyFields,
                                excluded: []
                            }}
                            settings={{
                                updateByRID: true,
                                socketEnabled: false,
                                overrideFields: {}
                            }}
                        />
                        : null
                }
            </div>
        )
    }
}

export default Step2Profile;