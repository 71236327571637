import React, { Suspense } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from "react-router";

import {
    UikTabContainer,
    UikTabItem,
    UikContainerVertical,
    UikContainerHorizontal,
} from '../../@uik';

// components
import Header from './components/Header';
import Breadcrumbs from './components/Breadcrumbs';
import NavigationTablet from './components/NavigationTablet';

import { loading, processing, SocketIO, userHasFunction } from '../../helpers'

import cls from './default-layout.module.scss';
import { sideMenuLinks } from './menuLinks.js';
import routes from '../../routes.js';
import { globalStateAction } from '../../redux/actions/global'

const Page403 = React.lazy(() => import('../../views/Pages/Page403'));

const mapStateToProps = state => ({
    globalState: {
        isLoading: state.isLoading,
        isFullWidthPage: state.isFullWidthPage,
        isMobileWidthPage: state.isMobileWidthPage,
        isProcessing: state.isProcessing
    },
    socketRdx: {
        socketEnabled: state.enableSocket
    }
});

const mapDispatchToProps = dispatch => ({
    isFullWidthPageAction: (data) => dispatch(globalStateAction.isFullWidthPage(data)),
    isMobileWidthPageAction: (data) => dispatch(globalStateAction.isMobileWidthPage(data)),
});

class DefaultLayout extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let self = this;
        function checkWidth() {
            // let isMobileWidthPage = checkIfMobile() ? true : false;
            let isMobileWidthPage = window.innerWidth <= 1024 ? true : false;
            self.props.isMobileWidthPageAction(isMobileWidthPage);
        }
        checkWidth();
        window.addEventListener('resize', function () {
            setTimeout(() => {
                checkWidth();
            }, 30);
        });
    }

    render() {
        const { isFullWidthPage, isMobileWidthPage } = this.props.globalState;

        return (
            <UikContainerHorizontal className={`${cls.mainContainer} showScrollbar`}>
                {
                    !isFullWidthPage
                        ? <NavigationTablet /> : null
                }
                <Header />

                <UikContainerVertical className={`main-content-container ${isFullWidthPage || isMobileWidthPage ? cls.containerFullWidthPage : ''}`}>
                    <UikTabContainer className={cls.mobileNavigation}>
                        {
                            sideMenuLinks.map(item => {
                                return (
                                    <UikTabItem
                                        key={item.link}
                                        className={`${cls.mobileItem} ${item.link === "/" ? item.link === window.location.pathname && "active" : window.location.pathname.includes(item.link) && "active"}`}
                                        Component={Link}
                                        size="smaller"
                                        to={item.link}
                                    >
                                        <i className={item.icon} />
                                    </UikTabItem>
                                )
                            })
                        }
                    </UikTabContainer>
                    <Breadcrumbs routes={routes} />
                    <UikContainerHorizontal className={`${cls.contentContainer} ${isMobileWidthPage && "mobileWrapper"}`}>
                        {
                            this.props.globalState.isProcessing.status
                                ? processing({ label: this.props.globalState.isProcessing.label })
                                : null
                        }
                        {
                            this.props.globalState.isLoading
                                ? loading()
                                : null
                        }
                        <Suspense fallback={loading()}>
                            <Switch>
                                {routes.map((route, idx) => {
                                    let hasFunction = true;
                                    /* if (route.component && "userHasFunction" in route) {
                                        if (!route["userHasFunction"]) {
                                            hasFunction = false;
                                        }
                                    } // route.component */

                                    if ("sysFunction" in route) {
                                        const uFn = route.sysFunction;
                                        if (!userHasFunction(uFn.m, uFn.a)) {
                                            hasFunction = false;
                                        }
                                    }

                                    return <Route
                                        key={idx}
                                        path={route.path}
                                        exact={route.exact}
                                        name={route.name}
                                        render={props => {
                                            if (hasFunction) {
                                                if (route.name && !route.hasTitle) {
                                                    document.title = route.name;
                                                }

                                                return <route.component
                                                    route={route}
                                                    isFullWidthPage={route.isFullWidthPage ? true : false} {...props} />
                                            } else {
                                                return <Page403 />
                                            }
                                        }}
                                    />
                                })}
                            </Switch>
                        </Suspense>
                    </UikContainerHorizontal>
                </UikContainerVertical>
                {
                    // check if socketio is enabled
                    this.props.socketRdx.socketEnabled.status ? <SocketIO /> : null
                }
            </UikContainerHorizontal>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DefaultLayout));