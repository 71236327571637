const showModal = (data) => dispatch => {
    dispatch({
        type: 'SHOW_MODAL',
        payload: data
    })
}
const hideModal = () => dispatch => {
    dispatch({
        type: 'HIDE_MODAL'
    })
}

const globalModalActions = {
    showModal,
    hideModal
}

export {
    globalModalActions
}