import React, { Component } from 'react';
import {
    UikButton
} from '../../../@uik';
import { formatBytes, loading, gHelpers, genericQuery } from '../../../helpers';
import Modal from 'react-modal';
import cls from '../mediaLibrary.module.scss';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import FieldsGenerator from '../../../helpers/fieldGenerator';

const mapStateToProps = state => ({
    globalState: {
        isMobileWidthPage: state.isMobileWidthPage,
    }
});

Modal.setAppElement('#root');
const FormatFileUpload = ({ openedFileDetails, imgLoaded }) => {
    let returnComponent = null;

    switch (openedFileDetails.Extension) {
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
        case 'bmp':
            returnComponent =
                <div>
                    <img
                        className={cls.mImg}
                        src={openedFileDetails.fileUrl}
                        alt={openedFileDetails.Name}
                        onLoad={() => imgLoaded()}
                    />
                </div>
            break; // images only

        case 'mp4':
            returnComponent =
                <div>
                    <video
                        className={`${cls.mImg} ${cls.mVid}`}
                        src={openedFileDetails.fileUrl}
                        alt={openedFileDetails.Name}
                        autoPlay
                        controls
                        onLoadStart={() => imgLoaded()} />
                </div>
            break; //videos only
        default:
            break;
    }

    return returnComponent;
}

class ModalViewFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            fields: {},
        }
    }

    componentDidMount() {
        const formDataObject = new FormData();
        formDataObject.append('requestjson', JSON.stringify({
            'Module': 'media_file_getattribute',
            "Parameters": {
                "MediaFileID": this.props.openedFileDetails.MediaFileID
            }
        }));

        // get options for additional fields
        genericQuery(
            {
                Url: 'single_api/',
                Method: 'POST',
                Data: {
                    requestjson: formDataObject
                },
                ResponseSuccessCallback: function (response) {
                    if (response.data.Status.IsSuccess) {
                        const passFields = response.data.Data.MediaFile;

                        let fields = {
                            fields: passFields,
                            excluded: []
                        };

                        this.setState({
                            fields: fields
                        });
                    } else {
                        gHelpers.notif('error', { msg: response.data.Status.Message });
                    }
                    // this.setState({ isFormLoading: false });
                }.bind(this),
                ResponseFailCallback: function () {
                    gHelpers.notif('error', { msg: gHelpers.getLabel({ isString: true,  key: 61003, fallback: `An error occured during the process` }) });
                    // this.setState({ isFormLoading: false });
                }.bind(this),
            }
        ); // genericQuery(...
    }

    render() {
        const { modalIsOpen, openedFileDetails } = this.props;
        const { isLoading, fields } = this.state;
        const { isMobileWidthPage } = this.props.globalState;

        if (!openedFileDetails) {
            return false;
        }

        return (
            <Modal
                isOpen={modalIsOpen}
                style={{
                    content: {
                        top: '0',
                        left: '0',
                        margin: 'auto',
                        minWidth: '150px',
                        maxWidth: '90vw',
                        padding: '0',
                        borderRadius: '5px',
                        maxHeight: '90vh',
                        position: 'relative',
                        background: 'transparent',
                        border: '0',
                        overflow: 'hidden'
                    }
                }}
                onRequestClose={this.props.handleClose}
            >

                <div className={`${cls.viewFileMainContainer} ${isMobileWidthPage && cls.mobile}`}>
                    <div className={`${cls.colFileMainDisplay}`}>
                        {
                            isLoading ? loading('sm', {
                                backgroundColor: 'transparent'
                            }) : null
                        }
                        <FormatFileUpload
                            openedFileDetails={openedFileDetails}
                            imgLoaded={() => this.setState({ isLoading: false })} />
                        {
                            this.props.openedFileHasPrev
                                ? <UikButton
                                    onClick={() => {
                                        this.props.handleChangeViewFile('prev')
                                        this.setState({ isLoading: true })
                                    }}
                                    icon={<i className={`fas fa-angle-left`} />}
                                    iconOnly
                                    className={`btnCircle ${cls.btnControl} ${cls.btnControlPrev}`}
                                />
                                : null
                        }
                        {
                            this.props.openedFileHasNext
                                ? <UikButton
                                    onClick={() => {
                                        this.props.handleChangeViewFile('next')
                                        this.setState({ isLoading: true })
                                    }}
                                    icon={<i className={`fas fa-angle-right`} />}
                                    iconOnly
                                    className={`btnCircle ${cls.btnControlNext} ${cls.btnControl}`}
                                />
                                : null
                        }
                    </div>
                    <div className={`${cls.colFileDetails}`}>
                        <div className={cls.colfileDetailsMain}>

                            <div className={cls.header}>
                                <i className={`${cls.close} fas fa-times`} onClick={this.props.handleClose}></i>
                                <h4>{gHelpers.getLabel({ key: 61009, fallback: `FILE DETAILS` })}</h4>
                                <div className={cls.headerMain}>
                                    <div className={cls.thumb}>
                                        <img src={openedFileDetails.thumbUrl} />
                                    </div>
                                    <div className={cls.details}>
                                        <div>
                                            <strong>{`${openedFileDetails.Name}.${openedFileDetails.Extension}`}</strong>
                                        </div>
                                        <div>
                                            {formatBytes(openedFileDetails.Size)}
                                        </div>
                                        {
                                            this.props.handleDeleteMediaFile
                                                ?
                                                <a
                                                    href={`#`}
                                                    className={`${cls.btnDelete} red`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.handleDeleteMediaFile(e, openedFileDetails.MediaFileID, () => this.props.handleClose());
                                                    }}
                                                >
                                                    <i className={`fas fa-trash-alt`} />
                                                </a>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className={cls.main}>
                                <div className={cls.fieldGen}>
                                    <span className="uik-content-title__wrapper">{gHelpers.getLabel({ key: 61015, fallback: `URL` })}</span>
                                    <div className={`uik-input__inputWrapper`}>
                                        <UikButton
                                            Component={`a`}
                                            href={openedFileDetails.fileUrl}
                                            target="_blank"
                                            icon={<i className={`fas fa-external-link-alt`} />}
                                            iconRight
                                            clear
                                            className={`${cls.btnLink} btnCatchOverflow`}
                                        >{openedFileDetails.fileUrl}</UikButton>
                                    </div>
                                </div>
                                <div className={cls.fieldGen}>
                                    {
                                        fields.fields &&
                                        <FieldsGenerator
                                            fields={fields}
                                            settings={{
                                                updateByRID: true,
                                            }}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

// export default ModalViewFile;
export default withRouter(connect(mapStateToProps)(ModalViewFile));
