import React from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { mediaFileLibraryActions } from '../../redux/actions/mediaFileLibrary';
import {
    UikButtonGroup,
    UikButton,
    UikInput,
    Uikon,
    UikCheckbox,
    UikProgressBar
} from '../../@uik';
import { genericQuery, localToObject, callApi, gHelpers, config, loading, dataURLtoBlob, formatBytes, toSeconds } from '../../helpers';
import ModalViewFile from './modals/viewFile';
import Dropzone from 'react-dropzone';
import cls from './mediaLibrary.module.scss';
import smTemplate from '../../assets/json/socialMediaTemplate.json';
import MediaFiles from './mediaFiles';
import { InView } from 'react-intersection-observer';

const Thumb = (props) => {
    const selected = props.canSelect && props.selected ? true : false;
    let playBtn = null;
    let thumbPreview = <img src={props.thumbUrl} alt={props.fileName} />; // temp orig
    let itemChoices = [
        {
            name: gHelpers.getLabel({ key: 1371, fallback: 'Edit' }),
            icon: <i className="fas fa-pencil-alt" />,
            btnProps: {
                onClick: props.onClick
            }
        },
        {
            name: gHelpers.getLabel({ key: 1372, fallback: 'Delete' }),
            icon: <i className="fas fa-trash" />,
            btnProps: {
                onClick: props.onRemoveClick
            }
        }
    ];
    const isListView = (props.isList === 'list-view');
    if (props.canSelect && isListView) {
        itemChoices = [
            {
                name: selected ? gHelpers.getLabel({ key: 61027, fallback: `Unselect` }) : gHelpers.getLabel({ key: 61033, fallback: `Select` }),
                icon: <i className={`fas ${selected ? 'fa-times' : 'fa-check'}`} />,
                btnProps: {
                    onClick: props.onSelect
                }
            },
            ...itemChoices
        ]
    }
    let hiddenVideo;
    if (props && props.mimeType && props.mimeType.includes('video') && false) { // temp
        playBtn = <span className={cls.playBtn} />
        thumbPreview = <img src={props.thumbUrl} />

        hiddenVideo = <video
            style={{
                display: "none"
            }}
            controls
            onLoadedMetadata={(e) => {
                if (props.setFileMetaData) {
                    props.setFileMetaData(
                        props.MediaFileID,
                        {
                            duration: e.currentTarget.duration
                        }
                    )
                }
            }}
        >
            <source src={props.fileUrl} type={props.mimeType}></source>
        </video>
    }

    return (
        <div
            key={props.idx}
            className={`${cls.fileItem} ${isListView ? cls.detailed : ''} ${props.canSelect ? cls.fileItemMd : ''}`}
        >
            {
                !props.isUploaded && props.type == 'temp'
                    ? loading('sm') : null
            }
            {hiddenVideo}
            <a
                href="#"
                onClick={props.canSelect ? props.onSelect : props.onClick}
                className={`${cls.lnkThumbFile} ${isListView ? cls.list : cls.grid} ${isListView ? cls.detailed : ''}`}
            >
                {
                    (() => {
                        if (props.uploadProgress && !props.isUploaded && props.type == 'temp') {
                            // console.log(`props.uploadProgress`, props.uploadProgress)
                            return (
                                <UikProgressBar fill={props.uploadProgress} style={{
                                    width: `${props.uploadProgress}%`
                                }} />
                            )
                        }
                    })()
                }
                <span className={cls.linkThumbBg}>
                    {playBtn}
                    {thumbPreview}
                    <p>
                        <b>{props.fileName}</b> <br />
                        {(props.fileSize / 1000000).toFixed(2)}MB
                    </p>
                </span>
                {
                    selected ?
                        <span className={`fa-stack fa-fw ${cls.lnkThumbSelected}`}>
                            <i className={`${cls.lnkCircle} fas fa-circle fa-lg fa-stack-1x`} />
                            <i className={`${cls.lnkCheck} fas fa-check fa-stack-1x`} />
                        </span>
                        : null
                }
            </a>
            {
                isListView ?
                    <div
                        className={`${cls.linkThumbDetails} ${!props.isMobile && cls.desktop}`}
                    >
                        <div>
                            <strong>Filename:</strong> {props.fileName}{props.mimeType !== undefined ? `.${props.mimeType.split("/")[1]}` : null}
                        </div>
                        <div>
                            <strong>URL:</strong> <a href={props.fileUrl} onClick={() => window.open(props.fileUrl)}>Link</a>
                        </div>
                        <div>
                            <strong>Title:</strong> {props.fileName}
                        </div>
                        <div>
                            <strong>Date:</strong> {props.dateCreated}
                        </div>
                        <div className={cls.linkThumbChoices}>
                            {
                                itemChoices.map((item, idx) => {
                                    let btnProps = {};
                                    if (item.btnProps) {
                                        btnProps = item.btnProps;
                                    }
                                    let isLastItem = idx >= itemChoices.length - 1;
                                    return <div
                                        key={idx}
                                        className={`${cls.choice} 
                                ${cls["choice" + item.name]}`}
                                        {...btnProps}
                                    >
                                        <UikButton
                                            key={idx}
                                            className={cls["choice" + item.name]}
                                            clear
                                            iconOnly
                                            icon={item.icon}
                                        />
                                        <div>{item.name}</div>
                                        <div className={cls.breaker}>{isLastItem ? '' : '|'}</div>
                                    </div>
                                })
                            }

                        </div>
                    </div>
                    :
                    null
            }
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    let returnStates = {
        globalState: {
            isLoading: state.isLoading,
            isFullWidthPage: state.isFullWidthPage,
            isMobileWidthPage: state.isMobileWidthPage,
        },
        mediaFileLibrary: {
            selectedFiles: state.selectedMediaFiles,
            listMediaFiles: state.listMediaFiles,
            // listMediaFilesMetaData: state.listMediaFilesMetaData,
            pagination: state.listMediaFilesPagination
        },
        fieldRdx: {
            fields: state.fields,
        },
    }

    if (ownProps.fieldGenerator && ownProps.fieldGenerator.attrib.hasSocialMediaRestrictions) {
        if (ownProps.fieldGenerator.settings.updateByRID) {
            returnStates.scheduledSMTypes = state.scheduledSMTypes;
        } else {
            if (state.newOutingSelectedSocialMediaAccounts.length) {
                returnStates.scheduledSMTypes = state.newOutingSelectedSocialMediaAccounts.map(smId => config.SocialMediaTypeNames[smId]);
            }
        }
    }

    return returnStates;
};

const mapDispatchToProps = dispatch => ({
    updateSelectedMediaFilesAction: (status) => dispatch(mediaFileLibraryActions.updateSelectedMediaFiles(status)),
    removeAllSelectedMediaFilesAction: (status) => dispatch(mediaFileLibraryActions.removeAllSelectedMediaFiles(status)),
    listMediaFilesAction: (data) => dispatch(mediaFileLibraryActions.listMediaFiles(data)),
    listMediaFilesPaginationAction: (data) => dispatch(mediaFileLibraryActions.listMediaFilesPagination(data)),
    listMediaFilesMetaDataAction: (data) => dispatch(mediaFileLibraryActions.listMediaFilesMetaData(data)),
});

class MediaLibraryComp extends React.Component {
    maxDisplayFiles = 28;
    columnCount = 6;
    mediaWidth = 200;
    mediaHeight = 200;

    constructor(props) {
        super(props);

        this.state = {
            activeTool: 'grid-view',
            mediaFiles: [],
            newMediaFiles: [],
            newMediaFilesUploadProgress: [],
            newMediaFileUploads: {},
            modalViewFileIsOpen: false,
            openedFileDetails: null,
            openedFileIndex: null,
            openedFileHasPrev: false,
            openedFileHasNext: false,
            isDragActiveState: false,
            isUploading: false,
            viewTemporarilyChanged: false,
            enabledFilterTypes: [],
            filterMediaSearch: "",
            fileMetaData: null,
            page: 1,
            maxPage: 1,
            filter: {},
            searchKeyword: '',
            isLoading: false,
            isRefreshing: false
        }

        this.handleModalViewFile = this.handleModalViewFile.bind(this);
        this.handleFileSelect = this.handleFileSelect.bind(this);
        this.processUpload = this.processUpload.bind(this);
        this.handleRefreshGallery = this.handleRefreshGallery.bind(this);
        this.refreshGallery = this.refreshGallery.bind(this);
        this.handleOnDrag = this.handleOnDrag.bind(this);
        this.handleOnDragLeave = this.handleOnDragLeave.bind(this);
        this.deleteMediaFile = this.deleteMediaFile.bind(this);
        this.handleChangeViewFile = this.handleChangeViewFile.bind(this);
        this.checkAccountRestrictions = this.checkAccountRestrictions.bind(this);
        this.loadMoreFiles = this.loadMoreFiles.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleSearchDebounce = this.handleSearchDebounce.bind(this);
        this.handleFilterTypes = this.handleFilterTypes.bind(this);
    }

    handleFilterTypes(type) {
        this.setState(prevState => {
            let types = [];
            let filter = prevState.filter;

            if (prevState.filter.Types) {
                types = prevState.filter.Types;
            }

            if (types.includes(type)) {
                types = types.filter(f => f !== type)
            } else {
                types.push(type)
            }

            if (types.length) {
                filter.Types = types;
            } else if (filter.Types.length) {
                delete filter.Types;
            }

            return {
                page: 1,
                filter: filter
            }
        }, () => this.refreshGallery())
    }

    handleSearch(keyword) {
        if (typeof keyword !== 'undefined') {
            this.setState({
                searchKeyword: keyword
            }, () => this.handleSearchDebounce())

        }
    }

    handleSearchDebounce = debounce(() => {
        this.refreshGallery({
            search: true
        });
    }, 500)

    setFileMetaData = (fileID, data) => {
        this.props.listMediaFilesMetaDataAction({
            keyName: fileID,
            data: data
        })
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    handleOnDrag = () => {
        this.setState({ isDragActiveState: true });
    }

    handleOnDragLeave = () => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.setState({ isDragActiveState: false });
        }, 2000);

    }

    processUpload = (acceptedFiles) => {
        const _this = this;

        if (acceptedFiles.dataTransfer) {
            // check if external link
            let link = acceptedFiles.dataTransfer.getData("text/plain");
            let request = new XMLHttpRequest();
            request.open('GET', link, true);
            // request.setRequestHeader('Access-Control-Allow-Headers', '*');
            request.responseType = 'blob';
            request.onload = function () {
                let reader = new FileReader();
                reader.readAsDataURL(request.response);
                reader.onload = function (e) {
                    const fileToUpload = dataURLtoBlob(e.target.result);
                    this.processUpload([fileToUpload]);
                }.bind(this);
            }.bind(this);
            request.send();

        } else {
            // file
            if (this.props.handleUploadingStatus) {
                this.props.handleUploadingStatus(true);
            }
            acceptedFiles.map((file, idx) => {
                const formDataObject = new FormData();
                formDataObject.append('CustomerID', localToObject('UserDetails').User.CustomerID);
                formDataObject.append('MediaFile', file);
                let newFile = {
                    isTemp: true,
                    isNewFile: true,
                    fileUrl: URL.createObjectURL(file),
                    fileTempId: `tmp-${Date.now()}`
                }

                genericQuery(
                    {
                        baseURL: config.mediaFileURL,
                        Url: 'file_manager/',
                        Method: 'POST',
                        Data: {
                            requestjson: formDataObject
                        },
                        Headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        onUploadProgress: function (progressEvent) {
                            const { loaded, total } = progressEvent;
                            let fileLoaded = (loaded / total) * 100;

                            this.setState(prevState => ({
                                newMediaFileUploads: {
                                    ...prevState.newMediaFileUploads,
                                    [newFile.fileTempId]: fileLoaded
                                }
                            }))

                            if (fileLoaded >= 100) {
                                // fileTempId
                                this.setState(prevState => ({
                                    newMediaFilesUploadProgress: [
                                        ...prevState.newMediaFilesUploadProgress,
                                        newFile.fileTempId
                                    ]
                                }))
                            }

                        }.bind(this),
                        ResponseSuccessCallback: function (response) {
                            let mediaFiles = this.state.newMediaFiles;

                            if (response.data.Status.IsSuccess) {
                                const indexOfTemp = mediaFiles.findIndex(el => el.fileTempId === newFile.fileTempId);

                                if (indexOfTemp !== -1) {
                                    // update data
                                    mediaFiles[indexOfTemp] = { ...response.data.Data.MediaFile, isNewFile: true };
                                    // remove temp
                                    // mediaFiles.splice(indexOfTemp, 1);
                                }
                            }
                            else {
                                gHelpers.notif('error', { msg: response.data.Status.Message });
                            }

                            this.setState({
                                newMediaFiles: mediaFiles,
                            })
                            if (this.props.handleUploadingStatus) {
                                this.props.handleUploadingStatus(false);
                            }
                        }.bind(this),
                        ResponseFailCallback: function (response) {
                            gHelpers.notif('error', { msg: 'An error occured during the process.' });
                            console.log(response)
                        }.bind(this),
                    }
                ); // genericQuery(...

                this.setState(prevState => ({
                    newMediaFiles: [
                        newFile,
                        ...prevState.newMediaFiles
                    ]
                }))

            }); // acceptedFiles.map(file => {..

        } // else (acceptedFiles.dataTransfer)

    }

    loadMoreFiles() {
        this.setState(prevState => ({
            page: prevState.page + 1
        }), () => {
            this.refreshGallery({
                loadMore: true
            })
        })
    }

    refreshGallery(args) {
        const { filter, searchKeyword, page } = this.state;

        this.setState({
            isLoading: true
        });
        let mediaFiles = this.props.mediaFileLibrary.listMediaFiles ? this.props.mediaFileLibrary.listMediaFiles : [];

        let passObject = {
            'Module': 'media_file_getall',
            'Parameters': {
                'CustomerID': localToObject('UserDetails').User.CustomerID,
                'Page': page,
                'Limit': this.maxDisplayFiles,
                'Filter': filter
            }
        }

        if (!(args && (args.loadMore))) {
            this.setState({
                newMediaFiles: [],
                isRefreshing: true
            });
            this.props.listMediaFilesAction([]);
        }

        if (typeof searchKeyword !== 'undefined') {
            passObject['Parameters']['Filter'].Keyword = searchKeyword;
        }

        if (args && args.additionalParams) {
            passObject['Parameters'] = {
                ...passObject['Parameters'],
                ...args.additionalParams
            }
        }

        callApi({
            passObject: passObject,
            success: (response) => {
                this.setState({ isLoading: false });
                if (response.Status.IsSuccess) {
                    let toUpdate = {
                        mediaFiles: [],
                        maxPage: response.Data.MediaFilePageCount ? response.Data.MediaFilePageCount : 1,
                        isRefreshing: false
                    };
                    this.props.listMediaFilesPaginationAction({
                        page: page,
                        maxPage: response.Data.MediaFilePageCount ? response.Data.MediaFilePageCount : 1
                    })

                    if (args && (args.loadMore)) {
                        mediaFiles = mediaFiles.concat(response.Data.MediaFiles);
                    } else {
                        mediaFiles = response.Data.MediaFiles;
                        toUpdate.newMediaFiles = [];
                    }

                    this.setState(toUpdate)
                    this.props.listMediaFilesAction(mediaFiles);
                }
            },
            unSuccess: (response) => {
                this.setState({ isLoading: false });
                gHelpers.notif('error', { msg: response.Status.Message });
            }
        });
    }

    checkAccountRestrictions(args) {
        let previewLayout = smTemplate;
        // const fileMetaData = this.props.mediaFileLibrary.listMediaFilesMetaData;

        let restrictedSMsCopy = [];
        let connectedErrors = 0;

        Object.entries(previewLayout).forEach((val) => {
            const key = val[0];
            if (this.props.scheduledSMTypes.includes(key)) {

                const template = previewLayout[key] ? previewLayout[key] : null;

                if (template && template.restrictions) {

                    let allowed = true;
                    let notAllowed = {};

                    // check file restrictions
                    if (template.restrictions.fileTypesAllowed) {
                        let mediaFiles = this.props.mediaFileLibrary.selectedFiles[this.props.fieldGenerator.name];

                        // temp check new file
                        if (args && args.newFile) {
                            const hasFile = mediaFiles.some(fileItem => fileItem.MediaFileID === args.newFile.MediaFileID);

                            if (hasFile) {
                                mediaFiles = mediaFiles.filter(fileItem => fileItem.MediaFileID !== args.newFile.MediaFileID)
                            } else {
                                mediaFiles = [
                                    ...mediaFiles,
                                    args.newFile
                                ]
                            }

                            // check if has allowed file extension
                            if (template.restrictions.fileMimeTypeAllowed) {
                                allowed = mediaFiles.every(f => template.restrictions.fileMimeTypeAllowed.includes(f.MimeType))

                                if (!allowed) {
                                    notAllowed = {
                                        Name: key,
                                        Message: `${gHelpers.getLabel({ isString: true, key: 72878, fallback: `File Extensions Allowed:` })} ${template.restrictions.fileMimeTypeAllowed.join(", ")}`,
                                        Type: 'file'
                                    }
                                }
                            }

                            // check video min/max duration
                            if (allowed && (template.restrictions.videoMinDuration || template.restrictions.videoMaxDuration)
                                && args.newFile.Type == 'Video') {

                                // check min
                                allowed = toSeconds(args.newFile.Duration) > template.restrictions.videoMinDuration

                                if (!allowed) {
                                    notAllowed = {
                                        Name: key,
                                        Message: `${gHelpers.getLabel({ isString: true, key: 61039, fallback: `Video duration must be more than` })} ${template.restrictions.videoMinDuration} seconds`,
                                        Type: 'file'
                                    }
                                } else {

                                    // check max
                                    allowed = toSeconds(args.newFile.Duration) < template.restrictions.videoMaxDuration
                                    if (!allowed) {
                                        notAllowed = {
                                            Name: key,
                                            Message: `${gHelpers.getLabel({ isString: true, key: 61045, fallback: `Video duration must be equal or less than` })} ${template.restrictions.videoMaxDuration} seconds`,
                                            Type: 'file'
                                        }
                                    }

                                }

                            }

                        }

                        if (allowed) {

                            if (mediaFiles.length) {
                                // check allowed file types
                                allowed = mediaFiles.every(x => {
                                    return template.restrictions.fileTypesAllowed.includes(x.Type)
                                });
                                if (!allowed) {
                                    notAllowed = {
                                        Name: key,
                                        Message: `${gHelpers.getLabel({ isString: true, key: 61051, fallback: `Allowed file types(s)` })}: ${template.restrictions.fileTypesAllowed.join(" or ")}`,
                                        Type: 'file'
                                    }
                                } else {

                                    // check if removing 1 file
                                    if (args && args.beforeRemoveFile && template.restrictions.isFileRequired) {
                                        const filesCount = mediaFiles.length;
                                        allowed = filesCount - 1 >= 1;
                                        if (!allowed) {
                                            notAllowed = {
                                                Name: key,
                                                Message: `${template.restrictions.fileTypesAllowed.join(" or ")} ${gHelpers.getLabel({ isString: true, key: 61135, fallback: `file is required.` })}`,
                                                Type: 'file'
                                            }
                                        }
                                    }

                                    // check number of files allowed total
                                    if (allowed && template.restrictions.maxFiles) {
                                        const filesCount = mediaFiles.length;
                                        allowed = filesCount <= template.restrictions.maxFiles;
                                        if (!allowed) {
                                            notAllowed = {
                                                Name: key,
                                                Message: `${gHelpers.getLabel({ isString: true, key: 61057, fallback: `Maximum number of files allowed:` })} ${template.restrictions.maxFiles}`,
                                                Type: 'file'
                                            }
                                        }
                                    }

                                    Object.keys(template.restrictions.fileTypes).forEach((key2) => {
                                        const fType = template.restrictions.fileTypes[key2];
                                        if (allowed) {

                                            // check number of files allowed
                                            const filesCount = mediaFiles.filter(x => x.Type == key2).length;
                                            if (fType.maxFiles) {
                                                allowed = filesCount <= fType.maxFiles;
                                                if (!allowed) {
                                                    notAllowed = {
                                                        Name: key,
                                                        Message: `${gHelpers.getLabel({ isString: true, key: 61063, fallback: `Maximum number of` })} ${key2} ${gHelpers.getLabel({ isString: true, key: 61069, fallback: `files allowed:` })} ${fType.maxFiles}`,
                                                        Type: 'file'
                                                    }
                                                }
                                            }

                                            // check file size
                                            if (allowed && fType.maxSize) {
                                                allowed = mediaFiles.every(x => x.Size <= fType.maxSize);
                                                if (!allowed) {
                                                    notAllowed = {
                                                        Name: key,
                                                        Message: `${gHelpers.getLabel({ isString: true, key: 61075, fallback: `File size must not exceed` })} ${formatBytes(fType.maxSize)}`,
                                                        Type: 'file'
                                                    }
                                                }
                                            }

                                        }
                                    })

                                }

                            } else {
                                if (template.restrictions.isFileRequired) {
                                    allowed = false;
                                    notAllowed = {
                                        Name: key,
                                        Message: `${gHelpers.getLabel({ isString: true, key: 61081, fallback: `Please select file(s):` })} ${template.restrictions.fileTypesAllowed.join(" or ")}`,
                                        Type: 'file'
                                    }
                                }
                            }

                        }
                    }
                    // check file restrictions end

                    if (!allowed) {
                        restrictedSMsCopy = [
                            ...restrictedSMsCopy,
                            notAllowed
                        ]

                        connectedErrors += 1;
                        gHelpers.notif('error', { title: `${notAllowed.Name} error!`, msg: notAllowed.Message });

                    } else {
                        restrictedSMsCopy = restrictedSMsCopy.filter(x => x.Name !== key)
                    }
                } // if (template.restrictions)

            }
        }); // Object.entries(socialMediaAccountsList).forEach

        if (args && args.cb) {
            args.cb(restrictedSMsCopy)
        }

        // show 
        if (args && args.cb2) {
            args.cb2(connectedErrors)
        }

        return connectedErrors;
    }

    handleFileSelect(e, file) {
        e.preventDefault();

        let passData = {
            selectedMediaFiles: this.props.mediaFileLibrary.selectedFiles,
            file: file,
            keyName: this.props.fieldGenerator.name
        };

        if (this.props.fieldGenerator.attrib.hasSocialMediaRestrictions && typeof this.props.scheduledSMTypes !== 'undefined') {
            const connectedErrors = this.checkAccountRestrictions({
                reset: true,
                // beforeRemoveFile: true,
                newFile: file
            });

            if (connectedErrors < 1) {
                if (this.props.fieldGenerator && this.props.fieldGenerator.singleSelect) {
                    passData.singleSelect = this.props.fieldGenerator.singleSelect;
                }
                this.props.updateSelectedMediaFilesAction(passData);
            }
        } else {
            if (this.props.fieldGenerator && this.props.fieldGenerator.singleSelect) {
                passData.singleSelect = this.props.fieldGenerator.singleSelect;
            }

            this.props.updateSelectedMediaFilesAction(passData);
        }

    }

    handleRefreshGallery() {
        if (!this.props.mediaFileLibrary.listMediaFiles.length) {
            this.refreshGallery();
        } // if(this.props.mediaFileLibrary.listMediaFiles)
    }

    handleChangeViewFile(direction) {
        let { openedFileIndex } = this.state;
        let mediaFiles = this.props.mediaFileLibrary.listMediaFiles ? this.props.mediaFileLibrary.listMediaFiles : [];
        let openFile;

        if (direction == 'next' && openedFileIndex < mediaFiles.length - 1) {
            openedFileIndex += 1;
        }
        if (direction == 'prev' && openedFileIndex > 0) {
            openedFileIndex -= 1;
        }
        openFile = {
            ...mediaFiles[openedFileIndex],
            fileUrl: `${config.mediaFileURL}file_manager/?path=${mediaFiles[openedFileIndex].Path}`,
            thumbUrl: `${config.mediaFileURL}file_manager/?path=${mediaFiles[openedFileIndex].Path}&width=${this.mediaWidth}&height=${this.mediaHeight}&crop=true`,
        }

        this.setState({
            openedFileDetails: openFile,
            openedFileHasPrev: typeof mediaFiles[openedFileIndex - 1] !== 'undefined',
            openedFileHasNext: typeof mediaFiles[openedFileIndex + 1] !== 'undefined',
            modalViewFileIsOpen: true,
            openedFileIndex
        })
    }

    handleModalViewFile(e, file, idx) {
        console.log(`file`, file)
        let mediaFiles = this.props.mediaFileLibrary.listMediaFiles ? this.props.mediaFileLibrary.listMediaFiles : [];

        mediaFiles = [
            ...this.state.mediaFiles,
            ...mediaFiles
        ];

        if (e) {
            e.preventDefault();
        }
        let isOpen = this.state.modalViewFileIsOpen;

        if (isOpen) {
            isOpen = false;
        } else {
            isOpen = true;
        }

        this.setState({
            modalViewFileIsOpen: isOpen,
            openedFileDetails: file,
            openedFileIndex: idx,
            openedFileHasPrev: typeof mediaFiles[idx - 1] !== 'undefined',
            openedFileHasNext: typeof mediaFiles[idx + 1] !== 'undefined'
        });
    }

    deleteMediaFile(e, mediaFileId, cb) {
        const { newMediaFiles } = this.state;
        e.preventDefault();

        gHelpers.confirmDialog({
            title: gHelpers.getLabel({ key: 61087, fallback: `Remove Media File From Media Library` }),
            desc: gHelpers.getLabel({ key: 61093, fallback: `Are you sure you want to remove this media file?` }),
            submitBtn: {
                text: 'Remove',
                props: {
                    error: true
                }
            },
            onSubmit: () => {
                let passObject = {
                    'Module': 'media_file_remove',
                    'Parameters': {
                        'MediaFileID': mediaFileId
                    }
                }

                callApi({
                    passObject: passObject,
                    success: (response) => {
                        this.refreshGallery();
                        this.setState({
                            modalViewFileIsOpen: false,
                            newMediaFiles: newMediaFiles.filter(x => x.MediaFileID !== mediaFileId)
                        });
                        gHelpers.notif('info', { msg: response.Status.Message });
                    },
                    unSuccess: (response) => {
                        gHelpers.notif('error', { msg: response.Status.Message });

                    }
                });

                if (cb) {
                    cb()
                }
            }
        });
    }

    componentDidMount() {
        if (this.props.type == 'selectFileDialog') {
            this.columnCount = 5;
            this.maxDisplayFiles = 15
        }
        if (this.props.mediaFileLibrary.pagination) {
            this.setState(this.props.mediaFileLibrary.pagination)
        }

        this.handleRefreshGallery();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.globalState.isMobileWidthPage !== this.props.globalState.isMobileWidthPage) {
            if (this.state.activeTool === 'list-view') {
                this.setState({
                    activeTool: 'grid-view',
                    viewTemporarilyChanged: true,
                })
            }
            else if (this.state.viewTemporarilyChanged) {
                this.setState({
                    activeTool: 'list-view',
                    viewTemporarilyChanged: false,
                })
            }
        }
    }

    componentWillUnmount() {
        this.props.listMediaFilesAction([...this.state.mediaFiles, ...this.props.mediaFileLibrary.listMediaFiles]);
    }

    render() {
        let mediaFiles = this.props.mediaFileLibrary.listMediaFiles ? this.props.mediaFileLibrary.listMediaFiles : [];
        const {
            activeTool,
            modalViewFileIsOpen,
            openedFileDetails,
            newMediaFilesUploadProgress,
            isDragActiveState,
            openedFileHasPrev,
            openedFileHasNext,
            filter,
            newMediaFileUploads,
            newMediaFiles,
            page,
            maxPage,
            isLoading,
            isRefreshing
        } = this.state;
        const {
            isFullWidthPage,
            isMobileWidthPage
        } = this.props.globalState;

        mediaFiles = [
            ...this.state.mediaFiles,
            ...mediaFiles
        ];

        let fieldGenKeyName = '';
        let selectedMediaFiles = [];
        if (this.props.fieldGenerator && this.props.fieldGenerator.name) {
            fieldGenKeyName = this.props.fieldGenerator.name ? this.props.fieldGenerator.name : '';
            selectedMediaFiles = this.props.mediaFileLibrary.selectedFiles[fieldGenKeyName];
        }
        let modals = [];
        if (modalViewFileIsOpen) {
            modals.push(<ModalViewFile
                key='modal-viewFile-0'
                modalIsOpen={modalViewFileIsOpen} //open
                handleClose={this.handleModalViewFile} //close media lib modal when clicked
                handleDeleteMediaFile={this.deleteMediaFile}
                handleChangeViewFile={this.handleChangeViewFile}
                openedFileHasPrev={openedFileHasPrev}
                openedFileHasNext={openedFileHasNext}
                openedFileDetails={openedFileDetails} />)
        }
        const tools = [
            {
                name: 'grid-view',
                icon: <i className={`fas fa-th-large`} />,
                btnProps: {
                    onClick: () => {
                        this.setState({
                            activeTool: "grid-view",
                        })
                    }
                }
            },
            {
                name: 'list-view',
                icon: <i className={`fas fa-th-list`} />,
                btnProps: {
                    onClick: () => {
                        this.setState({
                            activeTool: "list-view",
                        })
                    }
                }
            },
            // {
            //     name: 'filter',
            //     icon: <i className={`fas fa-filter`} />,
            // },
            {
                name: 'refresh',
                icon: <i className={`fas fa-redo-alt`} />,
                btnProps: {
                    onClick: function () {
                        this.setState({
                            page: 1,
                            maxPage: 1,
                            filter: {},
                            searchKeyword: '',
                        }, () => this.refreshGallery())

                    }.bind(this)
                }
            },

        ]; // tools

        let filesGrid = [], newFilesGrid = [];
        const filteredMediaFiles = mediaFiles;

        [...filteredMediaFiles, ...newMediaFiles].forEach((file, idx) => {

            const fileDetails = {
                ...file,
                fileUrl: `${config.mediaFileURL}file_manager/?path=${file.Path}`,
                thumbUrl: `${config.mediaFileURL}file_manager/?path=${file.Path}&width=${this.mediaWidth}&height=${this.mediaHeight}&crop=true`,
            }

            let thumb;

            let additionalProps = {};

            if (file.isTemp) {
                let isUploaded = newMediaFilesUploadProgress.includes(file.fileTempId) ? true : false;
                thumb =
                    <Thumb
                        key={idx}
                        idx={idx}
                        isUploaded={isUploaded}
                        uploadProgress={newMediaFileUploads[file.fileTempId]}
                        fileUrl={file.fileUrl}
                        thumbUrl={file.fileUrl}
                        fileSize={file.Size}
                        dateCreated={file.DT_Created}
                        fileName={'temp'}
                        type={'temp'}
                        isList={this.state.activeTool}
                        isMobile={isMobileWidthPage}
                        {...additionalProps}
                    />
            } else {
                const selected = selectedMediaFiles.findIndex(fileItem => fileItem.MediaFileID === file.MediaFileID);
                thumb =
                    <Thumb
                        key={idx}
                        idx={idx}
                        fileUrl={fileDetails.fileUrl}
                        thumbUrl={fileDetails.thumbUrl}
                        fileSize={file.Size}
                        fileName={file.Name}
                        type={'uploaded'}
                        mimeType={file.MimeType}
                        dateCreated={file.DT_Created}
                        isList={this.state.activeTool}
                        onClick={(e) => this.handleModalViewFile(e, fileDetails, idx)}
                        onRemoveClick={(e) => this.deleteMediaFile(e, fileDetails.MediaFileID)}
                        canSelect={this.props.type == 'selectFileDialog' ? true : false}
                        onSelect={(e) => this.handleFileSelect(e, fileDetails)}
                        selected={selected !== -1 ? selected + 1 : false}
                        isMobile={isMobileWidthPage}
                        setFileMetaData={this.setFileMetaData}
                        MediaFileID={file.MediaFileID}
                        {...additionalProps}
                    />
            }

            if (file.isNewFile) {
                newFilesGrid.push(thumb);
            } else {
                filesGrid.push(thumb);
            }


            // files.push(thumb);
        });

        let mediaFilesTemplateColumns;
        let mediaFilesStyle = {};
        let mediaDisplayLength = Math.floor(((this.props.containerWidth && !isMobileWidthPage ? parseInt(this.props.containerWidth) : window.innerWidth) - (!isMobileWidthPage ? this.mediaWidth : 0)) / this.mediaWidth);

        if (this.state.activeTool === 'grid-view') {
            mediaFilesTemplateColumns = `${100 / mediaDisplayLength}% `.repeat(mediaDisplayLength);
            mediaFilesStyle = {
                style: {
                    display: 'inline-grid',
                    gridTemplateColumns: mediaFilesTemplateColumns,
                }
            }
        }

        let accept = 'image/*, video/*, text/plain';

        // check if has file type extension checker
        if (this.props.scheduledSMTypes
            && this.props.scheduledSMTypes.some(smType => smTemplate[smType] && smTemplate[smType].restrictions.fileMimeTypeAllowed)) {
            let acceptArray = [];
            this.props.scheduledSMTypes
                .filter(smType => smTemplate[smType] && smTemplate[smType].restrictions.fileMimeTypeAllowed)
                .forEach(smType => {
                    if (smTemplate[smType] && smTemplate[smType].restrictions.fileMimeTypeAllowed) {
                        smTemplate[smType] && smTemplate[smType].restrictions.fileMimeTypeAllowed.forEach(ext => {
                            if (acceptArray.indexOf(ext) === -1) {
                                acceptArray.push(ext)
                            }
                        })
                    }
                });
            if (acceptArray.length) {
                accept = acceptArray.join(",")
            }
        }

        return (
            <div className={cls.mediaLibraryContainer}>
                <div className={cls.topBar}>
                    <div className={`row no-gutters`}>
                        <div className={`col-md-4`}>
                            <div className={` d-none d-sm-block`}>
                                {/* toolbar buttons */}
                                <UikButtonGroup>
                                    {
                                        tools.map((tool, idx) => {
                                            if (tool.name === 'list-view' && isMobileWidthPage) return;
                                            let btnProps = {};
                                            if (tool.btnProps) {
                                                btnProps = tool.btnProps;
                                            }

                                            return (
                                                <UikButton
                                                    key={idx}
                                                    className={activeTool == tool.name ? 'blue' : null}
                                                    clear
                                                    iconOnly
                                                    icon={tool.icon}
                                                    {...btnProps}
                                                />
                                            )
                                        })
                                    }
                                </UikButtonGroup>
                            </div>
                            <div className={`row no-gutters d-flex d-sm-none`}>
                                <div className={`col-4`}>
                                    <div className={cls.toolbar}>
                                        {/* toolbar buttons */}
                                        <UikButtonGroup>
                                            {
                                                tools.map((tool, idx) => {
                                                    if (tool.name === 'list-view' && isMobileWidthPage) return;
                                                    let btnProps = {};
                                                    if (tool.btnProps) {
                                                        btnProps = tool.btnProps;
                                                    }

                                                    return (
                                                        <UikButton
                                                            key={idx}
                                                            className={activeTool == tool.name ? 'blue' : null}
                                                            clear
                                                            iconOnly
                                                            icon={tool.icon}
                                                            {...btnProps}
                                                        />
                                                    )
                                                })
                                            }
                                        </UikButtonGroup>
                                    </div>
                                </div>
                                <div className={`col-8`}>
                                    <div className={cls.searchBar}>
                                        <form
                                            onSubmit={
                                                (e) => {
                                                    e.preventDefault();
                                                }
                                            }
                                        >
                                            <UikInput
                                                icon={(
                                                    <Uikon>
                                                        search_left
                                                    </Uikon>
                                                )}
                                                placeholder={gHelpers.getLabel({ isString: true, key: 61099, fallback: `Type to search...` })}
                                                onChange={(e) => {
                                                    // this.setState({
                                                    //     filterMediaSearch: e.target.value
                                                    // })

                                                    this.handleSearch(e.target.value)
                                                }}
                                            />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`col-md-4 text-center pageTitle`}>
                            <h3>{gHelpers.getLabel({ key: 1383, fallback: `Media Library` })}</h3>
                        </div>

                        {/* media library searchbar */}
                        <div className={`col-md-4 d-none d-sm-none d-md-block`}>
                            <div className={cls.searchBar}>
                                <form
                                    onSubmit={
                                        (e) => {
                                            e.preventDefault();
                                        }
                                    }
                                >
                                    <UikInput
                                        icon={(
                                            <Uikon>
                                                search_left
                                            </Uikon>
                                        )}
                                        placeholder={gHelpers.getLabel({ isString: true, key: 61099, fallback: `Type to search...` })}
                                        onChange={(e) => {
                                            // this.setState({
                                            //     filterMediaSearch: e.target.value
                                            // })

                                            this.handleSearch(e.target.value)
                                        }}
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`px-3 pt-3 ${cls.filterFileTypeContainer}`}>
                    <span>
                        <UikCheckbox
                            label="Image"
                            defaultChecked={filter.types && filter.types.includes("Image") ? true : false}
                            onClick={
                                () => {
                                    this.handleFilterTypes("Image")
                                    /* this.setState({
                                        enabledFilterTypes: enabledFilterTypes.includes("Image") ? enabledFilterTypes.filter(filter => filter !== "Image") : [...enabledFilterTypes, "Image"]
                                    }); */
                                }
                            }
                        />
                    </span>
                    <span>
                        <UikCheckbox
                            label="Video"
                            defaultChecked={filter.types && filter.types.includes("Image") ? true : false}
                            onClick={
                                () => {
                                    this.handleFilterTypes("Video")
                                    /* this.setState({
                                        enabledFilterTypes: enabledFilterTypes.includes("Video") ? enabledFilterTypes.filter(filter => filter !== "Video") : [...enabledFilterTypes, "Video"]
                                    }); */
                                }
                            }
                        />
                    </span>
                </div>

                {
                    (() => {
                        if (this.props.fieldGenerator && this.props.fieldGenerator.name) {
                            const fieldGenKeyName = this.props.fieldGenerator.name ? this.props.fieldGenerator.name : '';

                            return (
                                <MediaFiles
                                    fromSelect={true}
                                    mediaFiles={this.props.mediaFileLibrary.selectedFiles[fieldGenKeyName]}
                                    className={`${cls.mediaLibrarySelected} ${cls.mediaFilesNoWrap}`}
                                    fieldGenerator={
                                        { ...this.props.fieldGenerator }
                                    }
                                />
                            )
                        }
                    })()
                }

                <Dropzone
                    onDropAccepted={this.processUpload}
                    accept={accept}
                    noClick={true}
                >
                    {
                        ({
                            getRootProps,
                            getInputProps,
                            isDragActive,
                            // isDragAccept,
                            // isDragReject,
                            // acceptedFiles,
                            open
                        }) => (
                            <div {...getRootProps()} className={`${cls.dropzoneContainer} p-3`}>
                                <div className={`${isDragActive || isDragActiveState ? cls.dragActive : cls.dragInActive}`}>
                                    <h4><i className="fas fa-file-upload" /> {gHelpers.getLabel({ key: 1379, fallback: `DROP FILES HERE` })}</h4>
                                </div>
                                <input {...getInputProps()} />

                                {
                                    (() => {
                                        if (this.props.type != 'selectFileDialog') {
                                            return (
                                                <div
                                                    className={cls.btnUploadContainer}
                                                    onClick={open}
                                                >
                                                    <div className={`ml-auto mr-auto text-center`}>
                                                        <UikButton
                                                            // onClick={open} 
                                                            iconOnly
                                                            className={cls.btnUpload}
                                                            icon={<i className="fas fa-paperclip" style={{ fontSize: '14px' }} />}
                                                        />
                                                        <div className='ml-2 mt-2 d-block light-gray'>
                                                            {gHelpers.getLabel({ key: 61105, fallback: `Upload or drop files here.` })}<br />
                                                            <i className="fas fa-info-circle" /> {gHelpers.getLabel({ key: 61111, fallback: `Restriction according to platform:` })}
                                                            {
                                                                Object.keys(smTemplate).map((sm, idx) => {
                                                                    let selectedSM = smTemplate[sm];
                                                                    return <p key={idx}>
                                                                        {sm}:&nbsp;
                                                                            {
                                                                            Object.keys(selectedSM.restrictions.fileTypes).map(smr => {
                                                                                let selectedFileType = selectedSM.restrictions.fileTypes[smr];
                                                                                return `${smr}${selectedFileType && selectedFileType.maxSize ? `(${(selectedFileType.maxSize / 1000000000).toFixed(2)}GB)` : "(Unlimited)"} `;
                                                                            })
                                                                        }
                                                                    </p>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })()
                                }

                                <div
                                    className={`showScrollbar ${cls.mediaFiles} ${this.state.activeTool === 'grid-view' ? cls.grid : cls.list} ${isMobileWidthPage && cls.mobile} ${this.props.type == 'selectFileDialog' ? cls.isSelectFiles : ''}`}
                                    onDrop={this.processUpload}
                                >
                                    {
                                        isLoading ? loading('sm') : null
                                    }
                                    {
                                        newFilesGrid.map(file => file)
                                    }
                                    {
                                        filesGrid.map(file => file)
                                    }
                                    {
                                        !mediaFiles.length
                                            && !isRefreshing
                                            ? <div className={`text-center`}>{gHelpers.getLabel({ key: 61117, fallback: `No images found.` })}</div> : null
                                    }
                                    {
                                        isRefreshing ? <div className={`text-center`}>{gHelpers.getLabel({ key: 61123, fallback: `Refreshing...` })}</div> : null
                                    }
                                    {
                                        mediaFiles.length && (page < maxPage) ?
                                            <div className={cls.loadMore}>
                                                <InView as="div" onChange={(inView, entry) => {
                                                    if (inView) {
                                                        this.loadMoreFiles()
                                                    }
                                                }}>
                                                    <div className={`text-center`}>{gHelpers.getLabel({ key: 61129, fallback: `Loading more images...` })}</div>
                                                </InView>
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        )
                    }
                </Dropzone>

                {/*modal opening uploaded media files */}
                {modals}
            </div>
        )
    }

}
export default connect(mapStateToProps, mapDispatchToProps)(MediaLibraryComp);