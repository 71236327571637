import React, { Component } from 'react';
import { connect } from 'react-redux';
import { globalStateAction } from '../../../redux/actions/global';
import { socketActions } from '../../../redux/actions/socket';
import { Link } from 'react-router-dom';

import {
    UikWidget,
    UikWidgetContent,
    UikHeadline,
    UikScrollArea,
    UikDivider,
    UikButton
} from '../../../@uik';

import { genericQuery, gHelpers, refreshUserDetails, config } from '../../../helpers';
import FieldsGenerator from '../../../helpers/fieldGenerator';

import cls from './style.module.scss';

const mapStateToProps = state => ({
    globalState: {
        isLoading: state.isLoading
    }
});
const mapDispatchToProps = dispatch => ({
    isLoadingAction: (status) => dispatch(globalStateAction.isLoading(status)),
    enableSocket: (data) => dispatch(socketActions.enableSocket(data))
});

class NewAccountWelcome2 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: {},
            fieldsLoaded: false,
            errorMessage: ''
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        window.location.href = `${config.baseURL}company/`;
        refreshUserDetails();
    }

    componentDidMount() {
        this.props.isLoadingAction(true);

        const userID = this.props.match.params.id;

        const formDataObject = new FormData();
        formDataObject.append('requestjson', JSON.stringify({
            'Module': 'system_user_get'
        }));

        // get options for additional fields
        genericQuery(
            {
                Url: 'single_api/',
                Method: 'POST',
                Data: {
                    requestjson: formDataObject
                },
                ResponseSuccessCallback: function (response) {
                    if (response.data.Status.IsSuccess) {
                        const passFields = response.data.Data.User;

                        let fields = {
                            fields: passFields,
                            excluded: [
                                'Firstname',
                                'LastName',
                                'CustomerID',
                                'EmailAddress',
                                'ProfilePicture',
                                'Password',
                                'ConfirmPassword',
                                'ReceiveEmailUpdates',
                                'Initials'
                            ],
                        };

                        this.setState({
                            fields: fields
                        });

                        this.props.enableSocket(true);
                    } else {
                        gHelpers.notif('error', { msg: response.data.Status.Message });
                    }
                    this.props.isLoadingAction(false);
                }.bind(this),
                ResponseFailCallback: function (response) {
                    // do when fail
                    gHelpers.notif('error', { msg: gHelpers.getLabel({ isString: true,  key: 61351, fallback: 'An error occurred during the process.' }) });
                    this.props.isLoadingAction(false);
                }.bind(this),
            }
        ); // genericQuery(...
    }

    render() {
        const { fields } = this.state;

        let fieldGenerator;

        if (fields.fields) {
            fieldGenerator = <FieldsGenerator fields={fields}
                settings={{
                    updateByRID: true,
                    overrideFields: {
                        "WebsiteTermsSigned": {
                            Label: null
                        }
                    }
                }}
            />
        }

        return (
            <div className={`${cls.pageWrapper}`}>
                <div>
                    <div className="text-center">
                        <Link to="/">
                            <img src={`/logo_socrates.svg`} alt="" width="150" style={{ background: '#0f2850' }} />
                        </Link>
                    </div>
                    <div className={`${cls.contentWidgetWrapper} mt-5 ml-auto mr-auto`}>
                        <UikWidget
                            className={`${cls.widgetWrapper}`}
                        >
                            < UikWidgetContent>
                                <div className="text-center mb-3">
                                    <UikHeadline>
                                        {gHelpers.getLabel({ key: 1437, fallback: 'Welcome to Socrates!' })}
                                    </UikHeadline>
                                </div>
                                <form action={`/company/`} className="mb-3" method="POST" onSubmit={this.handleSubmit}>
                                    <div className={`${cls.textContent} mt-2`}>
                                        <h4>Terms and Conditions</h4>
                                        <UikScrollArea className={cls.scrollTextContent}>
                                            <h5>Lorem ipsum dolor sit amet</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                            <h5>Lorem ipsum dolor sit amet</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                            <h5>Lorem ipsum dolor sit amet</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                            <h5>Lorem ipsum dolor sit amet</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                            <h5>Lorem ipsum dolor sit amet</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                        </UikScrollArea>
                                    </div>

                                    <UikDivider margin />
                                    {fieldGenerator}
                                    <div className="text-center mt-4">
                                        <UikButton
                                            type="submit"
                                            success
                                        >
                                            {gHelpers.getLabel({ key: 1488, fallback: 'Get Started with Socrates!' })}
                                        </UikButton>
                                    </div>
                                </form>
                            </UikWidgetContent>
                        </UikWidget>

                        <div className="text-right mb-3">
                            <UikButton to={`/logout/`} Component={Link} xs>{gHelpers.getLabel({ key: 62233, fallback: 'Logout' })}</UikButton>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewAccountWelcome2);