import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import {
    UikNavLinkTwo,
    UikNavLinkTwoContainer,
    UikTopBar,
    UikTopBarSection,
    UikDivider,
    UikNavPanel,
    UikContainerVertical,
    UikScrollArea,
    UikAvatar
} from '../../../../@uik';

import { localToObject, getUserInitial, getLabel, userHasFunction } from '../../../../helpers';
import { globalStateAction } from '../../../../redux/actions/global';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronCircleRight, faChevronCircleLeft, faCaretDown } from '@fortawesome/free-solid-svg-icons';

import cls from './tablet-nav.module.scss';
import { sideMenuLinks } from '../../menuLinks.js';
import NavBottomSection from '../NavBottomSection';
import { connect } from 'react-redux';

const UserActions = ({ onClick, userName }) => {
    return (
        <a onClick={onClick} className={`${cls.userName}`}>
            {userName}
            <i className={`ml-2 fas fa-caret-down`} />
        </a>
    )
};

const mapStateToProps = state => ({
    globalState: {
        isLocalStorageUpdated: state.isLocalStorageUpdated
    },
    userDetails: state.selfUser,
});

const mapDispatchToProps = dispatch => ({
    isLocalStorageChangedAction: (status) => dispatch(globalStateAction.isLocalStorageUpdated(status)),
})

class Navigation extends Component {
    constructor(props) {
        super(props);

        let minimizedVal = false;
        let minimizeIconVal = "fa-chevron-circle-left";
        if (localToObject('ApplicationSettings')) {
            const appSettings = localToObject('ApplicationSettings');
            minimizedVal = appSettings.menuMinimized;
            minimizeIconVal = "fa-chevron-circle-right";
        }

        this.state = {
            minimized: minimizedVal,
            minimizeIcon: minimizeIconVal,
            // profileImageURL: null,
        }
    }

    handleMinimize(e) {
        e.preventDefault();

        let minimized = this.state.minimized;
        let minimizeIcon = this.state.minimizeIcon;

        if (minimized) {
            document.getElementsByTagName("body")[0].classList.remove('sidebar-opened');
            minimized = false;
            minimizeIcon = "fa-chevron-circle-left";
        } else {
            minimized = true;
            document.getElementsByTagName("body")[0].classList.add('sidebar-opened');
            minimizeIcon = "fa-chevron-circle-right";
        }

        this.setState({
            minimized: minimized,
            minimizeIcon: minimizeIcon
        });
    }

    componentDidMount() {
        // this.setState({
        //     profileImageURL: localToObject('UserDetails').UserDetails.ProfilePictureURL ? localToObject('UserDetails').UserDetails.ProfilePictureURL : null,
        // })
    }

    componentDidUpdate(prevProps) {
        // if ((prevProps.globalState.isLocalStorageUpdated !== this.props.globalState.isLocalStorageUpdated) && this.props.globalState.isLocalStorageUpdated) {
        //     this.setState({
        //         profileImageURL: localToObject('UserDetails').UserDetails.ProfilePictureURL ? localToObject('UserDetails').UserDetails.ProfilePictureURL : null,
        //     })
        //     this.props.isLocalStorageChangedAction(false);
        // }
    }

    render() {
        const { minimized } = this.state;
        // const profilePicture = localToObject('UserDetails').UserDetails.ProfilePictureURL ? localToObject('UserDetails').UserDetails.ProfilePictureURL : null;
        const userName = localToObject('UserDetails').UserDetails && localToObject('UserDetails').UserDetails.Firstname ? `${localToObject('UserDetails').UserDetails.Firstname.Value} ${localToObject('UserDetails').UserDetails.LastName.Value}` : null;
        return (
            <UikNavPanel className={`${cls.wrapper} ${minimized ? 'sidebar-minimized' : ''} main-sidebar-nav`}>
                <UikContainerVertical className={cls.containerVertical}>

                    <UikTopBar center className={cls.sideTopbar}>
                        <UikTopBarSection>
                            {/* <img src={`/logo_socrates.svg`} height={`34px`} /> */}
                        </UikTopBarSection>
                    </UikTopBar>

                    <UikScrollArea className={cls.containerVerticalMain}>

                        <div>
                            <UikAvatar
                                className={`avatar-overflow-visible avatar-sidebar-menu ${cls.avatarUser}`}
                                highlighted
                                imgUrl={this.props.userDetails.ProfilePictureURL}
                                avatarPlaceholder={{
                                    content: getUserInitial({
                                        Firstname: localToObject('UserDetails').UserDetails.Firstname.Value,
                                        Lastname: localToObject('UserDetails').UserDetails.LastName.Value
                                    }),
                                    color: 'blue',
                                }}
                                name={
                                    /*  <UikDropdown
                                         DisplayComponent={UserActions}
                                         displayComponentProps={
                                             { userName: userName }
                                         }
                                         position="bottomRight"
                                         listProps={{
                                             className: `buttonDisplayBlock`
                                         }}
                                     >
                                         <UikDropdownItem>
                                             Settings
                                         </UikDropdownItem>
                                         <UikDropdownItem Component={Link} to={`/users/profile/`}>
                                             Profile
                                         </UikDropdownItem>
                                         <UikDropdownItem Component={Link} to={`/logout/`}>
                                             Log out
                                         </UikDropdownItem>
                                     </UikDropdown> */
                                    <a href="#" onClick={(e) => e.preventDefault()} className={`${cls.userName}`}>
                                        {userName}
                                    </a>
                                }
                                textTop={<span className={cls.textTop}>{getLabel({ key: 61303, fallback: 'Welcome back,' })}</span>}
                            />
                        </div>
                        <UikDivider />
                        <UikNavLinkTwoContainer>
                            {
                                sideMenuLinks.map(link => {

                                    let hasFunction = true;
                                    /* if ("userHasFunction" in link) {
                                        if (!link["userHasFunction"]) {
                                            hasFunction = false;
                                        }
                                    } */

                                    if ("sysFunction" in link) {
                                        const uFn = link.sysFunction;
                                        if (!userHasFunction(uFn.m, uFn.a)) {
                                            hasFunction = false;
                                        }
                                    }

                                    return (
                                        hasFunction
                                            ?
                                            <UikNavLinkTwo
                                                to={link.link}
                                                key={link.link}
                                                highlighted
                                                icon={<i className={link.icon}></i>}
                                                Component={NavLink}
                                                className={`navLink ${cls.navLink}`}
                                                exact={link.link === '/' ? true : false}
                                                onClick={(e) => {
                                                    if (e.target.className.includes("fas") && e.target.parentNode.className == "lbl-trns-btn") {
                                                        e.preventDefault();
                                                    }
                                                }}
                                            >
                                                <span className={`text ${cls.navLinkText}`}>{link.text}</span>
                                            </UikNavLinkTwo>
                                            : null
                                    )

                                })
                            }
                        </UikNavLinkTwoContainer>
                        <NavBottomSection />
                    </UikScrollArea>
                </UikContainerVertical>
            </UikNavPanel>

        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
