import React from 'react';
import FieldsGenerator from 'helpers/fieldGenerator';
import { gHelpers, callApi, loading } from 'helpers';
import cls from '../style.module.scss';

class Step3Plans extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            subscriptionPlans: [],
            isLoading: false,
            selectedSubscriptionTermID: 1,
            selectedCurrencyID: null,
            selectedSubscriptionPlan: null,
            paymentProvider: []
        };

        this._isMounted = false;
        this.changeCurrency = this.changeCurrency.bind(this);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    changeCurrency(CurrencyID) {
        callApi({
            Url: 'public_single_api/',
            beforeSubmit: () => {
                this.setState({ isLoading: true, selectedCurrencyID: CurrencyID })
            },
            passObject: {
                'Module': 'subscription_plan_get',
                'Parameters': {
                    'CurrencyID': CurrencyID
                }
            },
            success: (responsePlans) => {
                if (responsePlans.Data.SubscriptionPlan && responsePlans.Data.SubscriptionPlan.length) {
                    this.setState({
                        subscriptionPlans: responsePlans.Data.SubscriptionPlan
                    })
                }
                this.setState({ isLoading: false })
            },
            unSuccess: (responsePlans) => {
                gHelpers.notif('error', { msg: responsePlans.Status.Message });
                this.setState({ isLoading: false })
            }
        });
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.onRef) {
            this.props.onRef(this)
        }

        callApi({
            Url: 'public_single_api/',

            beforeSubmit: () => {
                this.setState({ isLoading: true })
            },
            passObject: {
                'Module': 'subscription_plan_settings'
            },
            success: (response) => {
                if (response.Data.CurrencyList && response.Data.CurrencyList.length) {
                    const selectedCurrencyID = response.Data.CurrencyList.find(c => c.IsDefault).CurrencyID
                    callApi({
                        Url: 'public_single_api/',
                        passObject: {
                            'Module': 'subscription_plan_get',
                            'Parameters': {
                                'CurrencyID': selectedCurrencyID
                            }
                        },
                        success: (responsePlans) => {
                            if (responsePlans.Data.SubscriptionPlan && responsePlans.Data.SubscriptionPlan.length) {
                                this.setState({
                                    subscriptionPlans: responsePlans.Data.SubscriptionPlan,
                                    currencyList: response.Data.CurrencyList,
                                    selectedCurrencyID: selectedCurrencyID,
                                    paymentProvider: response.Data.PaymentProvider ? response.Data.PaymentProvider : []
                                })
                            }
                            this.setState({ isLoading: false })
                        },
                        unSuccess: (responsePlans) => {
                            gHelpers.notif('error', { msg: responsePlans.Status.Message });
                            this.setState({ isLoading: false })
                        }
                    });
                } else {
                    this.setState({ isLoading: false })
                }
            },
            unSuccess: (response) => {
                gHelpers.notif('error', { msg: response.Status.Message });
                this.setState({ isLoading: false })
            }
        });
    }

    render() {
        const { subscriptionPlans, currencyList, selectedSubscriptionTermID, selectedCurrencyID, selectedSubscriptionPlan, isLoading } = this.state;

        const planCss = {
            2: cls.plan2,
            4: cls.plan4
        }
        let currencyListOptions = [];
        let termListOptions = [];
        let subscriptionsPlansComp = [];

        if (subscriptionPlans.length) {

            subscriptionPlans.forEach((plan, idx) => {
                let price = plan.SubscriptionPlanTerms.find(t => selectedSubscriptionTermID == t.SubscriptionTermID);
                const priceStr = "" + price.Amount;

                subscriptionsPlansComp.push(
                    <div
                        className={`${cls.plan} ${planCss[plan.SubscriptionPlanID] || ''}`}
                        key={idx}
                    >
                        <h4 className={cls.planName}>{plan.PlanName}</h4>
                        <div className={cls.planDesc}>{plan.PlanDescription}</div>
                        <div className={cls.planPrice}>{price.CurrencySymbol}{priceStr.includes(".") ? <span>{priceStr.split(".")[0]}<small>.{priceStr.split(".")[1]}/{price.TermName}</small></span> : <span>{priceStr}<small>/{price.TermName}</small></span>}</div>
                        <div className={cls.planOptions}>
                            {
                                plan.SubscriptionPlanOptions.map((option, idx) => (
                                    <div
                                        className={cls.option}
                                        key={idx}
                                    >{option.HasLimit ? option.Limit : null} {option.OptionName}</div>
                                ))
                            }
                        </div>
                        <div className={cls.btnWrap}>
                            <button
                                className={`${cls.btnSelect} ${selectedSubscriptionPlan && selectedSubscriptionPlan.SubscriptionPlanID == plan.SubscriptionPlanID ? cls.active : ''}`}
                                onClick={(e) => {
                                    e.preventDefault();

                                    this.setState({
                                        selectedSubscriptionPlan: plan
                                    })
                                }}
                            >
                                {gHelpers.getLabel({ key: 72994, fallback: 'Select Plan' })}
                            </button>
                        </div>
                    </div>
                )
            })

            if (subscriptionPlans[0].SubscriptionPlanTerms) {
                subscriptionPlans[0].SubscriptionPlanTerms.forEach(st => {
                    termListOptions.push({
                        value: st.SubscriptionTermID,
                        label: st.TermName,
                        ...st
                    })
                })
            }

            currencyList.forEach(c => {
                currencyListOptions.push({
                    value: c.CurrencyID,
                    label: c.Code,
                    ...c
                })
            })
        }

        return (
            <div className={cls.stepContentFullWidth}>
                {
                    isLoading ? loading('sm') : null
                }
                <div className={cls.subFields}>
                    {
                        currencyListOptions.length ? <FieldsGenerator
                            onRef={ref => (this.subFields = ref)}
                            fields={{
                                fields: [
                                    {
                                        AttributeName: 'SubFieldCurrencyID',
                                        ListValue: JSON.stringify(currencyListOptions),
                                        IsSelectList: true,
                                        Label: 'Currency',
                                        RuleValue: JSON.stringify({
                                            position: {
                                                row: 0,
                                                col: 0
                                            }
                                        }),
                                        DefaultValue: currencyListOptions.find(c => c.IsDefault).value,
                                        Value: selectedCurrencyID,
                                        onSelectChange: (val) => {
                                            this.changeCurrency(val.CurrencyID)
                                        }
                                    },
                                    {
                                        AttributeName: 'SubFieldTermID',
                                        ListValue: JSON.stringify(termListOptions),
                                        IsSelectList: true,
                                        Label: 'Terms',
                                        RuleValue: JSON.stringify({
                                            position: {
                                                row: 0,
                                                col: 1
                                            }
                                        }),
                                        Value: termListOptions.find(t => t.value == selectedSubscriptionTermID).value,
                                        onSelectChange: (val) => {
                                            this.setState({
                                                selectedSubscriptionTermID: val.SubscriptionTermID
                                            })
                                        }
                                    }
                                ],
                                excluded: []
                            }}
                            settings={{
                                updateByRID: false
                            }}

                        /> : null
                    }
                </div>
                <div className={cls.plansWrapper}>
                    <div className={cls.plans}>{subscriptionsPlansComp}</div>
                </div>
            </div>
        )
    }
}

export default Step3Plans;