import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { mediaFileLibraryActions } from '../../redux/actions/mediaFileLibrary';
import { newOutingRemoveSocialMediaAccounts } from '../../redux/actions/mediaSchedule';
import { cellUpdating } from 'redux/actions/mediaSchedule';
import { gHelpers, localToObject, callApi, getAttribVal, loading, socket } from '../../helpers';
import { addBGProcesses, updateBGProcesses } from 'redux/actions/bgProcess';
import FormGenerator from '../../helpers/formGenerator';
import OutingPostIcons from '../OutingPostIcons';

import cls from './index.module.scss';

const mapStateToProps = state => ({
    mediaFileLibrary: {
        selectedFiles: state.selectedMediaFiles
    },
    fieldRdx: {
        fields: state.fields,
    },
    selectedSocialMediaAccounts: state.newOutingSelectedSocialMediaAccounts,
    selectedSocialMediaAccountsPages: state.newOutingSelectedSocialMediaAccountsPages
});
const mapDispatchToProps = dispatch => ({
    removeAllSelectedMediaFilesAction: (status) => dispatch(mediaFileLibraryActions.removeAllSelectedMediaFiles(status)),
    newOutingRemoveSocialMediaAccounts: (data) => dispatch(newOutingRemoveSocialMediaAccounts(data)),
    addBGProcesses: (data) => dispatch(addBGProcesses(data)),
    updateBGProcesses: (data) => dispatch(updateBGProcesses(data)),
    cellUpdatingAction: (data) => dispatch(cellUpdating(data)),
});
class QuickPostForm extends Component {
    timeStamp;

    constructor(props) {
        super(props);

        this.state = {
            selectedSocialMediaAccounts: [],
            modalIsOpen: false,
            MediaScheduleID: null,
            mediaScheduleLoaded: true,
            IsVertical: false,
            TimeZoneID: null,
            VerticalGroupID: null,
            hideSubmitBtn: true
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.timeStamp = Date.now();
    }

    handleSubmit(e) {
        e.preventDefault();
        const { isProcessing } = this.state;

        if (isProcessing) {
            return false;
        }

        this.setState({
            isProcessing: true
        });
    }

    handleSelectChange(selectedVal) {
        if (!selectedVal) {
            return false;
        }
        this.props.newOutingRemoveSocialMediaAccounts();

        this.setState({
            selectedSocialMediaAccounts: [],
            MediaScheduleID: selectedVal.value,
            mediaScheduleLoaded: false
        });

        let passObject = {
            'Module': 'media_schedule_get',
            'Parameters': {
                'MediaScheduleID': selectedVal.value
            }
        }

        callApi({
            passObject: passObject,
            success: (response) => {
                if (response.Data.AttributeSpecification) {
                    const AccountID = getAttribVal(response.Data.AttributeSpecification, 'AttributeName', 'AccountID');
                    const IsVertical = getAttribVal(response.Data.AttributeSpecification, 'AttributeName', 'IsVertical');
                    const VerticalID = getAttribVal(response.Data.AttributeSpecification, 'AttributeName', 'VerticalID');
                    const TimeZoneID = getAttribVal(response.Data.AttributeSpecification, 'AttributeName', 'TimeZoneID');

                    this.setState({ AccountID, IsVertical, VerticalID, mediaScheduleLoaded: true, TimeZoneID, hideSubmitBtn: false });
                    this.formRef.loadComponent();
                } // if (response.Data.AttributeSpecification)
            },
            unSuccess: (response) => {
            }
        });
    }

    componentDidMount() {
        this.props.newOutingRemoveSocialMediaAccounts();

        if (this.props.location && this.props.location.pathname.includes("/schedules/")) {
            const locationSlug = this.props.location.pathname.split("/");
            let MSID = locationSlug[locationSlug.length - 2];

            if (!isNaN(MSID)) {
                this.setState({
                    MediaScheduleID: MSID
                })

                this.handleSelectChange({ value: MSID })
            }
        }
    }

    render() {
        let overrideFields = {
            '[270].Content': {
                WithEmoji: true
            },
            // '[270].MediaFiles': {
            //     hasSocialMediaRestrictions: true
            // }
        };

        if (this.state.MediaScheduleID) {
            overrideFields = {
                ...overrideFields,
                '[270].MediaScheduleID': {
                    'DefaultValue': this.state.MediaScheduleID,
                }
            }
        }

        let bracketValues = {};

        let additionalSubmitDataKey = 'SocialMediaAccounts';
        let excluded = [];

        if (this.state.IsVertical) {
            additionalSubmitDataKey = 'SocialMediaTypes';
        } else {
            excluded.push('[270].VerticalGroupID')
        }

        const formFieldSettings = {
            stickyFooter: true,
            bracketValues: {
                '[UserID]': localToObject('UserDetails').User.UserID,
                '[MediaScheduleID]': this.state.MediaScheduleID,
                ...bracketValues
            },
            overrideTarget: 'socialmedia/quick_post/',
            excluded: excluded,
            beforeSubmit: () => {
                const fields = this.props.fieldRdx.fields;
                let optionLabel;
                if (fields[`[270].MediaScheduleID`].optionValue) {
                    optionLabel = fields[`[270].MediaScheduleID`].optionValue.label;
                } else if (fields[`[270].MediaScheduleID`].options) {
                    optionLabel = fields[`[270].MediaScheduleID`].options.find(x => x.value == this.state.MediaScheduleID).label;
                }

                // check if has selected social media accounts
                if (this.props.selectedSocialMediaAccounts.length) {
                    this.outingIcons.checkAccountRestrictions({
                        reset: true,
                        showErrors: 'text',
                        cb2: (connectedErrors) => {
                            if (connectedErrors < 1) {
                                this.formRef.handleSubmit(null, true, true);
                                this.outingIcons.resetSelected();
                                this.props.addBGProcesses({
                                    keyName: `quickpost-${this.timeStamp}`,
                                    data: {
                                        thumbnail: fields[`[270].MediaFiles`].mediaFiles ? fields[`[270].MediaFiles`].mediaFiles[0].thumbUrl : null,
                                        title: fields[`[270].Title`].newValue,
                                        subtitle: `a quick post in ${optionLabel}`,
                                        status: `in progress`,
                                        statusColor: `blue`
                                    }
                                });

                                gHelpers.notif('info', { msg: gHelpers.getLabel({ isString: true, key: 60985, fallback: `Request added in background processes.` }) });
                            }
                        }
                    });
                } else {
                    gHelpers.notif('error', { msg: gHelpers.getLabel({ isString: true, key: 60991, fallback: `Please select which social media account you want to post.` }) });
                }


                return false;
            },
            onSubmitSuccess: (response) => {
                if (response.Data && response.Data.MediaOutingID) {
                    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
                    const todate = new Date();

                    this.props.updateBGProcesses({
                        keyName: `quickpost-${this.timeStamp}`,
                        data: {
                            status: `done`,
                            link: `/schedules/${this.state.MediaScheduleID}/?post=${response.Data.MediaOutingID}`,
                            statusColor: `green`
                        }
                    });
                    socket.emit('updateModule', {
                        status: true,
                        module: 'MediaSchedule',
                        update: `${todate.getDate()}-${monthNames[todate.getMonth()]}-${todate.getFullYear()}`
                    });
                    this.props.cellUpdatingAction({
                        keyName: `${todate.getDate()}-${monthNames[todate.getMonth()]}-${todate.getFullYear()}`,
                        data: 'refreshOutings'
                    });
                    gHelpers.notif('success', {
                        msg: `${response.Status.Message}. ${gHelpers.getLabel({ isString: true, key: 60997, fallback: `Click to view.` })}`, cb: () => {
                            window.open(
                                `/schedules/${this.state.MediaScheduleID}/?post=${response.Data.MediaOutingID}`,
                                '_blank'
                            );
                        }
                    });
                }
            },
            onSubmitUnsuccess: (response) => {
                if (response.type) {
                    if (response.type === 1) {
                        gHelpers.notif('error', { msg: response.data.Status.Message });
                    }
                } else {
                    gHelpers.notif('error', { msg: response.Status.Message });
                }
            },
            onSubmitFail: (response) => {
                if (response.Status) {
                    gHelpers.notif('error', { msg: response.Status.Message });
                } else {
                    gHelpers.notif('error', { msg: gHelpers.getLabel({ isString: true, key: 61003, fallback: `An error occured during the process` }) });
                }
            },
            additionalFields: {
                '[270].MediaScheduleID': {
                    onSelectChange: function (val) {
                        this.handleSelectChange(val)
                    }.bind(this)
                },
                '[270].Content': {
                    onPasteImage: true
                },
                '[270].VerticalGroupID': {
                    additionalProps: {
                        onMenuOpen: function () {
                            if (!this.props.fromDashboard) {
                                if (this.timeout) clearTimeout(this.timeout);
                                this.timeout = setTimeout(() => {
                                    this.formWrapperEnd.scrollIntoView({ behavior: "smooth" });
                                }, 100);
                            }

                        }.bind(this)
                    },
                    className: `select-menu-is-relative`,
                    onSelectChange: function (val) {
                        this.setState({
                            VerticalGroupID: val.value
                        })
                    }.bind(this)

                }
            },
            isBgProcess: true,
            noLoading: true,
            overrideFields: overrideFields,
            formProps: {
                style: {
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }
            },
            btnSubmitText: gHelpers.getLabel({ key: 62702, fallback: 'Post' }),
            additionalSubmitData: {
                [additionalSubmitDataKey]: this.props.selectedSocialMediaAccounts,
                'SocialMediaAccountPageIDs': this.props.selectedSocialMediaAccountsPages
            },
            hideSubmitBtn: this.state.hideSubmitBtn,
            additionalComponents: {
                component:
                    <>
                        {
                            this.state.mediaScheduleLoaded
                                ?
                                <div className={`field-gen`} style={{ marginTop: '8px' }}>
                                    <span className={`uik-content-title__wrapper`}>{gHelpers.getLabel({ key: 60913, fallback: `Post in` })}</span>
                                    <OutingPostIcons
                                        onRef={ref => (this.outingIcons = ref)}
                                        accountID={this.state.AccountID}
                                        isVertical={this.state.IsVertical}
                                        VerticalID={this.state.VerticalID}
                                        VerticalGroupID={this.state.VerticalGroupID}
                                        MediaScheduleID={this.state.MediaScheduleID}
                                        MediaFileFieldKey={`[270].MediaFiles`}
                                    />
                                </div>
                                : null
                        }
                    </>


            },
            overrideFormData: (currentFormData) => {
                // check outing type
                let outingType = currentFormData.get('[270].PostType');

                // check if Image or Multiple Image
                if (currentFormData.get('[270].MediaFiles')) {
                    const mediaFiles = this.props.mediaFileLibrary.selectedFiles['[270].MediaFiles'];
                    if (mediaFiles.length && mediaFiles.length > 1) {
                        outingType = 'Multiple Image';
                    } else if (mediaFiles.length && mediaFiles.length == 1) {
                        outingType = 'Image';
                    }

                    mediaFiles.forEach(file => {
                        if (file.Extension === 'mp4') {
                            outingType = 'Video';
                        }
                    });
                }

                if (!outingType.includes('Image')) {
                    if (new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(currentFormData.get('[270].Content'))) {
                        outingType = 'Link';
                    }
                }

                currentFormData.set('[270].PostType', outingType);
                return currentFormData;
            }
        }

        return (
            <div className={`${cls.formWrap} showScrollbar`}>
                {
                    !this.state.mediaScheduleLoaded
                        ? loading('sm2') : null
                }
                <FormGenerator
                    onRef={ref => (this.formRef = ref)}
                    module={`media_quickpost_form`}
                    formFieldSettings={formFieldSettings}
                />
                <div ref={(el) => { this.formWrapperEnd = el }} />
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QuickPostForm));