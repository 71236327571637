import React from 'react';
import debounce from 'lodash/debounce';
import Select from 'react-select';

import {
    UikFormInputGroup,
    UikInput
} from '@uik';
import { localToObject, gHelpers, updateByRID, loading2, selectStyles } from 'helpers';

class ChangeLangForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            selectedLabelKey: null,
            selectedLanguage: null,
            languageOptions: [],
            selectedLabel: {
                key: null,
                RID: null,
                Value: ''
            },
            translatedLabelValue: '',
            isTranslatedLabelValueUpdating: false
        }

        this.changeLanguage = this.changeLanguage.bind(this);
        this.updateLanguage = this.updateLanguage.bind(this);
        this.handleTranslationChange = this.handleTranslationChange.bind(this);
    }

    handleTranslationChange(e) {
        var target = e.target;
        this.setState({
            translatedLabelValue: e.target.value
        }, () => {
            if (target.value) {
                this.updateLanguage(target.value);
            }
        })
    }

    updateLanguage = debounce((newValue) => {
        if (newValue.length) {
            const { RID, Value } = this.state.selectedLabel;
            let reUpdated = false;

            const updateByRIDCall = (args) => {
                this.setState({
                    isTranslatedLabelValueUpdating: true
                })

                updateByRID({
                    formDataObject: {
                        'RID': args.RID,
                        'OldValue': args.OldValue,
                        'Value': args.Value,
                    },
                    ResponseSuccessCallback: function (response) {
                        if (response.data.detail.Status.IsSuccess) {
                            this.setState(prevState => ({
                                translatedLabelValue: response.data.detail.Data.NewValue,
                                selectedLabel: {
                                    ...prevState.selectedLabel,
                                    Value: response.data.detail.Data.NewValue
                                },
                                isTranslatedLabelValueUpdating: false
                            }))
                        } else {

                            this.setState({
                                isTranslatedLabelValueUpdating: false
                            })

                            if (response.data.detail.Status.Message.includes('Someone') && args.cbUdpatedBySomeone) {
                                args.cbUdpatedBySomeone(response.data.detail.Data.NewValue);
                            }
                        }
                    }.bind(this),
                    ResponseFailCallback: function () {
                        this.setState({
                            isTranslatedLabelValueUpdating: false
                        })
                    }.bind(this)

                })
            }

            updateByRIDCall({
                'RID': `${RID}.label`,
                'OldValue': Value,
                'Value': newValue,
                cbUdpatedBySomeone: function (newUpdatedValue) {
                    if (!reUpdated) {
                        reUpdated = true;

                        updateByRIDCall({
                            'RID': `${RID}.label`,
                            'OldValue': newUpdatedValue,
                            'Value': newValue
                        })
                    }
                }
            })


        }
    }, 1000);

    changeLanguage(selectedLanguage) {

        const selectedLanguageLabel = localToObject(`lang-${selectedLanguage.value}-v${process.env.REACT_APP_LANG_VERSION}`)[this.props.Key];

        if (selectedLanguageLabel.RID) {
            this.setState({
                selectedLabel: {
                    Key: this.props.Key,
                    RID: selectedLanguageLabel.RID,
                    Value: selectedLanguageLabel.Value
                },
                translatedLabelValue: selectedLanguageLabel.Value,
                selectedLanguage: [selectedLanguage],
            })
        } else {
            gHelpers.notif('error', {
                msg: gHelpers.getLabel({ isString: true, key: 62848, fallback: 'Selected translated language is not available for this label. Please regenerate static labels.' })
            });
        }

    }

    componentDidMount() {
        const { Key, RID, Label } = this.props;
        let options = [];
        let value = [];
        const localLangObj = localToObject('langObj');
        Object.keys(localLangObj).forEach(langKey => {
            options.push({
                value: langKey,
                label: localLangObj[langKey]
            })
            if (localToObject('UserDetails').UserSystemSettings.DefaultLanguage.Value == langKey) {
                value.push({
                    value: langKey,
                    label: localLangObj[langKey]
                })
            }
        })

        this.setState({
            languageOptions: options,
            selectedLanguage: value,
            selectedLabel: {
                Key: Key,
                RID: RID,
                Value: Label
            },
            translatedLabelValue: Label,
        })
    }

    render() {
        const { selectedLanguage, languageOptions, translatedLabelValue, isTranslatedLabelValueUpdating } = this.state;

        return (
            <UikFormInputGroup
                className={`field-gen text-left`}
                direction={`horizontal`}>
                <div>
                    <span className='uik-content-title__wrapper'>{gHelpers.getLabel({ isString: true, key: 62708, fallback: `Select Language` })}</span>
                    <div className='uik-input__inputWrapper'>
                        <Select
                            key={`select-lang`}
                            value={selectedLanguage}
                            options={languageOptions}
                            className={`basic-multi-select`}
                            classNamePrefix="select"
                            onChange={(value) => this.changeLanguage(value)}
                            styles={selectStyles}
                            onMenuOpen={function () {
                                if (this.timeout) clearTimeout(this.timeout);
                                this.timeout = setTimeout(() => {
                                    this.formWrapperEnd.scrollIntoView({ behavior: "smooth" });
                                }, 100);

                            }.bind(this)}
                        />
                        <div ref={(el) => { this.formWrapperEnd = el }} />
                    </div>
                </div>
                <div className={`pos-rel`}>
                    <UikInput
                        label={gHelpers.getLabel({ isString: true, key: 62726, fallback: `Set Translation` })}
                        value={translatedLabelValue}
                        onChange={this.handleTranslationChange}
                    />
                    {
                        isTranslatedLabelValueUpdating ? loading2({
                            borderWidth: 3,
                            additionalCss: {
                                position: 'absolute',
                                top: `3px`,
                                right: `3px`
                            }
                        }) : null
                    }
                </div>
            </UikFormInputGroup>
        )
    }
}

export default ChangeLangForm;