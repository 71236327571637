import React, { Component } from 'react';
// import html2canvas from 'html2canvas';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { globalModalActions } from '../../redux/actions/globalModal';
import {
    UikWidget,
    UikWidgetContent,
    UikHeadline,
    UikHeadlineDesc
} from '../../@uik';

import cls from './modal.module.scss';

const customStyles = {
    content: {
        top: '0',
        left: '0',
        margin: 'auto',
        minWidth: '150px',
        maxWidth: '75%',
        padding: '0',
        borderRadius: '10px',
        maxHeight: '90vh',
        position: 'relative'
    }
}

const mapStateToProps = state => ({
    globalModal: state.globalModal,
});
const mapDispatchToProps = dispatch => ({
    hideModal: () => dispatch(globalModalActions.hideModal()),
});
class ModalGeneric extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { modalIsOpen } = this.props.globalModal;
        let customStylesComp = customStyles;
        let hasFooter = false;

        if (this.props.globalModal.modalFooterComponent) {
            hasFooter = true;
        }

        if (this.props.globalModal.customStyles) {
            customStylesComp['content'] = {
                ...customStylesComp['content'],
                ...this.props.globalModal.customStyles['content']
            };

            if (this.props.globalModal.customStyles.overlay) {
                customStylesComp.background = this.props.globalModal.customStyles.overlay.background;
            }
        }

        return (
            <Modal
                isOpen={modalIsOpen}
                style={customStylesComp}
                onRequestClose={() => {
                    if ('handleClose' in this.props.globalModal) {
                        this.props.globalModal.handleClose();
                    }

                    this.props.hideModal();
                }}
            >
                {
                    !this.props.globalModal.noHeader
                        ?
                        <div className={`${cls.modalHeader} ${this.props.globalModal.headerCentered ? 'text-center' : ''}`}>
                            <UikHeadline className={cls.modalHeaderText}>
                                {
                                    this.props.globalModal.headerText
                                        ? this.props.globalModal.headerText
                                        : ''
                                }
                            </UikHeadline>
                            {
                                this.props.globalModal.headerSubText
                                    ? <UikHeadlineDesc className={cls.modalHeaderSubText}>
                                        {this.props.globalModal.headerSubText}
                                    </UikHeadlineDesc>
                                    : null
                            }
                            <a to='#' className={`btnClose ${cls.btnClose}`} onClick={(e) => {
                                e.preventDefault();

                                if ('handleClose' in this.props.globalModal) {
                                    this.props.globalModal.handleClose();
                                }

                                this.props.hideModal();
                            }}>
                                <i className={`${cls.iconClose} fas fa-times`} />
                            </a>
                        </div>
                        : null
                }
                <UikWidget className={`${cls.modalWidgetContainer} ${!hasFooter ? cls.modalWidgetContainerFullHeight : ''} showScrollbar`}>
                    {
                        this.props.globalModal.noHeader
                            ? <a to='#' className={`btnClose ${cls.btnClose}`} onClick={(e) => {
                                e.preventDefault();

                                if ('handleClose' in this.props.globalModal) {
                                    this.props.globalModal.handleClose();
                                }
                                
                                this.props.hideModal();
                            }}>
                                <i className={`${cls.iconClose} fas fa-times`} />
                            </a>
                            : null
                    }
                    <UikWidgetContent className={cls.modalWidgetContent}>
                        {this.props.globalModal.content}
                    </UikWidgetContent>
                </UikWidget>
                {
                    hasFooter
                        ? <div className={`${cls.modalSelectFilesFooter}`}>
                            {
                                this.props.globalModal.modalFooterComponent
                            }
                        </div>
                        : null
                }
            </Modal>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalGeneric);