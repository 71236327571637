import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { gHelpers, genericQuery } from 'helpers';
import CardSection from './CardSection';

const getFieldValue = (fields, attrName) => {
    const field = Object.entries(fields).find(f => f[0].includes(attrName));

    return field.length ? field[1].newValue : null;
}

const CardSetupForm = forwardRef(({ price, fields, wizRef, currencyID, paymentProvider }, ref) => {
    console.log(`fields`, fields); console.log(`paymentProvider`, paymentProvider); // temp
    const stripe = useStripe();
    const elements = useElements();
    const [cardStatus, setCardStatus] = useState(null);
    const [stripeCustomer, setStripeCustomer] = useState(null);

    useImperativeHandle(ref, () => ({

        submitCard(cb) {

            if (!getFieldValue(fields, 'WebsiteTermsSigned')) {
                wizRef.current.setBtnLoading(false);
                gHelpers.notif('error', { msg: gHelpers.getLabel({ isString: true, key: 61339, fallback: 'This field is required.' }) + ' WebsiteTermsSigned' });
                return;
            }

            if (stripeCustomer) {
                handleSubmitCard(stripeCustomer);
            } else {
                handleCreateCustomer(cb);
            }
        }

    }));

    // step 1: create customer instance on stripe
    const handleCreateCustomer = (cb) => {
        // e.preventDefault();
        // setBtnLoading(true);
        wizRef.current.setBtnLoading(true); // temp; true
        const VatNumber = getFieldValue(fields, 'VatNumber');

        let Data = {
            Email: fields['CardEmail'].newValue,
            SubscriptionID: price.SubscriptionID,
            CurrencyID: fields['SubFieldCurrencyID'].newValue
        };
        const formData = new URLSearchParams();
        formData.append('Email', fields['CardEmail'].newValue);
        formData.append('SubscriptionID', price.SubscriptionID);
        formData.append('CurrencyID', fields['SubFieldCurrencyID'].newValue);

        if (VatNumber) {
            formData.append('VAT', VatNumber);
            Data['VAT'] = VatNumber;
        }
        // console.log(`vatNumberFd`, vatNumberFd); console.log(`fields`, fields); console.log(`formData`, formData); return; // temp

        genericQuery({
            Url: 'stripe_create_customer/',
            Method: 'POST',
            Data: {
                requestjson: formData
            },
            ResponseSuccessCallback: function (response) {
                // console.log(`response`, response)
                if (response.data.Status.IsSuccess && response.data.Data.CustomerDetails) {
                    setStripeCustomer(response.data.Data);
                    handleSubmitCard(response.data.Data);
                } else {
                    gHelpers.notif('error', { msg: response.data.Status.Message });
                }
            }.bind(this),
            ResponseFailCallback: function (response) {
            }.bind(this),
        });

    }

    const handleSubmitCard = async (customerStripeDetails) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        // If a previous payment was attempted, get the latest invoice
        const latestInvoicePaymentIntentStatus = localStorage.getItem(
            'latestInvoicePaymentIntentStatus'
        );
        const FirstName = getFieldValue(fields, 'Firstname');
        const LastName = getFieldValue(fields, 'LastName');

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: `${FirstName} ${LastName}`,
            },
        });

        if (error) {
            console.log('[createPaymentMethod error]', error);
            gHelpers.notif('error', { msg: error.message });
            wizRef.current.setBtnLoading(false);
        } else {
            wizRef.current.setBtnLoading(true);

            // console.log('[PaymentMethod]', paymentMethod);
            const customerId = customerStripeDetails.CustomerDetails.id;
            const paymentMethodId = paymentMethod.id;

            // Create the subscription
            const vatInfo = customerStripeDetails.TaxDetails ? customerStripeDetails.TaxDetails : null
            createSubscription({ customerId, paymentMethodId, vatInfo });
        }
    };

    function retryInvoiceWithNewPaymentMethod({
        customerId,
        paymentMethodId,
        invoiceId,
        priceId
    }) {
        return (
            fetch('/retry-invoice', {
                method: 'post',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({
                    customerId: customerId,
                    paymentMethodId: paymentMethodId,
                    invoiceId: invoiceId,
                }),
            })
                .then((response) => {
                    return response.json();
                })
                // If the card is declined, display an error to the user.
                .then((result) => {
                    if (result.error) {
                        // The card had an error when trying to attach it to a customer.
                        throw result;
                    }
                    return result;
                })
                // Normalize the result to contain the object returned by Stripe.
                // Add the additional details we need.
                .then((result) => {
                    return {
                        // Use the Stripe 'object' property on the
                        // returned result to understand what object is returned.
                        invoice: result,
                        paymentMethodId: paymentMethodId,
                        priceId: priceId,
                        isRetry: true,
                    };
                })
                // Some payment methods require a customer to be on session
                // to complete the payment process. Check the status of the
                // payment intent to handle these actions.
                .then(handlePaymentThatRequiresCustomerAction)
                // No more actions required. Provision your service for the user.
                .then(onSubscriptionComplete)
                .catch((error) => {
                    // An error has happened. Display the failure to the user here.
                    // We utilize the HTML element we created.
                    console.log(`error`, error)
                    // displayError(error);
                })
        );
    }

    function createSubscription({ customerId, paymentMethodId, vatInfo }) {

        const formData = new URLSearchParams();
        let requestjson = {
            "Subscription": {
                "SubscriptionID": price.SubscriptionID,
                "CurrencyID": currencyID,
            }
        };

        if (vatInfo) {
            const taxRateID = paymentProvider.length
                && paymentProvider[0].PaymentProviderVat
                && paymentProvider[0].PaymentProviderVat.find(c => getFieldValue(fields, 'CountryID') == c.CountryID)
                ? paymentProvider[0].PaymentProviderVat.find(c => getFieldValue(fields, 'CountryID') == c.CountryID).PaymentProviderVatReference : '';

                requestjson.VatInfo = {
                    "VatNumber": vatInfo.value,
                    "TaxID": vatInfo.id,
                    "TaxRateID": taxRateID // taxRateID // 'txr_1HFJCMKZ1GouSIqkMaqCtc9x' // tbd; temp;
                }

            formData.append('StripeTaxRateID', taxRateID) // taxRateID); //
        }

        formData.append('requestjson', JSON.stringify({
            "Module": "subscription_customer_addv3",
            "Parameters": requestjson
        })); // subscription_customer_addv3 Customer Params
        formData.append('StripeCustomerID', customerId); // 
        formData.append('PaymentMethodID', paymentMethodId); // 
        formData.append('AvailTrial', fields['AvailTrial'].newValue ? 1 : 0);

        genericQuery({
            Url: 'stripe_create_subscription/',
            Method: 'POST',
            Data: {
                requestjson: formData
            },
            ResponseSuccessCallback: function (response) {
                if (response.data.Status.IsSuccess) {
                    wizRef.current.setBtnLoading(false);
                    wizRef.current.setBtnDisabled(true, 'back');
                    wizRef.current.setBtnDisabled(true, 'next');
                    wizRef.current.handleNextStep();
                } else {
                    if (response.data.Status.Message == "Payment requires action.") {
                        const SubscriptionDetails = response.data.Data.CreateSubscriptionDetails;
                        const customerAction = handlePaymentThatRequiresCustomerAction({
                            paymentMethodId: paymentMethodId,
                            priceId: SubscriptionDetails.plan.id,
                            subscription: SubscriptionDetails,
                        });

                        gHelpers.notif('warning', { msg: response.data.Status.Message });
                    } else {
                        gHelpers.notif('error', { msg: response.data.Status.Message });
                    }
                }
            }.bind(this),
            ResponseFailCallback: function (response) {
            }.bind(this),
        });

    }

    function handlePaymentThatRequiresCustomerAction({
        subscription,
        invoice,
        priceId,
        paymentMethodId,
        isRetry,
    }) {
        if (subscription && subscription.status === 'active') {
            // Subscription is active, no customer actions required.
            return { subscription, priceId, paymentMethodId };
        }

        // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
        // If it's a retry, the payment intent will be on the invoice itself.
        let paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent;

        if (
            paymentIntent.status === 'requires_action' ||
            (isRetry === true && paymentIntent.status === 'requires_payment_method')
        ) {
            return stripe
                .confirmCardPayment(paymentIntent.client_secret, {
                    payment_method: paymentMethodId,
                })
                .then((result) => {
                    if (result.error) {
                        // Start code flow to handle updating the payment details.
                        // Display error message in your UI.
                        // The card was declined (i.e. insufficient funds, card has expired, etc).
                        throw result;
                    } else {
                        if (result.paymentIntent.status === 'succeeded') {
                            // Show a success message to your customer.
                            // There's a risk of the customer closing the window before the callback.
                            // We recommend setting up webhook endpoints later in this guide.
                            wizRef.current.setBtnLoading(false);
                            wizRef.current.setBtnDisabled(true, 'back');
                            wizRef.current.setBtnDisabled(true, 'next');
                            wizRef.current.handleNextStep();
                            return {
                                priceId: priceId,
                                subscription: subscription,
                                invoice: invoice,
                                paymentMethodId: paymentMethodId,
                            };
                        }
                    }
                })
                .catch((error) => {
                    gHelpers.notif('error', { msg: error.error.message });
                    wizRef.current.setBtnLoading(false);
                    console.log(`error`, error)
                    // displayError(error);
                });
        } else {
            // No customer action needed.
            return { subscription, priceId, paymentMethodId };
        }
    }

    function handleRequiresPaymentMethod({
        subscription,
        paymentMethodId,
        priceId,
    }) {
        if (subscription.status === 'active') {
            // subscription is active, no customer actions required.
            return { subscription, priceId, paymentMethodId };
        } else if (
            subscription.latest_invoice.payment_intent.status ===
            'requires_payment_method'
        ) {
            // Using localStorage to manage the state of the retry here,
            // feel free to replace with what you prefer.
            // Store the latest invoice ID and status.
            localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
            localStorage.setItem(
                'latestInvoicePaymentIntentStatus',
                subscription.latest_invoice.payment_intent.status
            );
            throw { error: { message: 'Your card was declined.' } };
        } else {
            return { subscription, priceId, paymentMethodId };
        }
    }

    function onSubscriptionComplete(result) {
        console.log(`result`, result)
        // Payment was successful.
        if (result.subscription.status === 'active') {
            // Change your UI to show a success message to your customer.
            // Call your backend to grant access to your service based on
            // `result.subscription.items.data[0].price.product` the customer subscribed to.
        }
    }

    let statusComponent;

    if (cardStatus) {
        if (!cardStatus.IsSuccess) {
            statusComponent = <p className="help is-danger">{cardStatus.Message}</p>
        } else {
            statusComponent = <p className="help is-success">Card verified</p>
        }
    }

    return (
        <div>
            <div className={`formCardSetup`}>
                <div className={`input-cc`}>
                    <CardSection />
                </div>
                {/* <button onClick={handleCreateCustomer} disabled={!stripe} className={`button signup-btn button-cta is-bold raised btn-align is-small btn-save-cc ${isBtnLoading ? 'is-loading' : ''}`}>Verify Card</button> */}
            </div>
            {statusComponent}
        </div>
    );
});

export default (CardSetupForm);