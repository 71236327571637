const backgroundProcesses = (passData) => dispatch => {
    dispatch({
        type: 'LIST_BACKGROUND_PROCESS',
        payload : passData
    })
}

const addBGProcesses = (passData) => dispatch => {
    dispatch({
        type: 'ADD_BACKGROUND_PROCESS',
        payload : passData
    })
}

const updateBGProcesses = (passData) => dispatch => {
    dispatch({
        type: 'UPDATE_BACKGROUND_PROCESS',
        payload : passData
    })
}

export {
    backgroundProcesses,
    addBGProcesses,
    updateBGProcesses
}