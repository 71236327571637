import React, { Component } from 'react';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import {
    UikButton,
    UikOutsideClickHandler
} from '../../@uik';
import cls from './index.module.scss';

class EmojiPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openEmojiSelection: false
        }

    }

    render() {
        const { openEmojiSelection } = this.state;

        return (
            <UikOutsideClickHandler
                className={`emojiPickerContainer`}
                onOutsideClick={
                    () => this.setState({
                        openEmojiSelection: false
                    })
                }
                onOutsideScroll={false}
            >
                <UikButton
                    icon={<i className={`light-gray far fa-smile`} />}
                    onClick={() => {
                        this.setState({
                            openEmojiSelection: true
                        })
                    }}
                    iconOnly
                    clear
                    className={cls.btnEmojiOpen}
                />
                {
                    (() => {
                        if (openEmojiSelection) {

                            return (
                                <Picker
                                    set='messenger'
                                    showPreview={false}
                                    onSelect={(emoji) => {
                                        if (this.props.onSelectEmoji) {
                                            this.props.onSelectEmoji(emoji)
                                        }
                                    }}
                                />
                            )
                        }
                    })()
                }
            </UikOutsideClickHandler>
        )
    }
}

export default EmojiPicker;