import React, { Component } from 'react';
import { connect } from 'react-redux';
import { gHelpers } from 'helpers';

import {
    UikDropdown,
    UikTopBarLink,
    UikScrollArea,
    UikTag,
    UikAvatar
} from '@uik';

import ReactTooltip from 'react-tooltip';

import cls from './index.module.scss';

const BGLink = (props) => {
    const { title, subtitle, status, thumbnail, link, statusColor } = props;
    let returnComp;

    const mainContent = <>
        {
            <div
                className={cls.notificationThumbSm}
            >
                {
                    thumbnail
                        ? (
                            <UikAvatar
                                imgUrl={thumbnail}
                            />
                        )
                        : <div
                            className={cls.notificationThumbSm}
                        >
                            <i className={`${cls.icon} fas fa-bell`} />
                        </div>
                }
            </div>
        }
        <div className={cls.notificationText}>
            <p dangerouslySetInnerHTML={{ __html: title }} />
            <p dangerouslySetInnerHTML={{ __html: subtitle }} />
            <p className={`${cls.notificationTimestamp}`}><em className={statusColor ? statusColor : ''}>{status}</em></p>
        </div>
    </>

    if (props.link) {
        returnComp = <a
            className={`${cls.notificationItem} ${cls.highlighted}`}
            href={link}>
            {mainContent}
        </a>
    } else {
        returnComp = <div
            className={`${cls.notificationItem} ${cls.highlighted}`}>
            {mainContent}
        </div>
    }

    return returnComp;
}

const BacgkroundProcessIcon = ({ onClick, countNotifs }) => (
    <>
        <UikTopBarLink className={cls.navLink}
            data-tip
            data-for={`ttip-top-header-bgp`}
            onClick={onClick}
        >
            <i className={`${cls.iconsStyle} fas fa-tasks`} />
            {
                countNotifs
                    ? <UikTag className={cls.counterTag} color="red" fill>{countNotifs}</UikTag>
                    : null
            }
        </UikTopBarLink>

        <ReactTooltip id={`ttip-top-header-bgp`} type='info'>
            <span>{gHelpers.getLabel({ key: 61267, fallback: 'Background Processes' })}</span>
        </ReactTooltip>
    </>
);

const mapStateToProps = state => ({
    backgroundProcess: state.backgroundProcess
});
class BackgroundProcesses extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            nextPage: 0,
            nextPageLoading: false,
            notifications: []
        }
    }

    componentDidMount() {
    }

    /*     componentDidUpdate() {
            if (Object.keys(this.props.backgroundProcess).length) {
                window.onbeforeunload = function () {
                    return "Dude, are you sure you want to leave? Think of the kittens!";
                }
            }
        } */

    render() {
        const countNotif = Object.keys(this.props.backgroundProcess).length;

        return (
            <UikDropdown
                DisplayComponent={BacgkroundProcessIcon}
                displayComponentProps={
                    {
                        countNotifs: countNotif,
                    }
                }
                position="bottomRight"
            >
                <div className={cls.dropdown}>
                    <UikScrollArea className={`${cls.dropdownMain} showScrollbar`}>
                        {
                            (() => {
                                if (countNotif) {
                                    let bgLinks = [];
                                    Object.keys(this.props.backgroundProcess).forEach(
                                        key => {
                                            const data = this.props.backgroundProcess[key];
                                            bgLinks.push(<BGLink key={key} {...data} />);
                                        }
                                    )
                                    return bgLinks;
                                } else {
                                    return (
                                        <span
                                            onClick={(e) => e.preventDefault()}
                                            className={`${cls.notificationItem} ${cls.highlighted}`}>{gHelpers.getLabel({ key: 61273, fallback: 'No background process at the moment.' })}</span>
                                    )
                                }
                            })()
                        }
                    </UikScrollArea>
                </div>
            </UikDropdown>
        )
    }
}
export default connect(mapStateToProps, null)(BackgroundProcesses);