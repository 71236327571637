import React, { Component } from 'react';
import { connect } from 'react-redux';
import { globalStateAction } from '../../redux/actions/global';
import { socketActions } from '../../redux/actions/socket';
import { fieldActions } from '../../redux/actions/fieldGenerator';
import { mediaFileLibraryActions } from '../../redux/actions/mediaFileLibrary';
import {
    UikButton,
    UikAvatar,
    UikWidgetContent
} from '../../@uik';
import ModalSelectFiles from './modals/selectFiles';
import { localToObject, callApi, gHelpers, loading, genericQuery, dataURLtoBlob, config, getUserInitial, updateByRID } from '../../helpers';
import MediaFiles from './mediaFiles';
import cls from './mediaLibrary.module.scss';
import Dropzone from 'react-dropzone';
import smTemplate from '../../assets/json/socialMediaTemplate.json';

const mapStateToProps = (state, ownProps) => {
    let returnStates = {
        globalState: {
            isLoading: state.isLoading
        },
        mediaFileLibrary: {
            selectedFiles: state.selectedMediaFiles,
            listMediaFiles: state.listMediaFiles
        },
        fieldRdx: {
            fields: state.fields,
        },
        userDetails: state.selfUser,
    }

    if (ownProps.fieldGenerator && ownProps.fieldGenerator.attrib.hasSocialMediaRestrictions) {
        if (ownProps.fieldGenerator.settings.updateByRID) {
            returnStates.scheduledSMTypes = state.scheduledSMTypes;
        } else {
            if (state.newOutingSelectedSocialMediaAccounts.length) {
                returnStates.scheduledSMTypes = state.newOutingSelectedSocialMediaAccounts.map(smId => config.SocialMediaTypeNames[smId]);
            }
        }
    }

    return returnStates;
};

const mapDispatchToProps = dispatch => ({
    isLoadingAction: (status) => dispatch(globalStateAction.isLoading(status)),
    updateField: (data) => dispatch(fieldActions.updateField(data)),
    listMediaFilesAction: (data) => dispatch(mediaFileLibraryActions.listMediaFiles(data)),
    removeAllSelectedMediaFiles: () => dispatch(mediaFileLibraryActions.removeAllSelectedMediaFiles()),
    updateSelectedMediaFilesAction: (status) => dispatch(mediaFileLibraryActions.updateSelectedMediaFiles(status)),
    updateSelfUser: (data) => dispatch(socketActions.updateSelfUser(data)),
});
class BtnSelectMediaFiles extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalSelectFilesOpen: false,
            mediaFiles: [],
            newMediaFilesUploadProgress: [],
            isLoading: false,
            openedFileDetails: null,
            isDragActiveState: false,
            modalViewFileIsOpen: false,
            singlePic: null
        }

        this.handleOpenMediaFiles = this.handleOpenMediaFiles.bind(this);
        this.handleDoneSelecting = this.handleDoneSelecting.bind(this);
        this.processUpload = this.processUpload.bind(this);
        this.handleFileSelect = this.handleFileSelect.bind(this);
        this.handleOnDrag = this.handleOnDrag.bind(this);
        this.handleOnDragLeave = this.handleOnDragLeave.bind(this);
        this.selectSinglePic = this.selectSinglePic.bind(this);
    }

    selectSinglePic(e) {
        const files = e.target.files;
        if (files.length) {
            const file = files[0];
            this.setState({
                isLoading: true,
                singlePic: URL.createObjectURL(file)
            })
            const formDataObject = new FormData();
            formDataObject.append('CustomerID', localToObject('UserDetails').User.CustomerID);
            formDataObject.append('MediaFile', file);
            formDataObject.append('IsProfile', true);

            genericQuery(
                {
                    baseURL: config.mediaFileURL,
                    Url: 'file_manager/',
                    Method: 'POST',
                    Data: {
                        requestjson: formDataObject
                    },
                    Headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    ResponseSuccessCallback: function (response) {
                        if (response.data.Status.IsSuccess) {
                            // update data
                            const mediaFile = response.data.Data.MediaFile;
                            updateByRID({
                                formDataObject: {
                                    RID: this.props.fieldGenerator.name,
                                    OldValue: this.props.fieldGenerator.value,
                                    Value: JSON.stringify([mediaFile.MediaFileID])
                                },
                                ResponseSuccessCallback: function (response) {
                                    if (response.data.detail.Status.IsSuccess) {
                                        this.props.fieldGenerator.reloadComponent();
                                        callApi({
                                            passObject: {
                                                'Module': 'media_file_get',
                                                'Parameters': {
                                                    'MediaFileID': JSON.parse(response.data.detail.Data.NewValue)[0]
                                                }
                                            },
                                            success: (response2) => {
                                                this.setState({
                                                    singlePic: `${config.mediaFileURL}file_manager/?path=${response2.Data.MediaFile.Path}&width=120&height=120&crop=true`,
                                                    isLoading: false
                                                });
                                                if (this.props.fieldGenerator.attrib.onUpdateCb) {
                                                    this.props.fieldGenerator.attrib.onUpdateCb({
                                                        oldValue: `${response2.Data.MediaFile.Path}`
                                                    })
                                                }
                                            },
                                            unSuccess: () => {
                                                this.setState({
                                                    isLoading: false
                                                });
                                            }
                                        });
                                    } else {
                                        this.setState({
                                            isLoading: false
                                        });
                                    }
                                }.bind(this),
                                ResponseFailCallback: function (response) {
                                    this.setState({ isLoading: false });
                                    // console.log('[check status]', response);
                                    // gHelpers.notif('error', { msg: response });
                                }.bind(this),
                            })
                        }
                    }.bind(this),
                    ResponseFailCallback: function (response) {
                        this.setState({ isLoading: false })
                        console.error(response);
                        gHelpers.notif('error', { msg: response.data.detail.Status.Message });
                    }.bind(this),
                }
            ); // genericQuery(...
        }
    }

    handleOnDrag = () => {
        this.setState({ isDragActiveState: true });
    }

    handleOnDragLeave = () => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.setState({ isDragActiveState: false });
        }, 2000);

    }

    //add uploaded media file -start
    processUpload = (acceptedFiles) => {
        this.setState({ isLoading: true })
        if (acceptedFiles.dataTransfer) {
            // check if external link
            let link = acceptedFiles.dataTransfer.getData("text/plain");
            let request = new XMLHttpRequest();
            request.open('GET', link, true);
            request.responseType = 'blob';
            request.onload = function () {
                let reader = new FileReader();
                reader.readAsDataURL(request.response);
                reader.onload = function (e) {
                    const fileToUpload = dataURLtoBlob(e.target.result);
                    this.processUpload([fileToUpload]);
                }.bind(this);
            }.bind(this);
            request.send();

        } else {
            // file
            acceptedFiles.map((file, idx) => {
                const formDataObject = new FormData();
                formDataObject.append('CustomerID', localToObject('UserDetails').User.CustomerID);
                formDataObject.append('MediaFile', file);
                let newFile = {
                    isTemp: true,
                    fileUrl: URL.createObjectURL(file),
                    fileTempId: `tmp-${Date.now()}`
                }

                genericQuery(
                    {
                        baseURL: config.mediaFileURL,
                        Url: 'file_manager/',
                        Method: 'POST',
                        Data: {
                            requestjson: formDataObject
                        },
                        Headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        onUploadProgress: function (progressEvent) {
                            const { loaded, total } = progressEvent;
                            let fileLoaded = (loaded / total) * 100;
                            if (fileLoaded >= 100) {
                                // fileTempId
                                this.setState(prevState => ({
                                    newMediaFilesUploadProgress: [
                                        ...prevState.newMediaFilesUploadProgress,
                                        newFile.fileTempId
                                    ]
                                }))
                            }

                        }.bind(this),
                        ResponseSuccessCallback: function (response) {
                            let mediaFiles = this.state.mediaFiles;
                            const indexOfTemp = this.state.mediaFiles.findIndex(el => el.fileTempId === newFile.fileTempId);

                            if (response.data.Status.IsSuccess) {
                                // update data
                                mediaFiles[indexOfTemp] = response.data.Data.MediaFile;
                                this.handleFileSelect(response.data.Data.MediaFile);
                                this.handleDoneSelecting();

                            } else {
                                // remove temp
                                mediaFiles.splice(indexOfTemp, 1);
                                gHelpers.notif('error', { msg: response.data.Status.Message });
                            }

                            this.setState({
                                mediaFiles: mediaFiles,
                                isLoading: false
                            })
                        }.bind(this),
                        ResponseFailCallback: function (response) {
                            gHelpers.notif('error', { msg: 'An error occured during the process.' });
                            console.log(response)
                            this.setState({ isLoading: false })
                        }.bind(this),
                    }
                ); // genericQuery(...


                this.setState(prevState => ({
                    mediaFiles: [
                        newFile,
                        ...prevState.mediaFiles
                    ]
                }))

            });

        }

    }
    //add uploaded media file -end

    handleFileSelect(file) {
        let passData = {
            selectedMediaFiles: this.props.mediaFileLibrary.selectedFiles,
            file: file,
            keyName: this.props.fieldGenerator.name
        };

        if (this.props.fieldGenerator && this.props.fieldGenerator.singleSelect) {
            passData.singleSelect = this.props.fieldGenerator.singleSelect;
        }

        this.props.updateSelectedMediaFilesAction(passData);
    }

    handleOpenMediaFiles(e) {
        if (e) {
            e.preventDefault();
        }

        if (this.props.fieldGenerator && this.props.fieldGenerator.readOnly) {
            gHelpers.notif('error', { msg: 'Unable to modify read-only file field.' });
            return;
        }

        let isOpen = !(this.state.modalSelectFilesOpen);

        this.setState({
            modalSelectFilesOpen: isOpen
        });

        if (!isOpen) {
            this.handleDoneSelecting();
        }
    }

    handleDoneSelecting(e) {
        if (e) {
            e.preventDefault();
            this.handleOpenMediaFiles(e);
        }
        if (this.props.fieldGenerator) {
            let selectedMediaFilesID = [];
            selectedMediaFilesID = this.props.mediaFileLibrary.selectedFiles[this.props.fieldGenerator.name].map(file => file.MediaFileID);

            this.props.updateField({
                keyName: this.props.fieldGenerator.name,
                data: {
                    newValue: JSON.stringify(selectedMediaFilesID),
                    mediaFiles: this.props.mediaFileLibrary.selectedFiles[this.props.fieldGenerator.name]
                }
            });
            if (this.props.fieldGenerator.handleDoneSelecting) {
                this.props.fieldGenerator.handleDoneSelecting(this.props.fieldGenerator.name, JSON.stringify(selectedMediaFilesID));
            }
        }
        this.setState({
            modalSelectFilesOpen: false
        });
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this)
        }

        if (this.props.fieldGenerator.settings.displayAsThumbnail
            && this.props.fieldGenerator.value) {
            const mediaFiles = JSON.parse(this.props.fieldGenerator.value);
            if (mediaFiles) {
                callApi({
                    passObject: {
                        'Module': 'media_file_get',
                        'Parameters': {
                            'MediaFileID': mediaFiles[0]
                        }
                    },
                    success: (response) => {
                        this.setState({
                            singlePic: `${config.mediaFileURL}file_manager/?path=${response.Data.MediaFile.Path}&width=120&height=120&crop=true`,
                        })
                    }
                });
            }
        }

        if (this.props.fieldGenerator) {
            if (this.props.fieldGenerator.mediaFiles && this.props.fieldGenerator.mediaFiles.length) {
                // console.log('you went here 1')
                this.props.updateSelectedMediaFilesAction({
                    file: this.props.fieldGenerator.mediaFiles,
                    keyName: this.props.fieldGenerator.name
                });

            }
        } // this.props.fieldGenerator
    }
    render() {
        const { modalSelectFilesOpen, isLoading, isDragActiveState, singlePic } = this.state;
        //modals start
        let fieldGenKeyName = '';
        let modals = [];

        if (modalSelectFilesOpen) {
            modals.push(<ModalSelectFiles
                key='modal-selectFiles-0'
                modalIsOpen={modalSelectFilesOpen}
                handleClose={this.handleOpenMediaFiles}
                handleDoneSelecting={this.handleDoneSelecting}
                fieldGenerator={
                    { ...this.props.fieldGenerator }
                }
            />);
        }
        if (this.props.fieldGenerator && this.props.fieldGenerator.name) {
            fieldGenKeyName = this.props.fieldGenerator.name ? this.props.fieldGenerator.name : '';
        }

        let isSlider = false;
        let mediaFiles = this.props.mediaFileLibrary.selectedFiles[fieldGenKeyName];

        let thumbnailComponent = null;
        if (this.props.fieldGenerator.settings.displayAsThumbnail) {
            let imgContent = this.props.fieldGenerator.fields.fields.find(field => field.AttributeName === "Firstname") ?
                getUserInitial({
                    Firstname: this.props.fieldGenerator.fields.fields.find(field => field.AttributeName === "Firstname").Value,
                    Lastname: this.props.fieldGenerator.fields.fields.find(field => field.AttributeName === "LastName").Value
                })
                :
                <i class="fas fa-image"></i>;

            let additionalAvatarProps = {};

            if (singlePic) {
                additionalAvatarProps.imgUrl = singlePic;
            }

            thumbnailComponent = <UikWidgetContent className={cls.userProfileAvatarContainer}>
                <input
                    type="file"
                    ref={filePic => this.filePic = filePic}
                    accept="image/*"
                    onChange={this.selectSinglePic}
                    className={`d-none`} />
                <UikAvatar
                    className={`avatarRounderCorners ${cls.userProfileAvatar}`}
                    size="jumbo"
                    avatarPlaceholder={{
                        content: imgContent,
                        color: 'blue',
                    }}
                    onClick={() => this.filePic.click()}
                    {...additionalAvatarProps}
                >
                </UikAvatar>
                <strong>
                    {
                        this.props.fieldGenerator.fields.fields.find(field => field.AttributeName === "Firstname") &&
                        `${this.props.fieldGenerator.fields.fields.find(field => field.AttributeName === "Firstname").Value} ${this.props.fieldGenerator.fields.fields.find(field => field.AttributeName === "LastName").Value}`
                    }
                </strong>
            </UikWidgetContent>;

        }
        let accept = 'image/*, video/*, text/plain';

        // check if has file type extension checker
        if (this.props.scheduledSMTypes
            && this.props.scheduledSMTypes.some(smType => smTemplate[smType] && smTemplate[smType].restrictions.fileMimeTypeAllowed)) {
            let acceptArray = [];
            this.props.scheduledSMTypes
                .filter(smType => smTemplate[smType] && smTemplate[smType].restrictions.fileMimeTypeAllowed)
                .forEach(smType => {
                    if (smTemplate[smType] && smTemplate[smType].restrictions.fileMimeTypeAllowed) {
                        smTemplate[smType] && smTemplate[smType].restrictions.fileMimeTypeAllowed.forEach(ext => {
                            if (acceptArray.indexOf(ext) === -1) {
                                acceptArray.push(ext)
                            }
                        })
                    }
                });
            if (acceptArray.length) {
                accept = acceptArray.join(",")
            }
        }

        return (
            <div
                style={{
                    position: 'relative'
                }}
            >
                {
                    isLoading
                        ? loading('sm') : null
                }
                <input
                    type="hidden"
                    value={this.props.fieldGenerator.value ? this.props.fieldGenerator.value : ''}
                    name={fieldGenKeyName}
                />
                {
                    this.props.fieldGenerator.settings.displayAsThumbnail ?
                        thumbnailComponent
                        :
                        <>
                            {
                                !isSlider
                                    ?
                                    <Dropzone
                                        onDropAccepted={this.processUpload}
                                        accept={accept}
                                        noClick={true}
                                    >
                                        {
                                            ({
                                                getRootProps,
                                                getInputProps,
                                                isDragActive
                                            }) => (
                                                <div
                                                    {...getRootProps()}
                                                    className={cls.dropzoneContainerQuickPost}
                                                    onClick={this.handleOpenMediaFiles}
                                                >
                                                    <div className={isDragActive || isDragActiveState ? cls.dragActive : cls.dragInActive} />
                                                    <input {...getInputProps()} />
                                                    <div className={`${cls.btnUploadContainer} justify-content-center ${cls.btnUploadFieldContainer}`}>
                                                        <div className={`align-self-center`}>
                                                            <UikButton type="button" className={`${cls.btnUpload}`} xs>
                                                                <i className={`fas fa-paperclip`} />
                                                            </UikButton>
                                                            <span className='ml-2 mt-2 d-inline-block'>{gHelpers.getLabel({ key: 61021, fallback: `Upload or drop your files here.` })}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </Dropzone>
                                    : null
                            }
                            <MediaFiles
                                mediaFiles={mediaFiles}
                                className={`${cls.mediaLibrarySelected} ${cls.mediaFilesNoWrap}`}
                                fieldGenerator={
                                    { ...this.props.fieldGenerator }
                                }
                            />
                        </>
                }
                {modals}
            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BtnSelectMediaFiles);