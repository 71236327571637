import React, { forwardRef } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import CardSetupForm from './CardSetupForm';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_test_51GqB86KZ1GouSIqkPFz7JLXEKgncd3TccPsBPAa3DAKdSS2ANnq1lunExzjId1Wk5fAJ3Y3P2szJsNUtDGhUCKwA00FrwfAssM");

const PaymentForm = forwardRef((props, ref) => {
    return (
        <Elements stripe={stripePromise}>
            <CardSetupForm {...props} ref={ref} />
        </Elements>
    )
});

export default PaymentForm;