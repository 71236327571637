const notificationsConnect = (data) => dispatch => {
    dispatch({
        type: 'NOTIFCATIONS_CONNECT',
        payload : data
    })
}

const notifSelfCID = (data) => dispatch => {
    dispatch({
        type: 'NOTIFCATIONS_SELF_CLIENT_ID',
        payload : data
    })
}

const notificationActions = {
    notificationsConnect: notificationsConnect,
    notifSelfCID: notifSelfCID
}

export {
    notificationActions
}