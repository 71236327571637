const selectMediaSchedule = (passData) => dispatch => {
    dispatch({
        type: 'SELECTED_MEDIA_SCHEDULE',
        payload: passData
    })
}

const listMediaSchedule = (passData) => dispatch => {
    dispatch({
        type: 'LIST_MEDIA_SCHEDULE',
        payload: passData
    })
}

const listMediaScheduleAppend = (passData) => dispatch => {
    dispatch({
        type: 'LIST_MEDIA_SCHEDULE_APPEND',
        payload: passData
    })
}

const masterMediaSchedule = (passData) => dispatch => {
    dispatch({
        type: 'MASTER_MEDIA_SCHEDULE',
        payload: passData
    })
}

const selectMediaScheduleOuting = (passData) => dispatch => {
    dispatch({
        type: 'SELECTED_MEDIA_SCHEDULE_OUTING',
        payload: passData
    })
}

const selectMediaScheduleCampaignAd = (passData) => dispatch => {
    dispatch({
        type: 'SELECTED_MEDIA_SCHEDULE_AD',
        payload: passData
    })
}

const hideMediaScheduleOuting = (passData) => dispatch => {
    dispatch({
        type: 'HIDE_SELECTED_MEDIA_SCHEDULE_OUTING',
        payload: passData
    })
}

const newOutingSelectedSocialMediaAccounts = (passData) => dispatch => {
    dispatch({
        type: 'NEW_OUTING_SELECTED_SOCIAL_MEDIA_ACCOUNTS',
        payload: passData
    })
}

const newOutingSelectedSocialMediaAccountsPages = (passData) => dispatch => {
    dispatch({
        type: 'NEW_OUTING_SELECTED_SOCIAL_MEDIA_ACCOUNTS_PAGES',
        payload: passData
    })
}

const newOutingRemoveSocialMediaAccounts = (passData) => dispatch => {
    dispatch({
        type: 'NEW_OUTING_REMOVE_SOCIAL_MEDIA_ACCOUNTS',
        payload: passData
    })
}

const newOutingRemoveSocialMediaAccountsPages = (passData) => dispatch => {
    dispatch({
        type: 'NEW_OUTING_REMOVE_SOCIAL_MEDIA_ACCOUNTS_PAGES',
        payload: passData
    })
}

const newOutingRemoveSocialMediaAccountsPage = (passData) => dispatch => {
    dispatch({
        type: 'NEW_OUTING_REMOVE_SINGLE_SOCIAL_MEDIA_ACCOUNT_PAGE',
        payload: passData
    })
}

const newOutingRemoveSingleSocialMediaAccount = (passData) => dispatch => {
    dispatch({
        type: 'NEW_OUTING_REMOVE_SINGLE_SOCIAL_MEDIA_ACCOUNT',
        payload: passData
    })
}

const selectMediaScheduleSocialMediaAccounts = (passData) => dispatch => {
    dispatch({
        type: 'SELECTED_MEDIA_SCHEDULE_SOCIAL_MEDIA_ACCOUNTS',
        payload: passData
    })
}

const selectMasterScheduleSocialMediaAccounts = (passData) => dispatch => {
    dispatch({
        type: 'SELECTED_MASTER_SCHEDULE_SOCIAL_MEDIA_ACCOUNTS',
        payload: passData
    })
}

const listMediaScheduleUsers = (passData) => dispatch => {
    dispatch({
        type: 'LIST_MEDIA_SCHEDULE_USERS',
        payload: passData
    })
}

const minimizeList = (passData) => dispatch => {
    dispatch({
        type: 'MINIMIZE_LIST',
        payload: passData
    })
}

const scheduledPostPreview = (passData) => dispatch => {
    dispatch({
        type: 'SCHEDULED_POST_PREVIEW',
        payload: passData
    })
}

const resetScheduledPostPreview = (passData) => dispatch => {
    dispatch({
        type: 'RESET_SCHEDULED_POST_PREVIEW',
        payload: passData
    })
}

const cellUpdating = (passData) => dispatch => {
    dispatch({
        type: 'CELL_UPDATING',
        payload: passData
    })
}

const filterMediaScheduleOuting = (passData) => dispatch => {
    dispatch({
        type: 'FILTER_MEDIA_SCHEDULE_OUTING',
        payload: passData
    })
}

const updateScheduledSMTypes = (passData) => dispatch => {
    dispatch({
        type: 'UPDATE_SCHEDULED_SOCIAL_MEDIA_TYPES',
        payload: passData
    })
}

const setIsScheduleAd = (passData) => dispatch => {
    dispatch({
        type: 'IS_SCHEDULE_AD',
        payload: passData
    })
}

export {
    selectMediaSchedule,
    listMediaSchedule,
    listMediaScheduleAppend,
    masterMediaSchedule,
    selectMediaScheduleOuting,
    selectMediaScheduleCampaignAd,
    hideMediaScheduleOuting,
    selectMediaScheduleSocialMediaAccounts,
    selectMasterScheduleSocialMediaAccounts,
    listMediaScheduleUsers,
    minimizeList,
    scheduledPostPreview,
    resetScheduledPostPreview,
    cellUpdating,
    filterMediaScheduleOuting,
    newOutingSelectedSocialMediaAccounts,
    newOutingSelectedSocialMediaAccountsPages,
    newOutingRemoveSocialMediaAccounts,
    newOutingRemoveSingleSocialMediaAccount,
    newOutingRemoveSocialMediaAccountsPage,
    newOutingRemoveSocialMediaAccountsPages,
    updateScheduledSMTypes,
    setIsScheduleAd
};