import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import {
    UikButton
} from '../../../../@uik';
import { gHelpers, localToObject } from '../../../../helpers';
import FormGenerator from '../../../../helpers/formGenerator';
import ModalGeneric from '../../../../components/Modal';
import cls from './modals.module.scss';
import { fieldActions } from '../../../../redux/actions/fieldGenerator';

const mapStateToProps = state => ({
    fieldRdx: {
        fields: state.fields,
    },
});

const mapDispatchToProps = dispatch => ({
    updateField: (data) => dispatch(fieldActions.updateField(data)),
});

class AddNewSchedule extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isBtnLoading: false
        }
    }

    onChangeWorkflow = (selectedVal) => {
        if (!selectedVal) {
            return false;
        }
        this.formRef.fieldGenRef().updateField({
            keyName: `[295].ClientAllowComment`,
            data: {
                ruleValueParsed: {
                    style: {
                        display: selectedVal.value === 3 ? 'block' : 'none',
                    }
                }
            }
        })
    }

    render() {
        const { isBtnLoading } = this.state;
        const fieldSettings = {
            bracketValues: {
                '[CustomerID]': localToObject('UserDetails').User.CustomerID
            },
            excluded: ["[295].Logo"],
            onSubmitSuccess: (response) => {
                window.location.href = `/schedules/${response.Data.MediaScheduleID}/`
            },
            onSubmitUnsuccess: (response) => {
                if (response.type) {
                    if (response.type === 1) {
                        gHelpers.notif('error', { msg: response.data.Status.Message });
                    }
                } else {
                    if (response.Status.Message.includes('Credential Vault was not set')) {
                        gHelpers.confirmDialog({
                            otherMainBodyProps: {
                                style: {
                                    left: '30%',
                                    right: '30%',
                                    maxWidth: '60%'
                                }
                            },
                            title: response.Status.Message,
                            desc: '',
                            submitBtn: {
                                text: gHelpers.getLabel({ key: 72926, fallback: 'Continue' }),
                                props: {
                                    success: true,
                                }
                            },
                            cancelBtn: {
                                text: gHelpers.getLabel({ key: 1373, fallback: `Cancel` }),
                            },
                            onSubmit: () => {
                                this.formRef.handleSubmit(null, true, true, {
                                    additionalParameters: {
                                        'Continue': 1
                                    }
                                })
                            }
                        });
                    } else {
                        gHelpers.notif('error', { msg: response.Status.Message });
                    }
                }
                this.setState({
                    isBtnLoading: false
                })
            },
            onSubmitFail: () => {
                gHelpers.notif('error', { msg: gHelpers.getLabel({ isString: true, key: 61351, fallback: 'An error occurred during the process.' }) });
                this.setState({
                    isBtnLoading: false
                })
            },
            hideSubmitBtn: true,
            overrideFields: {
                '[255].WorkflowID': {
                    help: {
                        title: gHelpers.getLabel({ key: 62239, fallback: 'About Workflow' }),
                        content: (
                            <div style={{
                                width: '900px',
                                maxWidth: '100%',
                                height: '500px',
                                marginBottom: '15px'
                            }}>
                                <iframe src="/resources/pdf/Socrates_Workflow.pdf" width="100%" height="100%" />
                            </div>
                        ),
                        type: 'dialog'
                    },
                    onSelectChange: (e) => {
                        this.onChangeWorkflow(e);
                    }
                },
                '[255].TimeZoneID': {
                    RuleValue: JSON.stringify({
                        'position': {
                            'row': 2,
                            'col': 1
                        },
                    })
                },
                '[265].AccountID': {
                    SelectFirstValue: true
                },
                '[265].VerticalID': {
                    SelectFirstValue: true,
                },
                '[295].ClientAllowComment': {
                    RuleValue: JSON.stringify({
                        style: {
                            display: 'none'
                        }
                    })
                }
            },
            additionalFields: {
                '[265].IsVertical': {
                    onChecked: {
                        'hideElements': ['[265].AccountID'],
                        'showElements': ['[265].VerticalID'],
                    }
                }
            }
        };

        return (
            <ModalGeneric {...this.props.modalProps}
                modalFooterComponent={(
                    <>
                        <UikButton
                            className={`mr-2`}
                            primary
                            onClick={() => {
                                this.setState({
                                    isBtnLoading: true
                                })
                                this.formRef.handleSubmit()
                            }}
                            isLoading={isBtnLoading}
                        >Create</UikButton>
                    </>
                )}>
                <div className={`${cls.modalMain}`}>
                    <FormGenerator
                        onRef={ref => (this.formRef = ref)}
                        module={`media_schedule_form`}
                        formFieldSettings={fieldSettings}
                    />
                </div>
            </ModalGeneric>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddNewSchedule));