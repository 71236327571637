import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { genericQuery, setCookie, refreshUserDetails } from '../../../helpers';
import {
    UikWidgetContent,
    UikWidget,
    UikInput,
    UikFormInputGroup,
    UikHeadline,
    UikHeadlineDesc,
    UikDivider,
    UikButton,
} from '../../../@uik';
import cls from './login.module.scss';

class Login extends Component {
    constructor(props) {
        super(props);

        document.title = 'Login';

        this.state = {
            isProcessing: false,
            EmailAddress: '',
            Password: '',
            messageError: '',
            messageInfo: '',
            isLoginSuccess: false
        }
        this.emailAddressChange = this.emailAddressChange.bind(this);
        this.passwordChange = this.passwordChange.bind(this);
        this.formSubmit = this.formSubmit.bind(this);

    }

    emailAddressChange(e) {
        this.setState({
            EmailAddress: e.target.value
        })
    }

    passwordChange(e) {
        this.setState({
            Password: e.target.value
        })
    }

    formSubmit(e) {
        e.preventDefault(); //prevents reload of page

        const { isProcessing } = this.state;
        // do not proceed if still processing
        if (isProcessing) {
            return false;
        }
        this.setState({
            isProcessing: true,
            messageError: '',
            messageInfo: '',
        });

        const formData = new FormData(e.target);

        const config = {
            Url: 'authentication/',
            Method: 'POST',
            Data: {
                requestjson: formData
            },
            ResponseSuccessCallback: function (responseLogin) {
                // do when success
                if (responseLogin.data.Status.IsSuccess) {
                    // if login success
                    localStorage.setItem('Token', responseLogin.data.Data.Token);
                    setCookie('IsLogin', responseLogin.data.Data.Token, 365);

                    // get user details
                    const passUserDetails = {
                        'Module': 'system_user_detail',
                        'Parameters': {
                            'UserID': responseLogin.data.Data.LoginID
                        }
                    };

                    const formDataUserDetails = new FormData();
                    formDataUserDetails.append('requestjson', JSON.stringify(passUserDetails));

                    genericQuery({
                        Url: 'single_api/',
                        Method: 'POST',
                        Data: {
                            requestjson: formDataUserDetails
                        },
                        ResponseSuccessCallback: function (responseUserDetails) {
                            if (responseUserDetails.data.Status.IsSuccess) {
                                localStorage.setItem('UserDetails', JSON.stringify({
                                    'User': responseUserDetails.data.Data.User,
                                    'UserDetails': responseUserDetails.data.Data.UserDetails,
                                    'CustomerDetails': responseUserDetails.data.Data.CustomerDetails
                                }));
                                localStorage.setItem('APP_VERSION', process.env.REACT_APP_VERSION);

                                this.setState({
                                    isLoginSuccess: true
                                });

                                refreshUserDetails({
                                    success: function () {
                                        window.location.reload();
                                    }.bind(this)
                                });
                                // window.location.reload();
                            }

                        }.bind(this),
                        ResponseFailCallback: function (responseUserDetails) {
                            // do when fail
                            this.setState({
                                messageError: 'There was an error. Please try again later.',
                                messageInfo: '',
                                isProcessing: false
                            });
                        }.bind(this),
                    });
                    // genericQuery({ Url: 'single_api/', ...

                } else {
                    // if login failed
                    this.setState({
                        messageError: responseLogin.data.Status.Message,
                        messageInfo: '',
                        isProcessing: false
                    });
                }
            }.bind(this),
            ResponseFailCallback: function (response) {
                // do when fail
                this.setState({
                    messageError: 'There was an error. Please try again later.',
                    messageInfo: '',
                    isProcessing: false
                });
            }.bind(this),
        }

        genericQuery(config);
    }

    render() {
        const { isProcessing, messageError, messageInfo } = this.state;

        return (
            <div className={cls.pageWrapper}>
                <UikWidget className={cls.widgetWrapper}>

                    <div className="content">
                        <form onSubmit={this.formSubmit}>
                            <UikWidgetContent className="left">
                                <UikHeadline>
                                    Log In Your Account
                                </UikHeadline>

                                <UikHeadlineDesc>
                                    Get started with Socrates and manage your social media accounts easily!
                                </UikHeadlineDesc>

                                <UikFormInputGroup>

                                    <UikInput
                                        label="Email Address"
                                        name="EmailAddress"
                                        placeholder="Enter your email address"
                                        value={this.state.EmailAddress}
                                        onChange={this.emailAddressChange}
                                    />

                                    <UikInput
                                        label="Password"
                                        name="Password"
                                        placeholder="·····"
                                        type="password"
                                        value={this.state.Password}
                                        onChange={this.passwordChange}
                                    />
                                    <p className="red">{messageError}</p>
                                    <p className="blue">{messageInfo}</p>

                                </UikFormInputGroup>

                                <UikDivider margin />

                                <UikButton
                                    type="submit"
                                    className="btnAction"
                                    success
                                    isLoading={isProcessing ? true : false}
                                >
                                    Log In
                                </UikButton>

                            </UikWidgetContent>

                        </form>
                    </div>
                </UikWidget>
                <p>
                    Don't have an account yet? Let us take you to
                        {' '}
                    <Link to='/register/'>
                        Sign Up!
                    </Link>
                </p>
            </div>
        )
    }
}

export default Login;