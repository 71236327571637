import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    UikAvatar,
    UikCheckbox,
    UikWidgetContent,
    UikNavLink,
    UikInput
} from '../../../@uik';
import ModalGeneric from '../../Modal';
import {
    FacebookIcon,
    InstagramIcon,
    LinkedinIcon,
    TwitterIcon,
    PinterestIcon,
    YoutubeIcon,
} from '../../../components/SocialIcons';
import { newOutingSelectedSocialMediaAccounts, newOutingSelectedSocialMediaAccountsPages } from '../../../redux/actions/mediaSchedule';
import cls from '../index.module.scss';
// import Select from 'react-select';

import { defaultPhoto as profileImg } from '../../../exampleAssets';
import { gHelpers } from 'helpers';

const mapStateToProps = state => ({
    selectedSocialMediaAccounts: state.newOutingSelectedSocialMediaAccounts,
    selectedSocialMediaAccountsPages: state.newOutingSelectedSocialMediaAccountsPages,
});
const mapDispatchToProps = dispatch => ({
    newOutingSelectedSocialMediaAccounts: (data) => dispatch(newOutingSelectedSocialMediaAccounts(data)),
    newOutingSelectedSocialMediaAccountsPages: (data) => dispatch(newOutingSelectedSocialMediaAccountsPages(data)),
});

const socialIcons = (type) => {
    let icon;

    switch (type) {
        case 'Facebook':
            icon = <FacebookIcon />;

            break;
        case 'Instagram':
            icon = <InstagramIcon />;

            break;
        case 'LinkedIn':
            icon = <LinkedinIcon />;

            break;
        case 'Twitter':
            icon = <TwitterIcon />;

            break;
        case 'Pinterest':
            icon = <PinterestIcon />;

            break;
        case 'Youtube':
            icon = <YoutubeIcon />;

            break;
    }

    return icon;
}
class ModalSelectSocialAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchKeyword: '',
            displayedItems: [],
            allItems: [],
            compUpdated: false,
        }

        this.handleOutingSelection = this.handleOutingSelection.bind(this);
        this.onSelectPage = this.onSelectPage.bind(this);
        this.onSelectItems = this.onSelectItems.bind(this);
        this.onFilterItems = this.onFilterItems.bind(this);
    }

    onFilterItems(args) {
        if (args.searchKeyword) {
            this.setState({
                displayedItems: this.state.allItems.filter(item => item.name.toLowerCase().includes(args.searchKeyword.toLowerCase()))
            })
        } else {
            this.setState({
                displayedItems: this.state.allItems
            })
        }
    }

    onSelectItems(args) {
        const { selectSocialMediaAccountsByType, selectedSocialMediaAccountsPages, selectedSocialMediaAccounts } = this.props;

        if (args.action === 'select-all' || args.action === 'unselect-all') {

            selectSocialMediaAccountsByType.forEach(social => {
                if (social.IsActive) {

                    // main
                    if (selectedSocialMediaAccounts.includes(social.SocialMediaAccountID) === false && args.action === 'select-all') {
                        this.props.newOutingSelectedSocialMediaAccounts(social.SocialMediaAccountID)
                    } else if (selectedSocialMediaAccounts.includes(social.SocialMediaAccountID) && args.action === 'unselect-all') {
                        this.props.newOutingSelectedSocialMediaAccounts(social.SocialMediaAccountID)
                    }

                    // pages
                    if (social.Pages && social.Pages.length) {

                        social.Pages.forEach(p => {
                            if (selectedSocialMediaAccountsPages.includes(p.SocialMediaAccountPageID) === false && args.action === 'select-all') {
                                this.onSelectPage(p.SocialMediaAccountPageID)
                            } else if (selectedSocialMediaAccountsPages.includes(p.SocialMediaAccountPageID) && args.action === 'unselect-all') {
                                this.onSelectPage(p.SocialMediaAccountPageID)
                            }
                        })

                    }

                }

            })
        }
    }

    onSelectPage(SocialMediaAccountPageID) {
        this.props.newOutingSelectedSocialMediaAccountsPages(SocialMediaAccountPageID);

        this.setState({
            compUpdated: false
        });
    }

    handleOutingSelection(SocialMediaAccountID) {
        this.props.newOutingSelectedSocialMediaAccounts(SocialMediaAccountID)
    }

    componentDidUpdate(prevProps) {
        const { compUpdated } = this.state;
        const { selectedSocialMediaAccountsPages, selectedSocialMediaAccounts, selectSocialMediaAccountsByType } = this.props;

        if (!compUpdated
            && prevProps.selectedSocialMediaAccountsPages.length !== selectedSocialMediaAccountsPages) {
            this.setState({
                compUpdated: true
            }, () => {

                selectSocialMediaAccountsByType.forEach(social => {
                    if (social.Pages && social.Pages.length) {
                        // check if pages and main social media accounts is connected
                        if (
                            social.Pages.some(sp => selectedSocialMediaAccountsPages.some(SocialMediaAccountPageID => SocialMediaAccountPageID === sp.SocialMediaAccountPageID))
                        ) {
                            // if social media account does not exist then add
                            if (!selectedSocialMediaAccounts.includes(social.SocialMediaAccountID)) {
                                this.props.newOutingSelectedSocialMediaAccounts(social.SocialMediaAccountID)
                            }
                        } else {
                            // if no pages exist and social media account does exist then remove
                            if (selectedSocialMediaAccounts.includes(social.SocialMediaAccountID)) {
                                this.props.newOutingSelectedSocialMediaAccounts(social.SocialMediaAccountID)
                            }
                        }
                    }
                })

            })
        }
    }

    componentDidMount() {

        if (this.props.selectSocialMediaAccountsByType && this.props.selectSocialMediaAccountsByType.length) {
            const { selectSocialMediaAccountsByType } = this.props;
            let allItems = [];
            let profImage = profileImg;
            // add here for main accounts

            // pages/organizations/boards
            selectSocialMediaAccountsByType.forEach(social => {
                if (social.SocialMediaAccountConfigurationData.profile_image) {
                    profImage = social.SocialMediaAccountConfigurationData.profile_image;
                }

                switch (social.SocialMediaTypeName) {
                    case 'LinkedIn':
                    case 'Twitter':
                    case 'Youtube':

                        allItems.push({
                            SocialMediaAccountID: social.SocialMediaAccountID,
                            type: `main`,
                            name: social.SocialMediaAccountConfigurationData.name,
                            textBottom: null,
                            image: profImage,
                            disabled: !social.IsActive
                        })
                        break;
                }

                if (social.Pages && social.Pages.length) {

                    social.Pages.forEach(p => {
                        let textBottom = null;
                        let name = p.Name;
                        if (p.Configuration && p.Configuration.profile_image) {
                            profImage = p.Configuration.profile_image;
                        }

                        if (social.SocialMediaTypeName === 'LinkedIn') {
                            name = `Organization: ${p.Name}`;
                        } else if (social.SocialMediaTypeName === 'Pinterest') {
                            name = `Board: ${p.Name}`;
                            textBottom = social.SocialMediaAccountConfigurationData.name
                        } else if (social.SocialMediaTypeName === 'Instagram') {
                            if (p.Configuration.instagram_business_account && p.Configuration.instagram_business_account.profile_image) {
                                profImage = p.Configuration.instagram_business_account.profile_image;
                            }
                        }

                        allItems.push({
                            SocialMediaAccountID: social.SocialMediaAccountID,
                            SocialMediaAccountPageID: p.SocialMediaAccountPageID,
                            type: `page`,
                            name: name,
                            textBottom: textBottom,
                            image: profImage,
                            disabled: !social.IsActive
                        })
                    })

                }

            })
            // selectSocialMediaAccountsByType.forEach


            this.setState({
                allItems: allItems,
                displayedItems: allItems
            })

        }
    }

    render() {
        const { displayedItems } = this.state;
        const { selectedSocialMediaAccounts, selectedSocialMediaAccountsPages } = this.props;

        let modalProps = this.props.modalProps;
        modalProps.customStyles = {
            content: {
                left: "0px",
                right: "40px",
                width: "600px",
                maxWidth: "100%",
                padding: 0,
                borderRadius: '10px',
                height: '90vh',
                position: "relative",
                top: 0,
                border: '1px solid rgb(204, 204, 204)',
            }
        }

        return (
            <ModalGeneric  {...modalProps}  >
                <div className={cls.socialAccountsWrapper}>
                    <div className={cls.modalHeaderText}>
                        <h4>{socialIcons(this.props.selectedType)} {this.props.selectedType}</h4>
                    </div>
                    <UikWidgetContent className={`p-0 ${cls.navListSection2} showScrollbar`}>
                        <div className={cls.searchBar}>
                            <div className={`row no-gutters align-items-center`}>
                                <div className={`col-sm-12 col-lg-9 field-gen`}>
                                    <UikInput
                                        className={cls.searchBarMargin}
                                        placeholder="Search"
                                        onChange={(e) => {
                                            this.onFilterItems({
                                                searchKeyword: e.target.value
                                            })
                                        }}
                                    />
                                </div>
                                <div className={`col-sm-12 col-lg-3 text-center ${cls.selectOption}`}>
                                    <a className={cls.selAll} href={"#"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.onSelectItems({
                                                action: 'unselect-all'
                                            })
                                        }}>
                                        {gHelpers.getLabel({ key: 61165, fallback: 'Unselect All' })}
                                    </a>
                                    <span className={`ml-1 mr-1`}>|</span>
                                    <a className={cls.selAll} href={"#"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.onSelectItems({
                                                action: 'select-all'
                                            })
                                        }}>
                                        {gHelpers.getLabel({ key: 61171, fallback: 'Select All' })}
                                    </a>
                                </div>
                            </div>
                        </div>
                        {
                            displayedItems.map((item, idx) => {
                                let isChecked = false;

                                if (item.type === 'page') {
                                    if (selectedSocialMediaAccountsPages.includes(item.SocialMediaAccountPageID)) {
                                        isChecked = true;
                                    }
                                } else if (item.type == 'main') {
                                    if (selectedSocialMediaAccounts.includes(item.SocialMediaAccountID)) {
                                        isChecked = true;
                                    }
                                }

                                return (
                                    <UikNavLink
                                        key={`${idx}-`}
                                        Component={'label'}
                                        className={`navLink4 navLinkSm ${cls.navLinkPage}`}
                                        onClick={() => {
                                            if (item.disabled) {
                                                gHelpers.notif('error', { title: `Expired token!`, msg: `Social media token has expired. Please reconnect your account.` });
                                            }
                                        }}
                                        rightEl={(
                                            <UikCheckbox
                                                onChange={() => {
                                                    if (item.type === 'page') {
                                                        this.onSelectPage(item.SocialMediaAccountPageID)

                                                    } else if (item.type === 'main') {
                                                        this.handleOutingSelection(item.SocialMediaAccountID)

                                                    }
                                                }}
                                                checked={isChecked}
                                                disabled={item.disabled}
                                            />)
                                        }>
                                        <UikAvatar
                                            className={`avatarRoundCorners`}
                                            imgUrl={item.image}
                                            name={(
                                                <span className={cls.iconText}>
                                                    {item.name}
                                                </span>
                                            )}
                                            textBottom={item.textBottom}
                                        />
                                    </UikNavLink>
                                )
                            })
                        }
                    </UikWidgetContent>
                </div>
            </ModalGeneric>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalSelectSocialAccount);
