import React, { Component } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import cls from './notification.module.scss';
import { callApi, gHelpers, IsJSON, getLabel } from '../../helpers';

const notifIcons = [
    {
        subject: 'Schedule Assignment',
        icon: 'fa-user-plus',
        color: 'green',
        TypeID: 1
    },
    {
        subject: 'Media Outing Assignment',
        icon: 'fa-calendar-plus',
        color: 'green'
    },
    {
        subject: 'Media Outing Comment Approval',
        icon: 'fa-comment',
        color: 'blue'
    },
    {
        subject: 'Media Outing Comment Mention',
        icon: 'fa-comments',
        color: 'green',
        TypeID: 4
    },
    {
        subject: 'Media Schedule Post Comment Mention',
        icon: 'fa-comments',
        color: 'green'
    },
    {
        subject: 'New Media Outing Comment',
        icon: 'fa-comment-dots',
        color: 'blue'
    },
    {
        subject: 'New Media Schedule Post Comment',
        icon: 'fa-comment-dots',
        color: 'blue'
    },
    {
        subject: 'Media Outing Posting Success',
        icon: 'fa-calendar-check',
        color: 'green'
    },
    {
        subject: 'Media Outing Posting Failed',
        icon: 'fa-calendar-times',
        color: 'red'
    },
    {
        subject: 'Facebook Boosted Failed',
        icon: 'fa-calendar-times',
        color: 'red',
        TypeID: 20006
    },
    {
        subject: 'New Media Outing was Created!',
        icon: 'fa-thumbtack',
        color: 'blue',
        TypeID: 10
    },
]

class Notification extends Component {
    constructor(props) {
        super(props);

        this.handleClickLink = this.handleClickLink.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);
    }

    handleClickLink(e, href) {
        e.preventDefault();

        let passObject = {
            'Module': 'system_notification_seen',
            'Parameters': {
                'NotificationID': this.props.notification.NotificationID
            }
        }

        callApi({
            passObject: passObject,
            success: (response) => {
                // console.log(response)
                window.open(
                    href,
                    '_blank'
                );
                // window.location.href = href;
            },
            unSuccess: (response) => {
                console.log(response);
            }
        });

    }

    handleOnClick(e, notification) {
        e.preventDefault();
        if(notification.Type == 'Facebook Boosted Failed') {
            gHelpers.confirmDialog({
                title: <span><i className={`fas fa-exclamation-triangle red mr-1`} />{notification.Type}</span>,
                desc: '',
                content: (
                    <div style={{
                        maxHeight: '50vh',
                        overflowY: 'auto'
                    }}>
                        {/* {
                            Object.keys(response.data.Errors).map(SocialMediaAccountID => {
                                const error = response.data.Errors[SocialMediaAccountID].error;
                                const smaConfig = SocialMediaAccounts.find(x => x.SocialMediaAccountID == SocialMediaAccountID);

                                return (
                                    <div key={SocialMediaAccountID} className={cls.errorPanel}>
                                        <ul>
                                            <li>Account Name: {defaultAccountAddresses[smaConfig.AccountID].acccountName}</li>
                                            <li>Error: {error.error_user_title ? error.error_user_title : error.message}</li>
                                            <li>Message: {error.error_user_msg}</li>
                                        </ul>
                                    </div>
                                )
                            })
                        } */}
                    </div>
                ),
                cancelBtn: {
                    text: getLabel({ isString: true,  key: 1369, fallback: `Close` })
                }
            }); // gHelpers.confirmDialog({
        } else {
            this.handleClickLink(e, notification.URL)
        }
    }

    render() {
        let { notification, size } = this.props;
        let icon = notifIcons.find(x => x.subject == notification.Type);
        
        if(!icon) {
            icon = {
                icon: 'fa-bell',
                color: 'blue',
            }
        }

        if(notification.Type == 'Facebook Boosted Failed') {
            const msg = IsJSON(notification.Message) ? JSON.parse(notification.Message) : null;
            if(msg) {
                notification.Message = `<i class="fas fa-times red"></i> ${msg.error.error_user_title ? msg.error.error_user_title : msg.error.message} <br> <i class="fas fa-times red"></i> ${msg.error.error_user_msg}`;
            }
        }

        return (
            <a 
                onClick={(e) => this.handleClickLink(e, notification.URL)}
                className={`${cls.notificationItem} ${size && size == 'big' ? cls.notificationItemBig : ''} ${!notification.IsSeen ? cls.highlighted : ''}`} 
                href={notification.URL}>
                {
                    <div
                        className={cls.notificationThumbSm}
                    >
                        <i className={`${cls.icon} ${icon.color} fas ${icon.icon}`} />
                    </div>
                }
                <div className={cls.notificationText}>
                    <p dangerouslySetInnerHTML={{ __html: `<strong>${notification.Type}</strong>` }} />
                    <p dangerouslySetInnerHTML={{ __html: notification.Message }} />
                    <p className={cls.notificationTimestamp}><em><Moment fromNow>{notification.DT_Created}</Moment></em></p>
                </div>
            </a>
        )
    }
}

export default Notification;