import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UikButtonGroup, UikButton } from '../../@uik';
import smTemplate from '../../assets/json/socialMediaTemplate.json';
import { updateScheduledSMTypes } from 'redux/actions/mediaSchedule';
import {
    newOutingSelectedSocialMediaAccounts,
    newOutingRemoveSocialMediaAccounts,
    newOutingRemoveSingleSocialMediaAccount,
    newOutingSelectedSocialMediaAccountsPages,
    newOutingRemoveSocialMediaAccountsPages,
    newOutingRemoveSocialMediaAccountsPage
} from '../../redux/actions/mediaSchedule';
import { gHelpers, genericQuery, loading, callApi, formatBytes, toSeconds } from '../../helpers';
import {
    FacebookIcon,
    InstagramIcon,
    LinkedinIcon,
    TwitterIcon,
    PinterestIcon,
    YoutubeIcon,
} from '../../components/SocialIcons';
import ModalSelectSocialAccount from './modals/modalSelectAccounts';
import cls from './index.module.scss';

const mapStateToProps = state => ({
    selectedSocialMediaAccounts: state.newOutingSelectedSocialMediaAccounts,
    selectedFiles: state.selectedMediaFiles,
    // listMediaFilesMetaData: state.listMediaFilesMetaData,
    fieldRdx: {
        fields: state.fields,
    },
    scheduledSMTypes: state.scheduledSMTypes
});
const mapDispatchToProps = dispatch => ({
    newOutingSelectedSocialMediaAccounts: (data) => dispatch(newOutingSelectedSocialMediaAccounts(data)),
    newOutingRemoveSocialMediaAccounts: (data) => dispatch(newOutingRemoveSocialMediaAccounts(data)),
    newOutingRemoveSocialMediaAccountsPages: (data) => dispatch(newOutingRemoveSocialMediaAccountsPages(data)),
    newOutingRemoveSocialMediaAccountsPage: (data) => dispatch(newOutingRemoveSocialMediaAccountsPage(data)),
    newOutingRemoveSingleSocialMediaAccount: (data) => dispatch(newOutingRemoveSingleSocialMediaAccount(data)),
    newOutingSelectedSocialMediaAccountsPages: (data) => dispatch(newOutingSelectedSocialMediaAccountsPages(data)),
    updateScheduledSMTypes: (data) => dispatch(updateScheduledSMTypes(data)),
});

const socialIcons = (type, connected) => {
    let icon = null;
    let props = {};
    // check if connected
    if (!connected) {
        props = {
            className: 'lighter-gray'
        }
    }

    switch (type) {
        case 'Facebook':
            icon = <FacebookIcon {...props} />;

            break;
        case 'Instagram':
            icon = <InstagramIcon {...props} />;

            break;
        case 'LinkedIn':
            icon = <LinkedinIcon {...props} />;

            break;
        case 'Twitter':
            icon = <TwitterIcon {...props} />;

            break;
        case 'Pinterest':
            icon = <PinterestIcon {...props} />;

            break;
        case 'Youtube':
            icon = <YoutubeIcon {...props} />;

            break;
    }

    return icon;
}
class OutingPostIcons extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalSelectSocialMediaAccountOpen: false,
            socialMediaAccountsList: {},
            selectSocialMediaAccountsByType: [],
            selectedType: null,
            loading: false,
            compUpdated: false,
            restrictedSMs: [],
            selectedFiles: null
        }

        this.loadAccounts = this.loadAccounts.bind(this);
        this.checkAccountRestrictions = this.checkAccountRestrictions.bind(this);
        this.getAccountRestrictions = this.getAccountRestrictions.bind(this);
        this.getState = this.getState.bind(this);
        this.resetSelected = this.resetSelected.bind(this);
    }

    resetSelected() {
        this.setState({
            isModalSelectSocialMediaAccountOpen: false,
            socialMediaAccountsList: {},
            selectSocialMediaAccountsByType: [],
            selectedType: null,
            loading: false,
            compUpdated: false,
            restrictedSMs: [],
            selectedFiles: null
        });
        this.props.newOutingRemoveSocialMediaAccounts();
        this.props.newOutingRemoveSocialMediaAccountsPages();
        this.props.updateScheduledSMTypes([])
    }

    componentWillUnmount() {
        this.resetSelected()
    }

    loadAccounts() {
        this.setState({ loading: true, compUpdated: false })
        this.props.newOutingRemoveSocialMediaAccounts();
        this.props.newOutingRemoveSocialMediaAccountsPages();

        if (!this.props.isVertical && this.props.accountID) {
            // account
            let passObject = {
                'Module': 'socialmedia_account_list',
                'Parameters': {
                    'AccountIDs': [this.props.accountID,]
                }
            }
            let formDataObject = new FormData();
            formDataObject.append('requestjson', JSON.stringify(passObject));
            genericQuery({
                Url: 'filtered_single_api/',
                Method: 'POST',
                Data: {
                    requestjson: formDataObject
                },
                ResponseSuccessCallback: function (response) {
                    if (response.data.Status.IsSuccess) {

                        let socialMediaAccounts = [];

                        if (response.data.Data.Accounts && response.data.Data.Accounts.length) {
                            socialMediaAccounts = response.data.Data.Accounts[0].SocialMediaAccounts;
                            let socialMediaAccountsObj = {};

                            // init array
                            socialMediaAccounts.forEach(sm => {
                                socialMediaAccountsObj[sm.SocialMediaTypeName] = [];
                            });

                            // push social media accounts to their respected type
                            socialMediaAccounts.forEach(sm => {
                                socialMediaAccountsObj[sm.SocialMediaTypeName] = [
                                    ...socialMediaAccountsObj[sm.SocialMediaTypeName],
                                    sm
                                ];
                                if (sm.IsActive) {
                                    this.props.newOutingSelectedSocialMediaAccounts(sm.SocialMediaAccountID)

                                    // connect pages
                                    if (sm.Pages && sm.Pages.length) {
                                        sm.Pages.forEach(p => {
                                            this.props.newOutingSelectedSocialMediaAccountsPages(p.SocialMediaAccountPageID)
                                        })
                                    }
                                }
                            });

                            this.setState({
                                socialMediaAccountsList: socialMediaAccountsObj,
                                compUpdated: false,
                                loading: false
                            }, () => this.checkAccountRestrictions());
                        }

                    } else {
                        gHelpers.notif('error', { msg: response.data.Status.Message });
                    }
                }.bind(this),
                ResponseFailCallback: function () {

                    this.setState({
                        compUpdated: false,
                        loading: false
                    });
                }.bind(this),
            });

        } else {
            // vertical

            if (this.props.VerticalGroupID) {
                // if vertical group

                let passObject = {
                    'Module': 'socialmedia_vertical_list',
                    'Parameters': {
                        'VerticalGroupID': this.props.VerticalGroupID,
                    }
                }

                callApi({
                    passObject: passObject,
                    success: (response) => {
                        if (response.Data.VerticalSocialMedias) {
                            let socialMediaAccounts = response.Data.VerticalSocialMedias;
                            let socialMediaAccountsObj = {};

                            // init array
                            socialMediaAccounts.forEach(sm => {
                                socialMediaAccountsObj[sm.Name] = [];
                            });

                            // push social media accounts to their respected type
                            socialMediaAccounts.forEach(sm => {
                                socialMediaAccountsObj[sm.Name] = [
                                    ...socialMediaAccountsObj[sm.Name],
                                    sm
                                ];

                                this.props.newOutingSelectedSocialMediaAccounts(sm.SocialMediaTypeID)
                            });

                            this.setState({
                                compUpdated: false,
                                socialMediaAccountsList: socialMediaAccountsObj,
                                loading: false
                            });
                            this.checkAccountRestrictions()
                        } // if (response.Data.SocialMediaAccounts)
                    },
                    unSuccess: (response) => {
                        this.setState({
                            compUpdated: false,
                            loading: false
                        });
                    }
                });

            } else {
                // if vertical

                let passObject = {
                    'Module': 'media_quickpost_form',
                    'Parameters': {
                        'MediaScheduleID': this.props.MediaScheduleID,
                    }
                }
                callApi({
                    passObject: passObject,
                    success: (response) => {
                        if (response.Data.SocialMediaTypes) {
                            let socialMediaAccounts = response.Data.SocialMediaTypes;
                            let socialMediaAccountsObj = {};

                            // init array
                            socialMediaAccounts.forEach(sm => {
                                socialMediaAccountsObj[sm.SocialMediaTypeName] = [];
                            });

                            // push social media accounts to their respected type
                            socialMediaAccounts.forEach(sm => {
                                socialMediaAccountsObj[sm.SocialMediaTypeName] = [
                                    ...socialMediaAccountsObj[sm.SocialMediaTypeName],
                                    sm
                                ];

                                this.props.newOutingSelectedSocialMediaAccounts(sm.SocialMediaTypeID);
                            });

                            this.setState({
                                compUpdated: false,
                                socialMediaAccountsList: socialMediaAccountsObj,
                                loading: false
                            });
                            this.checkAccountRestrictions()
                        } // if (response.Data.SocialMediaAccounts)
                    },
                    unSuccess: (response) => {
                        this.setState({
                            compUpdated: false,
                            loading: false
                        });
                    }
                });
            }

        }
    }

    checkAccountRestrictions(args) {
        let { socialMediaAccountsList } = this.state;
        let previewLayout = smTemplate;
        // const fileMetaData = this.props.listMediaFilesMetaData;

        this.setState(prevState => ({
            compUpdated: false,
            restrictedSMs: args && args.reset ? [] : prevState.restrictedSMs
        }), () => {

            if (args && args.SocialMediaTypeName) {
                socialMediaAccountsList = {
                    [args.SocialMediaTypeName]: socialMediaAccountsList[args.SocialMediaTypeName]
                }
            }

            if (Object.entries(socialMediaAccountsList).length) {

                let restrictedSMsCopy = [];
                let connectedErrors = 0;
                Object.entries(socialMediaAccountsList).forEach((val) => {

                    const key = val[0];
                    const socialMediaAccounts = val[1];
                    const template = previewLayout[key] ? previewLayout[key] : null;

                    if (template && template.restrictions) {

                        let allowed = true;
                        let notAllowed = {};

                        // check if active
                        /*  if (!socialMediaAccounts.IsActive) {
                             allowed = false;
                             notAllowed = {
                                 Name: key,
                                 Message: `Social media token has expired. Please reconnect your account.`,
                                 Type: 'text'
                             }
                         } */

                        // check file restrictions
                        if (template.restrictions.fileTypesAllowed && this.props.MediaFileFieldKey && allowed) {
                            if (this.props.selectedFiles[this.props.MediaFileFieldKey].length) {

                                // check allowed file types
                                allowed = this.props.selectedFiles[this.props.MediaFileFieldKey].every(x => {
                                    return template.restrictions.fileTypesAllowed.includes(x.Type)
                                });
                                if (!allowed) {
                                    notAllowed = {
                                        Name: key,
                                        Message: `${gHelpers.getLabel({ isString: true, isString: true, key: 61051, fallback: `Allowed file types(s)` })}: ${template.restrictions.fileTypesAllowed.join(" or ")}`,
                                        Type: 'file'
                                    }
                                } else {

                                    // check if has allowed file extension
                                    if (template.restrictions.fileMimeTypeAllowed) {
                                        allowed = this.props.selectedFiles[this.props.MediaFileFieldKey].every(f => template.restrictions.fileMimeTypeAllowed.includes(f.MimeType));

                                        if (!allowed) {
                                            notAllowed = {
                                                Name: key,
                                                Message: `${gHelpers.getLabel({ isString: true, key: 72878, fallback: `File Extensions Allowed:` })} ${template.restrictions.fileMimeTypeAllowed.join(", ")}`,
                                                Type: 'file'
                                            }
                                        }
                                    }

                                    // check video min/max duration
                                    if (allowed && (template.restrictions.videoMinDuration || template.restrictions.videoMaxDuration)
                                        && this.props.selectedFiles[this.props.MediaFileFieldKey].some(f => f.Type == 'Video')) {

                                        // check min
                                        allowed = this.props.selectedFiles[this.props.MediaFileFieldKey]
                                            .filter(f => f.Type == 'Video')
                                            .every(v => toSeconds(v.Duration) > template.restrictions.videoMinDuration)

                                        if (!allowed) {
                                            notAllowed = {
                                                Name: key,
                                                Message: `${gHelpers.getLabel({ isString: true, key: 61039, fallback: `Video duration must be more than` })} ${template.restrictions.videoMinDuration} seconds`,
                                                Type: 'file'
                                            }
                                        } else {

                                            // check max
                                            allowed = this.props.selectedFiles[this.props.MediaFileFieldKey]
                                                .filter(f => f.Type == 'Video')
                                                .every(v => toSeconds(v.Duration) < template.restrictions.videoMaxDuration)
                                            if (!allowed) {
                                                notAllowed = {
                                                    Name: key,
                                                    Message: `${gHelpers.getLabel({ isString: true, key: 61045, fallback: `Video duration must be equal or less than` })} ${template.restrictions.videoMaxDuration} seconds`,
                                                    Type: 'file'
                                                }
                                            }

                                        }

                                        /* this.props.selectedFiles[this.props.MediaFileFieldKey].forEach(f => {
                                            if (allowed) {
                                                // check minimum duration
                                                if (template.restrictions.videoMinDuration
                                                    && fileMetaData[f.MediaFileID]
                                                    && Math.floor(fileMetaData[f.MediaFileID].duration) < template.restrictions.videoMinDuration) {
                                                    allowed = false;
                                                    notAllowed = {
                                                        Name: key,
                                                        Message: `${gHelpers.getLabel({ isString: true, key: 61039, fallback: `Video duration must be more than` })} ${template.restrictions.videoMinDuration} seconds`,
                                                        Type: 'file'
                                                    }
                                                }

                                                // check maximum duration
                                                if (template.restrictions.videoMaxDuration
                                                    && fileMetaData[f.MediaFileID]
                                                    && Math.floor(fileMetaData[f.MediaFileID].duration) > template.restrictions.videoMaxDuration) {
                                                    allowed = false;
                                                    notAllowed = {
                                                        Name: key,
                                                        Message: `${gHelpers.getLabel({ isString: true, key: 61045, fallback: `Video duration must be equal or less than` })} ${template.restrictions.videoMaxDuration} seconds`,
                                                        Type: 'file'
                                                    }
                                                }
                                            }
                                        }) */
                                    }

                                    // check number of files allowed total
                                    if (allowed && template.restrictions.maxFiles) {
                                        const filesCount = this.props.selectedFiles[this.props.MediaFileFieldKey].length;
                                        allowed = filesCount <= template.restrictions.maxFiles;
                                        if (!allowed) {
                                            notAllowed = {
                                                Name: key,
                                                Message: `${gHelpers.getLabel({ isString: true, key: 61057, fallback: `Maximum number of files allowed:` })} ${template.restrictions.maxFiles}`,
                                                Type: 'file'
                                            }
                                        }
                                    }

                                    Object.keys(template.restrictions.fileTypes).forEach((key2) => {
                                        const fType = template.restrictions.fileTypes[key2];
                                        if (allowed) {

                                            // check number of files allowed
                                            if (fType.maxFiles) {
                                                const filesCount = this.props.selectedFiles[this.props.MediaFileFieldKey].filter(x => x.Type == key2).length;
                                                allowed = filesCount <= fType.maxFiles;
                                                if (!allowed) {
                                                    notAllowed = {
                                                        Name: key,
                                                        Message: `${gHelpers.getLabel({ isString: true, key: 61063, fallback: `Maximum number of` })} ${key2} ${gHelpers.getLabel({ isString: true, key: 61069, fallback: `files allowed:` })} ${fType.maxFiles}`,
                                                        Type: 'file'
                                                    }
                                                }
                                            }

                                            // check file size
                                            if (allowed && fType.maxSize) {
                                                allowed = this.props.selectedFiles[this.props.MediaFileFieldKey].every(x => x.Size <= fType.maxSize);
                                                if (!allowed) {
                                                    notAllowed = {
                                                        Name: key,
                                                        Message: `${gHelpers.getLabel({ isString: true, key: 61075, fallback: `File size must not exceed` })} ${formatBytes(fType.maxSize)}`,
                                                        Type: 'file'
                                                    }
                                                }
                                            }

                                        }
                                    })

                                }

                            } else {
                                if (template.restrictions.isFileRequired) {
                                    allowed = false;
                                    notAllowed = {
                                        Name: key,
                                        Message: `${gHelpers.getLabel({ isString: true, key: 61081, fallback: `Please select file(s):` })} ${template.restrictions.fileTypesAllowed.join(" or ")}`,
                                        Type: 'file'
                                    }
                                }
                            }
                        }
                        // check file restrictions end

                        // check text content restrictions
                        if (this.props.fieldRdx.fields && this.props.fieldRdx.fields['[270].Content']) {
                            const maxChars = template.restrictions.text.Content.maxCharacters;
                            if (this.props.fieldRdx.fields['[270].Content'].newValue.length > template.restrictions.text.Content.maxCharacters) {
                                allowed = false;
                                notAllowed = {
                                    Name: key,
                                    Message: `${gHelpers.getLabel({ isString: true, key: 61147, fallback: `Text characters must not exceed` })} ${maxChars}`,
                                    Type: 'text'
                                }
                            }
                        }
                        // check text content restrictions end

                        if (!allowed) {
                            restrictedSMsCopy = [
                                ...restrictedSMsCopy,
                                notAllowed
                            ]

                            this.setState(prevState => ({
                                restrictedSMs: [
                                    ...prevState.restrictedSMs,
                                    notAllowed
                                ]
                            }))

                            // alert errors only for connected
                            if (args && args.showErrors && args.showErrors === notAllowed.Type) {
                                let showError = false;
                                if (!this.props.isVertical) {
                                    showError = socialMediaAccounts.some(x => this.props.selectedSocialMediaAccounts.includes(x.SocialMediaAccountID))
                                } else {
                                    showError = socialMediaAccounts.some(x => this.props.selectedSocialMediaAccounts.includes(x.SocialMediaTypeID))
                                }

                                if (showError) {
                                    connectedErrors += 1;
                                    gHelpers.notif('error', { title: `${notAllowed.Name} error!`, msg: notAllowed.Message });
                                }
                            }
                        } else {
                            restrictedSMsCopy = restrictedSMsCopy.filter(x => x.Name !== key)
                            this.setState(prevState => ({
                                restrictedSMs: prevState.restrictedSMs.filter(x => x.Name !== key)
                            }))
                        }



                        socialMediaAccounts.forEach(sm => {
                            if (!allowed) {
                                // remove
                                if (!this.props.isVertical) {
                                    this.props.newOutingRemoveSingleSocialMediaAccount(sm.SocialMediaAccountID);
                                    if (sm.Pages && sm.Pages.length) {
                                        sm.Pages.forEach(p => this.props.newOutingRemoveSocialMediaAccountsPage(p.SocialMediaAccountPageID));
                                    }
                                } else {
                                    this.props.newOutingRemoveSingleSocialMediaAccount(sm.SocialMediaTypeID)
                                }
                            }
                        });
                    } // if (template.restrictions)

                }); // Object.entries(socialMediaAccountsList).forEach

                if (args && args.cb) {
                    args.cb(restrictedSMsCopy)
                }

                // show 
                if (args && args.cb2) {
                    args.cb2(connectedErrors)
                }

            }

        });
    }

    getState(key) {
        if (this.state[key]) {
            return this.state[key];
        }
    }

    getAccountRestrictions() {
        return this.state.restrictedSMs;
    }

    componentDidMount() {

        if (this.props.onRef) {
            this.props.onRef(this)
        }

        if (this.props.MediaScheduleID) {
            this.loadAccounts();

            this.setState({
                selectedFiles: this.props.fieldRdx.fields[this.props.MediaFileFieldKey].newValue
            })
        }
    }

    componentDidUpdate(prevProps) {
        let checkRCalled = false;

        // if account
        if (
            (!this.state.compUpdated && prevProps.MediaScheduleID !== this.props.MediaScheduleID) ||
            (!this.state.compUpdated && prevProps.VerticalGroupID !== this.props.VerticalGroupID)
        ) {
            // console.log(`updated 3`)
            this.setState({ compUpdated: true }, () => this.loadAccounts());
        }

        if (!this.state.compUpdated
            && this.props.MediaFileFieldKey
            && this.props.fieldRdx.fields[this.props.MediaFileFieldKey]
            && this.state.selectedFiles !== this.props.fieldRdx.fields[this.props.MediaFileFieldKey].newValue) {
            checkRCalled = true;
            // console.log(`updated 1`)
            this.setState({ compUpdated: true, selectedFiles: this.props.fieldRdx.fields[this.props.MediaFileFieldKey].newValue }, () => this.checkAccountRestrictions({ reset: true }));
        }
        if (!this.state.compUpdated
            && !checkRCalled
            && prevProps.selectedSocialMediaAccounts.length !== this.props.selectedSocialMediaAccounts.length) {
            // console.log(`prevProps.selectedSocialMediaAccounts`, prevProps.selectedSocialMediaAccounts)
            // console.log(`this.props.selectedSocialMediaAccounts`, this.props.selectedSocialMediaAccounts)
            // console.log(`updated 2`)
            this.setState({ compUpdated: true }, () => this.checkAccountRestrictions({ reset: true }));
        }
    }

    render() {
        const { socialMediaAccountsList, isModalSelectSocialMediaAccountOpen, selectSocialMediaAccountsByType, restrictedSMs } = this.state;
        const { selectedSocialMediaAccounts } = this.props;
        let modals = [];
        let socialBtns = [];

        if (!this.props.isVertical) {
            // account

            if (Object.entries(socialMediaAccountsList).length) {
                Object.entries(socialMediaAccountsList).forEach((val, idx) => {
                    const key = val[0];
                    const socialMediaAccounts = val[1];
                    let connected = false;

                    if (socialMediaAccounts.some(sm => selectedSocialMediaAccounts.includes(sm.SocialMediaAccountID))) {
                        connected = true;
                    }

                    socialBtns.push(
                        <UikButton
                            className={cls.btnPost}
                            key={idx}
                            onClick={
                                () => {

                                    this.checkAccountRestrictions({
                                        SocialMediaTypeName: key,
                                        cb: (errors) => {
                                            if (errors.length) {
                                                errors.forEach(error => {
                                                    gHelpers.notif('error', { title: `${key} error!`, msg: error.Message });
                                                })
                                            } else {
                                                this.setState({
                                                    selectSocialMediaAccountsByType: socialMediaAccounts,
                                                    isModalSelectSocialMediaAccountOpen: true,
                                                    selectedType: key
                                                })

                                            }
                                        }
                                    })

                                }
                            }
                            clear
                            icon={
                                <>
                                    {socialIcons(key, connected)}
                                </>
                            }
                            iconOnly
                        />
                    )
                });
            }
        } else {
            // vertical

            if (Object.entries(socialMediaAccountsList).length) {
                Object.entries(socialMediaAccountsList).forEach((val, idx) => {
                    const key = val[0];
                    const socialMediaAccounts = val[1];

                    let connected = false;
                    if (socialMediaAccounts.some(sm => selectedSocialMediaAccounts.includes(sm.SocialMediaTypeID))) {
                        connected = true;
                    }

                    socialBtns.push(
                        <UikButton
                            className={cls.btnPost}
                            key={idx}
                            onClick={
                                () => {

                                    this.checkAccountRestrictions({
                                        SocialMediaTypeName: key,
                                        cb: (errors) => {
                                            if (errors.length) {
                                                errors.forEach(error => {
                                                    gHelpers.notif('error', { title: `${key} error!`, msg: error.Message });
                                                })
                                            } else {
                                                this.props.newOutingSelectedSocialMediaAccounts(socialMediaAccounts[0].SocialMediaTypeID)
                                            }
                                        }
                                    })

                                }
                            }
                            clear
                            icon={
                                <>
                                    {socialIcons(key, connected)}
                                </>
                            }
                            iconOnly />
                    )
                });
            }
        }

        if (isModalSelectSocialMediaAccountOpen) {
            modals.push(
                <ModalSelectSocialAccount
                    key={'modal-ssa'}
                    modalProps={{
                        modalIsOpen: isModalSelectSocialMediaAccountOpen,
                        handleClose: () => this.setState({ isModalSelectSocialMediaAccountOpen: false }),
                        headerText: gHelpers.getLabel({ key: 61153, fallback: `Select which account` }),
                        headerSubText: gHelpers.getLabel({ key: 61159, fallback: `Choose a social media account to post` }),
                        headerCentered: true
                    }}
                    selectSocialMediaAccountsByType={selectSocialMediaAccountsByType}
                    selectedType={this.state.selectedType}
                />
            );
        }

        return <>
            {
                this.state.loading
                    ? loading('sm')
                    :
                    <>
                        <UikButtonGroup className={cls.btnGroup}>{socialBtns}</UikButtonGroup>
                        {modals}
                    </>
            }
        </>;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OutingPostIcons);