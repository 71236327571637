import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    UikWidget,
    UikWidgetContent,
    UikButton
} from '../../../@uik';
import { mediaFileLibraryActions } from '../../../redux/actions/mediaFileLibrary';
import MediaLibraryComp from '../index';
import Modal from 'react-modal';
import cls from '../mediaLibrary.module.scss';
import ModalGeneric from '../../Modal';
import { getLabel } from 'helpers';

Modal.setAppElement('#root');

const customStyles = {
    content: {
        position: 'relative',
        left: '0',
        right: '0',
        maxWidth: '90%',
        width: '1060px',
        paddingBottom: '0',
        paddingTop: '0',
    }
}
const mapStateToProps = state => ({
    mediaFileLibary: {
        selectedFiles: state.selectedMediaFiles
    }
});
const mapDispatchToProps = dispatch => ({
    updateSelectedMediaFilesAction: (status) => dispatch(mediaFileLibraryActions.updateSelectedMediaFiles(status)),
    removeAllSelectedMediaFilesAction: (status) => dispatch(mediaFileLibraryActions.removeAllSelectedMediaFiles(status)),
});

class ModalSelectFiles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isUploading: false,
        }
        this.handleRemoveAllSelected = this.handleRemoveAllSelected.bind(this);
        this.handleUploadingStatus = this.handleUploadingStatus.bind(this);
    }

    handleRemoveAllSelected(e) {
        e.preventDefault();
        if (this.props.fieldGenerator) {
            this.props.removeAllSelectedMediaFilesAction({ keyName: this.props.fieldGenerator.name });

            this.props.fieldGenerator.handleDoneSelecting(this.props.fieldGenerator.name, JSON.stringify([]));
        }
    }

    handleUploadingStatus(cond) {
        this.setState({
            isUploading: cond,
        })
    }

    render() {
        const { modalIsOpen } = this.props;
        return (
            <ModalGeneric
                headerText={'Select Media Files'}
                isOpen={modalIsOpen}
                modalIsOpen={modalIsOpen}
                onRequestClose={!this.state.isUploading && this.props.handleClose}
                handleClose={!this.state.isUploading && this.props.handleClose}//modal pops out when clicked outside
                customStyles={customStyles}
                fullHeight={true}
                modalFooterComponent=
                {<div className={`${cls.modalSelectFilesFooter} text-right`}>
                    <UikButton onClick={this.handleRemoveAllSelected} className={`mr-2`}>{getLabel({ key: 1377, fallback: `Deselect All` })}</UikButton>
                    <UikButton onClick={() => {
                        this.props.handleDoneSelecting();
                    }} primary>{getLabel({ key: 1378, fallback: `Done` })}</UikButton>
                </div>}
            >
                <UikWidget>
                    <UikWidgetContent className={cls.modalSelectFilesWidgetContent}>
                        <MediaLibraryComp
                            type='selectFileDialog'
                            handleUploadingStatus={this.handleUploadingStatus}
                            fieldGenerator={
                                { ...this.props.fieldGenerator }
                            }
                            containerWidth={customStyles.content.width}
                        />
                    </UikWidgetContent>
                </UikWidget>
            </ModalGeneric>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalSelectFiles);