import { getWeek } from './index';

var CalendarProcessor = function (o) {
    //Store div id
    this.divId = o.ParentID;

    // Days of week, starting on Sunday
    this.DaysOfWeek = o.DaysOfWeek;
    // Months, stating on January
    this.Months = o.Months;
    // Set the current month, year
    var d = new Date();
    this.CurrentMonth = d.getMonth();
    this.CurrentYear = d.getFullYear();
    this.CurrentDate = d.getDate();
    this.Date = d;

    var f = o.Format;

    if (typeof (f) == 'string') {
        this.f = f.charAt(0).toUpperCase();
    } else {
        this.f = 'M';
    }

};

// Goes to month
CalendarProcessor.prototype.goCalendar = function (params) {
    this.CurrentMonth = params.m;
    this.CurrentYear = params.y;
    return this.showCurrent();
};

// Goes to next month
CalendarProcessor.prototype.nextMonth = function () {
    if (this.CurrentMonth >= 11) {
        this.CurrentMonth = 0;
        this.CurrentYear = this.CurrentYear + 1;
    } else {
        this.CurrentMonth = this.CurrentMonth + 1;
    }
    return this.showCurrent();
};

// Goes to previous month
CalendarProcessor.prototype.previousMonth = function () {
    if (this.CurrentMonth <= 0) {
        this.CurrentMonth = 11;
        this.CurrentYear = this.CurrentYear - 1;
    } else {
        this.CurrentMonth = this.CurrentMonth - 1;
    }
    return this.showCurrent();
};

// 
CalendarProcessor.prototype.previousYear = function () {
    this.CurrentYear = this.CurrentYear - 1;
    return this.showCurrent();
}

// 
CalendarProcessor.prototype.nextYear = function () {
    this.CurrentYear = this.CurrentYear + 1;
    return this.showCurrent();
}

// Show current month
CalendarProcessor.prototype.showCurrent = function () {
    return this.CalendarProcessor(this.CurrentYear, this.CurrentMonth);
};

// 
CalendarProcessor.prototype.previousWeek = function () {
    this.Date = new Date(this.Date.getFullYear(), this.Date.getMonth(), this.Date.getDate() - 7);
    ;
    return this.showCurrentWeek();
}

// 
CalendarProcessor.prototype.nextWeek = function () {
    this.Date = new Date(this.Date.getFullYear(), this.Date.getMonth(), this.Date.getDate() + 7);
    return this.showCurrentWeek();
}

// Show current week
CalendarProcessor.prototype.showCurrentWeek = function () {
    return this.CalendarProcessorWeek(this.Date);
};

// Show month (year, month)
CalendarProcessor.prototype.CalendarProcessor = function (y, m) {
    var returnData = {
        days: [],
        current: {
            day: this.CurrentDate,
            month: this.Months[this.CurrentMonth],
            monthIndex: this.CurrentMonth,
            year: this.CurrentYear,
            Date: new Date(this.CurrentYear, this.CurrentMonth, this.CurrentDate)
        }
    }

    var check1 = typeof (y) == 'number' ? this.CurrentYear = y : null;
    var check2 = typeof (y) == 'number' ? this.CurrentMonth = m : null;

    // 1st day of the selected month
    var firstDayOfCurrentMonth = new Date(y, m, 1).getDay();
    // console.log(`firstDayOfCurrentMonth`, firstDayOfCurrentMonth);

    // Last date of the selected month
    var lastDateOfCurrentMonth = new Date(y, m + 1, 0).getDate();
    // console.log(`lastDateOfCurrentMonth`, lastDateOfCurrentMonth);

    // Last day of the previous month
    var lastDateOfLastMonth = m == 0 ? new Date(y - 1, 11, 31).getDate() : new Date(y, m, 0).getDate();
    // console.log(`lastDateOfLastMonth`, lastDateOfLastMonth);


    var dm;
    var p = dm = this.f == 'M' ? 1 : firstDayOfCurrentMonth == 0 ? -5 : 2;
    var cellvalue;

    for (var d, i = 0, z0 = 0; z0 < 6; z0++) {
        for (var z0a = 0; z0a < 7; z0a++) {
            d = i + dm - firstDayOfCurrentMonth;

            // Dates from prev month
            if (d < 1) {
                cellvalue = lastDateOfLastMonth - firstDayOfCurrentMonth + p++;
                const mIndex = m - 1 < 0 ? this.Months.length - 1 : m - 1;

                // check if back to january then add year - 1
                const year = mIndex === this.Months.length - 1 ? y - 1 : y;
                
                returnData.days.push({
                    dayNumber: cellvalue,
                    month: this.Months[mIndex],
                    monthNumber: mIndex + 1,
                    year: year,
                    formattedDate: `${year}-${mIndex + 1}-${cellvalue}`
                });

                // Dates from next month
            } else if (d > lastDateOfCurrentMonth) {
                const dayVal = p++;
                const mIndex = m + 1 > this.Months.length - 1 ? 0 : m + 1;

                // check if back to january then add year + 1
                const year = mIndex === 0 ? y + 1 : y;

                returnData.days.push({
                    dayNumber: dayVal,
                    month: this.Months[mIndex],
                    monthNumber: mIndex + 1,
                    year: year,
                    formattedDate: `${year}-${mIndex + 1}-${dayVal}`
                });

                // Current month dates
            } else {
                const mIndex = m;

                const dayObject = {
                    dayNumber: d,
                    month: this.Months[mIndex],
                    monthNumber: mIndex + 1,
                    year: y,
                    formattedDate: `${y}-${mIndex + 1}-${d}`
                };

                // check current date
                const cd1 = new Date(y, mIndex, d);
                const cd2 = new Date();

                if (`${cd1.getDate()} ${cd1.getMonth()} ${cd1.getFullYear()}` == `${cd2.getDate()} ${cd2.getMonth()} ${cd2.getFullYear()}`) {
                    dayObject.current = true;
                }

                returnData.days.push(dayObject);

                p = 1;

            }

            if (i % 7 == 6 && d >= lastDateOfCurrentMonth) {

                z0 = 10; // no more rows
            }

            i++;
        }
    }

    return returnData;
};

CalendarProcessor.prototype.CalendarProcessorWeek = function (thisDate) {
    var returnData = {
        days: [],
        current: {
            day: thisDate.getDate(),
            month: this.Months[thisDate.getMonth()],
            monthIndex: thisDate.getMonth(),
            year: thisDate.getFullYear(),
            Date: thisDate
        }
    }
    
    const week = getWeek(thisDate);
    week.forEach(day => {
        const mIndex = day.getMonth();

        returnData.days.push({
            dayNumber: day.getDate(),
            month: this.Months[mIndex],
            monthNumber: mIndex + 1,
            year: this.CurrentYear,
            formattedDate: `${this.CurrentYear}-${mIndex + 1}-${day.getDate()}`
        });
    });
    
    return returnData;
};

export default CalendarProcessor;