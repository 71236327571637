// Account Fields
import React from 'react';
import FieldsGenerator from 'helpers/fieldGenerator';
import { gHelpers, callApi, localToObject, loading } from 'helpers';
import cls from '../style.module.scss';

class Step1Account extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            accountFields: [],
        }

        this._isMounted = false;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;

        callApi({
            passObject: {
                'Module': 'media_schedule_getuser',
                'Parameters': {
                    'UserID': localToObject('UserDetails').User.UserID,
                }
            },
            success: (response) => {
                if (response.Data.MediaScheduleUser && response.Data.MediaScheduleUser.length) {
                    this._isMounted && this.setState({
                        accountFields: response.Data.MediaScheduleUser.filter(f => ['EmailAddress', 'Password'].includes(f.AttributeName))
                    })
                }
            },
            unSuccess: (response) => {
                gHelpers.notif('error', { msg: response.Status.Message });
            }
        });
    }

    render() {
        const { accountFields } = this.state;

        return (
            <div className={cls.stepContent}>
                {
                    accountFields.length
                        ? <FieldsGenerator
                            fields={{
                                fields: accountFields,
                                excluded: []
                            }}
                            settings={{
                                updateByRID: true,
                                socketEnabled: false,
                                overrideFields: {
                                    'EmailAddress': {
                                        IsReadOnly: true
                                    }
                                }
                            }}
                        /> : null
                }
            </div>
        )
    }
}

export default Step1Account;