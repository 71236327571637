import React, { Component } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import PropTypes from 'prop-types';

import {
    UikHeadline,
    UikHeadlineDesc,
    UikFormInputGroup,
    UikButton
} from '../@uik';
import cls from './confirmDialog.module.scss';

const getLabel = (data) => {
    let lang;
    if (localStorage.getItem('lang') !== null && localStorage.getItem('lang') !== 'undefined') {
        lang = localStorage.getItem('lang');
        const langObj = JSON.parse(lang);
        if (langObj) {
            return langObj.Labels[data.key] && langObj.Labels[data.key].Value
                ? langObj.Labels[data.key].Value
                : data.fallback
                    ? data.fallback
                    : 'N/A';
        }
    } else {
        return data.fallback ? data.fallback : 'N/A';
    }

}

class ModalConfirm extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
        content: PropTypes.element,
        submitBtn: PropTypes.object,
        cancelBtn: PropTypes.object,
        onClose: PropTypes.func,
        onSubmit: PropTypes.func
    }

    static defaultProps = {
        title: 'Confirm Action',
        desc: 'Are you sure you want to do this?',
        submitBtn: {
            text: 'Confirm',
            props: {
                primary: true
            }
        },
        cancelBtn: {
            text: getLabel({ key: 1373, fallback: `Cancel` }),
            props: {}
        },
    }

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.setState({
            isOpen: true
        });
    }


    handleClose() {
        if (this.props.onClose) {
            this.props.onClose();
        }

        removeDialog();
    }

    handleClickOverlay = e => {
        const isClickOutside = e.target === this.overlay;

        if (isClickOutside) {
            this.handleClose();
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.props.onSubmit) {
            this.props.onSubmit();
        }

        this.handleClose();
    }

    render() {

        let otherContainerProps = this.props.otherContainerProps ? this.props.otherContainerProps : {};
        let otherMainBodyProps = this.props.otherMainBodyProps ? this.props.otherMainBodyProps : {};

        return (
            <div
                className='soc-modal-dialog'
                ref={dom => (this.overlay = dom)}
                onClick={this.handleClickOverlay}
                {...otherContainerProps}
            >
                <div className='soc-modal-dialog-body text-center' {...otherMainBodyProps}>
                    <a to='#' className={`btnClose ${cls.btnClose}`} onClick={this.handleClose.bind(this)}>
                        <i className={`${cls.iconClose} fas fa-times`} />
                    </a>
                    <UikHeadline>
                        {this.props.title}
                    </UikHeadline>
                    <UikHeadlineDesc>
                        {this.props.desc}
                    </UikHeadlineDesc>
                    {
                        this.props.content
                            ? <div>{this.props.content}</div>
                            : null
                    }
                    <UikFormInputGroup className={cls.btnGroup} direction="horizontal">
                        <UikButton
                            onClick={this.handleClose.bind(this)}
                            className={cls.btnCancel}
                            {...this.props.cancelBtn.props}>
                            {this.props.cancelBtn.text}
                        </UikButton>
                        {
                            this.props.addonBtns
                                ? this.props.addonBtns({
                                    _events: {
                                        handleClose: this.handleClose.bind(this)
                                    }
                                })
                                : null
                        }
                        {
                            this.props.onSubmit
                                ? <UikButton
                                    onClick={this.handleSubmit.bind(this)}
                                    className={cls.btnSubmit}
                                    {...this.props.submitBtn.props}>
                                    {this.props.submitBtn.text}
                                </UikButton>
                                : null
                        }
                    </UikFormInputGroup>
                </div>
            </div>
        );
    }
}

function removeDialog() {
    document.body.classList.remove('soc-modal-dialog');
    const target = document.getElementById('react-confirm-dialog');
    unmountComponentAtNode(target)
    target.parentNode.removeChild(target)
}

export default function confirmDialog(props) {

    let divTarget = document.getElementById('react-confirm-dialog');

    if (divTarget) {
        render(<ModalConfirm {...props} />, divTarget);
    } else {
        divTarget = document.createElement('div')
        divTarget.id = 'react-confirm-dialog'
        document.body.appendChild(divTarget);
        render(<ModalConfirm {...props} />, divTarget);
    }
}